import {
  AggregateShipmentIssueDocument,
  ShipmentIssuesQuery,
  useShipmentIssuesLazyQuery,
  useShipmentIssuesQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { dateToString } from '../../utils/stringFunctions';
import DataTable from '../common/DataTable';
import DateRangeFilter from '../common/DateRangeFilter';
import DownloadCsvButton from '../common/DownloadCsvButton';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';

export const shipmentIssueColumns: Columns<
  Paths<ObjectFromArray<NonNullable<ShipmentIssuesQuery['shipmentIssues']>>>
> = [
  {
    header: 'TR No.',
    accessor: 'transactionNumber',
  },
  {
    header: 'Date Received',
    accessor: 'dateReceived',
    Cell: (children?: Date) => dateToString(children),
  },
  {
    header: 'Supplier',
    accessor: 'supplierName',
  },
  {
    header: 'Part Internal Code',
    accessor: 'internalCode',
  },
  {
    header: 'Part Item Name',
    accessor: 'partItemName',
  },
  {
    header: 'Mother Unit Item Name',
    accessor: 'motherUnitItemName',
  },
  {
    header: 'Quantity',
    accessor: 'quantity',
  },
  {
    header: 'Remaining Quantity',
    accessor: 'remainingBalance',
  },
  {
    header: 'Remarks',
    accessor: 'remarks',
  },
];

const ShipmentIssuesPage = ({ title = 'Shipment Issues Tracker' }) => {
  const { page, perPage } = usePagination();
  const [searchQueryString, startDateQueryString, endDateQueryString] =
    useGetQueryString(['search', 'startDate', 'endDate']);

  const filters = {
    ...(typeof searchQueryString === 'string' && {
      searchTerm: searchQueryString,
    }),
    ...(typeof startDateQueryString === 'string' && {
      startDate: startDateQueryString,
    }),
    ...(typeof endDateQueryString === 'string' && {
      endDate: endDateQueryString,
    }),
  };
  const variables = {
    ...filters,
    skip: page * perPage - perPage,
    take: perPage,
  };
  const countVariables = {
    searchTerm: searchQueryString,
    startDate: startDateQueryString,
    endDate: endDateQueryString,
  };
  const {
    data: { shipmentIssues } = {},
    loading,
    error,
  } = useShipmentIssuesQuery({
    fetchPolicy: 'network-only',
    variables,
  });

  const [
    getDownloadData,
    { data: downloadData, loading: downloadDataLoading },
  ] = useShipmentIssuesLazyQuery({
    variables: {
      ...filters,
    },
  });

  return (
    <>
      <ListView
        loading={loading}
        error={error}
        title={title}
        miscButtons={
          <>
            <DownloadCsvButton
              downloadData={downloadData?.shipmentIssues}
              getDownloadData={getDownloadData}
              downloadDataLoading={downloadDataLoading}
              columns={shipmentIssueColumns}
            />
          </>
        }
        filters={
          <>
            <DateRangeFilter />
            <Search />
          </>
        }
        dataTable={
          <DataTable data={shipmentIssues} columns={shipmentIssueColumns} />
        }
        pagination={
          <Pagination
            query={AggregateShipmentIssueDocument}
            perPage={perPage}
            page={Number(page)}
            variables={countVariables}
          />
        }
      />
    </>
  );
};

export default ShipmentIssuesPage;
