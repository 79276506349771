import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  useProformaInvoiceQuery,
  useUpdateProformaInvoiceMutation,
} from '../../graphql/generated/types';
import { cleanPayload } from '../../utils/apolloClient';
import {
  getItemsForDelete,
  getItemsForUpsert,
  getUploads,
  prismaCreate,
  prismaUpdate,
} from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Loading from '../common/Loading';
import Meta from '../page/Meta';
import ProformaInvoiceForm from './ProformaInvoiceForm';
import { schema, FormData } from './utils';

const UpdateProformaInvoicePage = () => {
  const title = `Update Proforma Invoice`;
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({
        proformaInvoiceItems,
        purchaseOrders,
        documentUrls,
        supplier,
        ...data
      }) => {
        const proformaInvoiceItemsToDelete = getItemsForDelete(
          proformaInvoice?.proformaInvoiceItems,
          proformaInvoiceItems
        );
        const proformaInvoiceItemsToUpsert =
          getItemsForUpsert(proformaInvoiceItems);

        await updateProformaInvoice({
          variables: {
            where: {
              id,
            },
            data: {
              ...prismaUpdate(data),
              documentUrlsUploads: getUploads(documentUrls),
              purchaseOrders: {
                set: purchaseOrders?.map((purchaseOrder) => {
                  return {
                    id: purchaseOrder.id,
                  };
                }),
              },
              supplier: {
                connect: {
                  id: supplier.id,
                },
              },
              proformaInvoiceItems: {
                delete: proformaInvoiceItemsToDelete,
                upsert: proformaInvoiceItemsToUpsert.map(
                  ({ id, ...proformaInvoiceItem }) => {
                    const { itemName, item, subtotal, internalCode, ...data } =
                      proformaInvoiceItem;
                    const itemNameData = itemName || item?.name;
                    return {
                      where: {
                        id,
                      },
                      update: {
                        ...prismaUpdate(data),
                        item: {
                          connect: { name: itemNameData },
                        },
                      },
                      create: {
                        ...prismaCreate(data),
                        item: {
                          connect: { name: itemNameData },
                        },
                      },
                    };
                  }
                ),
              },
            },
          },
        });
      },
      (error) =>
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        })
    )(e);
  };

  const {
    data: { proformaInvoice } = {},
    loading,
    error,
  } = useProformaInvoiceQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: { id },
    },
  });

  const [updateProformaInvoice, { loading: mutationLoading }] =
    useUpdateProformaInvoiceMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(-1);
      },
    });

  useEffect(() => {
    if (proformaInvoice) {
      methods.reset({ ...cleanPayload(proformaInvoice) });
    }
  }, [proformaInvoice, methods]);

  if (loading) return <Loading />;

  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <ProformaInvoiceForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
        />
      </FormProvider>
    </>
  );
};

export default UpdateProformaInvoicePage;
