import { ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';

type MetaProps = JSX.IntrinsicElements['meta'];
interface Props {
  children?: ReactNode;
  pageTitle: string;
  description?: string;
  meta?: MetaProps[];
}

const Meta = ({ pageTitle, description, meta = [] }: Props) => {
  return (
    <Helmet
      htmlAttributes={{ lang: 'en' }}
      title={`${pageTitle ? pageTitle + ' | ' : ''}${
        process.env.REACT_APP_WEBSITE_NAME
      }`}
      meta={[{ name: `description`, content: description }, ...meta]}
    />
  );
};

export default Meta;
