export enum Department {
  "Administration" = "ADMINISTRATION",
  "Human Resources" = "HUMAN_RESOURCES",
  "Sales" = "SALES",
  "Marketing" = "MARKETING",
  "Service" = "SERVICE",
  "Inventory" = "INVENTORY",
  "Logistics" = "LOGISTICS",
  "Other 1" = "OTHER_1",
  "Other 2" = "OTHER_2",
  "Warehouse" = "WAREHOUSE",
}
