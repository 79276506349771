import { array, date, InferType, mixed, number, object, string } from 'yup';
import { Currency } from '../../graphql/enums/Currency';



export const schema = object({
  date: date().required(),
  invoiceNumber: string().required(),
  origin: string().required(),
  destination: string().required(),
  currency: mixed<Currency>()
  .oneOf(Object.values(Currency))
  .required(),
  supplier: object().nullable().default(null).required(),

  vessel: string().nullable(),
  remarks: string().nullable(),
  madeIn: string().nullable(),
  packingLists: array().of(object({
    id: number().required(),
    number: string().required(),
    packingListItems: array(),
  })).required().min(1),
  bocInvoiceItems: array().of(object({
    packingListItem: object().nullable().default(null).required(),
    unitPrice: number().required(),
  })),
  documentUrls: array(mixed()).defined()
}).required();

export type FormData = InferType<typeof schema>