import React, { useEffect } from 'react';
import queryString from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';
import MUIPagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { DocumentNode, useQuery } from '@apollo/client';

type Props = {
  query: DocumentNode;
  variables?: {};
  page: number;
  perPage: number;
  skip?: boolean;
  sx?: {};
};
const Pagination = ({ query, variables, page, perPage, skip, sx }: Props) => {
  let navigate = useNavigate();
  let location = useLocation();
  const paramsObj = queryString.parse(location.search);
  const [currentPage, setCurrentPage] = React.useState(page);
  const [count, setCount] = React.useState<number | null>(null);
  const { data, loading, error } = useQuery(query, {
    fetchPolicy: 'network-only',
    variables,
    skip,
  });

  useEffect(() => {
    if (data) {
      setCount(
        Math.ceil(data[Object.keys(data)[0]]['_count']['_all'] / perPage)
      );
    }
  }, [data, perPage]);

  useEffect(() => {
    if (count && page > count) {
      navigate({
        pathname: `${location.pathname}`,
        search: queryString.stringify({ ...paramsObj, page: count }),
      });
    }
  }, [count, page, location.pathname, navigate, paramsObj]);

  if (error || loading || !count) return null;

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);

    navigate({
      pathname: `${location.pathname}`,
      search: queryString.stringify({ ...paramsObj, page: value }),
    });
  };

  return (
    <Stack spacing={2} sx={{ mt: 'auto', pt: 2, mx: 'auto', ...sx }}>
      <MUIPagination
        count={count}
        page={currentPage}
        onChange={handleChange}
        color="secondary"
      />
    </Stack>
  );
};

export default Pagination;
