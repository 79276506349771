import { SnackbarCloseReason } from '@mui/material';
import React, { ReactNode, useState } from 'react';

type Severity = 'error' | 'warning' | 'info' | 'success';
interface Props {
  children?: ReactNode;
}
interface HandleSnackbarOpenArgs {
  message: ReactNode;
  severity: Severity;
}
interface ContextState {
  // set the type of state you want to handle with context e.g.
  handleSnackbarOpen: ({ message, severity }: HandleSnackbarOpenArgs) => void;
  handleSnackbarClose: (
    event: Event | React.SyntheticEvent<Element, Event>,
    reason?: SnackbarCloseReason
  ) => void;
  snackbarOpen: boolean;
  snackbarSeverity: Severity;
  snackbarMessage: ReactNode;
}

const SnackbarContext = React.createContext({} as ContextState);
SnackbarContext.displayName = 'SnackbarContext';

const SnackbarProvider = ({ children }: Props) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<ReactNode | null>(
    null
  );

  const [snackbarSeverity, setSnackbarSeverity] = useState<Severity>('success');

  const handleSnackbarOpen: ContextState['handleSnackbarOpen'] = ({
    message,
    severity,
  }) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  const handleSnackbarClose: ContextState['handleSnackbarClose'] = (
    event,
    reason
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };
  return (
    <SnackbarContext.Provider
      value={{
        handleSnackbarOpen,
        handleSnackbarClose,
        snackbarOpen,
        snackbarSeverity,
        snackbarMessage,
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};

const useSnackbarContext = () => {
  return React.useContext(SnackbarContext);
};

export { SnackbarProvider, useSnackbarContext };
