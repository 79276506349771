import { Navigate, Outlet } from 'react-router-dom';

interface Props {
  user: {} | undefined | null;
  redirectPath?: string;
  children?: React.ReactNode;
}
const ProtectedRoute = ({ user, redirectPath = '/home' }: Props) => {
  if (!user) return <Navigate to={redirectPath} replace />;
  return <Outlet />;
};

export default ProtectedRoute;
