import React, { ReactElement, ReactNode, useState } from 'react';

interface Props {
  children?: ReactNode;
}

interface ContextState {
  // set the type of state you want to handle with context e.g.
  dialogOpen: boolean;
  dialogComponent: ReactNode;
  handleDialogOpen: (component: ReactElement) => void;
  handleDialogClose: () => void;
}

const DialogContext = React.createContext({} as ContextState);

DialogContext.displayName = 'DialogContext';

const DialogProvider = ({ children }: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogComponent, setDialogComponent] = useState(<></>);

  const handleDialogOpen = (component = <></>) => {
    setDialogComponent(component);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  return (
    <DialogContext.Provider
      value={{
        dialogOpen,
        dialogComponent,
        handleDialogOpen,
        handleDialogClose,
      }}
    >
      {children}
    </DialogContext.Provider>
  );
};

const useDialogContext = () => {
  return React.useContext(DialogContext);
};

export { DialogProvider, useDialogContext };
