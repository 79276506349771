import { Box, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import {
  ShipmentReportContainerType,
  useShipmentReportQuery,
} from '../../graphql/generated/types';
import { roundFloat } from '../../utils/numberFunctions';
import { dateToString, getEnumKeyFromValue } from '../../utils/stringFunctions';
import DataTable from '../common/DataTable';
import Loading from '../common/Loading';
import Meta from '../page/Meta';
import {
  missingItemsColumns,
  putToUnitItemsColumns,
  shipmentReportItemsColumns,
} from './ShipmentReportsPage';

const PrintShipmentReportPage = () => {
  const title = `Print Shipment Report`;
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');

  const {
    data: { shipmentReport } = {},
    loading,
    error,
  } = useShipmentReportQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: { id },
    },
  });

  const totalNumberOfBoxes =
    shipmentReport?.shipmentReportItems.reduce((acc, cur) => {
      if (!cur.numberOfBoxesReceived) return acc;
      return acc + cur.numberOfBoxesReceived;
    }, 0) || 0;
  if (loading) return <Loading />;

  return (
    <>
      <Meta pageTitle={title} />
      <Typography
        variant="h3"
        align="center"
        sx={{
          textTransform: 'uppercase',
          fontWeight: 'bold',
          color: (theme) => theme.palette.common.black,
        }}
      >
        Megafresh International Inc.
      </Typography>
      <Typography
        variant="h5"
        align="center"
        sx={{
          textTransform: 'uppercase',
          color: (theme) => theme.palette.common.black,
        }}
        gutterBottom
      >
        Shipment Report Form
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          columnGap: 5,
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            columnGap: 2,
            rowGap: 1,
            gridAutoRows: 'min-content',
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Transaction No.:
          </Typography>
          <Typography variant="body1">
            {shipmentReport?.transactionNumber}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Purchase Order Nos.:
          </Typography>
          <Typography variant="body1">
            {shipmentReport?.purchaseOrders
              .map(({ number }) => number)
              .join(', ')}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Supplier:
          </Typography>
          <Typography variant="body1">
            {shipmentReport?.supplier?.name}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Date Received:
          </Typography>
          <Typography variant="body1">
            {dateToString(shipmentReport?.dateReceived)}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Check In:
          </Typography>
          <Typography variant="body1">
            {shipmentReport?.checkIn &&
              format(new Date(shipmentReport?.checkIn), 'h:mm a')}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Check Out:
          </Typography>
          <Typography variant="body1">
            {shipmentReport?.checkOut &&
              format(new Date(shipmentReport?.checkOut), 'h:mm a')}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            No. of Boxes Received:
          </Typography>
          <Typography variant="body1">
            {shipmentReport?.numberOfBoxesReceived}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            columnGap: 2,
            rowGap: 1,
            gridAutoRows: 'min-content',
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Container Type.:
          </Typography>
          <Typography variant="body1">
            {getEnumKeyFromValue(
              ShipmentReportContainerType,
              shipmentReport?.shipmentReportContainerType
            )}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Origin:
          </Typography>
          <Typography variant="body1">
            {shipmentReport?.shipmentReportOrigin}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Destination:
          </Typography>
          <Typography variant="body1">
            {shipmentReport?.shipmentReportDestination}
          </Typography>{' '}
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Packing List:
          </Typography>
          <Typography variant="body1">
            {shipmentReport?.packingList?.number}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Employees:
          </Typography>
          <Typography variant="body1">
            {shipmentReport?.employees
              .map(({ firstName, lastName }) => `${firstName} ${lastName}`)
              .join(', ')}
          </Typography>
        </Box>
      </Box>
      <Typography
        variant="h5"
        sx={{
          color: (theme) => theme.palette.common.black,
          mt: 2,
        }}
        gutterBottom
      >
        Shipment Report Items
      </Typography>
      <DataTable
        data={shipmentReport?.shipmentReportItems}
        columns={shipmentReportItemsColumns}
        tableSummary={
          shipmentReport?.shipmentReportItems && (
            <TableRow>
              <TableCell colSpan={2} align="center">
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Total No. of Boxes:
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {roundFloat(totalNumberOfBoxes)}
                </Typography>
              </TableCell>
            </TableRow>
          )
        }
      />
      <Typography
        variant="h5"
        sx={{
          color: (theme) => theme.palette.common.black,
          mt: 2,
        }}
        gutterBottom
      >
        Missing Items
      </Typography>
      <DataTable
        data={shipmentReport?.missingItems}
        columns={missingItemsColumns}
      />
      <Typography
        variant="h5"
        sx={{
          color: (theme) => theme.palette.common.black,
          mt: 2,
        }}
        gutterBottom
      >
        Put to Unit Items
      </Typography>
      <DataTable
        data={shipmentReport?.putToUnits}
        columns={putToUnitItemsColumns}
      />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          columnGap: 5,
          mt: 2,
          color: (theme) => theme.palette.common.black,
        }}
      >
        <Stack direction="row" spacing={1}>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }} color="inherit">
            Prepared By:
          </Typography>
          <Typography variant="h5" color="inherit">
            {shipmentReport?.createdBy}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }} color="inherit">
            Checked By:
          </Typography>
          <Typography variant="h5" color="inherit">
            {shipmentReport?.checkedBy}
          </Typography>
        </Stack>
      </Box>
    </>
  );
};

export default PrintShipmentReportPage;
