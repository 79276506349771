import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import {
  ShipmentPreCostingItem,
  ShipmentPreCostingsDocument,
} from '../../graphql/generated/types';
import { gradientButton } from '../../styles/gradientButton';
import { nestedGrid } from '../../styles/nestedGrid';
import { FormProps } from '../../types/FormProps';
import { roundFloat } from '../../utils/numberFunctions';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import { dateToString } from '../../utils/stringFunctions';
import FormContainer from '../common/FormContainer';
import SearchAsYouTypeAutocomplete from '../common/SearchAsYouTypeAutocomplete';
import { FormData } from './utils';

const ShipmentCostForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
  type,
}: FormProps) => {
  const { control, setValue } = useFormContext<FormData>();
  const { fields } = useFieldArray({
    control,
    name: 'shipmentCostItems',
  });
  const [lastUpdatedAt, shipmentCostItems, shipmentPreCosting] = useWatch({
    name: ['lastUpdatedAt', 'shipmentCostItems', 'shipmentPreCosting'],
    control,
  });
  return (
    <FormContainer maxWidth="lg">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
        gutterBottom
      >
        {title}
      </Typography>
      {lastUpdatedAt && (
        <Typography variant="subtitle2" gutterBottom>
          Last updated at:{' '}
          {format(new Date(lastUpdatedAt), 'MM/dd/yyyy hh:mm aa')}
        </Typography>
      )}
      <Box component="form" onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 1,
          }}
        >
          <Controller
            name="shipmentPreCosting"
            control={control}
            defaultValue={null}
            render={({ field, fieldState }) => (
              <SearchAsYouTypeAutocomplete
                textFieldProps={{
                  ...textFieldHelperProps(field, fieldState, schema),
                  label: 'Proforma Invoice No.',
                }}
                queryOptions={(value) => {
                  return {
                    query: ShipmentPreCostingsDocument,
                    variables: {
                      where: {
                        shipmentCost: null,
                        OR: [
                          {
                            proformaInvoice: {
                              is: {
                                number: {
                                  contains: value,
                                  mode: 'insensitive',
                                },
                              },
                            },
                          },
                        ],
                      },
                    },
                  };
                }}
                getOptionLabel={(option) => option?.proformaInvoice?.number}
                value={field.value}
                onChange={(e, value) => {
                  setValue('shipmentCostItems', [
                    ...value?.shipmentPreCostingItems.map(
                      (shipmentPreCostingItem: ShipmentPreCostingItem) => {
                        return {
                          shipmentPreCostingItem,
                          quantity:
                            shipmentPreCostingItem.proformaInvoiceQuantity,
                          unitCostInPhp: shipmentPreCostingItem.estimatedCost,
                        };
                      }
                    ),
                  ]);
                  field.onChange(value);
                }}
                disabled={type === 'update'}
              />
            )}
          />

          <Controller
            name="remarks"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Remarks"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <TextField
            label={`PO Nos`}
            fullWidth
            value={
              (shipmentPreCosting &&
                shipmentPreCosting?.purchaseOrders
                  ?.map(({ number }: { number: string }) => number)
                  .join(', ')) ??
              ''
            }
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            label={`Shipment Report Nos`}
            fullWidth
            value={
              (shipmentPreCosting &&
                shipmentPreCosting?.shipmentReports
                  ?.map(
                    ({ transactionNumber }: { transactionNumber: string }) =>
                      transactionNumber
                  )
                  .join(', ')) ??
              ''
            }
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Shipment Cost Items
        </Typography>
        <Stack direction="column" spacing={1}>
          {fields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <TextField
                    label={`Item Name ${index + 1}`}
                    fullWidth
                    value={
                      (shipmentCostItems &&
                        shipmentCostItems[index]?.shipmentPreCostingItem
                          ?.shipmentReportItem?.item?.name) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    label={`Internal Code ${index + 1}`}
                    fullWidth
                    value={
                      (shipmentCostItems &&
                        shipmentCostItems[index]?.shipmentPreCostingItem
                          ?.shipmentReportItem?.item?.internalCode) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />

                  <TextField
                    label={`Packing List Qty ${index + 1}`}
                    fullWidth
                    value={
                      (shipmentCostItems &&
                        shipmentCostItems[index]?.shipmentPreCostingItem
                          ?.packingListQuantity) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <Controller
                    name={`shipmentCostItems.${index}.quantity`}
                    control={control}
                    defaultValue={0}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`Quantity ${index + 1}`}
                        fullWidth
                        type="number"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name={`shipmentCostItems.${index}.freeQuantity`}
                    control={control}
                    defaultValue={0}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`Invoice Free Quantity ${index + 1}`}
                        fullWidth
                        type="number"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                      />
                    )}
                  />
                  <TextField
                    label={`Received Qty ${index + 1}`}
                    fullWidth
                    value={
                      (shipmentCostItems &&
                        Number(shipmentCostItems[index]?.quantity) +
                          Number(shipmentCostItems[index]?.freeQuantity)) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <Controller
                    name={`shipmentCostItems.${index}.unitCostInPhp`}
                    control={control}
                    defaultValue={0}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`Unit Cost in PHP ${index + 1}`}
                        fullWidth
                        type="number"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                      />
                    )}
                  />
                  <TextField
                    label={`Date Last Purchased ${index + 1}`}
                    fullWidth
                    value={
                      (shipmentCostItems &&
                        dateToString(
                          shipmentCostItems[index]?.shipmentPreCostingItem
                            ?.dateLastPurchased
                        )) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    label={`Change in Price ${index + 1}`}
                    fullWidth
                    value={
                      (shipmentCostItems &&
                        roundFloat(
                          shipmentCostItems[index]?.unitCostInPhp -
                            shipmentCostItems[index]?.shipmentPreCostingItem
                              ?.latestUnitCostInPhp
                        )) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <Controller
                    name={`shipmentCostItems.${index}.additionalCost`}
                    control={control}
                    defaultValue={0}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`Additional Cost ${index + 1}`}
                        fullWidth
                        type="number"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                      />
                    )}
                  />
                  <TextField
                    label={`Total Cost ${index + 1}`}
                    fullWidth
                    value={
                      (shipmentCostItems &&
                        Number(shipmentCostItems[index]?.unitCostInPhp) +
                          Number(shipmentCostItems[index]?.additionalCost)) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    label={`Computed Total Cost ${index + 1}`}
                    fullWidth
                    value={
                      (shipmentCostItems &&
                        roundFloat(
                          Number(
                            shipmentCostItems[index]?.shipmentPreCostingItem
                              ?.estimatedCost
                          ) + Number(shipmentCostItems[index]?.additionalCost)
                        )) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <Controller
                    name={`shipmentCostItems.${index}.remarks`}
                    control={control}
                    defaultValue=""
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`Remarks ${index + 1}`}
                        fullWidth
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                      />
                    )}
                  />
                </Box>
              </Box>
            );
          })}
        </Stack>

        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ mt: 3, ...gradientButton }}
          disabled={mutationLoading}
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default ShipmentCostForm;
