import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  useEmployeeQuery,
  useUpdateEmployeeMutation,
} from '../../graphql/generated/types';
import { cleanPayload } from '../../utils/apolloClient';
import {
  getItemsForDelete,
  getItemsForUpsert,
  getUploads,
  prismaCreate,
  prismaUpdate,
} from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Loading from '../common/Loading';
import Meta from '../page/Meta';
import EmployeeForm from './EmployeeForm';
import { FormData, schema } from './utils';

const UpdateEmployeePage = ({
  title = `Update Employee`,
  readOnly = false,
}) => {
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({ documentUrls, employeeChildren, ...data }) => {
        const childrenToDelete = getItemsForDelete(
          employee?.employeeChildren,
          employeeChildren
        );
        const childrenToUpsert = getItemsForUpsert(employeeChildren);
        await updateEmployee({
          variables: {
            where: {
              id,
            },
            data: {
              ...prismaUpdate(data),
              departments: {
                set: data.departments,
              },
              documentUrlsUploads: getUploads(documentUrls),
              employeeChildren: {
                delete: childrenToDelete,
                upsert: childrenToUpsert.map(({ id, ...child }) => {
                  return {
                    where: {
                      id,
                    },
                    update: {
                      ...prismaUpdate(child),
                    },
                    create: {
                      ...prismaCreate(child),
                    },
                  };
                }),
              },
              coordinator: data?.coordinator?.id
                ? {
                    connect: {
                      id: data?.coordinator.id,
                    },
                  }
                : {
                    disconnect: true,
                  },
            },
          },
        });
      },
      (error) =>
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        })
    )(e);
  };

  const {
    data: { employee } = {},
    loading,
    error,
  } = useEmployeeQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: { id },
    },
  });

  const [updateEmployee, { loading: mutationLoading }] =
    useUpdateEmployeeMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(-1);
      },
    });

  useEffect(() => {
    if (employee) {
      methods.reset({ ...cleanPayload(employee) });
    }
  }, [employee, methods]);

  if (loading) return <Loading />;

  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <EmployeeForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          readOnly={readOnly}
        />
      </FormProvider>
    </>
  );
};

export default UpdateEmployeePage;
