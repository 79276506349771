import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  useShipmentReportQuery,
  useUpdateShipmentReportMutation,
} from '../../graphql/generated/types';
import { cleanPayload } from '../../utils/apolloClient';
import {
  getItemsForDelete,
  getItemsForUpsert,
  getUploads,
  prismaCreate,
  prismaUpdate,
} from '../../utils/prisma';
import { capitalizeFirstLetter, fullName } from '../../utils/stringFunctions';
import Loading from '../common/Loading';
import Meta from '../page/Meta';
import ShipmentReportForm from './ShipmentReportForm';
import { schema, FormData } from './utils';
import { useAuthContext } from '../../contexts/authContext';

const UpdateShipmentReportPage = () => {
  const title = `Update Shipment Report`;
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const { currentUser } = useAuthContext();
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({
        purchaseOrders,
        employees,
        shipmentReportItems,
        missingItems,
        putToUnits,
        documentUrls,
        supplier,
        packingList,
        ...data
      }) => {
        const shipmentReportItemsToDelete = getItemsForDelete(
          shipmentReport?.shipmentReportItems,
          shipmentReportItems
        );
        const shipmentReportItemsToUpsert =
          getItemsForUpsert(shipmentReportItems);

        await updateShipmentReport({
          variables: {
            where: {
              id,
            },
            data: {
              ...prismaUpdate({
                ...data,
                lastUpdatedBy: currentUser?.employee
                  ? fullName(currentUser.employee)
                  : currentUser?.username || '',
              }),
              ...(packingList
                ? {
                    packingList: {
                      connect: {
                        id: packingList.id,
                      },
                    },
                  }
                : {
                    packingList: {
                      disconnect: true,
                    },
                  }),
              documentUrlsUploads: getUploads(documentUrls),
              purchaseOrders: {
                set: purchaseOrders?.map((purchaseOrder) => {
                  return {
                    id: purchaseOrder.id,
                  };
                }),
              },
              employees: {
                set: employees?.map((employee) => {
                  return {
                    id: employee.id,
                  };
                }),
              },
              supplier: {
                connect: {
                  id: supplier.id,
                },
              },
              shipmentReportItems: {
                delete: shipmentReportItemsToDelete,
                upsert: shipmentReportItemsToUpsert.map(
                  ({ id, ...shipmentReportItem }) => {
                    const {
                      item,
                      subtotal,
                      putToUnitsTotalQuantity,
                      finalQuantity,
                      internalCode,
                      ...data
                    } = shipmentReportItem;
                    return {
                      where: {
                        id,
                      },
                      update: {
                        ...prismaUpdate(data),
                        item: {
                          connect: { id: item?.id },
                        },
                      },
                      create: {
                        ...prismaCreate(data),
                        item: {
                          connect: { id: item?.id },
                        },
                      },
                    };
                  }
                ),
              },
            },
          },
        });
      },
      (error) =>
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        })
    )(e);
  };

  const {
    data: { shipmentReport } = {},
    loading,
    error,
  } = useShipmentReportQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: { id },
    },
  });

  const [updateShipmentReport, { loading: mutationLoading }] =
    useUpdateShipmentReportMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(-1);
      },
    });

  useEffect(() => {
    if (shipmentReport) {
      const { putToUnits, ...data } = shipmentReport;
      methods.reset({
        ...cleanPayload({
          ...data,
          checkIn: data?.checkIn && format(new Date(data.checkIn), 'HH:mm'),
          checkOut: data?.checkOut && format(new Date(data.checkOut), 'HH:mm'),
          putToUnits: putToUnits.map((putToUnit) => {
            return {
              part: putToUnit?.missingItem?.part,
              motherUnit: putToUnit?.missingItem?.motherUnit,
              shipmentReport: putToUnit?.missingItem?.shipmentReport,
              ...putToUnit,
            };
          }),
        }),
      });
    }
  }, [shipmentReport, methods]);

  if (loading) return <Loading />;

  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <ShipmentReportForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="update"
        />
      </FormProvider>
    </>
  );
};

export default UpdateShipmentReportPage;
