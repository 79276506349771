import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material';
import { ReactNode } from 'react';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { BaseSchema } from 'yup';
import { getEnumKeyFromValue } from '../../utils/stringFunctions';
import { isFieldRequired } from '../../utils/yup';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TEnum extends Record<string, any> = Record<string, any>
> extends UseControllerProps<TFieldValues, TName> {
  schema: BaseSchema;
  children?: ReactNode;
  enumObj: TEnum;
}

const EnumSelect = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TEnum extends Record<string, any> = Record<string, any>
>({
  schema,
  control,
  name,
  defaultValue,
  children,
  label,
  enumObj,
  readOnly,
  multiple = false,
  ...selectProps
}: Props<TFieldValues, TName, TEnum> & SelectProps) => {
  const labelId = `${name}-label`;
  const { field, fieldState } = useController({ name, control, defaultValue });
  return (
    <FormControl
      fullWidth
      error={!!fieldState.error?.message}
      required={isFieldRequired(schema, field.name)}
    >
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        inputProps={{
          readOnly: readOnly,
        }}
        multiple={multiple}
        labelId={labelId}
        label={label}
        renderValue={(selected: any): ReactNode => {
          if (!multiple) return getEnumKeyFromValue(enumObj, selected);
          return selected
            .map((value: any) => getEnumKeyFromValue(enumObj, value))
            .join(', ');
        }}
        {...field}
        {...selectProps}
      >
        {!multiple && (
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
        )}
        {Object.values(enumObj).map((value, idx) => {
          return (
            <MenuItem key={idx} value={value}>
              {multiple && (
                <Checkbox checked={(field.value?.indexOf(value) || 0) > -1} />
              )}
              {getEnumKeyFromValue(enumObj, value)}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText sx={{ m: 0 }}>{fieldState.error?.message}</FormHelperText>
    </FormControl>
  );
};

export default EnumSelect;
