import { Checkbox } from '@mui/material';
import { ReactNode } from 'react';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  AggregateSmItemCategoryDocument,
  QueryMode,
  SmItemCategoriesDocument,
  SmItemCategoryWhereInput,
  SortOrder,
  useDeleteManySmItemCategoryMutation,
  useSmItemCategoriesQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { HandleDelete } from '../../types/HandleDelete';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';

const SmItemCategoriesPage = ({ title = 'SM Item Categories' }) => {
  const { handleSnackbarOpen } = useSnackbarContext();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();
  const [searchQueryString] = useGetQueryString(['search']);

  const where: SmItemCategoryWhereInput = {
    ...(typeof searchQueryString === 'string' && {
      OR: [
        {
          departmentCode: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
        {
          itemCategoryCode: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
        {
          itemCategoryName: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
        {
          remarks: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
      ],
    }),
  };
  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        itemCategoryName: SortOrder.Asc,
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { smItemCategories } = {},
    loading,
    error,
  } = useSmItemCategoriesQuery({
    fetchPolicy: 'network-only',
    variables,
  });

  const [deleteManySmItemCategory] = useDeleteManySmItemCategoryMutation({
    refetchQueries: [
      { query: AggregateSmItemCategoryDocument, variables: countVariables },
      {
        query: SmItemCategoriesDocument,
        variables,
      },
    ],
    onError: (error) =>
      handleSnackbarOpen({
        message: 'Delete' + title.toLowerCase() + ' error! ' + error,
        severity: 'error',
      }),
  });

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof smItemCategories>>>
  > = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },

    {
      header: 'Department Code',
      accessor: 'departmentCode',
    },
    {
      header: 'Item Category Code',
      accessor: 'itemCategoryCode',
    },
    {
      header: 'Item Category Name',
      accessor: 'itemCategoryName',
    },
    {
      header: 'Remarks',
      accessor: 'remarks',
    },
  ];

  const handleDelete: HandleDelete = (ids) => {
    deleteManySmItemCategory({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <>
      <ListView
        loading={loading}
        error={error}
        title={title}
        actionsMenu={
          <ActionsMenu
            handleDelete={handleDelete}
            selected={selected}
            setSelected={setSelected}
          />
        }
        filters={<Search />}
        dataTable={<DataTable data={smItemCategories} columns={columns} />}
        pagination={
          <Pagination
            query={AggregateSmItemCategoryDocument}
            perPage={perPage}
            page={Number(page)}
            variables={countVariables}
          />
        }
      />
    </>
  );
};

export default SmItemCategoriesPage;
