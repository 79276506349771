import RemoveIcon from '@mui/icons-material/Remove';
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { GetInFormItemsDocument } from '../../graphql/generated/types';
import { gradientButton } from '../../styles/gradientButton';
import { nestedGrid } from '../../styles/nestedGrid';
import { FormProps } from '../../types/FormProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import FormContainer from '../common/FormContainer';
import SearchAsYouTypeAutocomplete from '../common/SearchAsYouTypeAutocomplete';
import ConsigneeAutocomplete from '../shared/ConsigneeAutocomplete';
import SmItemCategoryAutocomplete from '../shared/SmItemCategoryAutocomplete';
import { FormData } from './CreateManyItemPricingPage';

const CreateManyItemPricingForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
}: FormProps) => {
  const { control } = useFormContext<FormData>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'itemPricingItems',
  });
  const itemPricingItems = useWatch({
    name: 'itemPricingItems',
    control,
  });
  const handleAddItem = () => {
    append({
      item: { id: undefined, internalCode: '' },
      consigneeItemName: '',
      price: 0,
      sku: '',
      effectivityDate: '' as unknown as Date,
      smItemCategory: {
        id: undefined,
        itemCategoryName: '',
      },
      remarks: '',
    });
  };

  const handleRemoveItem = (index: number) => {
    remove(index);
  };
  return (
    <FormContainer maxWidth="xl">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
        gutterBottom
      >
        {title}
      </Typography>
      <Box component="form" onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 1,
          }}
        >
          <ConsigneeAutocomplete
            schema={schema}
            name="consignee"
            control={control}
            label="Consignee"
            defaultValue={null}
          />
        </Box>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Items
        </Typography>
        <Stack direction="column" spacing={1}>
          {fields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <Controller
                    name={`itemPricingItems.${index}.item`}
                    control={control}
                    defaultValue={null}
                    render={({ field, fieldState }) => (
                      <SearchAsYouTypeAutocomplete
                        textFieldProps={{
                          ...textFieldHelperProps(field, fieldState, schema),
                          label: `Item ${index + 1}`,
                        }}
                        queryOptions={(value) => {
                          return {
                            query: GetInFormItemsDocument,
                            variables: {
                              where: {
                                OR: [
                                  {
                                    internalCode: {
                                      contains: value,
                                      mode: 'insensitive',
                                    },
                                  },
                                ],
                              },
                            },
                          };
                        }}
                        getOptionLabel={(option: any) => option?.internalCode}
                        value={field.value}
                        onChange={(e, value) => field.onChange(value)}
                      />
                    )}
                  />
                  <TextField
                    label={`Item Name ${index + 1}`}
                    fullWidth
                    value={itemPricingItems?.[index]?.item?.name || ''}
                    disabled
                  />
                  <Controller
                    name={`itemPricingItems.${index}.consigneeItemName`}
                    control={control}
                    defaultValue=""
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`Consignee Item Name ${index + 1}`}
                        fullWidth
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name={`itemPricingItems.${index}.price`}
                    control={control}
                    defaultValue={0}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`Price ${index + 1}`}
                        fullWidth
                        type="number"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name={`itemPricingItems.${index}.sku`}
                    control={control}
                    defaultValue=""
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`SKU ${index + 1}`}
                        fullWidth
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name={`itemPricingItems.${index}.effectivityDate`}
                    control={control}
                    defaultValue={'' as unknown as Date}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`Effectivity Date ${index + 1}`}
                        fullWidth
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                      />
                    )}
                  />
                  <SmItemCategoryAutocomplete
                    schema={schema}
                    name={`itemPricingItems.${index}.smItemCategory`}
                    control={control}
                    label={`SM Item Category ${index + 1}`}
                    defaultValue={null}
                  />
                  <Controller
                    name={`itemPricingItems.${index}.remarks`}
                    control={control}
                    defaultValue=""
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`Remarks ${index + 1}`}
                        fullWidth
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                      />
                    )}
                  />
                </Box>
                <IconButton
                  aria-label="delete"
                  color="primary"
                  size="small"
                  onClick={() => handleRemoveItem(index)}
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            );
          })}
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            sx={{ my: 1 }}
            type="button"
            variant="contained"
            color="primary"
            onClick={handleAddItem}
            size="small"
          >
            Add
          </Button>
        </Stack>
        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ mt: 3, ...gradientButton }}
          disabled={mutationLoading}
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default CreateManyItemPricingForm;
