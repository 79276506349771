import MuiAlert from "@mui/material/Alert";
import MuiSnackbar from "@mui/material/Snackbar";
import { useSnackbarContext } from "../../contexts/snackbarContext";

const Snackbar = () => {
  const {
    snackbarOpen,
    handleSnackbarClose,
    snackbarSeverity,
    snackbarMessage,
  } = useSnackbarContext();
  return (
    <MuiSnackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={snackbarOpen}
      autoHideDuration={3000}
      onClose={handleSnackbarClose}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={handleSnackbarClose}
        severity={snackbarSeverity}
      >
        {snackbarMessage}
      </MuiAlert>
    </MuiSnackbar>
  );
};

export default Snackbar;
