import { Autocomplete, SxProps, TextField, Theme } from '@mui/material';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { BaseSchema } from 'yup';
import {
  useGetInFormSuppliersQuery,
  useSuppliersQuery,
} from '../../graphql/generated/types';

import { RequireKeys } from '../../types/UtilityTypes';
import { textFieldHelperProps } from '../../utils/reactHookForm';

interface Props {
  label: string;
  schema: BaseSchema;
  sx?: SxProps<Theme> | undefined;
  readOnly?: boolean;
  onChange?: (value: any) => void;
}
const SupplierAutocomplete = <T extends FieldValues>({
  name,
  label,
  schema,
  control,
  defaultValue,
  sx,
  onChange,
  readOnly = false,
}: Props & RequireKeys<UseControllerProps<T>, 'defaultValue' | 'control'>) => {
  const { data: { getInFormSuppliers } = {}, loading } =
    useGetInFormSuppliersQuery({
      fetchPolicy: 'network-only',
    });
  if (!getInFormSuppliers) return null;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => (
        <Autocomplete
          sx={{ ...sx }}
          readOnly={readOnly}
          renderInput={(params) => (
            <TextField
              {...params}
              {...textFieldHelperProps(field, fieldState, schema)}
              label={label}
            />
          )}
          options={getInFormSuppliers}
          getOptionLabel={(option: any) => option?.name}
          value={field.value}
          onChange={(e, value) => {
            onChange && onChange(value);
            field.onChange(value);
          }}
        />
      )}
    />
  );
};

export default SupplierAutocomplete;
