import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import { useCreateInvoicePaymentMutation } from '../../graphql/generated/types';
import { getUploads, prismaCreate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Meta from '../page/Meta';
import InvoicePaymentForm from './InvoicePaymentForm';
import { schema, FormData } from './utils';

const CreateInvoicePaymentPage = () => {
  const title = `Create Invoice Payment`;
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({
        invoicePaymentItems,
        documentUrls,
        supplier,
        supplierBankAccount,
        checks,
        ...data
      }) =>
        await createInvoicePayment({
          variables: {
            data: {
              ...prismaCreate(data),
              documentUrlsUploads: getUploads(documentUrls),
              invoicePaymentItems: {
                create: invoicePaymentItems?.map(
                  ({ proformaInvoice, ...item }) => {
                    return {
                      ...prismaCreate(item),
                      proformaInvoice: {
                        connect: {
                          id: proformaInvoice.id,
                        },
                      },
                    };
                  }
                ),
              },
              checks: {
                create: checks?.map((check) => {
                  return {
                    ...prismaCreate(check),
                  };
                }),
              },
              supplier: {
                connect: {
                  id: supplier.id,
                },
              },
              ...(supplierBankAccount && {
                supplierBankAccount: {
                  connect: {
                    id: supplierBankAccount.id,
                  },
                },
              }),
            },
          },
        }),
      (error) =>
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        })
    )(e);
  };

  const [createInvoicePayment, { loading: mutationLoading }] =
    useCreateInvoicePaymentMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(location.pathname.replace('/create', ''));
      },
    });

  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <InvoicePaymentForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
        />
      </FormProvider>
    </>
  );
};

export default CreateInvoicePaymentPage;
