import { Checkbox } from '@mui/material';
import { ReactNode } from 'react';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  AggregateShipmentPreCostingDocument,
  PurchaseOrder,
  QueryMode,
  ShipmentPreCostingsDocument,
  ShipmentPreCostingWhereInput,
  ShipmentReport,
  ShipmentType,
  SortOrder,
  useDeleteManyShipmentPreCostingMutation,
  useShipmentPreCostingsQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { HandleDelete } from '../../types/HandleDelete';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { commaSeparatedNumber, roundFloat } from '../../utils/numberFunctions';
import { dateToString } from '../../utils/stringFunctions';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import DataTableAccordion from '../common/DataTableAccordion';
import EnumSearchFilter from '../common/EnumSearchFilter';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';

const ShipmentPreCostingsPage = ({ title = 'Shipment Pre Costings' }) => {
  const { handleSnackbarOpen } = useSnackbarContext();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();

  const [searchQueryString, shipmentTypeQueryString] = useGetQueryString([
    'search',
    'shipmentType',
  ]);

  const where: ShipmentPreCostingWhereInput = {
    ...(shipmentTypeQueryString && {
      shipmentType: {
        equals: shipmentTypeQueryString as ShipmentType,
      },
    }),
    ...(typeof searchQueryString === 'string' && {
      OR: [
        {
          bureauOfCustomsRequirement: {
            is: {
              invoiceNumber: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },
        {
          proformaInvoice: {
            is: {
              number: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },

        {
          purchaseOrders: {
            some: {
              number: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },
        {
          shipmentReports: {
            some: {
              transactionNumber: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },
      ],
    }),
  };
  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        id: SortOrder.Desc,
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { shipmentPreCostings } = {},
    loading,
    error,
  } = useShipmentPreCostingsQuery({
    fetchPolicy: 'network-only',
    variables,
  });

  const [deleteManyShipmentPreCosting] =
    useDeleteManyShipmentPreCostingMutation({
      refetchQueries: [
        {
          query: AggregateShipmentPreCostingDocument,
          variables: countVariables,
        },
        {
          query: ShipmentPreCostingsDocument,
          variables,
        },
      ],
      onError: (error) =>
        handleSnackbarOpen({
          message: 'Delete' + title.toLowerCase() + ' error! ' + error,
          severity: 'error',
        }),
    });

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof shipmentPreCostings>>>
  > = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },

    {
      header: 'BOC Invoice No',
      accessor: 'bureauOfCustomsRequirement.invoiceNumber',
    },
    {
      header: 'Proforma Invoice No',
      accessor: 'proformaInvoice.number',
    },
    {
      header: 'Shipment Type',
      accessor: 'shipmentType',
    },
    {
      header: 'Invoice Date',
      accessor: 'invoiceDate',
      Cell: (children?: Date) => dateToString(children),
    },

    {
      header: 'Purchase Orders',
      accessor: 'purchaseOrders',
      Cell: (children: PurchaseOrder[]) =>
        children.map(({ number }) => number).join(', '),
    },
    {
      header: 'Transaction Nos.',
      accessor: 'shipmentReports',
      Cell: (children: ShipmentReport[]) =>
        children.map(({ transactionNumber }) => transactionNumber).join(', '),
    },
    {
      header: 'Expense Status',
      accessor: 'expenseStatus',
    },

    {
      header: 'Expenses',
      accessor: 'id',
      Cell: (children: any, index: number) => {
        const currentShipmentPreCosting = shipmentPreCostings?.[index] || {};

        const shipmentPreCostingColumns: Columns<
          Paths<ObjectFromArray<NonNullable<typeof shipmentPreCostings>>>
        > = [
          {
            header: 'Duties and Taxes',
            accessor: 'dutiesAndTaxes',
            Cell: (children: number) =>
              commaSeparatedNumber(+roundFloat(children), 2),
          },
          {
            header: 'Bank Fee',
            accessor: 'bankFee',
            Cell: (children: number) =>
              commaSeparatedNumber(+roundFloat(children), 2),
          },
          {
            header: 'Brokerage',
            accessor: 'brokerage',
            Cell: (children: number) =>
              commaSeparatedNumber(+roundFloat(children), 2),
          },
          {
            header: 'Container Deposit',
            accessor: 'containerDeposit',
            Cell: (children: number) =>
              commaSeparatedNumber(+roundFloat(children), 2),
          },
          {
            header: 'Expediting',
            accessor: 'expediting',
            Cell: (children: number) =>
              commaSeparatedNumber(+roundFloat(children), 2),
          },
          {
            header: 'Handling',
            accessor: 'handling',
            Cell: (children: number) =>
              commaSeparatedNumber(+roundFloat(children), 2),
          },
          {
            header: 'Others',
            accessor: 'others',
            Cell: (children: number) =>
              commaSeparatedNumber(+roundFloat(children), 2),
          },
          {
            header: 'Draft',
            accessor: 'draft',
            Cell: (children: number) =>
              commaSeparatedNumber(+roundFloat(children), 2),
          },
          {
            header: 'Total',
            accessor: 'total',
            Cell: (children: number) =>
              commaSeparatedNumber(+roundFloat(children), 2),
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={[currentShipmentPreCosting]}
            index={index}
            columns={shipmentPreCostingColumns}
          />
        );
      },
    },
    {
      header: 'Items',
      accessor: 'shipmentPreCostingItems',
      Cell: (children: {}[], index: number) => {
        const shipmentPreCostingItemsColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<
                NonNullable<typeof shipmentPreCostings>
              >['shipmentPreCostingItems']
            >
          >
        > = [
          {
            header: 'Date Received',
            accessor: 'shipmentReportItem.shipmentReport.dateReceived',
            Cell: (children?: Date) => dateToString(children),
          },
          {
            header: 'Location',
            accessor:
              'shipmentReportItem.shipmentReport.shipmentReportDestination',
          },
          {
            header: 'Item Name',
            accessor: 'shipmentReportItem.item.name',
          },
          {
            header: 'Internal Code',
            accessor: 'shipmentReportItem.item.internalCode',
          },
          {
            header: `Boxes Received`,
            accessor: 'shipmentReportItem.numberOfBoxesReceived',
          },
          {
            header: `Qty Per Box`,
            accessor: 'shipmentReportItem.quantityPerBox',
          },
          {
            header: `Subtotal`,
            accessor: 'shipmentReportItem.subtotal',
            Cell: (children: number) =>
              commaSeparatedNumber(+roundFloat(children), 0),
          },
          {
            header: `Incomplete`,
            accessor: 'shipmentReportItem.incompleteQuantity',
            Cell: (children: number) =>
              commaSeparatedNumber(+roundFloat(children), 1),
          },
          {
            header: `Damaged`,
            accessor: 'shipmentReportItem.damagedQuantity',
            Cell: (children: number) =>
              commaSeparatedNumber(+roundFloat(children), 1),
          },
          {
            header: `Good`,
            accessor: 'shipmentReportItem.goodQuantity',
            Cell: (children: number) =>
              commaSeparatedNumber(+roundFloat(children), 1),
          },

          {
            header: `Put to Unit`,
            accessor: 'shipmentReportItem.putToUnitsTotalQuantity',
            Cell: (children: number) =>
              commaSeparatedNumber(+roundFloat(children), 1),
          },
          {
            header: `Final Quantity`,
            accessor: 'shipmentReportItem.finalQuantity',
            Cell: (children: number) =>
              commaSeparatedNumber(+roundFloat(children), 1),
          },
          {
            header: `Remarks`,
            accessor: 'remarks',
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={shipmentPreCostingItemsColumns}
          />
        );
      },
    },
  ];

  const handleDelete: HandleDelete = (ids) => {
    deleteManyShipmentPreCosting({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <>
      <ListView
        loading={loading}
        error={error}
        title={title}
        actionsMenu={
          <ActionsMenu
            handleDelete={handleDelete}
            selected={selected}
            setSelected={setSelected}
          />
        }
        filters={
          <>
            <EnumSearchFilter
              label="Shipment Type"
              field="shipmentType"
              enumObj={ShipmentType}
            />
            <Search />
          </>
        }
        dataTable={<DataTable data={shipmentPreCostings} columns={columns} />}
        pagination={
          <Pagination
            query={AggregateShipmentPreCostingDocument}
            perPage={perPage}
            page={Number(page)}
            variables={countVariables}
          />
        }
      />
    </>
  );
};

export default ShipmentPreCostingsPage;
