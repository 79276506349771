import RemoveIcon from '@mui/icons-material/Remove';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import {
  Currency,
  ModeOfPayment,
  ProformaInvoicesDocument,
  useSupplierBankAccountsQuery,
} from '../../graphql/generated/types';
import { gradientButton } from '../../styles/gradientButton';
import { nestedGrid } from '../../styles/nestedGrid';
import { FormProps } from '../../types/FormProps';
import { commaSeparatedNumber, roundFloat } from '../../utils/numberFunctions';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import { isFieldRequired } from '../../utils/yup';
import EnumSelect from '../common/EnumSelect';
import FormContainer from '../common/FormContainer';
import SearchAsYouTypeAutocomplete from '../common/SearchAsYouTypeAutocomplete';
import Upload from '../common/upload/Upload';
import SupplierAutocomplete from '../shared/SupplierAutocomplete';
import { FormData } from './utils';
import { NumberFormat } from '../common/NumberFormatCustom';

const InvoicePaymentForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
}: FormProps) => {
  const { control, setValue } = useFormContext<FormData>();
  const {
    fields: invoicePaymentItemFields,
    append: appendInvoicePaymentItem,
    remove: removeInvoicePaymentItem,
  } = useFieldArray({
    control,
    name: 'invoicePaymentItems',
  });

  const {
    fields: checkFields,
    append: appendCheck,
    remove: removeCheck,
  } = useFieldArray({
    control,
    name: 'checks',
  });

  const [supplier, supplierBankAccount, modeOfPayment, invoicePaymentItems] =
    useWatch({
      name: [
        'supplier',
        'supplierBankAccount',
        'modeOfPayment',
        'invoicePaymentItems',
      ],
      control,
    });

  const { data: { supplierBankAccounts } = {} } = useSupplierBankAccountsQuery({
    fetchPolicy: 'network-only',
    skip: !supplier,
    variables: {
      where: {
        supplier: {
          is: {
            id: {
              equals: supplier?.id,
            },
          },
        },
      },
    },
  });

  const handleAddInvoicePaymentItem = () => {
    appendInvoicePaymentItem({
      proformaInvoice: null,
      amount: 0,
      currency: '' as unknown as Currency,
    });
  };

  const handleRemoveInvoicePaymentItem = (index: number) => {
    removeInvoicePaymentItem(index);
  };

  const handleAddCheck = () => {
    appendCheck({
      payee: '',
      bank: '',
      branch: '',
      number: '',
      date: '' as unknown as Date,
      amount: 0,
    });
  };

  const handleRemoveCheck = (index: number) => {
    removeCheck(index);
  };

  const total = invoicePaymentItems?.reduce((acc, cur) => {
    if (!cur.amount) return acc;
    return acc + cur.amount;
  }, 0);

  return (
    <FormContainer maxWidth="md">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
        gutterBottom
      >
        {title}
      </Typography>
      <Box component="form" onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: 1,
          }}
        >
          <Controller
            name="date"
            control={control}
            defaultValue={'' as unknown as Date}
            render={({ field, fieldState }) => (
              <TextField
                label="Date"
                fullWidth
                type="date"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <SupplierAutocomplete
            name="supplier"
            schema={schema}
            control={control}
            label="Supplier"
            defaultValue={null}
          />
          <EnumSelect
            name="modeOfPayment"
            label="Mode of Payment"
            schema={schema}
            enumObj={ModeOfPayment}
            control={control}
            defaultValue={'' as unknown as undefined}
          />
          <Controller
            name="supplierBankAccount"
            control={control}
            defaultValue={null}
            render={({ field, fieldState }) => (
              <FormControl
                fullWidth
                error={!!fieldState.error?.message}
                required={isFieldRequired(schema, field.name)}
              >
                <InputLabel id="supplierBankAccount-label">
                  Supplier Bank Account
                </InputLabel>
                <Select
                  labelId="supplierBankAccount-label"
                  label="Supplier Bank Account"
                  {...field}
                  value={
                    supplierBankAccounts?.find(
                      (supplierBankAccount) =>
                        supplierBankAccount?.id === field?.value?.id
                    ) ?? ''
                  }
                  disabled={!supplierBankAccounts}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {supplierBankAccounts?.map((supplierBankAccount, idx) => {
                    return (
                      <MenuItem key={idx} value={supplierBankAccount as any}>
                        {supplierBankAccount &&
                          `${supplierBankAccount.bank}-${supplierBankAccount.accountNumber}`}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>{fieldState.error?.message}</FormHelperText>
              </FormControl>
            )}
          />
          <TextField
            label={`Swift Code`}
            fullWidth
            value={
              (supplierBankAccount && supplierBankAccount?.swiftCode) ?? ''
            }
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            label={`Bank Address`}
            fullWidth
            value={
              (supplierBankAccount && supplierBankAccount?.bankAddress) ?? ''
            }
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              readOnly: true,
            }}
          />
          <Controller
            name="remarks"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Remarks"
                fullWidth
                sx={{ gridColumn: 'span 3' }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
        </Box>

        <Upload
          title="Upload Documents"
          name="documentUrls"
          control={control}
          defaultValue={[]}
        />
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Invoices
        </Typography>
        <Stack direction="column" spacing={1}>
          {invoicePaymentItemFields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <Controller
                    name={`invoicePaymentItems.${index}.proformaInvoice`}
                    control={control}
                    defaultValue={null}
                    render={({ field, fieldState }) => (
                      <SearchAsYouTypeAutocomplete
                        textFieldProps={{
                          ...textFieldHelperProps(field, fieldState, schema),
                          label: `Proforma Invoice ${index + 1}`,
                          // ,
                        }}
                        queryOptions={(value) => {
                          return {
                            query: ProformaInvoicesDocument,
                            variables: {
                              where: {
                                supplier: {
                                  is: {
                                    id: {
                                      equals: supplier.id,
                                    },
                                  },
                                },
                                OR: [
                                  {
                                    number: {
                                      contains: value,
                                      mode: 'insensitive',
                                    },
                                  },
                                ],
                              },
                            },
                          };
                        }}
                        getOptionLabel={(option: any) => option?.number}
                        value={field.value}
                        onChange={(e, value) => {
                          field.onChange(value);
                          if (value) {
                            setValue(
                              `invoicePaymentItems.${index}.currency`,
                              value.currency
                            );
                          } else {
                            setValue(
                              `invoicePaymentItems.${index}.currency`,
                              '' as unknown as Currency
                            );
                          }
                        }}
                      />
                    )}
                  />
                  <EnumSelect
                    name={`invoicePaymentItems.${index}.currency`}
                    label="Currency"
                    schema={schema}
                    enumObj={Currency}
                    control={control}
                    defaultValue={'' as unknown as undefined}
                  />
                  {/* <Controller
                    name={`invoicePaymentItems.${index}.currency`}
                    control={control}
                    defaultValue=""
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`Currency ${index + 1}`}
                        disabled={index !== 0}
                        fullWidth
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          invoicePaymentItems?.forEach((item, index) => {
                            if (index !== 0)
                              setValue(
                                `invoicePaymentItems.${index}.currency`,
                                e.target.value
                              );
                          });
                        }}
                      />
                    )}
                  /> */}

                  <Controller
                    name={`invoicePaymentItems.${index}.amount`}
                    control={control}
                    defaultValue={0}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`Amount ${index + 1}`}
                        fullWidth
                        // type="number"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                        InputProps={{
                          inputComponent: NumberFormat as any,
                        }}
                      />
                    )}
                  />
                </Box>
                <IconButton
                  aria-label="delete"
                  color="primary"
                  size="small"
                  onClick={() => handleRemoveInvoicePaymentItem(index)}
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            );
          })}
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            sx={{ my: 1 }}
            type="button"
            variant="contained"
            color="primary"
            onClick={handleAddInvoicePaymentItem}
            size="small"
          >
            Add
          </Button>
        </Stack>
        {modeOfPayment === 'CHECK' && (
          <>
            <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
              Checks
            </Typography>
            <Stack direction="column" spacing={1}>
              {checkFields.map((field, index) => {
                return (
                  <Box
                    key={field.id}
                    sx={{
                      ...nestedGrid,
                    }}
                  >
                    <Box>
                      <Controller
                        name={`checks.${index}.payee`}
                        control={control}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            label={`Payee ${index + 1}`}
                            fullWidth
                            {...textFieldHelperProps(field, fieldState, schema)}
                            {...field}
                          />
                        )}
                      />{' '}
                      <Controller
                        name={`checks.${index}.bank`}
                        control={control}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            label={`Bank ${index + 1}`}
                            fullWidth
                            {...textFieldHelperProps(field, fieldState, schema)}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name={`checks.${index}.branch`}
                        control={control}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            label={`Branch ${index + 1}`}
                            fullWidth
                            {...textFieldHelperProps(field, fieldState, schema)}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name={`checks.${index}.number`}
                        control={control}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            label={`Number ${index + 1}`}
                            fullWidth
                            {...textFieldHelperProps(field, fieldState, schema)}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name={`checks.${index}.date`}
                        control={control}
                        defaultValue={'' as unknown as Date}
                        render={({ field, fieldState }) => (
                          <TextField
                            label={`Date ${index + 1}`}
                            fullWidth
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            {...textFieldHelperProps(field, fieldState, schema)}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name={`checks.${index}.amount`}
                        control={control}
                        defaultValue={0}
                        render={({ field, fieldState }) => (
                          <TextField
                            label={`Amount ${index + 1}`}
                            fullWidth
                            // type="number"
                            {...textFieldHelperProps(field, fieldState, schema)}
                            {...field}
                            InputProps={{
                              inputComponent: NumberFormat as any,
                            }}
                          />
                        )}
                      />
                    </Box>
                    <IconButton
                      aria-label="delete"
                      color="primary"
                      size="small"
                      onClick={() => handleRemoveCheck(index)}
                    >
                      <RemoveIcon />
                    </IconButton>
                  </Box>
                );
              })}
            </Stack>
            <Stack direction="row" justifyContent="flex-end">
              <Button
                sx={{ my: 1 }}
                type="button"
                variant="contained"
                color="primary"
                onClick={handleAddCheck}
                size="small"
              >
                Add
              </Button>
            </Stack>
          </>
        )}
        <Typography variant="h6" align="right">
          Total Payment: {invoicePaymentItems?.[0]?.currency || ''}{' '}
          {commaSeparatedNumber(+roundFloat(total || 0), 2)}
        </Typography>
        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ mt: 3, ...gradientButton }}
          disabled={mutationLoading}
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default InvoicePaymentForm;
