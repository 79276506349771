import RemoveIcon from '@mui/icons-material/Remove';
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { GetInFormItemsDocument, ItemsDocument } from '../../graphql/generated/types';
import { nestedGrid } from '../../styles/nestedGrid';
import { InputGroupProps } from '../../types/InputGroupProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import SearchAsYouTypeAutocomplete from '../common/SearchAsYouTypeAutocomplete';
import { FormData } from './utils';

const defaultValue = {
  internalCode: '',
  name: '',
  brand: {
    name: '',
  },
  supplier: {
    name: '',
  },
};

const Parts = ({ schema, readOnly }: InputGroupProps) => {
  const { control } = useFormContext<FormData>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'parts',
  });

  const parts = useWatch({
    control,
    name: 'parts',
  });

  const handleAddPart = () => {
    append(defaultValue);
  };

  const handleRemovePart = (index: number) => {
    remove(index);
  };

  return (
    <>
      <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
        Parts
      </Typography>
      <Stack direction="column" spacing={1}>
        {fields.map((field, index) => {
          return (
            <Box
              key={field.id}
              sx={{
                ...nestedGrid,
              }}
            >
              <Box>
                <Controller
                  name={`parts.${index}`}
                  control={control}
                  defaultValue={defaultValue}
                  render={({ field, fieldState }) => (
                    <SearchAsYouTypeAutocomplete
                      textFieldProps={{
                        ...textFieldHelperProps(
                          field,
                          fieldState,
                          schema,
                          `parts.${index}.internalCode`
                        ),
                        label: `Internal Code ${index + 1}`,
                        // ,
                      }}
                      queryOptions={(value) => {
                        return {
                          query: GetInFormItemsDocument,
                          variables: {
                            where: {
                              OR: [
                                {
                                  internalCode: {
                                    contains: value,
                                    mode: 'insensitive',
                                  },
                                },
                              ],
                            },
                          },
                        };
                      }}
                      getOptionLabel={(option: any) => option?.internalCode}
                      value={field.value}
                      onChange={(e, value) => field.onChange(value)}
                    />
                  )}
                />
                <TextField
                  label={`Item Name ${index + 1}`}
                  fullWidth
                  value={parts?.[index]?.name || ''}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label={`Brand ${index + 1}`}
                  fullWidth
                  value={parts?.[index]?.brand?.name || ''}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label={`Supplier ${index + 1}`}
                  fullWidth
                  value={parts?.[index]?.supplier?.name || ''}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>

              <IconButton
                aria-label="delete"
                color="primary"
                size="small"
                onClick={() => handleRemovePart(index)}
              >
                <RemoveIcon />
              </IconButton>
            </Box>
          );
        })}
      </Stack>
      <Stack direction="row" justifyContent="flex-end">
        <Button
          sx={{ my: 1 }}
          type="button"
          variant="contained"
          color="primary"
          onClick={handleAddPart}
          size="small"
        >
          Add
        </Button>
      </Stack>
    </>
  );
};

export default Parts;
