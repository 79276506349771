import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import React, { ReactNode, useState } from 'react';
import { Columns } from '../../types/Columns';

type Props<T extends string> = {
  data: {}[] | undefined;
  columns: Columns<T>;
  tableSummary?: ReactNode;
};

type Order = 'asc' | 'desc';
type GetComparator = (order: Order, orderBy: string) => DescendingComparator;
type DescendingComparator = (
  a: Record<string, any>,
  b: Record<string, any>,
  orderBy: string
) => number;

const getNestedValue = (obj: Record<string, any>, path: string): any => {
  return path.split('.').reduce((acc, cur) => {
    return acc && acc[cur];
  }, obj);
};

const descendingComparator: DescendingComparator = (a, b, orderBy) => {
  if (getNestedValue(a, orderBy) == null) {
    return 1;
  }
  if (getNestedValue(b, orderBy) == null) {
    return -1;
  }
  if (getNestedValue(b, orderBy) < getNestedValue(a, orderBy)) {
    return -1;
  }
  if (getNestedValue(b, orderBy) > getNestedValue(a, orderBy)) {
    return 1;
  }
  return 0;
};

const getComparator: GetComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a: Record<string, any>, b: Record<string, any>) =>
        descendingComparator(a, b, orderBy)
    : (a: Record<string, any>, b: Record<string, any>) =>
        -descendingComparator(a, b, orderBy);
};

const stableSort = (array: {}[], comparator: any) => {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order: any = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return (a[1] as number) - (b[1] as number);
  });

  return stabilizedThis.map((el) => el[0]);
};

const DataTable = <T extends string>({
  data,
  columns,
  tableSummary = <></>,
}: Props<T>) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      {data && data.length > 0 ? (
        <Table size="small">
          <TableHead sx={{ position: 'sticky', top: 0 }}>
            <TableRow>
              {columns.map(({ header, accessor, align = 'center' }, idx) => (
                <React.Fragment key={idx}>
                  {idx === 0 ? (
                    <TableCell
                      align={align}
                      sx={{ minWidth: '100px', color: 'inherit' }}
                    >
                      {header}
                    </TableCell>
                  ) : (
                    <TableCell align={align} sx={{ color: 'inherit' }}>
                      <TableSortLabel
                        active={orderBy === accessor}
                        direction={orderBy === accessor ? order : 'asc'}
                        onClick={(event) => handleRequestSort(event, accessor)}
                      >
                        {header}
                      </TableSortLabel>
                    </TableCell>
                  )}
                </React.Fragment>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(data, getComparator(order, orderBy)).map(
              (item, index) => {
                return (
                  <React.Fragment key={index}>
                    <TableRow>
                      {columns.map(
                        (
                          {
                            accessor,
                            align = 'center',
                            Cell = (children) => <>{children}</>,
                          },
                          idx
                        ) => (
                          <TableCell align={align} key={idx}>
                            {Cell(getNestedValue(item, accessor), index)}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        id={`portal-${index}`}
                        colSpan={columns.length}
                        padding="none"
                        style={{ borderBottom: 0 }}
                      ></TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              }
            )}
            {tableSummary}
          </TableBody>
        </Table>
      ) : (
        <Typography
          variant="h6"
          align="center"
          sx={{ my: 2, color: 'inherit' }}
        >
          No items here
        </Typography>
      )}
    </>
  );
};

// const areEqual = (prevProps: Readonly<Props>, nextProps: Readonly<Props>) => {
//   const areEqual = prevProps.data === nextProps.data;

//   return areEqual;
// };

export default DataTable;
