import { array, date, InferType, mixed, number, object, string } from 'yup';
import { ShipmentReportContainerType, ShipmentReportDestination, ShipmentReportOrigin } from '../../graphql/generated/types';



export const schema = object({
  transactionNumber: string().required(),
  checkIn: string().matches(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/).required(),
  checkOut: string().matches(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/).required(),
  supplier: object().nullable().default(null).required(),
  packingList: object().nullable().default(null),
  purchaseOrders: array().of(object({
    id: number().required(),
    number: string().required(),
    purchaseOrderItems: array()
  })).required().min(1),
  employees: array().of(object({
    id: number().required(),
    firstName: string().required(),
    lastName: string().required(),
  })).required().min(1),
  shipmentReportContainerType: mixed<ShipmentReportContainerType>()
  .oneOf(Object.values(ShipmentReportContainerType))
  .required(),
  numberOfBoxesReceived: number().required(),
  shipmentReportOrigin: mixed<ShipmentReportOrigin>()
  .oneOf(Object.values(ShipmentReportOrigin))
  .required(),
  shipmentReportDestination: mixed<ShipmentReportDestination>()
  .oneOf(Object.values(ShipmentReportDestination))
  .required(),
  dateReceived: date().required(),
  checkedBy: string().nullable(),
  createdBy: string().nullable(),
  lastUpdatedBy: string().nullable(),
  shipmentReportItems: array().of(object({
    item: object().nullable().default(null).required(),
    numberOfBoxesReceived: number().required(),
    quantityPerBox: number().required(),
    goodQuantity: number().required(),
    incompleteQuantity: number().required(),
    damagedQuantity: number().required(),
    remarks: string().nullable(),
  })),
  missingItems: array().of(object({
    part: object().nullable().default(null).required(),
    motherUnit: object().nullable().default(null).required(),
    quantity: number().required(),
    remarks: string().nullable(),
  })),
  putToUnits: array().of(object({
    part: object().nullable().default(null).required(),
    motherUnit: object().nullable().default(null).required(),
    shipmentReport: object().nullable().default(null).required(),
    quantity: number().required(),
    remarks: string().nullable(),
  })),
  documentUrls: array(mixed()).defined()
}).required();

export type FormData = InferType<typeof schema>