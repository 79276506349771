import { Checkbox } from '@mui/material';
import { endOfDay, startOfDay } from 'date-fns';
import { ReactNode } from 'react';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  AggregatePackingListDocument,
  PackingListsDocument,
  PackingListsQuery,
  PackingListWhereInput,
  ProformaInvoice,
  PurchaseOrder,
  QueryMode,
  SortOrder,
  useDeleteManyPackingListMutation,
  usePackingListsQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { HandleDelete } from '../../types/HandleDelete';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { dateToString } from '../../utils/stringFunctions';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import DataTableAccordion from '../common/DataTableAccordion';
import DateRangeFilter from '../common/DateRangeFilter';
import Pagination from '../common/Pagination';
import PrintMenuItem from '../common/PrintMenuItem';
import Search from '../common/Search';
import ListView from '../templates/ListView';
import { commaSeparatedNumber, roundFloat } from '../../utils/numberFunctions';

export const packingListColumns: Columns<
  Paths<ObjectFromArray<NonNullable<PackingListsQuery['packingLists']>>>
> = [
  {
    header: 'Number',
    accessor: 'number',
  },

  {
    header: 'Date',
    accessor: 'date',
    Cell: (children?: Date) => dateToString(children),
  },
  {
    header: 'Est Time of Departure',
    accessor: 'estimatedTimeOfDeparture',
    Cell: (children?: Date) => dateToString(children),
  },
  {
    header: 'Origin',
    accessor: 'origin',
  },
  {
    header: 'Destination',
    accessor: 'destination',
  },
  {
    header: 'Proforma Invoices',
    accessor: 'proformaInvoices',
    Cell: (children: ProformaInvoice[]) =>
      children.map(({ number }) => number).join(', '),
  },
  {
    header: 'Purchase Orders',
    accessor: 'purchaseOrders',
    Cell: (children: PurchaseOrder[]) =>
      children.map(({ number }) => number).join(', '),
  },
  {
    header: 'Items',
    accessor: 'packingListItems',
    Cell: (children: {}[], index: number) => {
      const itemsColumns: Columns<
        Paths<
          ObjectFromArray<
            ObjectFromArray<
              NonNullable<PackingListsQuery['packingLists']>
            >['packingListItems']
          >
        >
      > = [
        {
          header: 'Actual Quantity',
          accessor: 'actualQuantity',
          Cell: (children: number) =>
            commaSeparatedNumber(+roundFloat(children)),
        },
        {
          header: 'Actual No. of Boxes',
          accessor: 'actualNumberOfBoxes',
          Cell: (children: number) =>
            commaSeparatedNumber(+roundFloat(children)),
        },
        {
          header: `Internal Code`,
          accessor: 'item.internalCode',
        },
        {
          header: `Item Name`,
          accessor: 'item.name',
        },
        {
          header: `Supplier's Item Code`,
          accessor: 'item.supplierItemCode',
        },
        {
          header: 'Quantity Per Box',
          accessor: 'item.quantityPerBox',
          Cell: (children: number) =>
            commaSeparatedNumber(+roundFloat(children)),
        },

        {
          header: 'Net Weight',
          accessor: 'netWeight',
          Cell: (children: number) =>
            commaSeparatedNumber(+roundFloat(children), 2),
        },
        {
          header: 'Gross Weight',
          accessor: 'grossWeight',
          Cell: (children: number) =>
            commaSeparatedNumber(+roundFloat(children), 2),
        },
        {
          header: 'CBM per Box',
          accessor: 'cubicMeterPerBox',
          Cell: (children: number) =>
            commaSeparatedNumber(+roundFloat(children), 3),
        },
        {
          header: 'Total CBM',
          accessor: 'totalCbm',
          Cell: (children: number) =>
            commaSeparatedNumber(+roundFloat(children), 3),
        },
        {
          header: 'Remarks',
          accessor: 'remarks',
        },
      ];
      return (
        <DataTableAccordion
          title="Items"
          data={children}
          index={index}
          columns={itemsColumns}
        />
      );
    },
  },
];

const PackingListsPage = ({ title = 'Packing Lists' }) => {
  const { handleSnackbarOpen } = useSnackbarContext();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();
  const [searchQueryString, startDateQueryString, endDateQueryString] =
    useGetQueryString(['search', 'startDate', 'endDate']);

  const where: PackingListWhereInput = {
    ...(typeof startDateQueryString === 'string' &&
      typeof endDateQueryString === 'string' && {
        date: {
          gte: startOfDay(new Date(startDateQueryString)),
          lte: endOfDay(new Date(endDateQueryString)),
        },
      }),
    ...(typeof searchQueryString === 'string' && {
      OR: [
        {
          number: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
        {
          proformaInvoices: {
            some: {
              number: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },
        {
          purchaseOrders: {
            some: {
              number: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },
        {
          packingListItems: {
            some: {
              item: {
                is: {
                  internalCode: {
                    contains: searchQueryString,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
            },
          },
        },
        {
          packingListItems: {
            some: {
              item: {
                is: {
                  name: {
                    contains: searchQueryString,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
            },
          },
        },
      ],
    }),
  };
  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [{ date: SortOrder.Desc }],
  };
  const countVariables = {
    where,
  };
  const {
    data: { packingLists } = {},
    loading,
    error,
  } = usePackingListsQuery({
    fetchPolicy: 'network-only',
    variables,
  });

  const [deleteManyPackingList] = useDeleteManyPackingListMutation({
    refetchQueries: [
      { query: AggregatePackingListDocument, variables: countVariables },
      {
        query: PackingListsDocument,
        variables,
      },
    ],
    onError: (error) =>
      handleSnackbarOpen({
        message: 'Delete' + title.toLowerCase() + ' error! ' + error,
        severity: 'error',
      }),
  });

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<PackingListsQuery['packingLists']>>>
  > = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },
    ...packingListColumns,
  ];

  const handleDelete: HandleDelete = (ids) => {
    deleteManyPackingList({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <>
      <ListView
        loading={loading}
        error={error}
        title={title}
        actionsMenu={
          <ActionsMenu
            handleDelete={handleDelete}
            selected={selected}
            setSelected={setSelected}
          >
            <PrintMenuItem selected={selected} />
          </ActionsMenu>
        }
        filters={
          <>
            <DateRangeFilter />
            <Search />
          </>
        }
        dataTable={<DataTable data={packingLists} columns={columns} />}
        pagination={
          <Pagination
            query={AggregatePackingListDocument}
            perPage={perPage}
            page={Number(page)}
            variables={countVariables}
          />
        }
      />
    </>
  );
};

export default PackingListsPage;
