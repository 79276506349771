import { endOfDay, startOfDay, subDays } from 'date-fns';
import {
  AggregatePendingProformaInvoiceDocument,
  PurchaseOrderWhereInput,
  QueryMode,
  SortOrder,
  usePendingProformaInvoicesQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import DataTable from '../common/DataTable';
import DateRangeFilter from '../common/DateRangeFilter';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import { purchaseOrderColumns } from '../purchaseOrders/PurchaseOrdersPage';
import ListView from '../templates/ListView';

const PendingProformaInvoicesPage = ({ title = 'PI Pending' }) => {
  const dateFilter = subDays(new Date(), 14);

  const { page, perPage } = usePagination();
  const [searchQueryString, startDateQueryString, endDateQueryString] =
    useGetQueryString(['search', 'startDate', 'endDate']);

  const where: PurchaseOrderWhereInput = {
    proformaInvoices: {
      none: {},
    },
    date: {
      lt: startOfDay(dateFilter),
    },
    ...(typeof startDateQueryString === 'string' &&
      typeof endDateQueryString === 'string' && {
        date: {
          gte: startOfDay(new Date(startDateQueryString)),
          lte: endOfDay(new Date(endDateQueryString)),
          lt: startOfDay(dateFilter),
        },
      }),
    ...(typeof searchQueryString === 'string' && {
      OR: [
        {
          number: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
        {
          notes: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
        {
          supplier: {
            is: {
              name: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },
        {
          purchaseOrderItems: {
            some: {
              item: {
                is: {
                  internalCode: {
                    contains: searchQueryString,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
            },
          },
        },
        {
          purchaseOrderItems: {
            some: {
              item: {
                is: {
                  name: {
                    contains: searchQueryString,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
            },
          },
        },
      ],
    }),
  };
  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        date: SortOrder.Desc,
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { pendingProformaInvoices } = {},
    loading,
    error,
  } = usePendingProformaInvoicesQuery({
    fetchPolicy: 'network-only',
    variables,
  });

  return (
    <>
      <ListView
        loading={loading}
        error={error}
        title={title}
        filters={
          <>
            <DateRangeFilter />
            <Search />
          </>
        }
        dataTable={
          <DataTable
            data={pendingProformaInvoices}
            columns={purchaseOrderColumns}
          />
        }
        pagination={
          <Pagination
            query={AggregatePendingProformaInvoiceDocument}
            perPage={perPage}
            page={Number(page)}
            variables={countVariables}
          />
        }
      />
    </>
  );
};

export default PendingProformaInvoicesPage;
