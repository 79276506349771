import { grey } from '@mui/material/colors';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { customColors } from './customColors';

let theme = createTheme({
  palette: {
    background: {
      default: grey[50],
    },
    primary: {
      main: '#00BFBF',
    },
    secondary: {
      main: '#ACE217',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        #root {
          min-height: 100vh;
          display: flex;
          flex-direction: column;
        }

      `,
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: customColors.linearGradient,
          height: '60px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiInputBase-readOnly fieldset': {
            borderColor: '#ACE217',
          },
        },
      },
    },
    // MuiButton: {
    //   styleOverrides: {
    //     root: {
    //       background: customColors.linearGradient,
    //       color: '#fff',
    //     },
    //   },
    // },
    MuiTypography: {
      styleOverrides: {
        h1: {
          color: customColors.headingText,
        },
        h2: {
          color: customColors.headingText,
        },
        h3: {
          color: customColors.headingText,
        },
        h4: {
          color: customColors.headingText,
        },
        h5: {
          color: customColors.headingText,
        },
        h6: {
          color: customColors.headingText,
        },
      },
    },
    // MuiTableCell: {
    //   styleOverrides: {
    //     head: {
    //       color: customColors.headingText,
    //     },
    //   },
    // },
    MuiTableHead: {
      styleOverrides: {
        root: {
          color: customColors.headingText,
          backgroundColor: 'white',
          zIndex: 1000,
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
