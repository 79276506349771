import { endOfDay, startOfDay, subDays } from 'date-fns';
import {
  AggregatePendingPackingListDocument,
  PackingListWhereInput,
  QueryMode,
  SortOrder,
  usePendingPackingListsQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import DataTable from '../common/DataTable';
import DateRangeFilter from '../common/DateRangeFilter';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import { packingListColumns } from '../packingLists/PackingListsPage';
import ListView from '../templates/ListView';

const PendingPackingListsPage = ({ title = 'PL Pending' }) => {
  const dateFilter = subDays(new Date(), 30);
  const { page, perPage } = usePagination();
  const [searchQueryString, startDateQueryString, endDateQueryString] =
    useGetQueryString(['search', 'startDate', 'endDate']);

  const where: PackingListWhereInput = {
    bureauOfCustomsRequirementId: {
      equals: null,
    },
    date: {
      lt: startOfDay(dateFilter),
    },
    ...(typeof startDateQueryString === 'string' &&
      typeof endDateQueryString === 'string' && {
        date: {
          gte: startOfDay(new Date(startDateQueryString)),
          lte: endOfDay(new Date(endDateQueryString)),
          lt: startOfDay(dateFilter),
        },
      }),
    ...(typeof searchQueryString === 'string' && {
      OR: [
        {
          number: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
        {
          proformaInvoices: {
            some: {
              number: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },
        {
          purchaseOrders: {
            some: {
              number: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },
        {
          packingListItems: {
            some: {
              item: {
                is: {
                  internalCode: {
                    contains: searchQueryString,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
            },
          },
        },
        {
          packingListItems: {
            some: {
              item: {
                is: {
                  name: {
                    contains: searchQueryString,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
            },
          },
        },
      ],
    }),
  };
  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [{ date: SortOrder.Desc }],
  };
  const countVariables = {
    where,
  };
  const {
    data: { pendingPackingLists } = {},
    loading,
    error,
  } = usePendingPackingListsQuery({
    fetchPolicy: 'network-only',
    variables,
  });

  return (
    <>
      <ListView
        loading={loading}
        error={error}
        title={title}
        filters={
          <>
            <DateRangeFilter />
            <Search />
          </>
        }
        dataTable={
          <DataTable data={pendingPackingLists} columns={packingListColumns} />
        }
        pagination={
          <Pagination
            query={AggregatePendingPackingListDocument}
            perPage={perPage}
            page={Number(page)}
            variables={countVariables}
          />
        }
      />
    </>
  );
};

export default PendingPackingListsPage;
