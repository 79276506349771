import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  useShipmentPreCostingQuery,
  useUpdateShipmentPreCostingMutation,
} from '../../graphql/generated/types';
import { cleanPayload } from '../../utils/apolloClient';
import {
  getItemsForDelete,
  getItemsForUpsert,
  prismaCreate,
  prismaUpdate,
} from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Loading from '../common/Loading';
import Meta from '../page/Meta';
import ShipmentPreCostingForm from './ShipmentPreCostingForm';
import { FormData, schema } from './utils';

const UpdateShipmentPreCostingPage = () => {
  const title = `Update Shipment Pre Costing`;
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({
        bureauOfCustomsRequirement,
        proformaInvoice,
        purchaseOrders,
        shipmentReports,
        shipmentPreCostingItems,
        ...data
      }) => {
        const shipmentPreCostingItemsToDelete = getItemsForDelete(
          bureauOfCustomsRequirement?.shipmentPreCostingItems,
          shipmentPreCostingItems
        );
        const shipmentPreCostingItemsToUpsert = getItemsForUpsert(
          shipmentPreCostingItems
        );

        await updateShipmentPreCosting({
          variables: {
            where: {
              id,
            },
            data: {
              ...prismaUpdate(data),
              // bureauOfCustomsRequirement: {
              //   connect: {
              //     id: bureauOfCustomsRequirement.id,
              //   },
              // },
              proformaInvoice: {
                connect: {
                  id: proformaInvoice.id,
                },
              },
              purchaseOrders: {
                connect: purchaseOrders?.map((purchaseOrder) => {
                  return {
                    id: purchaseOrder.id,
                  };
                }),
              },
              shipmentReports: {
                connect: shipmentReports?.map((shipmentReport) => {
                  return {
                    id: shipmentReport.id,
                  };
                }),
              },
              shipmentPreCostingItems: {
                delete: shipmentPreCostingItemsToDelete,
                upsert: shipmentPreCostingItemsToUpsert.map(
                  ({ id, shipmentReportItem, ...shipmentPreCostingItem }) => {
                    return {
                      where: {
                        id,
                      },
                      update: {
                        ...prismaUpdate(shipmentPreCostingItem),
                        shipmentReportItem: {
                          connect: { id: shipmentReportItem.id },
                        },
                      },
                      create: {
                        ...prismaCreate(shipmentPreCostingItem),
                        shipmentReportItem: {
                          connect: { id: shipmentReportItem.id },
                        },
                      },
                    };
                  }
                ),
              },
            },
          },
        });
      },
      (error) =>
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        })
    )(e);
  };

  const {
    data: { shipmentPreCosting } = {},
    loading,
    error,
  } = useShipmentPreCostingQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: { id },
    },
  });

  const [updateShipmentPreCosting, { loading: mutationLoading }] =
    useUpdateShipmentPreCostingMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(-1);
      },
    });

  useEffect(() => {
    if (shipmentPreCosting) {
      methods.reset({ ...cleanPayload(shipmentPreCosting) });
    }
  }, [shipmentPreCosting, methods]);

  if (loading) return <Loading />;

  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <ShipmentPreCostingForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="update"
        />
      </FormProvider>
    </>
  );
};

export default UpdateShipmentPreCostingPage;
