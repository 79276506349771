import { array, date, InferType, mixed, number, object, string } from 'yup';
import { ItemCommissionType } from '../../graphql/enums/ItemCommissionType';
import { ItemType } from '../../graphql/enums/ItemType';
import { Status } from '../../graphql/enums/Status';
import { BocClassification, ItemsQuery } from '../../graphql/generated/types';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { formatMoney } from '../../utils/numberFunctions';
import { getEnumKeyFromValue } from '../../utils/stringFunctions';
import { emptyStringToNull } from '../../utils/yup';
import DataTableAccordion from '../common/DataTableAccordion';

export const schema = object({
  name: string().required(),
  supplier: object().nullable().default(null).required(),
  supplierItemCode: string().required(),
  supplierItemName: string().required(),
  brand: object().nullable().default(null).required(),
  namingGroup: object().nullable().default(null).required(),
  itemType: mixed<ItemType>().oneOf(Object.values(ItemType)).required(),
  internalCode: string().required(),
  birthday: date().required(),
  itemSubcategory: object().nullable().default(null).required(),
  itemCommissionType: mixed<ItemCommissionType>()
    .oneOf(Object.values(ItemCommissionType))
    .required(),
  quantityPerBox: number().integer().required(),
  netWeight: number().required(),
  grossWeight: number().required(),
  cubicMeterPerBox: number().transform(emptyStringToNull).nullable(),
  stockLevel: number().integer(),
  bocClassification: mixed<BocClassification>()
    .oneOf([...Object.values(BocClassification), null])
    .nullable()
    .transform(emptyStringToNull),
  length: number().required(),
  width: number().required(),
  height: number().required(),
  weight: number().required(),
  dimensionWithBox: string().required(),
  photoUrls: array(mixed()).defined(),

  status: mixed<Status>().oneOf(Object.values(Status)).required(),
  marketingName: string().nullable(),
  features: string().nullable(),
  remarks: string().nullable(),
  weightWithBox: number().transform(emptyStringToNull).nullable(),
  waterTankCapacity: string().nullable(),
  wattage: string().nullable(),
  flowrateFanSpeed: string().nullable(),
  maxPressureMaxHoursUsed: string().nullable(),
  voltage: string().nullable(),
  temperature: string().nullable(),
  inletOutletNptSettingType: string().nullable(),
  filterLifeSpanInMonths: number().transform(emptyStringToNull).nullable(),
  typeOfFilter: string().nullable(),
  noiseLevel: string().nullable(),
  areaCoverage: string().nullable(),

  parts: array().of(
    object({
      internalCode: string().required(),
      name: string().nullable(),
      brand: object({
        name: string().nullable(),
      }).nullable(),
      supplier: object({
        name: string().nullable(),
      }).nullable(),
    }).required()
  ),

  itemHistories: array().of(
    object({
      date: date().required(),
      particular: string().required(),
    }).required()
  ),
}).required();

export type FormData = InferType<typeof schema>;

export const downloadItemsColumns: Columns<
  Paths<ObjectFromArray<NonNullable<ItemsQuery['items']>>>
> = [
  {
    header: 'Internal Code',
    accessor: 'internalCode',
  },
  {
    header: 'Name',
    accessor: 'name',
  },
  {
    header: 'Brand',
    accessor: 'brand.name',
  },
  {
    header: 'Status',
    accessor: 'status',
  },
  {
    header: 'Supplier Name',
    accessor: 'supplier.name',
  },
  {
    header: 'Supplier Origin',
    accessor: 'supplier.origin',
  },
  {
    header: `Supplier's Item Code`,
    accessor: 'supplierItemCode',
  },
  {
    header: `Supplier's Item Name`,
    accessor: 'supplierItemName',
  },
  {
    header: `Naming Group`,
    accessor: 'namingGroup.name',
  },
  {
    header: 'Item Type',
    accessor: 'itemType',
  },
  {
    header: `Birthday`,
    accessor: 'birthday',
  },
  {
    header: 'Item Subcategory',
    accessor: 'itemSubcategory.name',
  },
  {
    header: 'Item Commission Type',
    accessor: 'itemCommissionType',
  },
  {
    header: 'Marketing Name',
    accessor: 'marketingName',
  },
  {
    header: `Quantity Per Box`,
    accessor: 'quantityPerBox',
  },
  {
    header: `Net Weight`,
    accessor: 'netWeight',
  },
  {
    header: `Gross Weight`,
    accessor: 'grossWeight',
  },
  {
    header: `Cubic Meter Per Box`,
    accessor: 'cubicMeterPerBox',
  },
  {
    header: `Features`,
    accessor: 'features',
  },
  {
    header: `Stock Level`,
    accessor: 'stockLevel',
  },
  {
    header: `BOC Classification`,
    accessor: 'bocClassification',
  },
  {
    header: `Remarks`,
    accessor: 'remarks',
  },
  {
    header: `Length`,
    accessor: 'length',
  },
  {
    header: `Width`,
    accessor: 'width',
  },
  {
    header: `Height`,
    accessor: 'height',
  },
  {
    header: `Weight`,
    accessor: 'weight',
  },
  {
    header: `Dimension With Box`,
    accessor: 'dimensionWithBox',
  },
  {
    header: `Weight With Box`,
    accessor: 'weightWithBox',
  },
  {
    header: `Water Tank Capacity`,
    accessor: 'waterTankCapacity',
  },
  {
    header: `Wattage`,
    accessor: 'wattage',
  },
  {
    header: `Flow Rate/Fan Speed`,
    accessor: 'flowrateFanSpeed',
  },
  {
    header: `Max Pressure/Max Hours Used`,
    accessor: 'maxPressureMaxHoursUsed',
  },
  {
    header: `Voltage`,
    accessor: 'voltage',
  },
  {
    header: `Temperature`,
    accessor: 'temperature',
  },
  {
    header: `Inlet Outlet Npt Setting Type`,
    accessor: 'inletOutletNptSettingType',
  },
  {
    header: `Filter Life Span in Months`,
    accessor: 'filterLifeSpanInMonths',
  },
  {
    header: `Type of Filter`,
    accessor: 'typeOfFilter',
  },
  {
    header: `Noise Level`,
    accessor: 'noiseLevel',
  },
  {
    header: `Area Coverage`,
    accessor: 'areaCoverage',
  },
];
