import { array, InferType, mixed, object, string } from 'yup';
import { UserRole } from '../../graphql/enums/UserRole';
import { UserGroup } from '../../graphql/generated/types';

export const schema = object({
  username: string().required().min(3, 'Must be 3 characters long'),
  password: string().required(),
  userRole: mixed<UserRole>()
  .oneOf(Object.values(UserRole))
  .required(),
  userGroups: array(
    mixed<UserGroup>()
      .oneOf(Object.values(UserGroup))
      .defined()
  ).required().min(1),
  employee: object().nullable().default(null).required()
}).required();

export type FormData = InferType<typeof schema>