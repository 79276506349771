import ListAltIcon from '@mui/icons-material/ListAlt';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { Checkbox, Link, MenuItem, TextField, Typography } from '@mui/material';
import { endOfDay, startOfDay } from 'date-fns';
import { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  AggregateBureauOfCustomsRequirementDocument,
  BureauOfCustomsRequirementsDocument,
  BureauOfCustomsRequirementWhereInput,
  PackingList,
  QueryMode,
  SortOrder,
  useBureauOfCustomsRequirementsLazyQuery,
  useBureauOfCustomsRequirementsQuery,
  useDeleteManyBureauOfCustomsRequirementMutation,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { HandleDelete } from '../../types/HandleDelete';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { commaSeparatedNumber, roundFloat } from '../../utils/numberFunctions';
import { dateToString } from '../../utils/stringFunctions';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import DataTableAccordion from '../common/DataTableAccordion';
import DateRangeFilter from '../common/DateRangeFilter';
import DownloadCsvButton from '../common/DownloadCsvButton';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';

const BureauOfCustomsRequirementsPage = ({
  title = 'Bureau of Customs Requirements',
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();
  const [searchQueryString, startDateQueryString, endDateQueryString] =
    useGetQueryString(['search', 'startDate', 'endDate']);

  const where: BureauOfCustomsRequirementWhereInput = {
    ...(typeof startDateQueryString === 'string' &&
      typeof endDateQueryString === 'string' && {
        date: {
          gte: startOfDay(new Date(startDateQueryString)),
          lte: endOfDay(new Date(endDateQueryString)),
        },
      }),
    ...(typeof searchQueryString === 'string' && {
      OR: [
        {
          invoiceNumber: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
        {
          origin: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
        {
          remarks: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
        {
          supplier: {
            is: {
              name: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },
        {
          packingLists: {
            some: {
              number: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },
        {
          bocInvoiceItems: {
            some: {
              packingListItem: {
                is: {
                  item: {
                    is: {
                      internalCode: {
                        contains: searchQueryString,
                        mode: QueryMode.Insensitive,
                      },
                    },
                  },
                },
              },
            },
          },
        },
        {
          bocInvoiceItems: {
            some: {
              packingListItem: {
                is: {
                  item: {
                    is: {
                      name: {
                        contains: searchQueryString,
                        mode: QueryMode.Insensitive,
                      },
                    },
                  },
                },
              },
            },
          },
        },
      ],
    }),
  };
  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        date: SortOrder.Desc,
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { bureauOfCustomsRequirements } = {},
    loading,
    error,
  } = useBureauOfCustomsRequirementsQuery({
    fetchPolicy: 'network-only',
    variables,
  });

  const [
    getDownloadData,
    { data: downloadData, loading: downloadDataLoading },
  ] = useBureauOfCustomsRequirementsLazyQuery({
    variables: {
      where,
    },
  });

  const [deleteManyBureauOfCustomsRequirement] =
    useDeleteManyBureauOfCustomsRequirementMutation({
      refetchQueries: [
        {
          query: AggregateBureauOfCustomsRequirementDocument,
          variables: countVariables,
        },
        {
          query: BureauOfCustomsRequirementsDocument,
          variables,
        },
      ],
      onError: (error) =>
        handleSnackbarOpen({
          message: 'Delete' + title.toLowerCase() + ' error! ' + error,
          severity: 'error',
        }),
    });

  const bocInvoiceItemsColumns: Columns<
    Paths<
      ObjectFromArray<
        ObjectFromArray<
          NonNullable<typeof bureauOfCustomsRequirements>
        >['bocInvoiceItems']
      >
    >
  > = [
    {
      header: 'Actual Quantity',
      accessor: 'packingListItem.actualQuantity',
      Cell: (children: number) => commaSeparatedNumber(+roundFloat(children)),
    },
    {
      header: 'Actual No. of Boxes',
      accessor: 'packingListItem.actualNumberOfBoxes',
      Cell: (children: number) => commaSeparatedNumber(+roundFloat(children)),
    },
    {
      header: 'Internal Code',
      accessor: 'packingListItem.item.internalCode',
    },
    {
      header: 'Item Name',
      accessor: 'packingListItem.item.name',
    },
    {
      header: `Supplier's Item Code`,
      accessor: 'packingListItem.item.supplierItemCode',
    },
    {
      header: `Qty Per Box`,
      accessor: 'packingListItem.item.quantityPerBox',
      Cell: (children: number) => commaSeparatedNumber(+roundFloat(children)),
    },
    {
      header: `Net Weight`,
      accessor: 'packingListItem.totalNetWeight',
      Cell: (children: number) =>
        commaSeparatedNumber(+roundFloat(children), 2),
    },
    {
      header: `Gross Weight`,
      accessor: 'packingListItem.totalGrossWeight',
      Cell: (children: number) =>
        commaSeparatedNumber(+roundFloat(children), 2),
    },
    {
      header: `CBM per Box`,
      accessor: 'packingListItem.cubicMeterPerBox',
      Cell: (children: number) =>
        commaSeparatedNumber(+roundFloat(children), 3),
    },
    {
      header: `Total CBM`,
      accessor: 'packingListItem.totalCbm',
      Cell: (children: number) =>
        commaSeparatedNumber(+roundFloat(children), 3),
    },
    {
      header: `Remarks`,
      accessor: 'packingListItem.remarks',
    },
    {
      header: `Unit Price`,
      accessor: 'unitPrice',
      Cell: (children: number) => roundFloat(children),
    },
    {
      header: `Subtotal`,
      accessor: 'subtotal',
      Cell: (children: number) => roundFloat(children),
    },
  ];

  const shipmentPreCostingColumns: Columns<
    Paths<
      ObjectFromArray<
        NonNullable<typeof bureauOfCustomsRequirements>
      >['shipmentPreCosting']
    >
  > = [
    {
      header: 'Duties and Taxes',
      accessor: 'dutiesAndTaxes',
      Cell: (children: number) =>
        commaSeparatedNumber(+roundFloat(children), 2),
    },
    {
      header: 'Bank Fee',
      accessor: 'bankFee',
      Cell: (children: number) =>
        commaSeparatedNumber(+roundFloat(children), 2),
    },
    {
      header: 'Brokerage',
      accessor: 'brokerage',
      Cell: (children: number) =>
        commaSeparatedNumber(+roundFloat(children), 2),
    },
    {
      header: 'Container Deposit',
      accessor: 'containerDeposit',
      Cell: (children: number) =>
        commaSeparatedNumber(+roundFloat(children), 2),
    },
    {
      header: 'Expediting',
      accessor: 'expediting',
      Cell: (children: number) =>
        commaSeparatedNumber(+roundFloat(children), 2),
    },
    {
      header: 'Handling',
      accessor: 'handling',
      Cell: (children: number) =>
        commaSeparatedNumber(+roundFloat(children), 2),
    },
    {
      header: 'Others',
      accessor: 'others',
      Cell: (children: number) =>
        commaSeparatedNumber(+roundFloat(children), 2),
    },
    {
      header: 'Draft',
      accessor: 'draft',
      Cell: (children: number) =>
        commaSeparatedNumber(+roundFloat(children), 2),
    },
    {
      header: 'Total',
      accessor: 'total',
      Cell: (children: number) =>
        commaSeparatedNumber(+roundFloat(children), 2),
    },
  ];

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof bureauOfCustomsRequirements>>>
  > = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },
    {
      header: 'Date',
      accessor: 'date',
      Cell: (children?: Date) => dateToString(children),
    },
    {
      header: 'Pending PreCosting',
      accessor: 'shipmentPreCosting',
      Cell: (children) =>
        children ? (
          <Typography>Done</Typography>
        ) : (
          <Typography>Pending</Typography>
        ),
    },
    {
      header: 'Invoice Number',
      accessor: 'invoiceNumber',
    },
    {
      header: 'Supplier',
      accessor: 'supplier.name',
    },
    {
      header: 'Packing Lists',
      accessor: 'packingLists',
      Cell: (children: PackingList[]) =>
        children.map(({ number }) => number).join(', '),
    },
    {
      header: 'Vessel',
      accessor: 'vessel',
    },
    {
      header: 'Origin',
      accessor: 'origin',
    },
    {
      header: 'Remarks',
      accessor: 'remarks',
    },
    {
      header: 'Documents',
      accessor: 'documentUrls',
      Cell: (children: string[]) =>
        children.map((url, idx) => (
          <Link
            key={idx}
            target="_blank"
            rel="noopener noreferrer"
            href={url}
            sx={{ ':not(:last-child)::after': { content: `", "` } }}
          >{`Document ${idx + 1}`}</Link>
        )),
    },
    {
      header: 'Currency',
      accessor: 'currency',
    },
    {
      header: 'Total',
      accessor: 'total',
      Cell: (children: number) =>
        commaSeparatedNumber(+roundFloat(children), 2),
    },
    {
      header: 'Expenses',
      accessor: 'shipmentPreCosting',
      Cell: (children: {}[], index: number) => {
        return (
          <DataTableAccordion
            title="Items"
            data={[children]}
            index={index}
            columns={shipmentPreCostingColumns}
          />
        );
      },
    },
    {
      header: 'Items',
      accessor: 'bocInvoiceItems',
      Cell: (children: {}[], index: number) => {
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={bocInvoiceItemsColumns}
          />
        );
      },
    },
  ];

  const handleDelete: HandleDelete = (ids) => {
    deleteManyBureauOfCustomsRequirement({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <>
      <ListView
        loading={loading}
        error={error}
        title={title}
        miscButtons={
          <DownloadCsvButton
            downloadData={downloadData?.bureauOfCustomsRequirements.reduce(
              (acc: any, { bocInvoiceItems, ...cur }: any) => {
                if (bocInvoiceItems.length > 0) {
                  return [
                    ...acc,
                    ...(bocInvoiceItems || [])?.map((bocInvoiceItem: any) => {
                      return {
                        ...bocInvoiceItem,
                        ...cur,
                        packingLists: cur.packingLists
                          .map(({ number }: { number: string }) => number)
                          .join(', '),
                      };
                    }),
                  ];
                } else {
                  return [
                    ...acc,
                    {
                      ...cur,
                      packingLists: cur.packingLists
                        .map(({ number }: { number: string }) => number)
                        .join(', '),
                    },
                  ];
                }
              },
              []
            )}
            getDownloadData={getDownloadData}
            downloadDataLoading={downloadDataLoading}
            columns={[
              ...columns,
              ...shipmentPreCostingColumns.map((obj) => {
                return {
                  ...obj,
                  accessor: `shipmentPreCosting.${obj.accessor}`,
                };
              }),
              ...bocInvoiceItemsColumns,
            ].filter(
              ({ accessor }) =>
                accessor !== 'bocInvoiceItems' &&
                accessor !== 'shipmentPreCosting'
            )}
          />
        }
        actionsMenu={
          <ActionsMenu
            handleDelete={handleDelete}
            selected={selected}
            setSelected={setSelected}
          >
            {selected.length === 1 && (
              <MenuItem
                onClick={() =>
                  navigate(`${location.pathname}/${selected[0]}/invoice`)
                }
              >
                <RequestQuoteIcon />
                Invoice
              </MenuItem>
            )}
            {selected.length === 1 && (
              <MenuItem
                onClick={() =>
                  navigate(`${location.pathname}/${selected[0]}/packing-list`)
                }
              >
                <ListAltIcon />
                Packing List
              </MenuItem>
            )}
          </ActionsMenu>
        }
        filters={
          <>
            <DateRangeFilter />
            <Search />
          </>
        }
        dataTable={
          <DataTable data={bureauOfCustomsRequirements} columns={columns} />
        }
        pagination={
          <Pagination
            query={AggregateBureauOfCustomsRequirementDocument}
            perPage={perPage}
            page={Number(page)}
            variables={countVariables}
          />
        }
      />
    </>
  );
};

export default BureauOfCustomsRequirementsPage;
