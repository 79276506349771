import { useState } from 'react';

const useSelectCheckbox = () => {
  const [selected, setSelected] = useState<number[]>([]);
  const isSelected = (index: number) =>
    selected.findIndex((item) => item === index) > -1;
  const handleSelect = (index: number) => {
    if (isSelected(index)) {
      setSelected(selected.filter((item) => item !== index));
    } else {
      setSelected([...selected, index]);
    }
  };

  return { selected, setSelected, isSelected, handleSelect };
};

export { useSelectCheckbox };
