import { Button, Collapse, Portal } from '@mui/material';
import React, { ReactNode, useState } from 'react';
import NestedTable from './NestedTable';

type Props = {
  data: {}[] | undefined;
  columns: {
    header: string;
    accessor: string;
    Cell?: (children: any, index: number) => ReactNode;
  }[];
  index: number;
  title: string;
};

const DataTableAccordion = ({ title, data, index, columns }: Props) => {
  const [collapse, setCollapse] = useState<number[]>([]);
  return (
    <div>
      {collapse?.includes(index) ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setCollapse(collapse.filter((item) => item !== index));
          }}
        >
          {title}
        </Button>
      ) : (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setCollapse([...collapse, index]);
          }}
        >
          {title}
        </Button>
      )}
      <Portal container={document.querySelector(`#portal-${index}`)}>
        <Collapse in={collapse.includes(index)} timeout="auto" unmountOnExit>
          <NestedTable columns={columns} data={data} />
        </Collapse>
      </Portal>
    </div>
  );
};

export default DataTableAccordion;
