import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

const usePagination = (perPage = 50) => {
  const paramsObj = queryString.parse(useLocation().search);
  const page =
    typeof paramsObj.page === 'string' ? parseInt(paramsObj.page) : 1;

  return { page, perPage };
};

export default usePagination;
