import { Box, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { ToWords } from 'to-words';
import { useParams } from 'react-router-dom';
import { dateToString, getEnumKeyFromValue } from '../../utils/stringFunctions';
import DataTable from '../common/DataTable';
import Loading from '../common/Loading';
import Meta from '../page/Meta';

import {
  BocClassification,
  useBureauOfCustomsRequirementQuery,
} from '../../graphql/generated/types';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { commaSeparatedNumber, roundFloat } from '../../utils/numberFunctions';

const BureauOfCustomsRequirementPackingListPage = () => {
  const toWords = new ToWords();
  const title = `Print BOC Packing List`;
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');

  const {
    data: { bureauOfCustomsRequirement } = {},
    loading,
    error,
  } = useBureauOfCustomsRequirementQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: { id },
    },
  });

  const bocInvoiceItemsColumns: Columns<
    Paths<
      ObjectFromArray<
        NonNullable<typeof bureauOfCustomsRequirement>['bocInvoiceItems']
      >
    >
  > = [
    {
      header: ``,
      accessor: 'packingListItem.item.bocClassification',
      align: 'left',
      Cell: (children: string) =>
        getEnumKeyFromValue(BocClassification, children),
    },
    {
      header: 'No. of Boxes',
      accessor: 'packingListItem.actualNumberOfBoxes',
      Cell: (children) => children && commaSeparatedNumber(children),
    },
    {
      header: `Item Code`,
      align: 'left',
      accessor: 'packingListItem.item.supplierItemCode',
    },
    {
      header: `Item Name`,
      align: 'left',
      accessor: 'packingListItem.item.supplierItemName',
    },
    {
      header: 'Quantity',
      accessor: 'packingListItem.actualQuantity',
      Cell: (children) => children && commaSeparatedNumber(children),
    },
    {
      header: 'Net Weight',
      accessor: 'packingListItem.netWeight',
      align: 'right',
      Cell: (children: number) =>
        commaSeparatedNumber(+roundFloat(children), 2),
    },
    {
      header: 'Gross Weight',
      accessor: 'packingListItem.grossWeight',
      align: 'right',
      Cell: (children: number) =>
        commaSeparatedNumber(+roundFloat(children), 2),
    },
    {
      header: 'Measurement',
      accessor: 'packingListItem.cubicMeterPerBox',
      align: 'right',
      Cell: (children: number) =>
        commaSeparatedNumber(+roundFloat(children), 3),
    },
  ];

  const totalNumberOfBoxes =
    bureauOfCustomsRequirement?.bocInvoiceItems.reduce((acc, cur) => {
      if (!cur.packingListItem?.actualNumberOfBoxes) return acc;
      return acc + cur.packingListItem?.actualNumberOfBoxes;
    }, 0) || 0;

  const totalQuantity =
    bureauOfCustomsRequirement?.bocInvoiceItems.reduce((acc, cur) => {
      if (!cur.packingListItem?.actualQuantity) return acc;
      return acc + cur.packingListItem?.actualQuantity;
    }, 0) || 0;

  const totalNetWeight =
    bureauOfCustomsRequirement?.bocInvoiceItems.reduce((acc, cur) => {
      if (!cur.packingListItem?.netWeight) return acc;
      return acc + cur.packingListItem?.netWeight;
    }, 0) || 0;

  const totalGrossWeight =
    bureauOfCustomsRequirement?.bocInvoiceItems.reduce((acc, cur) => {
      if (!cur.packingListItem?.grossWeight) return acc;
      return acc + cur.packingListItem?.grossWeight;
    }, 0) || 0;

  const totalCbm =
    bureauOfCustomsRequirement?.bocInvoiceItems.reduce((acc, cur) => {
      if (!cur.packingListItem?.cubicMeterPerBox) return acc;
      return acc + cur.packingListItem?.cubicMeterPerBox;
    }, 0) || 0;

  if (loading) return <Loading />;

  return (
    <>
      <Meta pageTitle={title} />
      <Typography
        variant="h3"
        align="center"
        sx={{
          textTransform: 'uppercase',
          fontWeight: 'bold',
          color: (theme) => theme.palette.common.black,
        }}
      >
        {bureauOfCustomsRequirement?.supplier.name}
      </Typography>
      <Typography
        variant="h5"
        align="center"
        sx={{
          textTransform: 'uppercase',
          color: (theme) => theme.palette.common.black,
        }}
        gutterBottom
      >
        {bureauOfCustomsRequirement?.supplier.origin}
      </Typography>
      <Typography
        variant="h5"
        align="center"
        sx={{
          textTransform: 'uppercase',
          color: (theme) => theme.palette.common.black,
        }}
        gutterBottom
      >
        {bureauOfCustomsRequirement?.supplier.remarks1}
      </Typography>
      <Typography
        variant="h3"
        align="center"
        sx={{
          textDecoration: 'underline',
          textTransform: 'uppercase',
          fontWeight: 'bold',
          my: 2,
          color: (theme) => theme.palette.common.black,
        }}
      >
        Packing List
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          columnGap: 5,
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            columnGap: 2,
            rowGap: 1,
            gridAutoRows: 'min-content',
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Invoice of:
          </Typography>
          <Typography variant="body1">
            {bureauOfCustomsRequirement?.supplier.name}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            For account and Rsk Messrs.:
          </Typography>
          <Typography variant="body1">
            Megafresh International Inc. <br /> Unit 2415 & 2416 AIC Burgundy
            Empire Tower, ADB Avenue cor. Sapphire Road, Pasig City, Metro
            Manila, Philippines
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            columnGap: 2,
            rowGap: 1,
            gridAutoRows: 'min-content',
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Number:
          </Typography>
          <Typography variant="body1">
            {bureauOfCustomsRequirement?.invoiceNumber}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Date:
          </Typography>
          <Typography variant="body1">
            {dateToString(bureauOfCustomsRequirement?.date, 'MMMM d, yyyy')}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Per:
          </Typography>
          <Typography variant="body1">
            {bureauOfCustomsRequirement?.vessel}
          </Typography>{' '}
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            From:
          </Typography>
          <Typography variant="body1">
            {bureauOfCustomsRequirement?.origin}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            To:
          </Typography>
          <Typography variant="body1">
            {bureauOfCustomsRequirement?.destination}
          </Typography>
        </Box>
      </Box>
      {/* <Typography
        variant="h5"
        sx={{
          color: (theme) => theme.palette.common.black,
          mt: 2,
        }}
        gutterBottom
      >
        Shipment Report Items
      </Typography> */}
      <DataTable
        data={bureauOfCustomsRequirement?.bocInvoiceItems}
        columns={bocInvoiceItemsColumns}
        tableSummary={
          bureauOfCustomsRequirement?.bocInvoiceItems && (
            <TableRow>
              <TableCell colSpan={1} align="center">
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Total:
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {commaSeparatedNumber(totalNumberOfBoxes)}
                </Typography>
              </TableCell>
              <TableCell colSpan={2}></TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                {commaSeparatedNumber(totalQuantity)}
              </TableCell>

              <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                {commaSeparatedNumber(+roundFloat(totalNetWeight), 2)}
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                {commaSeparatedNumber(+roundFloat(totalGrossWeight), 2)}
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                {commaSeparatedNumber(+roundFloat(totalCbm), 3)}
              </TableCell>
            </TableRow>
          )
        }
      />

      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          mt: 2,
          color: (theme) => theme.palette.common.black,
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}
          color="inherit"
        >
          Total No. of Boxes: {toWords.convert(totalNumberOfBoxes)}
        </Typography>

        <Typography
          variant="body1"
          sx={{ borderTop: `1px solid black`, px: 10, mt: 5 }}
          color="inherit"
        >
          Signature
        </Typography>
      </Stack>
    </>
  );
};

export default BureauOfCustomsRequirementPackingListPage;
