import { array, date, InferType, number, object, string } from 'yup';



export const schema = object({
  shipmentPreCosting: object().nullable().default(null).required(),
  lastUpdatedAt: date().nullable(),
  remarks: string().nullable(),
  shipmentCostItems: array().of(object({
    quantity: number().required(),
    freeQuantity: number().required(),
    unitCostInPhp: number().required(),
    additionalCost: number().required(),
    remarks: string().nullable(),
    shipmentPreCostingItem: object().nullable().default(null).required(),
  })),

}).required();

export type FormData = InferType<typeof schema>