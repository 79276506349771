import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Typography } from '@mui/material';
import { useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { array, date, InferType, number, object, string } from 'yup';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  useCreateManyItemPricingMutation,
  useItemPricingQuery,
} from '../../graphql/generated/types';
import { gradientButton } from '../../styles/gradientButton';
import { cleanPayload } from '../../utils/apolloClient';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import CSVReaderClickAndDragUpload from '../common/CSVReaderClickAndDragUpload';
import FormContainer from '../common/FormContainer';
import Loading from '../common/Loading';
import Meta from '../page/Meta';

export const schema = object({
  itemPricingItems: array().of(
    object({
      consignee: string().required(),
      internalCode: string().required(),
      consigneeItemName: string().required(),
      effectivityDate: date().required(),
      price: number().required(),
      remarks: string().nullable(),
      sku: string().nullable(),
      smItemCategory: string().required(),
    })
  ),
}).required();

export type FormData = InferType<typeof schema>;

const UploadItemPricingPage = () => {
  const title = `Upload Item Pricing`;
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
  });
  const [itemPricingItems] = useWatch({
    name: ['itemPricingItems'],
    control: methods.control,
  });
  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async (data) =>
        await createManyItemPricing({
          variables: {
            data:
              data?.itemPricingItems?.map(
                ({
                  consignee,
                  internalCode,
                  smItemCategory,
                  ...itemPricingItem
                }) => {
                  return {
                    ...itemPricingItem,
                    consignee: {
                      connect: {
                        name: consignee,
                      },
                    },
                    item: {
                      connect: {
                        internalCode: internalCode,
                      },
                    },
                    smItemCategory: smItemCategory
                      ? {
                          connect: {
                            itemCategoryName: smItemCategory,
                          },
                        }
                      : {},
                  };
                }
              ) || [],
          },
        }),
      (error) =>
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        })
    )(e);
  };

  const {
    data: { itemPricing } = {},
    loading,
    error,
  } = useItemPricingQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: { id },
    },
  });

  const [createManyItemPricing, { loading: mutationLoading }] =
    useCreateManyItemPricingMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(location.pathname.replace('/create', ''));
      },
    });

  useEffect(() => {
    if (itemPricing) {
      methods.reset({ ...cleanPayload(itemPricing) });
    }
  }, [itemPricing, methods]);

  if (loading) return <Loading />;

  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <FormContainer maxWidth="sm">
          <Box component="form" onSubmit={onSubmit} noValidate>
            <Typography
              component="h1"
              variant="h4"
              sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
              gutterBottom
            >
              {title}
            </Typography>
            <CSVReaderClickAndDragUpload
              data={itemPricingItems}
              setData={methods.setValue}
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ mt: 3, ...gradientButton }}
              disabled={mutationLoading}
            >
              Submit
            </Button>
          </Box>
        </FormContainer>
      </FormProvider>
    </>
  );
};

export default UploadItemPricingPage;
