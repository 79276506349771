import { array, date, InferType, mixed, number, object, string } from 'yup';
import { CivilStatus } from '../../graphql/enums/CivilStatus';
import { Department } from '../../graphql/enums/Department';
import { Rank } from '../../graphql/enums/Rank';
import { Status } from '../../graphql/enums/Status';


export const schema = object({
  lastName: string().required(),
  firstName: string().required(),
  middleName: string().nullable(),
  suffix: string().nullable(),
  number: string().required(),
  address: string().nullable(),
  status: mixed<Status>()
  .oneOf(Object.values(Status))
  .required(),
  birthday: date().required(),
  civilStatus: mixed<CivilStatus>().oneOf([null, "", ...Object.values(CivilStatus)] as CivilStatus[]),
  dateHired: date().required(),
  coordinator: object().nullable(),
  departments: array(
    mixed<Department>()
      .oneOf(Object.values(Department))
      .defined()
  ).required(),
  group: string().required(),
  rank: mixed<Rank>()
    .oneOf(Object.values(Rank))
    .required(),
  accountNumber: string().nullable(),
  tinNumber: string().required(),
  sssNumber: string().required(),
  philhealthNumber: string().required(),
  pagibigNumber: string().required(),
  basicRate: number().min(0).required(),
  ecola: number().min(0),
  dailyAllowance: number().min(0),
  monthlyAllowance: number().min(0),
  nameOfFather: string().nullable(),
  nameOfMother: string().nullable(),
  nameOfSpouse: string().nullable(),
  numberOfChildren: number().integer().nullable(),
  employeeChildren: array().of(object({
    name: string().required(),
    birthday:date().required(),
  }).required()),
  documentUrls: array(mixed()).defined()
}).required();

export type FormData = InferType<typeof schema>