import { ControllerFieldState, ControllerRenderProps, FieldPath, FieldValues } from "react-hook-form"
import { BaseSchema } from "yup"
import { isFieldRequired } from "./yup"

export const textFieldHelperProps =
  <
    TFieldValues extends FieldValues = FieldValues, 
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
  >(
    field: ControllerRenderProps<TFieldValues, TName>, 
    fieldState: ControllerFieldState, 
    schema: BaseSchema,
    controllerName: string = field.name
  ) => {
  return {
    error:!!fieldState.error?.message,
    helperText:fieldState.error?.message,
    required: isFieldRequired(schema, controllerName),
  }
}