import { Checkbox, Link } from '@mui/material';
import { endOfDay, startOfDay } from 'date-fns';
import { ReactNode } from 'react';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  AggregateInvoicePaymentDocument,
  InvoicePaymentsDocument,
  InvoicePaymentWhereInput,
  QueryMode,
  SupplierBankAccount,
  useDeleteManyInvoicePaymentMutation,
  useInvoicePaymentsLazyQuery,
  useInvoicePaymentsQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { HandleDelete } from '../../types/HandleDelete';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { commaSeparatedNumber, roundFloat } from '../../utils/numberFunctions';
import { dateToString } from '../../utils/stringFunctions';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import DataTableAccordion from '../common/DataTableAccordion';
import DateRangeFilter from '../common/DateRangeFilter';
import DownloadCsvButton from '../common/DownloadCsvButton';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';

const InvoicePaymentsPage = ({ title = 'Invoice Payments' }) => {
  const { handleSnackbarOpen } = useSnackbarContext();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();
  const [searchQueryString, startDateQueryString, endDateQueryString] =
    useGetQueryString(['search', 'startDate', 'endDate']);

  const where: InvoicePaymentWhereInput = {
    ...(typeof startDateQueryString === 'string' &&
      typeof endDateQueryString === 'string' && {
        date: {
          gte: startOfDay(new Date(startDateQueryString)),
          lte: endOfDay(new Date(endDateQueryString)),
        },
      }),
    ...(typeof searchQueryString === 'string' && {
      OR: [
        {
          remarks: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
        {
          supplier: {
            is: {
              name: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },
      ],
    }),
  };
  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
  };
  const countVariables = {
    where,
  };
  const {
    data: { invoicePayments } = {},
    loading,
    error,
  } = useInvoicePaymentsQuery({
    fetchPolicy: 'network-only',
    variables,
  });

  const [
    getDownloadData,
    { data: downloadData, loading: downloadDataLoading },
  ] = useInvoicePaymentsLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      where,
    },
  });

  const [deleteManyInvoicePayment] = useDeleteManyInvoicePaymentMutation({
    refetchQueries: [
      { query: AggregateInvoicePaymentDocument, variables: countVariables },
      {
        query: InvoicePaymentsDocument,
        variables,
      },
    ],
    onError: (error) =>
      handleSnackbarOpen({
        message: 'Delete' + title.toLowerCase() + ' error! ' + error,
        severity: 'error',
      }),
  });
  const checksColumns: Columns<
    Paths<
      ObjectFromArray<
        ObjectFromArray<NonNullable<typeof invoicePayments>>['checks']
      >
    >
  > = [
    {
      header: 'Payee',
      accessor: 'payee',
    },
    {
      header: 'Bank',
      accessor: 'bank',
    },

    {
      header: 'Branch',
      accessor: 'branch',
    },
    {
      header: 'Number',
      accessor: 'number',
    },
    {
      header: 'Date',
      accessor: 'date',
      Cell: (children?: Date) => dateToString(children),
    },
    {
      header: 'Amount',
      accessor: 'amount',
      Cell: (children: number) =>
        commaSeparatedNumber(+roundFloat(children), 2),
    },
  ];
  const itemsColumns: Columns<
    Paths<
      ObjectFromArray<
        ObjectFromArray<
          NonNullable<typeof invoicePayments>
        >['invoicePaymentItems']
      >
    >
  > = [
    {
      header: 'Proforma Invoice Number',
      accessor: 'proformaInvoice.number',
    },
    {
      header: 'Currency',
      accessor: 'currency',
    },
    {
      header: 'Amount',
      accessor: 'amount',
      Cell: (children: number) =>
        commaSeparatedNumber(+roundFloat(children), 2),
    },
  ];

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof invoicePayments>>>
  > = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },
    {
      header: 'Date',
      accessor: 'date',
      Cell: (children?: Date) => dateToString(children),
    },
    {
      header: 'Supplier',
      accessor: 'supplier.name',
    },
    {
      header: 'Mode of Payment',
      accessor: 'modeOfPayment',
    },

    {
      header: 'Supplier Bank Account',
      accessor: 'supplierBankAccount',
      Cell: (children?: SupplierBankAccount) =>
        children && `${children.bank}-${children.accountNumber}`,
    },

    {
      header: 'Swift Code',
      accessor: 'supplierBankAccount.swiftCode',
    },
    {
      header: 'Bank Address',
      accessor: 'supplierBankAccount.bankAddress',
    },
    {
      header: 'Remarks',
      accessor: 'remarks',
    },
    {
      header: 'Documents',
      accessor: 'documentUrls',
      Cell: (children: string[]) =>
        children.map((url, idx) => (
          <Link
            key={idx}
            target="_blank"
            rel="noopener noreferrer"
            href={url}
            sx={{ ':not(:last-child)::after': { content: `", "` } }}
          >{`Document ${idx + 1}`}</Link>
        )),
    },
    {
      header: 'Total',
      accessor: 'total',
      Cell: (children: number) =>
        commaSeparatedNumber(+roundFloat(children), 2),
    },
    {
      header: 'Checks',
      accessor: 'checks',
      Cell: (children: {}[], index: number) => {
        return (
          <DataTableAccordion
            title="Checks"
            data={children}
            index={index}
            columns={checksColumns}
          />
        );
      },
    },
    {
      header: 'Items',
      accessor: 'invoicePaymentItems',
      Cell: (children: {}[], index: number) => {
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={itemsColumns}
          />
        );
      },
    },
  ];

  const handleDelete: HandleDelete = (ids) => {
    deleteManyInvoicePayment({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <>
      <ListView
        loading={loading}
        error={error}
        title={title}
        miscButtons={
          <DownloadCsvButton
            downloadData={downloadData?.invoicePayments.reduce(
              (acc: any, { invoicePaymentItems, checks, ...cur }: any) => {
                const firstIteration =
                  invoicePaymentItems.length > 0
                    ? (invoicePaymentItems || [])?.map(
                        (invoicePaymentItem: any) => {
                          return { ...invoicePaymentItem, ...cur };
                        }
                      )
                    : [{ ...cur }];
                const secondIteration = (checks || [])?.map((check: any) => {
                  return { ...check, ...firstIteration };
                });

                return [
                  ...acc,
                  ...(secondIteration.length > 0
                    ? secondIteration
                    : firstIteration.length > 0
                    ? firstIteration
                    : []),
                ];
              },
              []
            )}
            getDownloadData={getDownloadData}
            downloadDataLoading={downloadDataLoading}
            columns={[...columns, ...itemsColumns, ...checksColumns].filter(
              ({ accessor }) =>
                accessor !== 'invoicePaymentItems' && accessor !== 'checks'
            )}
          />
        }
        actionsMenu={
          <ActionsMenu
            handleDelete={handleDelete}
            selected={selected}
            setSelected={setSelected}
          />
        }
        filters={
          <>
            <DateRangeFilter />
            <Search />
          </>
        }
        dataTable={<DataTable data={invoicePayments} columns={columns} />}
        pagination={
          <Pagination
            query={AggregateInvoicePaymentDocument}
            perPage={perPage}
            page={Number(page)}
            variables={countVariables}
          />
        }
      />
    </>
  );
};

export default InvoicePaymentsPage;
