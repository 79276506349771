import {
  AggregateCostMonitoringDocument,
  CostMonitoringsQuery,
  useCostMonitoringsQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { commaSeparatedNumber, roundFloat } from '../../utils/numberFunctions';
import { dateToString } from '../../utils/stringFunctions';
import DataTable from '../common/DataTable';
import DateRangeFilter from '../common/DateRangeFilter';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';

export const costMonitoringColumns: Columns<
  Paths<ObjectFromArray<NonNullable<CostMonitoringsQuery['costMonitorings']>>>
> = [
  {
    header: 'Item Name.',
    accessor: 'itemName',
  },
  {
    header: 'Internal Code',
    accessor: 'internalCode',
  },
  {
    header: 'Last Costing',
    accessor: 'lastCosting',
    Cell: (children: number) => commaSeparatedNumber(+roundFloat(children), 2),
  },
  {
    header: '2nd to the Last Costing',
    accessor: 'secondToTheLastCosting',
    Cell: (children: number) => commaSeparatedNumber(+roundFloat(children), 2),
  },
  {
    header: '% Increase',
    accessor: 'percentIncrease',
    Cell: (children) => children + '%',
  },
  {
    header: 'Date of Last Pricing a Year Ago',
    accessor: 'dateOfTheLastPricingAYearAgo',
    Cell: (children?: Date) => dateToString(children),
  },
  {
    header: 'Last Year Price',
    accessor: 'lastYearPrice',
    Cell: (children: number) => commaSeparatedNumber(+roundFloat(children), 2),
  },
  {
    header: `% Increase from Last Year's Price`,
    accessor: 'percentIncreaseFromLastYearPrice',
  },
];

const CostMonitoringsPage = ({ title = 'Cost Monitoring' }) => {
  const { page, perPage } = usePagination();
  const [searchQueryString, startDateQueryString, endDateQueryString] =
    useGetQueryString(['search', 'startDate', 'endDate']);

  const filters = {
    ...(typeof searchQueryString === 'string' && {
      searchTerm: searchQueryString,
    }),
    ...(typeof startDateQueryString === 'string' && {
      startDate: startDateQueryString,
    }),
    ...(typeof endDateQueryString === 'string' && {
      endDate: endDateQueryString,
    }),
  };
  const variables = {
    ...filters,
    skip: page * perPage - perPage,
    take: perPage,
  };
  const countVariables = {
    searchTerm: searchQueryString,
    startDate: startDateQueryString,
    endDate: endDateQueryString,
  };
  const skip = !startDateQueryString || !endDateQueryString;

  const {
    data: { costMonitorings } = {},
    loading,
    error,
  } = useCostMonitoringsQuery({
    fetchPolicy: 'network-only',
    variables,
    skip,
  });

  return (
    <>
      <ListView
        loading={loading}
        error={error}
        title={title}
        filters={
          <>
            <DateRangeFilter />
            <Search />
          </>
        }
        dataTable={
          <DataTable data={costMonitorings} columns={costMonitoringColumns} />
        }
        pagination={
          <Pagination
            query={AggregateCostMonitoringDocument}
            perPage={perPage}
            page={Number(page)}
            variables={countVariables}
            skip={skip}
          />
        }
      />
    </>
  );
};

export default CostMonitoringsPage;
