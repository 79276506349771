import { Theme } from '@mui/material';

export const nestedGrid = {
  display: 'grid',
  gridTemplateColumns: '1fr 40px',
  columnGap: 1,
  alignItems: 'center',
  // justifyItems: 'center',
  border: (theme: Theme) => `1px solid ${theme.palette.grey[300]}`,
  borderRadius: '4px',
  py: 2,
  px: 1,
  "& > div:first-of-type": {
    display: 'grid',
    gap: 1,
    gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
  }
};
