import { Checkbox } from '@mui/material';
import { ReactNode } from 'react';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  AggregateShipmentCostDocument,
  QueryMode,
  ShipmentCostsDocument,
  ShipmentCostWhereInput,
  useDeleteManyShipmentCostMutation,
  useShipmentCostsLazyQuery,
  useShipmentCostsQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { HandleDelete } from '../../types/HandleDelete';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { commaSeparatedNumber, roundFloat } from '../../utils/numberFunctions';
import { dateToString } from '../../utils/stringFunctions';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import DataTableAccordion from '../common/DataTableAccordion';
import DownloadCsvButton from '../common/DownloadCsvButton';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';

const ShipmentCostsPage = ({ title = 'Shipment Costs' }) => {
  const { handleSnackbarOpen } = useSnackbarContext();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();
  const [searchQueryString] = useGetQueryString(['search']);

  const where: ShipmentCostWhereInput = {
    ...(typeof searchQueryString === 'string' && {
      OR: [
        {
          shipmentPreCosting: {
            is: {
              bureauOfCustomsRequirement: {
                is: {
                  invoiceNumber: {
                    contains: searchQueryString,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
            },
          },
        },
        {
          remarks: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },

        {
          shipmentCostItems: {
            some: {
              shipmentPreCostingItem: {
                is: {
                  shipmentReportItem: {
                    is: {
                      item: {
                        is: {
                          internalCode: {
                            contains: searchQueryString,
                            mode: QueryMode.Insensitive,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        {
          shipmentCostItems: {
            some: {
              shipmentPreCostingItem: {
                is: {
                  shipmentReportItem: {
                    is: {
                      item: {
                        is: {
                          name: {
                            contains: searchQueryString,
                            mode: QueryMode.Insensitive,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      ],
    }),
  };
  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
  };
  const countVariables = {
    where,
  };
  const {
    data: { shipmentCosts } = {},
    loading,
    error,
  } = useShipmentCostsQuery({
    fetchPolicy: 'network-only',
    variables,
  });

  const [
    getDownloadData,
    { data: downloadData, loading: downloadDataLoading },
  ] = useShipmentCostsLazyQuery({
    variables: {
      where,
    },
  });

  const [deleteManyShipmentCost] = useDeleteManyShipmentCostMutation({
    refetchQueries: [
      { query: AggregateShipmentCostDocument, variables: countVariables },
      {
        query: ShipmentCostsDocument,
        variables,
      },
    ],
    onError: (error) =>
      handleSnackbarOpen({
        message: 'Delete' + title.toLowerCase() + ' error! ' + error,
        severity: 'error',
      }),
  });

  const itemsColumns: Columns<
    Paths<
      ObjectFromArray<
        ObjectFromArray<NonNullable<typeof shipmentCosts>>['shipmentCostItems']
      >
    >
  > = [
    {
      header: 'Internal Code',
      accessor: 'shipmentPreCostingItem.shipmentReportItem.item.internalCode',
    },
    {
      header: 'Item Name',
      accessor: 'shipmentPreCostingItem.shipmentReportItem.item.name',
    },
    {
      header: `Quantity`,
      accessor: 'quantity',
      Cell: (children: number) => children.toLocaleString(),
    },
    {
      header: `Received Quantity`,
      accessor: 'receivedQuantity',
      Cell: (children: number) => children.toLocaleString(),
    },
    {
      header: `Invoice Free Quantity`,
      accessor: 'freeQuantity',
      Cell: (children: number) => children.toLocaleString(),
    },
    {
      header: `Unit Cost in PHP`,
      accessor: 'unitCostInPhp',
      Cell: (children: number) =>
        commaSeparatedNumber(+roundFloat(children), 2),
    },
    {
      header: `Additional Cost`,
      accessor: 'additionalCost',
      Cell: (children: number) =>
        commaSeparatedNumber(+roundFloat(children), 2),
    },
    {
      header: `Total Cost`,
      accessor: 'totalCost',
      Cell: (children: number) =>
        commaSeparatedNumber(+roundFloat(children), 2),
    },
    {
      header: `Remarks`,
      accessor: 'remarks',
    },
    {
      header: `Difference in PHP`,
      accessor: 'differenceInPhp',
      Cell: (children: number) =>
        commaSeparatedNumber(+roundFloat(children), 2),
    },
  ];

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof shipmentCosts>>>
  > = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },
    {
      header: 'BOC Invoice No.',
      accessor: 'shipmentPreCosting.bureauOfCustomsRequirement.invoiceNumber',
    },
    {
      header: 'Updated At',
      accessor: 'updatedAt',
      Cell: (children?: Date) => dateToString(children),
    },

    {
      header: 'Remarks',
      accessor: 'remarks',
    },

    {
      header: 'Recon Rounding Difference',
      accessor: 'reconRoundingDifference',
      Cell: (children: number) =>
        commaSeparatedNumber(+roundFloat(children), 2),
    },
    {
      header: 'Invoice Difference in PHP',
      accessor: 'invoiceDifferenceInPhp',
      Cell: (children: number) =>
        commaSeparatedNumber(+roundFloat(children), 2),
    },

    {
      header: 'Items',
      accessor: 'shipmentCostItems',
      Cell: (children: {}[], index: number) => {
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={itemsColumns}
          />
        );
      },
    },
  ];

  const handleDelete: HandleDelete = (ids) => {
    deleteManyShipmentCost({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <>
      <ListView
        loading={loading}
        error={error}
        title={title}
        miscButtons={
          <DownloadCsvButton
            downloadData={downloadData?.shipmentCosts.reduce(
              (acc: any, { shipmentCostItems, ...cur }: any) => {
                if (shipmentCostItems.length > 0) {
                  return [
                    ...acc,
                    ...(shipmentCostItems || [])?.map(
                      (shipmentCostItem: any) => {
                        return { ...shipmentCostItem, ...cur };
                      }
                    ),
                  ];
                } else {
                  return [...acc, { ...cur }];
                }
              },
              []
            )}
            getDownloadData={getDownloadData}
            downloadDataLoading={downloadDataLoading}
            columns={[...columns, ...itemsColumns].filter(
              ({ accessor }) => accessor !== 'shipmentCostItems'
            )}
          />
        }
        actionsMenu={
          <ActionsMenu
            handleDelete={handleDelete}
            selected={selected}
            setSelected={setSelected}
          />
        }
        filters={
          <>
            <Search />
          </>
        }
        dataTable={<DataTable data={shipmentCosts} columns={columns} />}
        pagination={
          <Pagination
            query={AggregateShipmentCostDocument}
            perPage={perPage}
            page={Number(page)}
            variables={countVariables}
          />
        }
      />
    </>
  );
};

export default ShipmentCostsPage;
