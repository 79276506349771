import {
  Box,
  Button,
  ButtonGroup,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';

import { useCSVReader } from 'react-papaparse';
import { customColors } from '../../styles/customColors';
import { commaSeparatedNumber, roundFloat } from '../../utils/numberFunctions';
import { proformaInvoiceItemsColumns } from '../proformaInvoices/ProformaInvoicesPage';
import NestedTable from './NestedTable';

export function lowercaseFirstLetterAndRemoveBlanks(str: string) {
  const strWithoutBlanks = str.trim().replace(/\s+/g, '');
  return strWithoutBlanks.charAt(0).toLowerCase() + strWithoutBlanks.slice(1);
}

interface Props {
  currency: string;
  data: any[] | undefined;
  setData: (name: any, value: unknown, config?: Object) => void;
}

// function isEqual(a: any[], b: any[]) {
//   return (
//     a.length === b.length &&
//     a.every((val, i) => {
//       return Object.keys(val).every((key) => {
//         return val[key] === b[i][key];
//       });
//     })
//   );
// }

export default function CSVReader({ data, setData, currency }: Props) {
  const [parsedColumns, setParsedColumns] = useState<any[]>([]);

  const { CSVReader } = useCSVReader();
  const total =
    data?.reduce((acc, cur) => {
      if (!cur.subtotal) return acc;
      return acc + cur.subtotal;
    }, 0) || 0;
  return (
    <CSVReader
      onUploadAccepted={(results: { data: any[]; errors: any[] }) => {
        if (results.errors.length === 0) {
          const [headers, ...data] = results.data;
          const columns = headers
            .map((header: string) => {
              if (['Quantity'].includes(header)) {
                return {
                  header,
                  accessor: lowercaseFirstLetterAndRemoveBlanks(header),
                  Cell: (children: number) =>
                    commaSeparatedNumber(+roundFloat(children)),
                };
              } else if (
                ['Unit Gross Cost', 'Unit Net Cost'].includes(header)
              ) {
                return {
                  header,
                  accessor: lowercaseFirstLetterAndRemoveBlanks(header),
                  Cell: (children: number) =>
                    commaSeparatedNumber(+roundFloat(children), 2),
                };
              }
              return {
                header,
                accessor: lowercaseFirstLetterAndRemoveBlanks(header),
              };
            })
            .concat([
              {
                header: 'Subtotal',
                accessor: 'subtotal',
                Cell: (children: number) =>
                  commaSeparatedNumber(+roundFloat(children), 2),
              },
            ]);

          setParsedColumns(columns);
          setData(
            'proformaInvoiceItems',
            data
              .filter((values) => {
                return values.length === headers.length;
              })
              .map((values: any[]) => {
                const keys = headers.map((header: string) =>
                  lowercaseFirstLetterAndRemoveBlanks(header)
                );
                const object = keys.reduce(
                  (acc: any, key: string, i: number) => ({
                    ...acc,
                    [key]: values[i],
                  }),
                  {}
                );

                return {
                  ...object,
                  subtotal: object.quantity * object.unitNetCost,
                };
              })
          );
        }
      }}
      onUploadRejected={(file?: File, event?: DragEvent | Event) => {
        console.log(
          `An error occured with the file ${file} for the event ${event}`
        );
      }}
    >
      {({
        getRootProps,
        acceptedFile,
        ProgressBar,
        getRemoveFileProps,
      }: any) => (
        <>
          {/* <CSVReaderClickAndDragUpload data={data} setData={setData} /> */}
          <Stack direction="row" justifyContent="space-between" sx={{ mb: 1 }}>
            <ButtonGroup>
              <Button type="button" {...getRootProps()}>
                Upload CSV
              </Button>
              <Button
                {...getRemoveFileProps()}
                onClick={(event: Event) => {
                  getRemoveFileProps().onClick(event);
                  setParsedColumns([]);
                  setData('proformaInvoiceItems', []);
                }}
              >
                Remove
              </Button>
            </ButtonGroup>
            <div>{acceptedFile && acceptedFile.name}</div>
          </Stack>
          <Box sx={{ overflowX: 'auto', overflowY: 'hidden', mt: 2 }}>
            <NestedTable
              columns={
                parsedColumns.length === 0
                  ? proformaInvoiceItemsColumns
                  : parsedColumns
              }
              data={data}
              tableSummary={
                data &&
                data.length > 0 && (
                  <TableRow>
                    <TableCell colSpan={3}></TableCell>
                    <TableCell>
                      <Typography variant="h6">GrandTotal:</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6">{currency}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6">
                        {commaSeparatedNumber(+roundFloat(total), 2)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              }
            />
          </Box>
          <ProgressBar style={{ background: customColors.linearGradient }} />
        </>
      )}
    </CSVReader>
  );
}
