import {
  AggregateStockKeepingUnitPriceDocument,
  StockKeepingUnitPricesQuery,
  useStockKeepingUnitPricesLazyQuery,
  useStockKeepingUnitPricesQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { commaSeparatedNumber, roundFloat } from '../../utils/numberFunctions';
import { dateToString } from '../../utils/stringFunctions';
import DataTable from '../common/DataTable';

import DownloadCsvButton from '../common/DownloadCsvButton';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';

export const stockKeepingUnitPriceColumns: Columns<
  Paths<
    ObjectFromArray<
      NonNullable<StockKeepingUnitPricesQuery['stockKeepingUnitPrices']>
    >
  >
> = [
  {
    header: 'Consignee',
    accessor: 'consigneeName',
  },
  {
    header: 'Supplier',
    accessor: 'supplier',
  },
  {
    header: 'Supplier Code',
    accessor: 'supplierCode',
  },
  {
    header: 'Item Code',
    accessor: 'itemCode',
  },
  {
    header: 'Item Name',
    accessor: 'itemName',
  },
  {
    header: 'Marketing Name',
    accessor: 'marketingName',
  },
  {
    header: 'Item Type',
    accessor: 'itemType',
  },
  {
    header: 'Last Price',
    accessor: 'lastPrice',
    Cell: (children: number) => commaSeparatedNumber(+roundFloat(children), 2),
  },
  {
    header: 'Last Sku',
    accessor: 'lastSku',
  },
  {
    header: '2nd to the Last Price',
    accessor: 'secondToTheLastPrice',
    Cell: (children: number) => commaSeparatedNumber(+roundFloat(children), 2),
  },
  {
    header: '2nd to the Last Sku',
    accessor: 'secondToTheLastSku',
  },
  {
    header: 'Last Effectivity Date',
    accessor: 'lastEffectivityDate',
    Cell: (children?: Date) => dateToString(children),
  },
  {
    header: 'SM Item',
    accessor: 'smItemCategory',
  },
  {
    header: `Remarks`,
    accessor: 'remarks',
  },
];

const StockKeepingUnitPricesPage = ({ title = 'SKU Price List' }) => {
  const { page, perPage } = usePagination();
  const [searchQueryString] = useGetQueryString(['search']);

  const filters = {
    ...(typeof searchQueryString === 'string' && {
      searchTerm: searchQueryString,
    }),
  };
  const variables = {
    ...filters,
    skip: page * perPage - perPage,
    take: perPage,
  };
  const countVariables = {
    searchTerm: searchQueryString,
  };

  const {
    data: { stockKeepingUnitPrices } = {},
    loading,
    error,
  } = useStockKeepingUnitPricesQuery({
    fetchPolicy: 'network-only',
    variables,
  });

  const [
    getDownloadData,
    { data: downloadData, loading: downloadDataLoading },
  ] = useStockKeepingUnitPricesLazyQuery({
    variables: {
      ...filters,
    },
  });

  return (
    <>
      <ListView
        loading={loading}
        error={error}
        title={title}
        miscButtons={
          <>
            <DownloadCsvButton
              downloadData={downloadData?.stockKeepingUnitPrices}
              getDownloadData={getDownloadData}
              downloadDataLoading={downloadDataLoading}
              columns={stockKeepingUnitPriceColumns}
            />
          </>
        }
        filters={
          <>
            <Search />
          </>
        }
        dataTable={
          <DataTable
            data={stockKeepingUnitPrices}
            columns={stockKeepingUnitPriceColumns}
          />
        }
        pagination={
          <Pagination
            query={AggregateStockKeepingUnitPriceDocument}
            perPage={perPage}
            page={Number(page)}
            variables={countVariables}
          />
        }
      />
    </>
  );
};

export default StockKeepingUnitPricesPage;
