import RemoveIcon from '@mui/icons-material/Remove';
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch
} from 'react-hook-form';
import { gradientButton } from '../../styles/gradientButton';
import { nestedGrid } from '../../styles/nestedGrid';
import { FormProps } from '../../types/FormProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import FormContainer from '../common/FormContainer';
import { FormData } from './utils';

const SupplierForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
  readOnly,
}: FormProps) => {
  const { control } = useFormContext<FormData>();
  const {
    fields: supplierBankAccountFields,
    append: appendSupplierBankAccount,
    remove: removeSupplierBankAccount,
  } = useFieldArray({
    control,
    name: 'supplierBankAccounts',
  });

  const items = useWatch({
    name: 'items',
    control,
  });
  const handleAddBankAccount = () => {
    appendSupplierBankAccount({
      id: undefined,
      bank: '',
      accountName: '',
      accountNumber: '',
      bankAddress: '',
      swiftCode: '',
    });
  };

  const handleRemoveBankAccount = (index: number) => {
    removeSupplierBankAccount(index);
  };

  return (
    <FormContainer maxWidth="md" readOnly={readOnly}>
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
        gutterBottom
      >
        {title}
      </Typography>
      <Box component="form" onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(6, 1fr)',
            gap: 1,
            '& > *': {
              gridColumn: 'span 2',
            },
          }}
        >
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Name"
                fullWidth
                sx={{ gridColumn: 'span 6' }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="address"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Address"
                fullWidth
                sx={{ gridColumn: 'span 6' }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="code"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Code"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="tin"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="TIN"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="origin"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Origin"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />

          <Controller
            name="description1"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Description 1"
                fullWidth
                sx={{ gridColumn: 'span 3' }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="description2"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Description 2"
                fullWidth
                sx={{ gridColumn: 'span 3' }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="remarks1"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Remarks 1"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="remarks2"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Remarks 2"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="remarks3"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Remarks 3"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
        </Box>

        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Supplier Bank Accounts
        </Typography>
        <Stack direction="column" spacing={1}>
          {supplierBankAccountFields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <Controller
                    name={`supplierBankAccounts.${index}.bank`}
                    control={control}
                    defaultValue=""
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`Bank ${index + 1}`}
                        fullWidth
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name={`supplierBankAccounts.${index}.swiftCode`}
                    control={control}
                    defaultValue=""
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`Swift ${index + 1}`}
                        fullWidth
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name={`supplierBankAccounts.${index}.accountNumber`}
                    control={control}
                    defaultValue=""
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`Account Number ${index + 1}`}
                        fullWidth
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name={`supplierBankAccounts.${index}.accountName`}
                    control={control}
                    defaultValue=""
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`Account Name ${index + 1}`}
                        fullWidth
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name={`supplierBankAccounts.${index}.bankAddress`}
                    control={control}
                    defaultValue=""
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`Address ${index + 1}`}
                        fullWidth
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                      />
                    )}
                  />
                </Box>
                <IconButton
                  aria-label="delete"
                  color="primary"
                  size="small"
                  onClick={() => handleRemoveBankAccount(index)}
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            );
          })}
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            sx={{ my: 1 }}
            type="button"
            variant="contained"
            color="primary"
            onClick={handleAddBankAccount}
            size="small"
          >
            Add
          </Button>
        </Stack>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Items
        </Typography>
        <Stack direction="column" spacing={1}>
          {(items || [])?.map((item, index) => {
            return (
              <Box
                key={index}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <TextField
                    label={`Internal Code ${index + 1}`}
                    fullWidth
                    value={(items && items[index]?.internalCode) ?? ''}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    label={`Item Name ${index + 1}`}
                    fullWidth
                    value={(items && items[index]?.name) ?? ''}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    label={`Supplier Code ${index + 1}`}
                    fullWidth
                    value={(items && items[index]?.supplierItemCode) ?? ''}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Box>
              </Box>
            );
          })}
        </Stack>

        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ mt: 3, ...gradientButton }}
          disabled={mutationLoading}
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default SupplierForm;
