import { array, date, InferType, number, object, string } from 'yup';



export const schema = object({
  number: string().nullable(),
  date: date().required(),
  supplier: object().nullable().default(null),
  estimatedTimeOfDeparture: date().required(),
  origin: string().required(),
  destination: string().required(),
  purchaseOrders: array().of(object({
    id: number().required(),
    number: string().required(),
    purchaseOrderItems: array()
    
  })).required().min(1),
  proformaInvoices: array().of(object({
    id: number().required(),
    number: string().required()
  })).required().min(1),
  packingListItems: array().of(object({
    item: object().nullable().default(null).required(),
    purchaseOrderItem: object({
      id: number().required(),
      quantity: number(),
      poBalance: number(),
    }).nullable().default(null),
    actualQuantity: number().required(),
    actualNumberOfBoxes: number().required(),
    netWeight: number().required(),
    grossWeight: number().required(),
    cubicMeterPerBox: number().required(),
    remarks: string().nullable(),
  }))
}).required();

export type FormData = InferType<typeof schema>