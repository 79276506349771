import {
  Checkbox,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
} from '@mui/material';
import { endOfDay, startOfDay } from 'date-fns';
import { ReactNode, useState } from 'react';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  AggregateProformaInvoiceDocument,
  AggregateProformaInvoiceWithBalanceDocument,
  Currency,
  ProformaInvoicesDocument,
  ProformaInvoicesQuery,
  ProformaInvoiceWhereInput,
  PurchaseOrder,
  QueryMode,
  SortOrder,
  useDeleteManyProformaInvoiceMutation,
  useProformaInvoicesQuery,
  useProformaInvoicesWithBalanceQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { HandleDelete } from '../../types/HandleDelete';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { commaSeparatedNumber, roundFloat } from '../../utils/numberFunctions';
import { dateToString } from '../../utils/stringFunctions';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import DataTableAccordion from '../common/DataTableAccordion';
import DateRangeFilter from '../common/DateRangeFilter';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';
import EnumSearchFilter from '../common/EnumSearchFilter';

export const proformaInvoiceColumns: Columns<
  Paths<ObjectFromArray<NonNullable<ProformaInvoicesQuery['proformaInvoices']>>>
> = [
  {
    header: 'Supplier',
    accessor: 'supplier.name',
  },
  {
    header: 'Number',
    accessor: 'number',
  },
  {
    header: 'Invoice Date',
    accessor: 'invoiceDate',
    Cell: (children?: Date) => dateToString(children),
  },
  {
    header: 'Payment Due Date',
    accessor: 'paymentDueDate',
    Cell: (children?: Date) => dateToString(children),
  },

  {
    header: 'Currency',
    accessor: 'currency',
  },
  {
    header: 'Total',
    accessor: 'total',
    Cell: (children: number) => commaSeparatedNumber(+roundFloat(children), 2),
  },
  {
    header: 'Purchase Orders',
    accessor: 'purchaseOrders',
    Cell: (children: PurchaseOrder[]) =>
      children.map(({ number }) => number).join(', '),
  },
  {
    header: 'Documents',
    accessor: 'documentUrls',
    Cell: (children: string[]) =>
      children.map((url, idx) => (
        <Link
          key={idx}
          target="_blank"
          rel="noopener noreferrer"
          href={url}
          sx={{ ':not(:last-child)::after': { content: `", "` } }}
        >{`Document ${idx + 1}`}</Link>
      )),
  },
  {
    header: 'Balance',
    accessor: 'balance',
    Cell: (children: number) => commaSeparatedNumber(+roundFloat(children), 2),
  },
  {
    header: 'Items',
    accessor: 'proformaInvoiceItems',
    Cell: (children: {}[], index: number) => {
      return (
        <DataTableAccordion
          title="Items"
          data={children}
          index={index}
          columns={proformaInvoiceItemsColumns}
        />
      );
    },
  },
];

export const proformaInvoiceItemsColumns: Columns<
  Paths<
    ObjectFromArray<
      ObjectFromArray<
        NonNullable<ProformaInvoicesQuery['proformaInvoices']>
      >['proformaInvoiceItems']
    >
  >
> = [
  {
    header: 'Internal Code',
    accessor: 'item.internalCode',
  },
  {
    header: 'Item Name',
    accessor: 'item.name',
  },

  {
    header: 'Quantity',
    accessor: 'quantity',
    Cell: (children: number) => commaSeparatedNumber(+roundFloat(children)),
  },

  {
    header: 'Unit Gross Cost',
    accessor: 'unitGrossCost',
    Cell: (children: number) => commaSeparatedNumber(+roundFloat(children), 2),
  },
  {
    header: 'Unit Net Cost',
    accessor: 'unitNetCost',
    Cell: (children: number) => commaSeparatedNumber(+roundFloat(children), 2),
  },
  {
    header: 'Subtotal',
    accessor: 'subtotal',
    Cell: (children: number) => commaSeparatedNumber(+roundFloat(children), 2),
  },
];

const ProformaInvoicesPage = ({ title = 'Proforma Invoices' }) => {
  const [selectValue, setSelectValue] = useState('No');
  const { handleSnackbarOpen } = useSnackbarContext();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();
  const [
    searchQueryString,
    startDateQueryString,
    endDateQueryString,
    currencyQueryString,
  ] = useGetQueryString(['search', 'startDate', 'endDate', 'currency']);

  const where: ProformaInvoiceWhereInput = {
    ...(currencyQueryString && {
      currency: {
        equals: currencyQueryString as Currency,
      },
    }),
    ...(typeof startDateQueryString === 'string' &&
      typeof endDateQueryString === 'string' && {
        invoiceDate: {
          gte: startOfDay(new Date(startDateQueryString)),
          lte: endOfDay(new Date(endDateQueryString)),
        },
      }),
    ...(typeof searchQueryString === 'string' && {
      OR: [
        {
          number: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },

        {
          supplier: {
            is: {
              name: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },
        {
          purchaseOrders: {
            some: {
              number: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },
        {
          proformaInvoiceItems: {
            some: {
              item: {
                is: {
                  name: {
                    contains: searchQueryString,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
            },
          },
        },
        {
          proformaInvoiceItems: {
            some: {
              item: {
                is: {
                  internalCode: {
                    contains: searchQueryString,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
            },
          },
        },
      ],
    }),
  };
  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [{ invoiceDate: SortOrder.Desc }],
  };
  const countVariables = {
    where,
  };
  const {
    data: { proformaInvoices } = {},
    loading,
    error,
  } = useProformaInvoicesQuery({
    fetchPolicy: 'network-only',
    variables,
  });

  const {
    data: { proformaInvoicesWithBalance } = {},
    loading: proformaInvoicesWithBalanceLoading,
  } = useProformaInvoicesWithBalanceQuery({
    fetchPolicy: 'network-only',
    variables,
    skip: selectValue !== 'Yes',
  });

  const [deleteManyProformaInvoice] = useDeleteManyProformaInvoiceMutation({
    refetchQueries: [
      { query: AggregateProformaInvoiceDocument, variables: countVariables },
      {
        query: ProformaInvoicesDocument,
        variables,
      },
    ],
    onError: (error) =>
      handleSnackbarOpen({
        message: 'Delete' + title.toLowerCase() + ' error! ' + error,
        severity: 'error',
      }),
  });

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof proformaInvoices>>>
  > = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },
    ...proformaInvoiceColumns,
  ];

  const handleDelete: HandleDelete = (ids) => {
    deleteManyProformaInvoice({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  if (loading || proformaInvoicesWithBalanceLoading) return null;

  return (
    <>
      <ListView
        loading={loading}
        error={error}
        title={title}
        actionsMenu={
          <ActionsMenu
            handleDelete={handleDelete}
            selected={selected}
            setSelected={setSelected}
          />
        }
        filters={
          <>
            <DateRangeFilter />
            <EnumSearchFilter
              label="Currency"
              field="currency"
              enumObj={Currency}
            />
            <FormControl size="small">
              <InputLabel id={`balance-label`}>With Balance?</InputLabel>
              <Select
                labelId={`balance-label`}
                label={`With Balance?`}
                value={selectValue ?? ''}
                onChange={(e) => setSelectValue(e.target.value)}
                sx={{
                  backgroundColor: (theme) => theme.palette.common.white,
                  minWidth: '150px',
                }}
              >
                <MenuItem value="No">No</MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
              </Select>
            </FormControl>
            <Search />
          </>
        }
        dataTable={
          <DataTable
            data={
              selectValue === 'No'
                ? proformaInvoices
                : proformaInvoicesWithBalance
            }
            columns={columns}
          />
        }
        pagination={
          <Pagination
            query={
              selectValue === 'No'
                ? AggregateProformaInvoiceDocument
                : AggregateProformaInvoiceWithBalanceDocument
            }
            perPage={perPage}
            page={Number(page)}
            variables={countVariables}
          />
        }
      />
    </>
  );
};

export default ProformaInvoicesPage;
