import {
  AggregateAuditTrailDocument,
  AuditTrailWhereInput,
  QueryMode,
  SortOrder,
  useAuditTrailsQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { dateToString } from '../../utils/stringFunctions';
import DataTable from '../common/DataTable';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';

const AuditTrailsPage = ({ title = 'Audit Trail' }) => {
  const { page, perPage } = usePagination();
  const [searchQueryString] = useGetQueryString(['search']);

  const where: AuditTrailWhereInput = Object.assign(
    {},
    typeof searchQueryString === 'string'
      ? {
          OR: [
            {
              username: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          ],
        }
      : {}
  );

  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        timestamp: SortOrder.Desc,
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { auditTrails } = {},
    loading,
    error,
  } = useAuditTrailsQuery({
    fetchPolicy: 'network-only',
    variables,
  });

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof auditTrails>>>
  > = [
    {
      header: 'Username',
      accessor: 'username',
    },
    {
      header: 'Timestamp',
      accessor: 'timestamp',
      Cell: (children?: Date) => dateToString(children, 'MM/dd/yyyy hh:mm aa'),
    },
    {
      header: 'Action',
      accessor: 'action',
    },
    {
      header: 'Module/Form',
      accessor: 'moduleForm',
    },
    {
      header: 'New Data',
      accessor: 'newData',
    },
    {
      header: 'Old Data',
      accessor: 'oldData',
    },
  ];

  return (
    <>
      <ListView
        loading={loading}
        error={error}
        title={title}
        filters={<Search />}
        dataTable={<DataTable data={auditTrails} columns={columns} />}
        pagination={
          <Pagination
            query={AggregateAuditTrailDocument}
            perPage={perPage}
            page={Number(page)}
            variables={countVariables}
          />
        }
      />
    </>
  );
};

export default AuditTrailsPage;
