import PageviewIcon from '@mui/icons-material/Pageview';
import { Checkbox, MenuItem } from '@mui/material';
import { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  AggregateItemDocument,
  ItemCategory,
  ItemCommissionType,
  ItemsDocument,
  ItemType,
  ItemWhereInput,
  QueryMode,
  SortOrder,
  Status,
  useDeleteManyItemMutation,
  useItemsLazyQuery,
  useItemsQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { HandleDelete } from '../../types/HandleDelete';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { formatMoney } from '../../utils/numberFunctions';
import { getEnumKeyFromValue } from '../../utils/stringFunctions';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import DataTableAccordion from '../common/DataTableAccordion';
import DownloadCsvButton from '../common/DownloadCsvButton';
import EnumSearchFilter from '../common/EnumSearchFilter';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';
import { downloadItemsColumns } from './utils';

const ItemsPage = ({ title = 'Items' }) => {
  const { handleSnackbarOpen } = useSnackbarContext();
  const location = useLocation();
  const navigate = useNavigate();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();

  const [
    searchQueryString,
    itemTypeQueryString,
    itemCategoryQueryString,
    itemCommissionTypeQueryString,
  ] = useGetQueryString([
    'search',
    'itemType',
    'itemCategory',
    'itemCommissionType',
  ]);
  const where: ItemWhereInput = {
    ...(itemTypeQueryString && {
      itemType: {
        equals: itemTypeQueryString as ItemType,
      },
    }),
    ...(itemCommissionTypeQueryString && {
      itemCommissionType: {
        equals: itemCommissionTypeQueryString as ItemCommissionType,
      },
    }),
    ...(itemCategoryQueryString && {
      itemSubcategory: {
        is: {
          itemCategory: {
            equals: itemCategoryQueryString as ItemCategory,
          },
        },
      },
    }),
    ...(typeof searchQueryString === 'string' && {
      OR: [
        {
          internalCode: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
        {
          name: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
        {
          supplier: {
            is: {
              name: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },
        {
          supplierItemCode: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
        {
          supplierItemName: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
        {
          brand: {
            is: {
              name: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },
        {
          namingGroup: {
            is: {
              name: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },
        {
          itemSubcategory: {
            is: {
              name: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },
      ],
    }),
  };
  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        internalCode: SortOrder.Asc,
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { items } = {},
    loading,
    error,
  } = useItemsQuery({
    fetchPolicy: 'network-only',
    variables,
  });

  const [
    getDownloadData,
    { data: downloadData, loading: downloadDataLoading },
  ] = useItemsLazyQuery({
    variables: {
      where,
    },
  });

  const [deleteManyItem] = useDeleteManyItemMutation({
    refetchQueries: [
      { query: AggregateItemDocument, variables: countVariables },
      {
        query: ItemsDocument,
        variables,
      },
    ],
    onError: (error) =>
      handleSnackbarOpen({
        message: 'Delete' + title.toLowerCase() + ' error! ' + error,
        severity: 'error',
      }),
  });
  const partsColumns: Columns<
    Paths<ObjectFromArray<ObjectFromArray<NonNullable<typeof items>>['parts']>>
  > = [
    {
      header: 'Internal Code',
      accessor: 'internalCode',
    },
    {
      header: 'Item Name',
      accessor: 'name',
    },
    {
      header: 'Brand',
      accessor: 'brand.name',
    },
    {
      header: 'Supplier',
      accessor: 'supplier.name',
    },
  ];
  const columns: Columns<Paths<ObjectFromArray<NonNullable<typeof items>>>> = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },
    {
      header: 'Internal Code',
      accessor: 'internalCode',
    },
    {
      header: 'Name',
      accessor: 'name',
    },

    {
      header: `Supplier's Item Code`,
      accessor: 'supplierItemCode',
    },
    {
      header: 'Item Type',
      accessor: 'itemType',
      Cell: (children: string) => getEnumKeyFromValue(ItemType, children),
    },
    {
      header: 'Item Subcategory',
      accessor: 'itemSubcategory.name',
    },
    {
      header: 'Brand',
      accessor: 'brand.name',
    },
    {
      header: 'Marketing Name',
      accessor: 'marketingName',
    },
    {
      header: 'Status',
      accessor: 'status',
      Cell: (children: string) => getEnumKeyFromValue(Status, children),
    },
    {
      header: 'Parts',
      accessor: 'parts',
      Cell: (children: {}[], index: number) => {
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={partsColumns}
          />
        );
      },
    },
    {
      header: 'Prices',
      accessor: 'latestPerConsigneeItemPricings',
      Cell: (children: {}[], index: number) => {
        const itemsColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<
                NonNullable<typeof items>
              >['latestPerConsigneeItemPricings']
            >
          >
        > = [
          {
            header: 'Consignee Name',
            accessor: 'consignee.name',
          },
          {
            header: 'SKU',
            accessor: 'sku',
          },
          {
            header: 'Price',
            accessor: 'price',
            Cell: (children) => formatMoney(children),
          },
          {
            header: 'SM Item Category',
            accessor: 'smItemCategory.itemCategoryName',
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={itemsColumns}
          />
        );
      },
    },
  ];

  const handleDelete: HandleDelete = (ids) => {
    deleteManyItem({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <>
      <ListView
        loading={loading}
        error={error}
        title={title}
        miscButtons={
          <DownloadCsvButton
            downloadData={downloadData?.items}
            getDownloadData={getDownloadData}
            downloadDataLoading={downloadDataLoading}
            columns={downloadItemsColumns}
          />
        }
        actionsMenu={
          <ActionsMenu
            handleDelete={handleDelete}
            selected={selected}
            setSelected={setSelected}
          >
            {selected.length === 1 && (
              <MenuItem
                onClick={() => navigate(`${location.pathname}/${selected[0]}`)}
              >
                <PageviewIcon />
                View
              </MenuItem>
            )}
          </ActionsMenu>
        }
        filters={
          <>
            <EnumSearchFilter
              label="Item Type"
              field="itemType"
              enumObj={ItemType}
            />
            <EnumSearchFilter
              label="Commission Type"
              field="itemCommissionType"
              enumObj={ItemCommissionType}
            />
            <EnumSearchFilter
              label="Item Category"
              field="itemCategory"
              enumObj={ItemCategory}
            />
            <Search />
          </>
        }
        dataTable={<DataTable data={items} columns={columns} />}
        pagination={
          <Pagination
            query={AggregateItemDocument}
            perPage={perPage}
            page={Number(page)}
            variables={countVariables}
          />
        }
      />
    </>
  );
};

export default ItemsPage;
