import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import { useCreateUserMutation } from '../../graphql/generated/types';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Meta from '../page/Meta';
import UserForm from './UserForm';
import { schema, FormData } from './utils';

const CreateUserPage = () => {
  const title = `Create User`;
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({ employee, userGroups, ...data }) =>
        await createUser({
          variables: {
            data: {
              ...data,
              employee: {
                connect: {
                  id: employee?.id,
                },
              },
              userGroups: {
                set: userGroups || [],
              },
            },
          },
        }),
      (error) =>
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        })
    )(e);
  };

  const [createUser, { loading: mutationLoading }] = useCreateUserMutation({
    onError: (error) =>
      handleSnackbarOpen({
        message: capitalizeFirstLetter(title) + ' error! ' + error,
        severity: 'error',
      }),
    onCompleted: () => {
      handleSnackbarOpen({
        message: capitalizeFirstLetter(title) + ' success!',
        severity: 'success',
      });
      navigate(location.pathname.replace('/create', ''));
    },
  });

  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <UserForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="create"
        />
      </FormProvider>
    </>
  );
};

export default CreateUserPage;
