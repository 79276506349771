import { array, date, InferType, mixed, number, object, string } from 'yup';



export const schema = object({
  shipmentReport: object().nullable().default(null).required(),
  part: object().nullable().default(null).required(),
  motherUnit: object().nullable().default(null).required(),
  quantity: number().required(),
  remarks: string().nullable()


}).required();

export type FormData = InferType<typeof schema>