import React from 'react';

interface Props {
  providers: Array<React.JSXElementConstructor<React.PropsWithChildren<any>>>;
  children: React.ReactNode;
}

const CombineProviders = ({ providers = [], children, ...rest }: Props) => {
  return (
    <>
      {providers.reduceRight((acc, Provider) => {
        return <Provider {...rest}>{acc}</Provider>;
      }, children)}
    </>
  );
};

export default CombineProviders;
