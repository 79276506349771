import { endOfDay, startOfDay } from 'date-fns';
import {
  AggregateShipmentSummaryDocument,
  QueryMode,
  ShipmentReportItemWhereInput,
  SortOrder,
  useShipmentSummariesLazyQuery,
  useShipmentSummariesQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { Columns } from '../../types/Columns';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { dateToString } from '../../utils/stringFunctions';
import DataTable from '../common/DataTable';
import DateRangeFilter from '../common/DateRangeFilter';
import DownloadCsvButton from '../common/DownloadCsvButton';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';

const ShipmentSummaryPage = ({ title = 'Shipment Summary' }) => {
  const { page, perPage } = usePagination();
  const [searchQueryString, startDateQueryString, endDateQueryString] =
    useGetQueryString(['search', 'startDate', 'endDate']);

  const where: ShipmentReportItemWhereInput = {
    ...(typeof startDateQueryString === 'string' &&
      typeof endDateQueryString === 'string' && {
        shipmentReport: {
          is: {
            dateReceived: {
              gte: startOfDay(new Date(startDateQueryString)),
              lte: endOfDay(new Date(endDateQueryString)),
            },
          },
        },
      }),
    ...(typeof searchQueryString === 'string' && {
      OR: [
        {
          shipmentReport: {
            is: {
              transactionNumber: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },
        {
          item: {
            is: {
              name: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },
        {
          item: {
            is: {
              internalCode: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },
      ],
    }),
  };
  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        shipmentReport: {
          dateReceived: SortOrder.Desc,
        },
      },
    ],
  };
  const countVariables = {
    where,
  };
  const skip = !startDateQueryString || !endDateQueryString;

  const {
    data: { shipmentSummaries } = {},
    loading,
    error,
  } = useShipmentSummariesQuery({
    fetchPolicy: 'network-only',
    variables,
    skip,
  });
  const [
    getDownloadData,
    { data: downloadData, loading: downloadDataLoading },
  ] = useShipmentSummariesLazyQuery({
    variables: {
      where,
    },
  });

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof shipmentSummaries>>>
  > = [
    {
      header: 'TR No.',
      accessor: 'shipmentReport.transactionNumber',
    },

    {
      header: 'Date Received',
      accessor: 'shipmentReport.dateReceived',
      Cell: (children?: Date) => dateToString(children),
    },
    {
      header: 'Destination',
      accessor: 'shipmentReport.shipmentReportDestination',
    },

    {
      header: 'Internal Code',
      accessor: 'item.internalCode',
    },
    {
      header: 'Item Name',
      accessor: 'item.name',
    },
    {
      header: 'PO Qty',
      accessor: 'purchaseOrderQuantity',
      Cell: (children: number) => children.toLocaleString(),
    },
    {
      header: 'PL Qty',
      accessor: 'packingListQuantity',
      Cell: (children: number) => children.toLocaleString(),
    },
    {
      header: 'Good Qty',
      accessor: 'goodQuantity',
      Cell: (children: number) => children.toLocaleString(),
    },
    {
      header: 'Damaged Qty',
      accessor: 'damagedQuantity',
      Cell: (children: number) => children.toLocaleString(),
    },
    {
      header: 'Incomplete Qty',
      accessor: 'incompleteQuantity',
      Cell: (children: number) => children.toLocaleString(),
    },
    {
      header: 'Remarks',
      accessor: 'remarks',
    },
  ];

  return (
    <>
      <ListView
        loading={loading}
        error={error}
        title={title}
        miscButtons={
          <DownloadCsvButton
            downloadData={downloadData?.shipmentSummaries}
            getDownloadData={getDownloadData}
            downloadDataLoading={downloadDataLoading}
            columns={columns}
          />
        }
        filters={
          <>
            <DateRangeFilter />
            <Search />
          </>
        }
        dataTable={<DataTable data={shipmentSummaries} columns={columns} />}
        pagination={
          <Pagination
            query={AggregateShipmentSummaryDocument}
            perPage={perPage}
            page={Number(page)}
            variables={countVariables}
            skip={skip}
          />
        }
      />
    </>
  );
};

export default ShipmentSummaryPage;
