import { date, InferType, mixed, object, string } from 'yup';
import { BranchLocationCode, Region } from '../../graphql/generated/types';
import { emptyStringToNull, parseDateString } from '../../utils/yup';


export const schema = object({
  name: string().required(),
  code: string().required(),
  locationCode: mixed<BranchLocationCode>()
  .oneOf(Object.values(BranchLocationCode))
  .required(),
  region: mixed<Region>()
  .oneOf(Object.values(Region))
  .required(),
  address: string().required(),
  province: string().required(),
  city: string().required(),
  storeCode: string().required(),
  storeName: string().required(),
  dateOpened: date().required(),
  contactNumber: string().required(),
  email: string().required(),
  consignee: object().nullable().default(null).required(),

  remarks: string().nullable(),
  dateClosed: date().transform(emptyStringToNull).nullable(),
  employee: object().nullable().default(null),
}).required();

export type FormData = InferType<typeof schema>