import { isDate, parse } from "date-fns";


function isFieldRequired(schema: {} , controllerName: string) {

  const isRequired = (obj: any): boolean => {
    return obj.tests.some(({ name }: { name: string }) => name === 'required')
  }
  const arr = controllerName.split(".")
  
  if (arr.length > 1) {
    const arrName = arr[0]
    const fieldName = arr.at(-1)
    //@ts-ignore

    //@ts-ignore
    return isRequired(schema.describe().fields[arrName].innerType.fields[fieldName])

  } else {
    //@ts-ignore
    return isRequired(schema.describe().fields[controllerName])
  }

}

//https://www.codedaily.io/tutorials/Yup-Date-Validation-with-Custom-Transform
function parseDateString(value: any, originalValue: any) {
  const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, "yyyy-MM-dd", new Date())
  return parsedDate
}

function emptyStringToNull(value: any, originalValue: any) {

  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
}

export {
  isFieldRequired,
  parseDateString,
  emptyStringToNull,
};
