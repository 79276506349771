import { Navigate, Route, Routes as ReactRouterRoutes } from 'react-router-dom';
import { useAuthContext } from '../../contexts/authContext';
import BrandsPage from '../brands/BrandsPage';
import CreateBrandPage from '../brands/CreateBrandPage';
import UpdateBrandPage from '../brands/UpdateBrandPage';
import BureauOfCustomsRequirementInvoicePage from '../bureauOfCustomsRequirements/BureauOfCustomsRequirementInvoicePage';
import BureauOfCustomsRequirementPackingListPage from '../bureauOfCustomsRequirements/BureauOfCustomsRequirementPackingListPage';
import BureauOfCustomsRequirementsPage from '../bureauOfCustomsRequirements/BureauOfCustomsRequirementsPage';
import CreateBureauOfCustomsRequirementPage from '../bureauOfCustomsRequirements/CreateBureauOfCustomsRequirementPage';
import UpdateBureauOfCustomsRequirementPage from '../bureauOfCustomsRequirements/UpdateBureauOfCustomsRequirementPage';
import ConsigneeBranchesPage from '../consigneeBranches/ConsigneeBranchesPage';
import CreateConsigneeBranchPage from '../consigneeBranches/CreateConsigneeBranchPage';
import UpdateConsigneeBranchPage from '../consigneeBranches/UpdateConsigneeBranchPage';
import ConsigneesPage from '../consignees/ConsigneesPage';
import CreateConsigneePage from '../consignees/CreateConsigneePage';
import UpdateConsigneePage from '../consignees/UpdateConsigneePage';
import CreateEmployeePage from '../employees/CreateEmployeePage';
import EmployeesPage from '../employees/EmployeesPage';
import UpdateEmployeePage from '../employees/UpdateEmployeePage';
import CreateInvoicePaymentPage from '../invoicePayments/CreateInvoicePaymentPage';
import InvoicePaymentsPage from '../invoicePayments/InvoicePaymentsPage';
import UpdateInvoicePaymentPage from '../invoicePayments/UpdateInvoicePaymentPage';
import CreateManyItemPricingPage from '../itemPricings/CreateManyItemPricingPage';
import ItemPricingsPage from '../itemPricings/ItemPricingsPage';
import UpdateItemPricingPage from '../itemPricings/UpdateItemPricingPage';
import UploadItemPricingPage from '../itemPricings/UploadItemPricingPage';
import CreateItemPage from '../items/CreateItemPage';
import ItemsPage from '../items/ItemsPage';
import UpdateItemPage from '../items/UpdateItemPage';
import CreateItemSubcategoryPage from '../itemSubcategories/CreateItemSubcategoryPage';
import ItemSubcategoriesPage from '../itemSubcategories/ItemSubcategoriesPage';
import UpdateItemSubcategoryPage from '../itemSubcategories/UpdateItemSubcategoryPage';
import CreateMissingItemPage from '../missingItems/CreateMissingItemPage';
import MissingItemsPage from '../missingItems/MissingItemsPage';
import UpdateMissingItemPage from '../missingItems/UpdateMissingItemPage';
import CreateNamingGroupPage from '../namingGroups/CreateNamingGroupPage';
import NamingGroupsPage from '../namingGroups/NamingGroupsPage';
import UpdateNamingGroupPage from '../namingGroups/UpdateNamingGroupPage';
import CreatePackingListPage from '../packingLists/CreatePackingList';
import PackingListsPage from '../packingLists/PackingListsPage';
import UpdatePackingListPage from '../packingLists/UpdatePackingListPage';
import CreateProformaInvoicePage from '../proformaInvoices/CreateProformaInvoicePage';
import ProformaInvoicesPage from '../proformaInvoices/ProformaInvoicesPage';
import UpdateProformaInvoicePage from '../proformaInvoices/UpdateProformaInvoicePage';
import CreatePurchaseOrderPage from '../purchaseOrders/CreatePurchaseOrderPage';
import PrintPurchaseOrderPage from '../purchaseOrders/PrintPurchaseOrderPage';
import PurchaseOrdersPage from '../purchaseOrders/PurchaseOrdersPage';
import UpdatePurchaseOrderPage from '../purchaseOrders/UpdatePurchaseOrderPage';
import CreatePutToUnitPage from '../putToUnits/CreatePutToUnitPage';
import PutToUnitsPage from '../putToUnits/PutToUnitsPage';
import UpdatePutToUnitPage from '../putToUnits/UpdatePutToUnitPage';
import CostMonitoringsPage from '../reports/CostMonitoringsPage';
import PayableProformaInvoicesPage from '../reports/PayableProformaInvoicesPage';
import PendingOrdersPage from '../reports/PendingOrdersPage';
import PendingPackingListsPage from '../reports/PendingPackingListsPage';
import PendingProformaInvoicesPage from '../reports/PendingProformaInvoicesPage';
import ShipmentIssuesPage from '../reports/ShipmentIssuesPage';
import ShipmentSummaryPage from '../reports/ShipmentSummaryPage';
import StockKeepingUnitPricesPage from '../reports/StockKeepingUnitPricesPage';
import CreateShipmentCostPage from '../shipmentCosts/CreateShipmentCostPage';
import ShipmentCostsPage from '../shipmentCosts/ShipmentCostsPage';
import UpdateShipmentCostPage from '../shipmentCosts/UpdateShipmentCostPage';
import CreateShipmentPreCostingPage from '../shipmentPreCostings/CreateShipmentPreCostingPage';
import ShipmentPreCostingsPage from '../shipmentPreCostings/ShipmentPreCostingsPage';
import UpdateShipmentPreCostingPage from '../shipmentPreCostings/UpdateShipmentPreCostingPage';
import CreateShipmentReportPage from '../shipmentReports/CreateShipmentReportPage';
import PrintShipmentReportPage from '../shipmentReports/PrintShipmentReportPage';
import ShipmentReportsPage from '../shipmentReports/ShipmentReportsPage';
import UpdateShipmentReportPage from '../shipmentReports/UpdateShipmentReportPage';
import CreateSmItemCategoryPage from '../smItemCategories/CreateSmItemCategoryPage';
import SmItemCategoriesPage from '../smItemCategories/SmItemCategoriesPage';
import UpdateSmItemCategoryPage from '../smItemCategories/UpdateSmItemCategoryPage';
import CreateSupplierPage from '../suppliers/CreateSupplierPage';
import SuppliersPage from '../suppliers/SuppliersPage';
import UpdateSupplierPage from '../suppliers/UpdateSupplierPage';
import ChangePasswordPage from '../users/ChangePasswordPage';
import CreateUserPage from '../users/CreateUserPage';
import UpdateUserPage from '../users/UpdateUserPage';
import UsersPage from '../users/UsersPage';
import HomePage from './HomePage';
import ProtectedRoute from './ProtectedRoute';
import AuditTrailsPage from '../reports/AuditTrailsPage';

const Routes = () => {
  const { currentUser } = useAuthContext();
  return (
    <ReactRouterRoutes>
      <Route path="*" element={<Navigate to="/" />} />
      <Route path="/home" element={<HomePage />} />
      <Route path="/">
        <Route element={<ProtectedRoute user={currentUser} />}>
          <Route index={true} element={<HomePage />} />
          <Route path="users">
            <Route index={true} element={<UsersPage />} />
            <Route path="create" element={<CreateUserPage />} />
            <Route path=":id">
              <Route path="update" element={<UpdateUserPage />} />
              <Route path="change-password" element={<ChangePasswordPage />} />
            </Route>
          </Route>
          <Route path="employees">
            <Route index={true} element={<EmployeesPage />} />
            <Route path="create" element={<CreateEmployeePage />} />
            <Route path=":id">
              <Route path="update" element={<UpdateEmployeePage />} />
              <Route
                index={true}
                element={
                  <UpdateEmployeePage title="View Employee" readOnly={true} />
                }
              />
            </Route>
          </Route>
          <Route path="suppliers">
            <Route index={true} element={<SuppliersPage />} />
            <Route path="create" element={<CreateSupplierPage />} />
            <Route path=":id">
              <Route
                index={true}
                element={
                  <UpdateSupplierPage title="View Supplier" readOnly={true} />
                }
              />
              <Route path="update" element={<UpdateSupplierPage />} />
            </Route>
          </Route>
          <Route path="item-subcategories">
            <Route index={true} element={<ItemSubcategoriesPage />} />
            <Route path="create" element={<CreateItemSubcategoryPage />} />
            <Route path=":id">
              <Route path="update" element={<UpdateItemSubcategoryPage />} />
            </Route>
          </Route>
          <Route path="naming-groups">
            <Route index={true} element={<NamingGroupsPage />} />
            <Route path="create" element={<CreateNamingGroupPage />} />
            <Route path=":id">
              <Route path="update" element={<UpdateNamingGroupPage />} />
            </Route>
          </Route>
          <Route path="brands">
            <Route index={true} element={<BrandsPage />} />
            <Route path="create" element={<CreateBrandPage />} />
            <Route path=":id">
              <Route path="update" element={<UpdateBrandPage />} />
            </Route>
          </Route>
          <Route path="sm-item-categories">
            <Route index={true} element={<SmItemCategoriesPage />} />
            <Route path="create" element={<CreateSmItemCategoryPage />} />
            <Route path=":id">
              <Route path="update" element={<UpdateSmItemCategoryPage />} />
            </Route>
          </Route>
          <Route path="items">
            <Route index={true} element={<ItemsPage />} />
            <Route path="create" element={<CreateItemPage />} />
            <Route path=":id">
              <Route
                index={true}
                element={<UpdateItemPage title="View Item" readOnly={true} />}
              />
              <Route path="update" element={<UpdateItemPage />} />
            </Route>
          </Route>
          <Route path="item-pricings">
            <Route index={true} element={<ItemPricingsPage />} />
            <Route path="create" element={<CreateManyItemPricingPage />} />
            <Route path="upload" element={<UploadItemPricingPage />} />
            <Route path=":id">
              <Route path="update" element={<UpdateItemPricingPage />} />
            </Route>
          </Route>
          <Route path="purchase-orders">
            <Route index={true} element={<PurchaseOrdersPage />} />
            <Route path="create" element={<CreatePurchaseOrderPage />} />
            <Route path=":id">
              <Route path="update" element={<UpdatePurchaseOrderPage />} />
              <Route
                path="print"
                element={
                  <UpdatePurchaseOrderPage
                    title="Purchase Order No. "
                    readOnly={true}
                  />
                }
              />
            </Route>
          </Route>
          <Route path="proforma-invoices">
            <Route index={true} element={<ProformaInvoicesPage />} />
            <Route path="create" element={<CreateProformaInvoicePage />} />
            <Route path=":id">
              <Route path="update" element={<UpdateProformaInvoicePage />} />
            </Route>
          </Route>
          <Route path="invoice-payments">
            <Route index={true} element={<InvoicePaymentsPage />} />
            <Route path="create" element={<CreateInvoicePaymentPage />} />
            <Route path=":id">
              <Route path="update" element={<UpdateInvoicePaymentPage />} />
            </Route>
          </Route>
          <Route path="packing-lists">
            <Route index={true} element={<PackingListsPage />} />
            <Route path="create" element={<CreatePackingListPage />} />
            <Route path=":id">
              <Route path="update" element={<UpdatePackingListPage />} />
              <Route
                path="print"
                element={
                  <UpdatePackingListPage title="Packing List" readOnly={true} />
                }
              />
            </Route>
          </Route>
          <Route path="boc-requirements">
            <Route index={true} element={<BureauOfCustomsRequirementsPage />} />
            <Route
              path="create"
              element={<CreateBureauOfCustomsRequirementPage />}
            />
            <Route path=":id">
              <Route
                path="update"
                element={<UpdateBureauOfCustomsRequirementPage />}
              />
              <Route
                path="invoice"
                element={<BureauOfCustomsRequirementInvoicePage />}
              />
              <Route
                path="packing-list"
                element={<BureauOfCustomsRequirementPackingListPage />}
              />
            </Route>
          </Route>
          <Route path="shipment-reports">
            <Route index={true} element={<ShipmentReportsPage />} />
            <Route path="create" element={<CreateShipmentReportPage />} />
            <Route path=":id">
              <Route path="update" element={<UpdateShipmentReportPage />} />
              <Route path="print" element={<PrintShipmentReportPage />} />
            </Route>
          </Route>
          <Route path="missing-items">
            <Route index={true} element={<MissingItemsPage />} />
            <Route path="create" element={<CreateMissingItemPage />} />
            <Route path=":id">
              <Route path="update" element={<UpdateMissingItemPage />} />
            </Route>
          </Route>
          <Route path="put-to-units">
            <Route index={true} element={<PutToUnitsPage />} />
            <Route path="create" element={<CreatePutToUnitPage />} />
            <Route path=":id">
              <Route path="update" element={<UpdatePutToUnitPage />} />
            </Route>
          </Route>
          <Route path="shipment-pre-costings">
            <Route index={true} element={<ShipmentPreCostingsPage />} />
            <Route path="create" element={<CreateShipmentPreCostingPage />} />
            <Route path=":id">
              <Route path="update" element={<UpdateShipmentPreCostingPage />} />
            </Route>
          </Route>
          <Route path="shipment-costs">
            <Route index={true} element={<ShipmentCostsPage />} />
            <Route path="create" element={<CreateShipmentCostPage />} />
            <Route path=":id">
              <Route path="update" element={<UpdateShipmentCostPage />} />
            </Route>
          </Route>
          <Route path="consignees">
            <Route index={true} element={<ConsigneesPage />} />
            <Route path="create" element={<CreateConsigneePage />} />
            <Route path=":id">
              <Route path="update" element={<UpdateConsigneePage />} />
            </Route>
          </Route>
          <Route path="consignee-branches">
            <Route index={true} element={<ConsigneeBranchesPage />} />
            <Route path="create" element={<CreateConsigneeBranchPage />} />
            <Route path=":id">
              <Route
                index={true}
                element={
                  <UpdateConsigneeBranchPage
                    title="View Consginee Branch"
                    readOnly={true}
                  />
                }
              />
              <Route path="update" element={<UpdateConsigneeBranchPage />} />
            </Route>
          </Route>
          {/* Reports */}
          <Route
            path="pending-proforma-invoices"
            element={<PendingProformaInvoicesPage />}
          />
          <Route
            path="payable-proforma-invoices"
            element={<PayableProformaInvoicesPage />}
          />
          <Route
            path="pending-packing-lists"
            element={<PendingPackingListsPage />}
          />
          <Route path="pending-orders" element={<PendingOrdersPage />} />
          <Route path="shipment-summary" element={<ShipmentSummaryPage />} />
          <Route
            path="shipment-issues-tracker"
            element={<ShipmentIssuesPage />}
          />
          <Route path="cost-monitoring" element={<CostMonitoringsPage />} />
          <Route
            path="sku-price-list"
            element={<StockKeepingUnitPricesPage />}
          />
          <Route path="audit-trails" element={<AuditTrailsPage />} />
        </Route>
      </Route>
    </ReactRouterRoutes>
  );
};

export default Routes;
