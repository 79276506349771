import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Menu, MenuItem } from '@mui/material';
import { HandleUpdate } from '../../types/HandleUpdate';
import React from 'react';
import { HandleDelete } from '../../types/HandleDelete';
import { HandleCreate } from '../../types/HandleCreate';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDialogContext } from '../../contexts/dialogContext';
import AlertDialog from './AlertDialog';

interface Props {
  children?: React.ReactNode;
  selected: number[];
  setSelected: React.Dispatch<React.SetStateAction<number[]>>;
  handleDelete?: HandleDelete;
}

const ActionsMenu = ({
  children,
  selected,
  setSelected,
  handleDelete,
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { handleDialogOpen, handleDialogClose } = useDialogContext();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCreate: HandleCreate = () => {
    navigate(`${location.pathname}/create`);
  };

  const handleUpdate: HandleUpdate = (id) => {
    navigate(`${location.pathname}/${id}/update`);
  };

  return (
    <>
      <Button
        id="actions-button"
        aria-controls={open ? 'actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="contained"
        endIcon={<KeyboardArrowDownIcon />}
        disableElevation
        disableFocusRipple
        disableRipple
      >
        Actions
      </Button>
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        // onClick={handleReset}
        MenuListProps={{
          'aria-labelledby': 'actions-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
              fontSize: 18,
              color: (theme) => theme.palette.text.secondary,
              marginRight: (theme) => theme.spacing(1.5),
            },
          },
        }}
      >
        {selected.length === 0 && handleCreate && (
          <MenuItem onClick={() => handleCreate()}>
            <AddIcon />
            Create
          </MenuItem>
        )}
        {selected.length === 1 && handleUpdate && (
          <MenuItem onClick={() => handleUpdate(selected[0])}>
            <EditIcon />
            Update
          </MenuItem>
        )}
        {selected.length > 0 && handleDelete && (
          <MenuItem
            onClick={() => {
              handleDialogOpen(
                <AlertDialog
                  handleClose={handleDialogClose}
                  callback={() => {
                    handleDelete(selected);
                    handleClose();
                    setSelected([]);
                  }}
                  dialogContentText={`Are you sure you want to delete ${selected.length} item(s)?`}
                />
              );
            }}
          >
            <DeleteIcon />
            Delete
          </MenuItem>
        )}
        {children}
      </Menu>
    </>
  );
};

export default ActionsMenu;
