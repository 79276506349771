import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import { useCreatePackingListMutation } from '../../graphql/generated/types';
import { prismaCreate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Meta from '../page/Meta';
import PackingListForm from './PackingListForm';

import { schema, FormData } from './utils';

const CreatePackingListPage = () => {
  const title = `Create Packing List`;
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({
        purchaseOrders,
        proformaInvoices,
        packingListItems,
        supplier,
        ...data
      }) =>
        await createPackingList({
          variables: {
            data: {
              ...prismaCreate(data),
              purchaseOrders: {
                connect: purchaseOrders?.map((purchaseOrder) => {
                  return {
                    id: purchaseOrder.id,
                  };
                }),
              },
              proformaInvoices: {
                connect: proformaInvoices?.map((proformaInvoice) => {
                  return {
                    id: proformaInvoice.id,
                  };
                }),
              },
              packingListItems: {
                create: packingListItems?.map((packingListItem) => {
                  const { item, purchaseOrderItem, ...data } = packingListItem;
                  return {
                    ...prismaCreate(data),
                    ...(purchaseOrderItem && {
                      purchaseOrderItem: {
                        connect: {
                          id: purchaseOrderItem?.id,
                        },
                      },
                    }),
                    item: {
                      connect: { id: item?.id },
                    },
                  };
                }),
              },
            },
          },
        }),
      (error) =>
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        })
    )(e);
  };

  const [createPackingList, { loading: mutationLoading }] =
    useCreatePackingListMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(location.pathname.replace('/create', ''));
      },
    });

  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <PackingListForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
        />
      </FormProvider>
    </>
  );
};

export default CreatePackingListPage;
