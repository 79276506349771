import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  usePackingListQuery,
  useUpdatePackingListMutation,
} from '../../graphql/generated/types';
import { cleanPayload } from '../../utils/apolloClient';
import {
  getItemsForDelete,
  getItemsForUpsert,
  prismaCreate,
  prismaUpdate,
} from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Loading from '../common/Loading';
import Meta from '../page/Meta';
import PackingListForm from './PackingListForm';
import { schema, FormData } from './utils';

const UpdatePackingListPage = ({
  title = `Update Packing List`,
  readOnly = false,
}) => {
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({
        purchaseOrders,
        proformaInvoices,
        packingListItems,
        supplier,
        ...data
      }) => {
        const packingListItemsToDelete = getItemsForDelete(
          packingList?.packingListItems,
          packingListItems
        );
        const packingListItemsToUpsert = getItemsForUpsert(packingListItems);

        await updatePackingList({
          variables: {
            where: {
              id,
            },
            data: {
              ...prismaUpdate(data),
              purchaseOrders: {
                set: purchaseOrders?.map((purchaseOrder) => {
                  return {
                    id: purchaseOrder.id,
                  };
                }),
              },
              proformaInvoices: {
                set: proformaInvoices?.map((proformaInvoice) => {
                  return {
                    id: proformaInvoice.id,
                  };
                }),
              },
              packingListItems: {
                delete: packingListItemsToDelete,
                upsert: packingListItemsToUpsert.map(
                  ({ id, purchaseOrderItem, ...packingListItem }) => {
                    return {
                      where: {
                        id,
                      },
                      update: {
                        ...prismaUpdate(packingListItem),

                        purchaseOrderItem: purchaseOrderItem
                          ? {
                              connect: {
                                id: purchaseOrderItem?.id,
                              },
                            }
                          : { disconnect: true },

                        item: { connect: { id: packingListItem.item.id } },
                      },
                      create: {
                        ...prismaCreate(packingListItem),
                        ...(purchaseOrderItem && {
                          purchaseOrderItem: {
                            connect: {
                              id: purchaseOrderItem?.id,
                            },
                          },
                        }),
                        item: { connect: { id: packingListItem.item.id } },
                      },
                    };
                  }
                ),
              },
            },
          },
        });
      },
      (error) =>
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        })
    )(e);
  };

  const {
    data: { packingList } = {},
    loading,
    error,
  } = usePackingListQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: { id },
    },
  });

  const [updatePackingList, { loading: mutationLoading }] =
    useUpdatePackingListMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(-1);
      },
    });

  useEffect(() => {
    if (packingList) {
      methods.reset({
        ...cleanPayload(packingList),
        supplier: packingList?.proformaInvoices?.[0]?.supplier,
      });
    }
  }, [packingList, methods]);

  if (loading) return <Loading />;

  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <PackingListForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="update"
          readOnly={readOnly}
        />
      </FormProvider>
    </>
  );
};

export default UpdatePackingListPage;
