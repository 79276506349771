import PrintIcon from '@mui/icons-material/Print';
import { MenuItem } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface Props {
  selected: number[];
}
const PrintMenuItem = ({ selected }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <>
      {selected.length === 1 && (
        <MenuItem
          onClick={() => navigate(`${location.pathname}/${selected[0]}/print`)}
        >
          <PrintIcon />
          Print
        </MenuItem>
      )}
    </>
  );
};

export default PrintMenuItem;
