import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { BaseSchema } from 'yup';
import { ConsigneesDocument } from '../../graphql/generated/types';

import { RequireKeys } from '../../types/UtilityTypes';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import SearchAsYouTypeAutocomplete from '../common/SearchAsYouTypeAutocomplete';

interface Props {
  label: string;
  schema: BaseSchema;
}
const ConsigneeAutocomplete = <T extends FieldValues>({
  name,
  label,
  schema,
  control,
  defaultValue,
}: Props & RequireKeys<UseControllerProps<T>, 'defaultValue' | 'control'>) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => (
        <SearchAsYouTypeAutocomplete
          textFieldProps={{
            ...textFieldHelperProps(field, fieldState, schema),
            label,
          }}
          queryOptions={(value) => {
            return {
              query: ConsigneesDocument,
              variables: {
                where: {
                  OR: [
                    {
                      name: {
                        contains: value,
                        mode: 'insensitive',
                      },
                    },
                  ],
                },
              },
            };
          }}
          getOptionLabel={(option: any) => option?.name}
          value={field.value}
          onChange={(e, value) => field.onChange(value)}
        />
      )}
    />
  );
};

export default ConsigneeAutocomplete;
