import { array, date, InferType, mixed, number, object, string } from 'yup';
import { Currency, ModeOfPayment } from '../../graphql/generated/types';



export const schema = object({
  date: date().required(),
  supplier: object().nullable().default(null).required(),
  modeOfPayment: mixed<ModeOfPayment>()
  .oneOf(Object.values(ModeOfPayment))
  .required(),
  supplierBankAccount: object().nullable().default(null),
  remarks: string().nullable(),
  invoicePaymentItems: array().of(object({
    proformaInvoice: object().nullable().default(null).required(),
    currency: mixed<Currency>()
    .oneOf(Object.values(Currency))
    .required(),
    amount: number().required(),
  })),
  checks: array().of(object({
    payee: string().required(),
    bank: string().required(),
    branch: string().required(),
    number: string().required(),
    date: date().required(),
    amount: number().required(),
  })),
  documentUrls: array(mixed()).defined()
}).required();

export type FormData = InferType<typeof schema>