import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  useShipmentCostQuery,
  useUpdateShipmentCostMutation,
} from '../../graphql/generated/types';
import { cleanPayload } from '../../utils/apolloClient';
import {
  getItemsForDelete,
  getItemsForUpsert,
  prismaCreate,
  prismaUpdate,
} from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Loading from '../common/Loading';
import Meta from '../page/Meta';
import ShipmentCostForm from './ShipmentCostForm';
import { schema, FormData } from './utils';

const UpdateShipmentCostPage = () => {
  const title = `Update Shipment Cost`;
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({ shipmentCostItems, shipmentPreCosting, ...data }) => {
        const shipmentCostItemsToDelete = getItemsForDelete(
          shipmentCost?.shipmentCostItems,
          shipmentCostItems
        );
        const shipmentCostItemsToUpsert = getItemsForUpsert(shipmentCostItems);

        await updateShipmentCost({
          variables: {
            where: {
              id,
            },
            data: {
              ...prismaUpdate(data),
              shipmentPreCosting: {
                connect: {
                  id: shipmentPreCosting.id,
                },
              },
              shipmentCostItems: {
                delete: shipmentCostItemsToDelete,
                upsert: shipmentCostItemsToUpsert.map(
                  ({ id, shipmentPreCostingItem, ...shipmentCostItem }) => {
                    return {
                      where: {
                        id,
                      },
                      update: {
                        ...prismaUpdate(shipmentCostItem),
                        shipmentPreCostingItem: {
                          connect: { id: shipmentPreCostingItem.id },
                        },
                      },
                      create: {
                        ...prismaCreate(shipmentCostItem),
                        shipmentPreCostingItem: {
                          connect: { id: shipmentPreCostingItem.id },
                        },
                      },
                    };
                  }
                ),
              },
            },
          },
        });
      },
      (error) =>
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        })
    )(e);
  };

  const {
    data: { shipmentCost } = {},
    loading,
    error,
  } = useShipmentCostQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: { id },
    },
  });

  const [updateShipmentCost, { loading: mutationLoading }] =
    useUpdateShipmentCostMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(-1);
      },
    });

  useEffect(() => {
    if (shipmentCost) {
      methods.reset({ ...cleanPayload(shipmentCost) });
    }
  }, [shipmentCost, methods]);

  if (loading) return <Loading />;

  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <ShipmentCostForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="update"
        />
      </FormProvider>
    </>
  );
};

export default UpdateShipmentCostPage;
