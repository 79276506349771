import { array, InferType, number, object, string } from 'yup';


export const schema = object({
  name: string().required(),
  code: string().required(),
  address: string().required(),
  tin: string().nullable(),
  origin: string().nullable(),
  description1: string().nullable(),
  description2: string().nullable(),
  remarks1: string().nullable(),
  remarks2: string().nullable(),
  remarks3: string().nullable(),
  supplierBankAccounts: array().of(object({
    id: number().integer().optional(),
    bank: string().required(),
    accountName: string().required(),
    accountNumber: string().required(),
    bankAddress: string().nullable(),
    swiftCode: string().nullable(),
  })),
  items: array().of(object({
    internalCode: string().required(),
    name: string().required(),
    supplierItemCode: string().required(),
  }))
}).required();

export type FormData = InferType<typeof schema>