import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  usePurchaseOrderQuery,
  useUpdatePurchaseOrderMutation,
} from '../../graphql/generated/types';
import { cleanPayload } from '../../utils/apolloClient';
import {
  getItemsForDelete,
  getItemsForUpsert,
  prismaCreate,
  prismaUpdate,
} from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Loading from '../common/Loading';
import Meta from '../page/Meta';
import PurchaseOrderForm from './PurchaseOrderForm';
import { schema, FormData } from './utils';

const UpdatePurchaseOrderPage = ({
  title = `Update Purchase Order No. `,
  readOnly = false,
}) => {
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({ purchaseOrderItems, supplier, ...data }) => {
        const purchaseOrderItemsToDelete = getItemsForDelete(
          purchaseOrder?.purchaseOrderItems,
          purchaseOrderItems
        );
        const purchaseOrderItemsToUpsert =
          getItemsForUpsert(purchaseOrderItems);

        await updatePurchaseOrder({
          variables: {
            where: {
              id,
            },
            data: {
              ...prismaUpdate(data),
              supplier: {
                connect: {
                  id: supplier.id,
                },
              },
              purchaseOrderItems: {
                delete: purchaseOrderItemsToDelete,
                upsert: purchaseOrderItemsToUpsert.map(
                  ({ id, ...purchaseOrderItem }) => {
                    return {
                      where: {
                        id,
                      },
                      update: {
                        ...prismaUpdate(purchaseOrderItem),
                        item: { connect: { id: purchaseOrderItem.item.id } },
                      },
                      create: {
                        ...prismaCreate(purchaseOrderItem),
                        item: { connect: { id: purchaseOrderItem.item.id } },
                      },
                    };
                  }
                ),
              },
            },
          },
        });
      },
      (error) =>
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        })
    )(e);
  };

  const {
    data: { purchaseOrder } = {},
    loading,
    error,
  } = usePurchaseOrderQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: { id },
    },
  });

  const [updatePurchaseOrder, { loading: mutationLoading }] =
    useUpdatePurchaseOrderMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(-1);
      },
    });

  useEffect(() => {
    if (purchaseOrder) {
      methods.reset({ ...cleanPayload(purchaseOrder) });
    }
  }, [purchaseOrder, methods]);

  if (loading) return <Loading />;

  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <PurchaseOrderForm
          title={title + purchaseOrder?.number}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          readOnly={readOnly}
        />
      </FormProvider>
    </>
  );
};

export default UpdatePurchaseOrderPage;
