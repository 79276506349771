import { Checkbox, MenuItem } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { endOfDay, startOfDay } from 'date-fns';
import { ReactNode } from 'react';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  AggregatePurchaseOrderDocument,
  ContainerCapacity,
  PurchaseOrdersDocument,
  PurchaseOrdersQuery,
  PurchaseOrderWhereInput,
  QueryMode,
  SortOrder,
  useDeleteManyPurchaseOrderMutation,
  usePurchaseOrdersLazyQuery,
  usePurchaseOrdersQuery,
  UserRole,
  useUpdateManyPurchaseOrderMutation,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { HandleDelete } from '../../types/HandleDelete';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import {
  dateToString,
  fullName,
  getEnumKeyFromValue,
} from '../../utils/stringFunctions';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import DataTableAccordion from '../common/DataTableAccordion';
import DateRangeFilter from '../common/DateRangeFilter';
import DownloadCsvButton from '../common/DownloadCsvButton';
import Pagination from '../common/Pagination';
import PrintMenuItem from '../common/PrintMenuItem';
import Search from '../common/Search';
import ListView from '../templates/ListView';
import { commaSeparatedNumber, roundFloat } from '../../utils/numberFunctions';
import { useAuthContext } from '../../contexts/authContext';

export const purchaseOrderColumns: Columns<
  Paths<ObjectFromArray<NonNullable<PurchaseOrdersQuery['purchaseOrders']>>>
> = [
  {
    header: 'Date',
    accessor: 'date',
    Cell: (children?: Date) => dateToString(children),
  },
  {
    header: 'Number',
    accessor: 'number',
  },
  {
    header: 'Supplier',
    accessor: 'supplier.name',
  },
  {
    header: 'Container Capacity',
    accessor: 'containerCapacity',
    Cell: (children) => getEnumKeyFromValue(ContainerCapacity, children),
  },
  {
    header: 'Notes',
    accessor: 'notes',
  },
  {
    header: 'Approved By',
    accessor: 'approvedBy',
  },
  {
    header: 'Items',
    accessor: 'purchaseOrderItems',
    Cell: (children: {}[], index: number) => {
      return (
        <DataTableAccordion
          title="Items"
          data={children}
          index={index}
          columns={itemsColumns}
        />
      );
    },
  },
];

const itemsColumns: Columns<
  Paths<
    ObjectFromArray<
      ObjectFromArray<
        NonNullable<PurchaseOrdersQuery['purchaseOrders']>
      >['purchaseOrderItems']
    >
  >
> = [
  {
    header: 'Internal Code',
    accessor: 'item.internalCode',
  },
  {
    header: 'Item Name',
    accessor: 'item.name',
  },
  {
    header: `Supplier's Item Code`,
    accessor: 'item.supplierItemCode',
  },
  {
    header: `Supplier's Item Name`,
    accessor: 'item.supplierItemName',
  },
  {
    header: 'Quantity',
    accessor: 'quantity',
    Cell: (children: number) => commaSeparatedNumber(+roundFloat(children)),
  },
  {
    header: 'Quantity Per Box',
    accessor: 'item.quantityPerBox',
    Cell: (children: number) => commaSeparatedNumber(+roundFloat(children)),
  },
  {
    header: 'Total Boxes',
    accessor: 'totalBoxes',
    Cell: (children) => commaSeparatedNumber(Math.ceil(children)),
  },
  {
    header: 'Net Weight',
    accessor: 'netWeight',
    Cell: (children: number) => commaSeparatedNumber(+roundFloat(children), 2),
  },
  {
    header: 'Gross Weight',
    accessor: 'grossWeight',
    Cell: (children: number) => commaSeparatedNumber(+roundFloat(children), 2),
  },
  {
    header: 'CBM per Box',
    accessor: 'cubicMeterPerBox',
    Cell: (children: number) => commaSeparatedNumber(+roundFloat(children), 3),
  },
  {
    header: 'Total CBM',
    accessor: 'totalCbm',
    Cell: (children: number) => commaSeparatedNumber(+roundFloat(children), 3),
  },
  {
    header: 'Remarks',
    accessor: 'remarks',
  },
];

const PurchaseOrdersPage = ({ title = 'Purchase Orders' }) => {
  const { currentUser } = useAuthContext();
  const { handleSnackbarOpen } = useSnackbarContext();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();
  const [searchQueryString, startDateQueryString, endDateQueryString] =
    useGetQueryString(['search', 'startDate', 'endDate']);

  const where: PurchaseOrderWhereInput = {
    ...(typeof startDateQueryString === 'string' &&
      typeof endDateQueryString === 'string' && {
        date: {
          gte: startOfDay(new Date(startDateQueryString)),
          lte: endOfDay(new Date(endDateQueryString)),
        },
      }),
    ...(typeof searchQueryString === 'string' && {
      OR: [
        {
          number: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
        {
          notes: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
        {
          supplier: {
            is: {
              name: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },
        {
          purchaseOrderItems: {
            some: {
              item: {
                is: {
                  internalCode: {
                    contains: searchQueryString,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
            },
          },
        },
        {
          purchaseOrderItems: {
            some: {
              item: {
                is: {
                  name: {
                    contains: searchQueryString,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
            },
          },
        },
      ],
    }),
  };
  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        date: SortOrder.Desc,
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { purchaseOrders } = {},
    loading,
    error,
  } = usePurchaseOrdersQuery({
    fetchPolicy: 'network-only',
    variables,
  });

  const [
    getDownloadData,
    { data: downloadData, loading: downloadDataLoading },
  ] = usePurchaseOrdersLazyQuery({
    variables: {
      where,
    },
  });

  const [deleteManyPurchaseOrder] = useDeleteManyPurchaseOrderMutation({
    refetchQueries: [
      { query: AggregatePurchaseOrderDocument, variables: countVariables },
      {
        query: PurchaseOrdersDocument,
        variables,
      },
    ],
    onError: (error) =>
      handleSnackbarOpen({
        message: 'Delete' + title.toLowerCase() + ' error! ' + error,
        severity: 'error',
      }),
  });

  const [updateManyPurchaseOrder] = useUpdateManyPurchaseOrderMutation({
    refetchQueries: [
      { query: AggregatePurchaseOrderDocument, variables: countVariables },
      {
        query: PurchaseOrdersDocument,
        variables,
      },
    ],
    onError: (error) =>
      handleSnackbarOpen({
        message: 'Update may purchase orders error! ' + error,
        severity: 'error',
      }),
    onCompleted: () => {
      setSelected([]);
      handleSnackbarOpen({
        message: 'Update may purchase orders success!',
        severity: 'success',
      });
    },
  });

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof purchaseOrders>>>
  > = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },
    ...purchaseOrderColumns,
  ];

  const handleDelete: HandleDelete = (ids) => {
    deleteManyPurchaseOrder({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <>
      <ListView
        loading={loading}
        error={error}
        title={title}
        miscButtons={
          <DownloadCsvButton
            downloadData={downloadData?.purchaseOrders.reduce(
              (acc: any, { purchaseOrderItems, ...cur }: any) => {
                if (purchaseOrderItems.length > 0) {
                  return [
                    ...acc,
                    ...(purchaseOrderItems || [])?.map(
                      (purchaseOrderItem: any) => {
                        return { ...purchaseOrderItem, ...cur };
                      }
                    ),
                  ];
                } else {
                  return [...acc, { ...cur }];
                }
              },
              []
            )}
            getDownloadData={getDownloadData}
            downloadDataLoading={downloadDataLoading}
            columns={[...columns, ...itemsColumns].filter(
              ({ accessor }) => accessor !== 'purchaseOrderItems'
            )}
          />
        }
        actionsMenu={
          <ActionsMenu
            handleDelete={handleDelete}
            selected={selected}
            setSelected={setSelected}
          >
            <PrintMenuItem selected={selected} />
            {selected.length > 0 &&
              (currentUser?.userRole === UserRole.Management ||
                currentUser?.userRole === UserRole['Super User']) && (
                <>
                  <MenuItem
                    onClick={() =>
                      updateManyPurchaseOrder({
                        variables: {
                          where: {
                            id: {
                              in: selected,
                            },
                          },
                          data: {
                            approvedBy: {
                              set: currentUser?.employee
                                ? fullName(currentUser.employee)
                                : currentUser?.username,
                            },
                          },
                        },
                      })
                    }
                  >
                    <ThumbUpIcon />
                    Approve
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      updateManyPurchaseOrder({
                        variables: {
                          where: {
                            id: {
                              in: selected,
                            },
                          },
                          data: {
                            approvedBy: {
                              set: null,
                            },
                          },
                        },
                      })
                    }
                  >
                    <ThumbDownIcon />
                    Disapprove
                  </MenuItem>
                </>
              )}
          </ActionsMenu>
        }
        filters={
          <>
            <DateRangeFilter />
            <Search />
          </>
        }
        dataTable={<DataTable data={purchaseOrders} columns={columns} />}
        pagination={
          <Pagination
            query={AggregatePurchaseOrderDocument}
            perPage={perPage}
            page={Number(page)}
            variables={countVariables}
          />
        }
      />
    </>
  );
};

export default PurchaseOrdersPage;
