import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { UserGroup, UserRole } from '../../graphql/generated/types';
import { gradientButton } from '../../styles/gradientButton';
import { FormProps } from '../../types/FormProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import EnumSelect from '../common/EnumSelect';
import FormContainer from '../common/FormContainer';
import EmployeeAutocomplete from '../shared/EmployeeAutocomplete';
import { FormData } from './utils';

const UserForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
  type,
}: FormProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const { control } = useFormContext<FormData>();
  return (
    <FormContainer maxWidth="xs">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
        gutterBottom
      >
        {title}
      </Typography>

      <Box component="form" onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gap: 1,
          }}
        >
          <Controller
            name="username"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Username"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                InputProps={{
                  readOnly: type === 'update' || type === 'change password',
                }}
                {...field}
              />
            )}
          />

          {(type === 'create' || type === 'change password') && (
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field, fieldState }) => (
                <TextField
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                          size="large"
                        >
                          {showPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...textFieldHelperProps(field, fieldState, schema)}
                  {...field}
                />
              )}
            />
          )}

          {type !== 'change password' && (
            <>
              <EnumSelect
                multiple={true}
                name="userGroups"
                label="User Groups"
                schema={schema}
                enumObj={UserGroup}
                control={control}
                defaultValue={[]}
              />
              <EnumSelect
                name="userRole"
                label="User Role"
                schema={schema}
                enumObj={UserRole}
                control={control}
                defaultValue={'' as unknown as undefined}
              />
              <EmployeeAutocomplete
                schema={schema}
                name="employee"
                control={control}
                label="Employee"
                defaultValue={null}
              />
            </>
          )}
        </Box>
        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ mt: 3, ...gradientButton }}
          disabled={mutationLoading}
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default UserForm;
