import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import queryString from 'query-string';
import { useNavigate, useLocation } from 'react-router';
import { useState } from 'react';

const Search = ({ field = 'search' }) => {
  let navigate = useNavigate();
  let location = useLocation();
  const parsed = queryString.parse(location.search);
  const initialQueryStringValue = parsed[field] || undefined;

  const [searchValue, setSearchValue] = useState(initialQueryStringValue);

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleSubmitSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    delete parsed['page'];
    if (!searchValue) {
      delete parsed[field];
      navigate({
        pathname: location.pathname,
        search: queryString.stringify({ ...parsed }),
      });
    } else {
      navigate({
        pathname: location.pathname,
        search: queryString.stringify({ ...parsed, [field]: searchValue }),
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmitSearch}>
        <TextField
          sx={{
            backgroundColor: (theme) => theme.palette.common.white,
            borderRadius: '4px',
            minWidth: '200px',
          }}
          value={searchValue || ''}
          onChange={handleChangeSearch}
          id="search"
          size="small"
          placeholder="Search..."
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </form>
    </>
  );
};

export default Search;
