import PageviewIcon from '@mui/icons-material/Pageview';
import { Checkbox, MenuItem } from '@mui/material';
import { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  AggregateSupplierDocument,
  QueryMode,
  SortOrder,
  SuppliersDocument,
  SupplierWhereInput,
  useDeleteManySupplierMutation,
  useSuppliersQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { HandleDelete } from '../../types/HandleDelete';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import DataTableAccordion from '../common/DataTableAccordion';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';

const SuppliersPage = ({ title = 'Suppliers' }) => {
  const { handleSnackbarOpen } = useSnackbarContext();
  const location = useLocation();
  const navigate = useNavigate();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();
  const [searchQueryString] = useGetQueryString(['search']);

  const where: SupplierWhereInput = {
    ...(typeof searchQueryString === 'string' && {
      OR: [
        {
          name: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
        {
          origin: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
        {
          code: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
        {
          items: {
            some: {
              name: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },
        {
          items: {
            some: {
              internalCode: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },
      ],
    }),
  };
  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        name: SortOrder.Asc,
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { suppliers } = {},
    loading,
    error,
  } = useSuppliersQuery({
    fetchPolicy: 'network-only',
    variables,
  });

  const [deleteManySupplier] = useDeleteManySupplierMutation({
    refetchQueries: [
      { query: AggregateSupplierDocument, variables: countVariables },
      {
        query: SuppliersDocument,
        variables,
      },
    ],
    onError: (error) =>
      handleSnackbarOpen({
        message: 'Delete' + title.toLowerCase() + ' error! ' + error,
        severity: 'error',
      }),
  });

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof suppliers>>>
  > = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },
    {
      header: 'Code',
      accessor: 'code',
    },
    {
      header: 'Name',
      accessor: 'name',
    },
    {
      header: 'Address',
      accessor: 'address',
    },
    {
      header: 'TIN',
      accessor: 'tin',
    },
    {
      header: 'Origin',
      accessor: 'origin',
    },
    {
      header: 'Items',
      accessor: 'items',
      Cell: (children: {}[], index: number) => {
        const itemsColumns: Columns<
          Paths<
            ObjectFromArray<
              ObjectFromArray<NonNullable<typeof suppliers>>['items']
            >
          >
        > = [
          {
            header: 'Internal Code',
            accessor: 'internalCode',
          },
          {
            header: 'Item Name',
            accessor: 'name',
          },
          {
            header: 'Supplier Code',
            accessor: 'supplierItemCode',
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={itemsColumns}
          />
        );
      },
    },
  ];

  const handleDelete: HandleDelete = (ids) => {
    deleteManySupplier({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <>
      <ListView
        loading={loading}
        error={error}
        title={title}
        actionsMenu={
          <ActionsMenu
            handleDelete={handleDelete}
            selected={selected}
            setSelected={setSelected}
          >
            {selected.length === 1 && (
              <MenuItem
                onClick={() => navigate(`${location.pathname}/${selected[0]}`)}
              >
                <PageviewIcon />
                View
              </MenuItem>
            )}
          </ActionsMenu>
        }
        filters={<Search />}
        dataTable={<DataTable data={suppliers} columns={columns} />}
        pagination={
          <Pagination
            query={AggregateSupplierDocument}
            perPage={perPage}
            page={Number(page)}
            variables={countVariables}
          />
        }
      />
    </>
  );
};

export default SuppliersPage;
