import { Box, TextField, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { InputGroupProps } from '../../types/InputGroupProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import { FormData } from './utils';

const Specifications = ({ schema }: InputGroupProps) => {
  const { control } = useFormContext<FormData>();

  return (
    <>
      <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
        Specifications
      </Typography>
      <Box
        component="div"
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: 1,
        }}
      >
        <Controller
          name="length"
          control={control}
          defaultValue={0}
          render={({ field, fieldState }) => (
            <TextField
              label="Length"
              fullWidth
              type="number"
              {...textFieldHelperProps(field, fieldState, schema)}
              {...field}
            />
          )}
        />
        <Controller
          name="width"
          control={control}
          defaultValue={0}
          render={({ field, fieldState }) => (
            <TextField
              label="Width"
              fullWidth
              type="number"
              {...textFieldHelperProps(field, fieldState, schema)}
              {...field}
            />
          )}
        />
        <Controller
          name="height"
          control={control}
          defaultValue={0}
          render={({ field, fieldState }) => (
            <TextField
              label="Height"
              fullWidth
              type="number"
              {...textFieldHelperProps(field, fieldState, schema)}
              {...field}
            />
          )}
        />
        <Controller
          name="weight"
          control={control}
          defaultValue={0}
          render={({ field, fieldState }) => (
            <TextField
              label="Weight"
              fullWidth
              type="number"
              {...textFieldHelperProps(field, fieldState, schema)}
              {...field}
            />
          )}
        />
        <Controller
          name="dimensionWithBox"
          control={control}
          defaultValue=""
          render={({ field, fieldState }) => (
            <TextField
              label="Dimension With Box"
              fullWidth
              {...textFieldHelperProps(field, fieldState, schema)}
              {...field}
            />
          )}
        />
        <Controller
          name="weightWithBox"
          control={control}
          defaultValue={0}
          render={({ field, fieldState }) => (
            <TextField
              label="Weight With Box"
              fullWidth
              type="number"
              {...textFieldHelperProps(field, fieldState, schema)}
              {...field}
            />
          )}
        />
        <Controller
          name="waterTankCapacity"
          control={control}
          defaultValue=""
          render={({ field, fieldState }) => (
            <TextField
              label="Water Tank Capacity"
              fullWidth
              {...textFieldHelperProps(field, fieldState, schema)}
              {...field}
            />
          )}
        />
        <Controller
          name="wattage"
          control={control}
          defaultValue=""
          render={({ field, fieldState }) => (
            <TextField
              label="Wattage"
              fullWidth
              {...textFieldHelperProps(field, fieldState, schema)}
              {...field}
            />
          )}
        />
        <Controller
          name="flowrateFanSpeed"
          control={control}
          defaultValue=""
          render={({ field, fieldState }) => (
            <TextField
              label="Flowrate / Fan Speed"
              fullWidth
              {...textFieldHelperProps(field, fieldState, schema)}
              {...field}
            />
          )}
        />
        <Controller
          name="maxPressureMaxHoursUsed"
          control={control}
          defaultValue=""
          render={({ field, fieldState }) => (
            <TextField
              label="Max Pressure / Max Hours Used"
              fullWidth
              {...textFieldHelperProps(field, fieldState, schema)}
              {...field}
            />
          )}
        />
        <Controller
          name="voltage"
          control={control}
          defaultValue=""
          render={({ field, fieldState }) => (
            <TextField
              label="Voltage"
              fullWidth
              {...textFieldHelperProps(field, fieldState, schema)}
              {...field}
            />
          )}
        />
        <Controller
          name="temperature"
          control={control}
          defaultValue=""
          render={({ field, fieldState }) => (
            <TextField
              label="Temperature"
              fullWidth
              {...textFieldHelperProps(field, fieldState, schema)}
              {...field}
            />
          )}
        />
        <Controller
          name="inletOutletNptSettingType"
          control={control}
          defaultValue=""
          render={({ field, fieldState }) => (
            <TextField
              label="Inlet Outlet NPT Setting Type"
              fullWidth
              {...textFieldHelperProps(field, fieldState, schema)}
              {...field}
            />
          )}
        />
        <Controller
          name="filterLifeSpanInMonths"
          control={control}
          defaultValue={0}
          render={({ field, fieldState }) => (
            <TextField
              label="Filter Life Span in Mos"
              fullWidth
              type="number"
              {...textFieldHelperProps(field, fieldState, schema)}
              {...field}
            />
          )}
        />
        <Controller
          name="typeOfFilter"
          control={control}
          defaultValue=""
          render={({ field, fieldState }) => (
            <TextField
              label="Type of Filter"
              fullWidth
              {...textFieldHelperProps(field, fieldState, schema)}
              {...field}
            />
          )}
        />
        <Controller
          name="noiseLevel"
          control={control}
          defaultValue=""
          render={({ field, fieldState }) => (
            <TextField
              label="Noise Level"
              fullWidth
              {...textFieldHelperProps(field, fieldState, schema)}
              {...field}
            />
          )}
        />
        <Controller
          name="areaCoverage"
          control={control}
          defaultValue=""
          render={({ field, fieldState }) => (
            <TextField
              label="Area Coverage"
              fullWidth
              {...textFieldHelperProps(field, fieldState, schema)}
              {...field}
            />
          )}
        />
      </Box>
    </>
  );
};

export default Specifications;
