import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import { useCreateBureauOfCustomsRequirementMutation } from '../../graphql/generated/types';
import { getUploads, prismaCreate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Meta from '../page/Meta';
import BureauOfCustomsRequirementForm from './BureauOfCustomsRequirementForm';
import { schema, FormData } from './utils';

const CreateBureauOfCustomsRequirementPage = () => {
  const title = `Create BOC Requirement`;
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({
        bocInvoiceItems,
        packingLists,
        documentUrls,
        supplier,
        ...data
      }) =>
        await createBureauOfCustomsRequirement({
          variables: {
            data: {
              ...prismaCreate(data),
              documentUrlsUploads: getUploads(documentUrls),
              packingLists: {
                connect: packingLists?.map((packingList) => {
                  return {
                    id: packingList.id,
                  };
                }),
              },
              bocInvoiceItems: {
                create: bocInvoiceItems?.map((bocInvoiceItem) => {
                  const { packingListItem, ...data } = bocInvoiceItem;
                  return {
                    ...prismaCreate(data),
                    packingListItem: {
                      connect: { id: packingListItem.id },
                    },
                  };
                }),
              },
              supplier: {
                connect: {
                  id: supplier.id,
                },
              },
            },
          },
        }),
      (error) =>
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        })
    )(e);
  };

  const [createBureauOfCustomsRequirement, { loading: mutationLoading }] =
    useCreateBureauOfCustomsRequirementMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(location.pathname.replace('/create', ''));
      },
    });

  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <BureauOfCustomsRequirementForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
        />
      </FormProvider>
    </>
  );
};

export default CreateBureauOfCustomsRequirementPage;
