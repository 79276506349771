import { InferType, number, object, string } from 'yup';



export const schema = object({
  part: object().nullable().default(null).required(),
  motherUnit: object().nullable().default(null).required(),
  shipmentReport: object().nullable().default(null).required(),
  missingItemShipmentReport: object().nullable().default(null).required(),
  quantity: number().required(),
  remarks: string().nullable(),
}).required();

export type FormData = InferType<typeof schema>