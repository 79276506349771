import { Box, Button, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import {
  GetInFormItemsDocument,
  ShipmentReportsDocument,
} from '../../graphql/generated/types';
import { gradientButton } from '../../styles/gradientButton';
import { FormProps } from '../../types/FormProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import FormContainer from '../common/FormContainer';
import SearchAsYouTypeAutocomplete from '../common/SearchAsYouTypeAutocomplete';
import { FormData } from './utils';

const PutToUnitForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
}: FormProps) => {
  const { control } = useFormContext<FormData>();

  const [shipmentReport, part, motherUnit] = useWatch({
    name: ['shipmentReport', 'part', 'motherUnit'],
    control,
  });

  return (
    <FormContainer maxWidth="sm">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
        gutterBottom
      >
        {title}
      </Typography>
      <Box component="form" onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gap: 1,
          }}
        >
          <Controller
            name={`shipmentReport`}
            control={control}
            defaultValue={null}
            render={({ field, fieldState }) => (
              <SearchAsYouTypeAutocomplete
                textFieldProps={{
                  ...textFieldHelperProps(field, fieldState, schema),
                  label: `Transaction No.`,
                  // ,
                }}
                sx={{ gridColumn: 'span 2' }}
                queryOptions={(value) => {
                  return {
                    query: ShipmentReportsDocument,
                    variables: {
                      where: {
                        OR: [
                          {
                            transactionNumber: {
                              contains: value,
                              mode: 'insensitive',
                            },
                          },
                        ],
                      },
                    },
                  };
                }}
                getOptionLabel={(option: any) => option?.transactionNumber}
                value={field.value}
                onChange={(e, value) => field.onChange(value)}
              />
            )}
          />
          <TextField
            label={`Date Received`}
            fullWidth
            value={
              (shipmentReport &&
                format(new Date(shipmentReport.dateReceived), 'MM/dd/yyyy')) ??
              ''
            }
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              readOnly: true,
            }}
            sx={{ gridColumn: 'span 2' }}
          />
          <TextField
            label={`Supplier`}
            fullWidth
            value={(shipmentReport && shipmentReport.supplier?.name) ?? ''}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              readOnly: true,
            }}
            sx={{ gridColumn: 'span 4' }}
          />
          <Controller
            name={`part`}
            control={control}
            defaultValue={null}
            render={({ field, fieldState }) => (
              <SearchAsYouTypeAutocomplete
                textFieldProps={{
                  ...textFieldHelperProps(field, fieldState, schema),
                  label: `Part Item Name`,
                  // ,
                }}
                sx={{ gridColumn: 'span 3' }}
                queryOptions={(value) => {
                  return {
                    query: GetInFormItemsDocument,
                    variables: {
                      where: {
                        OR: [
                          {
                            name: {
                              contains: value,
                              mode: 'insensitive',
                            },
                          },
                        ],
                      },
                    },
                  };
                }}
                getOptionLabel={(option: any) => option?.name}
                value={field.value}
                onChange={(e, value) => field.onChange(value)}
              />
            )}
          />
          <TextField
            label={`Part Internal Code`}
            fullWidth
            value={(part && part.internalCode) ?? ''}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              readOnly: true,
            }}
          />
          <Controller
            name={`motherUnit`}
            control={control}
            defaultValue={null}
            render={({ field, fieldState }) => (
              <SearchAsYouTypeAutocomplete
                textFieldProps={{
                  ...textFieldHelperProps(field, fieldState, schema),
                  label: `Mother Unit Item Name`,
                  // ,
                }}
                sx={{ gridColumn: 'span 3' }}
                queryOptions={(value) => {
                  return {
                    query: GetInFormItemsDocument,
                    variables: {
                      where: {
                        OR: [
                          {
                            name: {
                              contains: value,
                              mode: 'insensitive',
                            },
                          },
                        ],
                      },
                    },
                  };
                }}
                getOptionLabel={(option: any) => option?.name}
                value={field.value}
                onChange={(e, value) => field.onChange(value)}
              />
            )}
          />
          <Controller
            name="quantity"
            control={control}
            defaultValue={0}
            render={({ field, fieldState }) => (
              <TextField
                label="Quantity"
                fullWidth
                type="number"
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name={`missingItemShipmentReport`}
            control={control}
            defaultValue={null}
            render={({ field, fieldState }) => (
              <SearchAsYouTypeAutocomplete
                textFieldProps={{
                  ...textFieldHelperProps(field, fieldState, schema),
                  label: `TR No. with Missing Item`,
                  // ,
                }}
                sx={{ gridColumn: 'span 4' }}
                queryOptions={(value) => {
                  return {
                    query: ShipmentReportsDocument,
                    variables: {
                      where: {
                        missingItems: {
                          some: {
                            AND: [
                              {
                                motherUnitId: {
                                  equals: motherUnit.id,
                                },
                              },
                              {
                                partId: {
                                  equals: part.id,
                                },
                              },
                            ],
                          },
                        },
                        OR: [
                          {
                            transactionNumber: {
                              contains: value,
                              mode: 'insensitive',
                            },
                          },
                        ],
                      },
                    },
                  };
                }}
                getOptionLabel={(option: any) => option?.transactionNumber}
                value={field.value}
                onChange={(e, value) => field.onChange(value)}
                disabled={!motherUnit?.id || !part?.id}
              />
            )}
          />
          <Controller
            name="remarks"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Remarks"
                fullWidth
                sx={{ gridColumn: 'span 4' }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
        </Box>

        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ mt: 3, ...gradientButton }}
          disabled={mutationLoading}
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default PutToUnitForm;
