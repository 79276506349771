import DownloadIcon from '@mui/icons-material/Download';
import { Box, Button, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useRef } from 'react';
import { CSVLink } from 'react-csv';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import {
  Currency,
  GetInFormPurchaseOrdersDocument,
} from '../../graphql/generated/types';
import { gradientButton } from '../../styles/gradientButton';
import { FormProps } from '../../types/FormProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import CSVReader from '../common/CSVReader';
import EnumSelect from '../common/EnumSelect';
import FormContainer from '../common/FormContainer';
import SearchAsYouTypeAutocomplete from '../common/SearchAsYouTypeAutocomplete';
import Upload from '../common/upload/Upload';
import SupplierAutocomplete from '../shared/SupplierAutocomplete';
import { FormData } from './utils';

const ProformaInvoiceForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
}: FormProps) => {
  const { control, setValue } = useFormContext<FormData>();
  const csvLink = useRef<any>(null);
  const [proformaInvoiceItems, currency, supplier, purchaseOrders] = useWatch({
    name: ['proformaInvoiceItems', 'currency', 'supplier', 'purchaseOrders'],
    control,
  });

  return (
    <FormContainer maxWidth="md">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
        gutterBottom
      >
        {title}
      </Typography>
      <Box component="form" onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(6, 1fr)',
            gap: 1,
          }}
        >
          <SupplierAutocomplete
            name="supplier"
            schema={schema}
            control={control}
            label="Supplier"
            defaultValue={null}
            sx={{ gridColumn: 'span 3' }}
          />
          <Controller
            name="number"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Number"
                fullWidth
                sx={{ gridColumn: 'span 3' }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="invoiceDate"
            control={control}
            defaultValue={'' as unknown as Date}
            render={({ field, fieldState }) => (
              <TextField
                label="Invoice Date"
                fullWidth
                sx={{ gridColumn: 'span 2' }}
                type="date"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="paymentDueDate"
            control={control}
            defaultValue={'' as unknown as Date}
            render={({ field, fieldState }) => (
              <TextField
                label="Payment Due Date"
                fullWidth
                sx={{ gridColumn: 'span 2' }}
                type="date"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <EnumSelect
            name="currency"
            label="Currency"
            schema={schema}
            enumObj={Currency}
            control={control}
            sx={{ gridColumn: 'span 2' }}
            defaultValue={'' as unknown as undefined}
          />
          {/* <Controller
            name="currency"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Currency"
                fullWidth
                sx={{ gridColumn: 'span 2' }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          /> */}
          <Controller
            name="purchaseOrders"
            control={control}
            defaultValue={[]}
            render={({ field, fieldState }) => (
              <SearchAsYouTypeAutocomplete
                sx={{ gridColumn: 'span 6' }}
                multiple
                textFieldProps={{
                  ...textFieldHelperProps(field, fieldState, schema),
                  label: 'Purchase Orders',
                }}
                queryOptions={(value) => {
                  return {
                    query: GetInFormPurchaseOrdersDocument,
                    variables: {
                      where: {
                        supplierId: {
                          equals: supplier?.id,
                        },
                        approvedBy: {
                          not: null,
                        },
                        OR: [
                          {
                            number: {
                              contains: value,
                              mode: 'insensitive',
                            },
                          },
                        ],
                      },
                    },
                  };
                }}
                getOptionLabel={(option: any) => option?.number}
                value={field.value as any}
                onChange={(e, value) => field.onChange(value)}
                disabled={!supplier?.id}
              />
            )}
          />
        </Box>

        <Upload
          title="Upload Documents"
          name="documentUrls"
          control={control}
          defaultValue={[]}
        />
        <CSVReader
          currency={currency || ''}
          data={proformaInvoiceItems}
          setData={setValue}
        />
        <CSVLink
          headers={[
            { label: 'PO number', key: 'number' },
            { label: 'Internal Code', key: 'item.internalCode' },
            { label: 'Item Name', key: 'item.name' },
            { label: 'Quantity', key: 'quantity' },
            { label: 'Unit Gross Cost', key: 'unitGrossCost' },
            { label: 'Unit Net Cost', key: 'unitNetCost' },
          ]}
          filename={`purchase-order-items-${format(
            Date.now(),
            'yyyy-MM-dd'
          )}.csv`}
          data={(purchaseOrders || []).flatMap(
            ({ purchaseOrderItems, ...purchaseOrder }: Record<string, any>) =>
              purchaseOrderItems?.map(
                (purchaseOrderItem: Record<string, any>) => ({
                  ...purchaseOrderItem,
                  number: purchaseOrder.number,
                })
              )
          )}
          ref={csvLink}
        />
        <Button
          onClick={() => {
            csvLink.current.link.click();
          }}
          variant="contained"
          startIcon={<DownloadIcon />}
          sx={{ marginRight: 'auto', mt: 2 }}
        >
          Download P.O
        </Button>
        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ mt: 3, ...gradientButton }}
          disabled={mutationLoading}
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default ProformaInvoiceForm;
