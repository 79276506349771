import { InferType, object, string } from 'yup';


export const schema = object({
  name: string().required(),
  code: string().required(),
  companyName: string().required(),
  tinNumber: string().required(),
  businessStyle: string().required(),
  businessAddress: string().required(),
  remarks: string().nullable(),
  vendorCode: string().nullable(),
}).required();

export type FormData = InferType<typeof schema>