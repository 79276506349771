import React, { ReactNode } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

const getNestedValue = (obj: Record<string, any>, path: string): any => {
  return path.split('.').reduce((acc, cur) => {
    return acc && acc[cur];
  }, obj);
};

type Props = {
  data: {}[] | undefined;
  columns: {
    header: string;
    accessor: string;
    Cell?: (children: any, index: number) => ReactNode;
  }[];
  tableSummary?: ReactNode;
};

const NestedTable = ({ data, columns, tableSummary = <></> }: Props) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          {columns.map(({ header }) => (
            <TableCell align="center" key={header}>
              {header}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data?.map((item, idx) => (
          <TableRow key={idx}>
            {columns.map(({ accessor, Cell = (children) => <>
                  {children}
                </> }, idx) => (
              <TableCell align="center" key={idx} size="medium">
                {Cell(getNestedValue(item, accessor), idx)}
              </TableCell>
            ))}
          </TableRow>
        ))}
        {tableSummary}
      </TableBody>
    </Table>
  );
};

export default NestedTable;
