import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import { useCreateShipmentPreCostingMutation } from '../../graphql/generated/types';
import { prismaCreate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Meta from '../page/Meta';
import ShipmentPreCostingForm from './ShipmentPreCostingForm';

import { FormData, schema } from './utils';

const CreateShipmentPreCostingPage = () => {
  const title = `Create Shipment Pre Costing`;
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({
        bureauOfCustomsRequirement,
        proformaInvoice,
        purchaseOrders,
        shipmentReports,
        shipmentPreCostingItems,
        ...data
      }) =>
        await createShipmentPreCosting({
          variables: {
            data: {
              ...prismaCreate(data),
              bureauOfCustomsRequirement: {
                connect: {
                  id: bureauOfCustomsRequirement.id,
                },
              },
              proformaInvoice: {
                connect: {
                  id: proformaInvoice.id,
                },
              },
              purchaseOrders: {
                connect: purchaseOrders?.map((purchaseOrder) => {
                  return {
                    id: purchaseOrder.id,
                  };
                }),
              },
              shipmentReports: {
                connect: shipmentReports?.map((shipmentReport) => {
                  return {
                    id: shipmentReport.id,
                  };
                }),
              },
              shipmentPreCostingItems: {
                create: shipmentPreCostingItems?.map(
                  (shipmentPreCostingItem) => {
                    const { shipmentReportItem, ...data } =
                      shipmentPreCostingItem;
                    return {
                      ...prismaCreate(data),
                      shipmentReportItem: {
                        connect: { id: shipmentReportItem.id },
                      },
                    };
                  }
                ),
              },
            },
          },
        }),
      (error) =>
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        })
    )(e);
  };

  const [createShipmentPreCosting, { loading: mutationLoading }] =
    useCreateShipmentPreCostingMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(location.pathname.replace('/create', ''));
      },
    });

  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <ShipmentPreCostingForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
        />
      </FormProvider>
    </>
  );
};

export default CreateShipmentPreCostingPage;
