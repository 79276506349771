import { ToWords } from 'to-words';
import { Currency } from "../graphql/enums/Currency";

export const convertToCurrency = (amount: number, currency?: Currency) => {
  const toWords = new ToWords({
    localeCode: 'en-US'
  });
  let convertedCurrency = ''
  switch (currency) {
    case Currency.CNY:
      convertedCurrency = toWords.convert(amount, {
        currency: true,
        currencyOptions: {
          name: 'Yuan',
          plural: 'Yuan',
          symbol: '¥',
          fractionalUnit: {
            name: 'Cents',
            plural: 'Cents',
            symbol: '',
          },
        },
      })
      break;
    case Currency.PHP:
      convertedCurrency = toWords.convert(amount, {
        
        currency: true,
        currencyOptions: {
          name: 'Pesos',
          plural: 'Pesos',
          symbol: '₱',
          fractionalUnit: {
            name: 'Cents',
            plural: 'Cents',
            symbol: '',
          },
        },
      })
      break;
    case Currency.USD:
      convertedCurrency = toWords.convert(amount, {
        currency: true,
        currencyOptions: {
          name: 'US Dollars',
          plural: 'US Dollars',
          symbol: '$',
          fractionalUnit: {
            name: 'Cents',
            plural: 'Cents',
            symbol: '',
          },
        },
      })
      break;
      case Currency.AUD:
        convertedCurrency = toWords.convert(amount, {
          currency: true,
          currencyOptions: {
            name: 'Australian Dollars',
            plural: 'Australian Dollars',
            symbol: 'A$',
            fractionalUnit: {
              name: 'Cents',
              plural: 'Cents',
              symbol: '',
            },
          },
        })
        break;
      default:
        convertedCurrency = toWords.convert(amount)
  }
  return convertedCurrency
}