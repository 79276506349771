import { ButtonGroup, Stack, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import queryString from 'query-string';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
interface Props {
  startParameter: string;
  endParameter: string;
  startLabel: string;
  endLabel: string;
}

const RangeFilter = ({
  startParameter,
  endParameter,
  startLabel,
  endLabel,
}: Props) => {
  let navigate = useNavigate();
  let location = useLocation();
  const paramsObj = queryString.parse(location.search);
  const startQueryString = paramsObj[startParameter] || null;
  const endQueryString = paramsObj[endParameter] || null;
  const [start, setStart] = useState(startQueryString);
  const [end, setEnd] = useState(endQueryString);

  const handleChangeStartRange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setStart(value);
  };

  const handleChangeEndRange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setEnd(value);
  };

  const handleSubmitRangeFilters = () => {
    if (!start) {
      delete paramsObj[startParameter];
    }
    if (!end) {
      delete paramsObj[endParameter];
    }
    navigate({
      pathname: location.pathname,
      search: queryString.stringify({
        ...paramsObj,
        ...(start && { [startParameter]: start }),
        ...(end && { [endParameter]: end }),
      }),
    });
  };

  // const handleClearRangeFilters = () => {
  //   delete paramsObj[startParameter];
  //   delete paramsObj[endParameter];
  //   navigate({
  //     pathname: location.pathname,
  //     search: queryString.stringify({ ...paramsObj }),
  //   });
  //   setStart('');
  //   setEnd('');
  // };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={1}>
        <TextField
          label={startLabel}
          value={start || ''}
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleChangeStartRange}
          size="small"
          sx={{
            backgroundColor: (theme) => theme.palette.common.white,
          }}
        />

        <TextField
          label={endLabel}
          value={end || ''}
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleChangeEndRange}
          size="small"
          sx={{
            backgroundColor: (theme) => theme.palette.common.white,
            borderRadius: '4px',
          }}
        />
        <ButtonGroup variant="text">
          <Button onClick={handleSubmitRangeFilters}>Submit</Button>
          {/* <Button onClick={handleClearRangeFilters}>Clear</Button> */}
        </ButtonGroup>
      </Stack>
    </LocalizationProvider>
  );
};

export default RangeFilter;
