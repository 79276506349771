import RemoveIcon from '@mui/icons-material/Remove';
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { CivilStatus } from '../../graphql/enums/CivilStatus';
import { Department } from '../../graphql/enums/Department';
import { Rank } from '../../graphql/enums/Rank';
import { gradientButton } from '../../styles/gradientButton';
import { nestedGrid } from '../../styles/nestedGrid';
import { FormProps } from '../../types/FormProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import Dropzone from '../common/upload/Dropzone';
import EmployeeAutocomplete from '../shared/EmployeeAutocomplete';
import EnumSelect from '../common/EnumSelect';
import FormContainer from '../common/FormContainer';
import { FormData } from './utils';
import { Status } from '../../graphql/enums/Status';
import Upload from '../common/upload/Upload';

const EmployeeForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
  readOnly,
}: FormProps) => {
  const { control } = useFormContext<FormData>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'employeeChildren',
  });

  const handleAddChild = () => {
    append({
      name: '',
      birthday: '' as unknown as Date,
    });
  };

  const handleRemoveChild = (index: number) => {
    remove(index);
  };
  return (
    <FormContainer maxWidth="xl" readOnly={readOnly}>
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
        gutterBottom
      >
        {title}
      </Typography>
      <Box component="form" onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gap: 1,
          }}
        >
          <Controller
            name="lastName"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Last Name"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="firstName"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="First Name"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="middleName"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Middle Name"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="suffix"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Suffix"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="number"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Number"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="address"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Address"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                sx={{ gridColumn: 'span 2' }}
                {...field}
              />
            )}
          />
          <EnumSelect
            name="status"
            label="Status"
            schema={schema}
            enumObj={Status}
            control={control}
            defaultValue={'' as unknown as undefined}
          />
          <Controller
            name="birthday"
            control={control}
            defaultValue={'' as unknown as Date}
            render={({ field, fieldState }) => (
              <TextField
                label="Birthday"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                {...field}
              />
            )}
          />
          <EnumSelect
            name="civilStatus"
            label="Civil Status"
            schema={schema}
            enumObj={CivilStatus}
            control={control}
            defaultValue={'' as unknown as undefined}
          />

          <Controller
            name="dateHired"
            control={control}
            defaultValue={'' as unknown as Date}
            render={({ field, fieldState }) => (
              <TextField
                label="Date Hired"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                {...field}
              />
            )}
          />
          <EmployeeAutocomplete
            schema={schema}
            name="coordinator"
            control={control}
            label="Coordinator"
            defaultValue={null}
          />
          <EnumSelect
            multiple={true}
            name="departments"
            label="Departments"
            schema={schema}
            enumObj={Department}
            control={control}
            defaultValue={[]}
          />
          <Controller
            name="group"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Group"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <EnumSelect
            name="rank"
            label="Rank"
            schema={schema}
            enumObj={Rank}
            control={control}
            defaultValue={'' as unknown as undefined}
          />
          <Controller
            name="accountNumber"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Account Number"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="tinNumber"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="TIN Number"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="sssNumber"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="SSS Number"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="philhealthNumber"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Philhealth Number"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="pagibigNumber"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Pagibig Number"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
        </Box>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Salary
        </Typography>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gap: 1,
          }}
        >
          <Controller
            name="basicRate"
            control={control}
            defaultValue={0}
            render={({ field, fieldState }) => (
              <TextField
                label="Basic Rate"
                fullWidth
                type="number"
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="ecola"
            control={control}
            defaultValue={0}
            render={({ field, fieldState }) => (
              <TextField
                label="Ecola"
                fullWidth
                type="number"
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="dailyAllowance"
            control={control}
            defaultValue={0}
            render={({ field, fieldState }) => (
              <TextField
                label="Daily Allowance"
                fullWidth
                type="number"
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="monthlyAllowance"
            control={control}
            defaultValue={0}
            render={({ field, fieldState }) => (
              <TextField
                label="Monthly Allowance"
                fullWidth
                type="number"
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
        </Box>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Family Background
        </Typography>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gap: 1,
          }}
        >
          <Controller
            name="nameOfFather"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Name of Father"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="nameOfMother"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Name of Mother"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="nameOfSpouse"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Name of Spouse"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="numberOfChildren"
            control={control}
            defaultValue={0}
            render={({ field, fieldState }) => (
              <TextField
                label="Number of Children"
                fullWidth
                type="number"
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
        </Box>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Children
        </Typography>
        <Stack direction="column" spacing={1}>
          {fields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <Controller
                    name={`employeeChildren.${index}.name`}
                    control={control}
                    defaultValue=""
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`Name ${index + 1}`}
                        fullWidth
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name={`employeeChildren.${index}.birthday`}
                    control={control}
                    defaultValue={'' as unknown as Date}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`Birthday ${index + 1}`}
                        fullWidth
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                      />
                    )}
                  />
                </Box>
                <IconButton
                  aria-label="delete"
                  color="primary"
                  size="small"
                  onClick={() => handleRemoveChild(index)}
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            );
          })}
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            sx={{ my: 1 }}
            type="button"
            variant="contained"
            color="primary"
            onClick={handleAddChild}
            size="small"
          >
            Add
          </Button>
        </Stack>

        <Upload
          title="Upload Documents"
          name="documentUrls"
          control={control}
          defaultValue={[]}
          readOnly={readOnly}
        />
        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ mt: 3, ...gradientButton }}
          disabled={mutationLoading}
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default EmployeeForm;
