import { Box } from '@mui/material';
import { AuthProvider } from '../../contexts/authContext';
import { DialogProvider } from '../../contexts/dialogContext';
import { SnackbarProvider } from '../../contexts/snackbarContext';
import DrawerHeader from '../common/DrawerHeader';
import CombineProviders from './CombineProviders';
import Dialog from './Dialog';
import Drawer from './Drawer';
import Meta from './Meta';
import Routes from './Routes';
import Snackbar from './Snackbar';

const Page = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CombineProviders
        providers={[SnackbarProvider, AuthProvider, DialogProvider]}
      >
        <Drawer />
        <DrawerHeader />
        <Box
          component="main"
          sx={{
            maxWidth: (theme) => theme.breakpoints.values.lg,
            width: '100%',
            margin: '0 auto',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            padding: 1,
          }}
        >
          <Meta pageTitle={''} />
          <Routes />
        </Box>
        <Snackbar />
        <Dialog />
      </CombineProviders>
    </Box>
  );
};

export default Page;
