import { Box, Button, TextField, Typography } from '@mui/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { SmItemCategoriesDocument } from '../../graphql/generated/types';
import { gradientButton } from '../../styles/gradientButton';
import { FormProps } from '../../types/FormProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import FormContainer from '../common/FormContainer';
import SearchAsYouTypeAutocomplete from '../common/SearchAsYouTypeAutocomplete';
import SmItemCategoryAutocomplete from '../shared/SmItemCategoryAutocomplete';
import { FormData } from './UpdateItemPricingPage';

const UpdateItemPricingForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
  type,
}: FormProps) => {
  const disabled = type === 'update';
  const { control } = useFormContext<FormData>();
  const [consignee, item] = useWatch({
    name: ['consignee', 'item'],
    control,
  });

  return (
    <FormContainer maxWidth="sm">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
        gutterBottom
      >
        {title}
      </Typography>
      <Box component="form" onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 1,
          }}
        >
          <TextField
            label="Consignee"
            value={consignee?.name || ''}
            sx={{ gridColumn: 'span 2' }}
            disabled={disabled}
            fullWidth
          />
          <TextField
            label="Item Name"
            value={item?.name || ''}
            disabled={disabled}
            fullWidth
          />
          <TextField
            label="Internal Code"
            value={item?.internalCode || ''}
            disabled={disabled}
            fullWidth
          />
          <Controller
            name="consigneeItemName"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Consignee Item Name"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="price"
            control={control}
            defaultValue={0}
            render={({ field, fieldState }) => (
              <TextField
                label="Price"
                fullWidth
                type="number"
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="sku"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="SKU"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="effectivityDate"
            control={control}
            defaultValue={'' as unknown as Date}
            render={({ field, fieldState }) => (
              <TextField
                label="Effectivity Date"
                fullWidth
                type="date"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <SmItemCategoryAutocomplete
            schema={schema}
            name="smItemCategory"
            control={control}
            label="SM Item Category"
            defaultValue={null}
          />
          {/* <Controller
            name="smItemCategory"
            control={control}
            defaultValue={null}
            render={({ field, fieldState }) => (
              <SearchAsYouTypeAutocomplete
                textFieldProps={{
                  ...textFieldHelperProps(field, fieldState, schema),
                  label: 'SM Item Category',
                }}
                queryOptions={(value) => {
                  return {
                    query: SmItemCategoriesDocument,
                    variables: {
                      where: {
                        OR: [
                          {
                            itemCategoryName: {
                              contains: value,
                              mode: 'insensitive',
                            },
                          },
                        ],
                      },
                    },
                  };
                }}
                getOptionLabel={(option: any) => option?.itemCategoryName}
                value={field.value}
                onChange={(e, value) => field.onChange(value)}
              />
            )}
          /> */}
          <Controller
            name="remarks"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Remarks"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
        </Box>

        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ mt: 3, ...gradientButton }}
          disabled={mutationLoading}
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default UpdateItemPricingForm;
