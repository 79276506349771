import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  useBureauOfCustomsRequirementQuery,
  useUpdateBureauOfCustomsRequirementMutation,
} from '../../graphql/generated/types';
import { cleanPayload } from '../../utils/apolloClient';
import {
  getItemsForDelete,
  getItemsForUpsert,
  prismaCreate,
  prismaUpdate,
} from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Loading from '../common/Loading';
import Meta from '../page/Meta';
import BureauOfCustomsRequirementForm from './BureauOfCustomsRequirementForm';
import { schema, FormData } from './utils';

const UpdateBureauOfCustomsRequirementPage = () => {
  const title = `Update BOC Requirement`;
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({
        bocInvoiceItems,
        packingLists,
        documentUrls,
        supplier,
        ...data
      }) => {
        const bocInvoiceItemsToDelete = getItemsForDelete(
          bureauOfCustomsRequirement?.bocInvoiceItems,
          bocInvoiceItems
        );
        const bocInvoiceItemsToUpsert = getItemsForUpsert(bocInvoiceItems);

        await updateBureauOfCustomsRequirement({
          variables: {
            where: {
              id,
            },
            data: {
              ...prismaUpdate(data),
              supplier: {
                connect: {
                  id: supplier.id,
                },
              },
              packingLists: {
                set: packingLists?.map((packingList) => {
                  return {
                    id: packingList.id,
                  };
                }),
              },
              bocInvoiceItems: {
                delete: bocInvoiceItemsToDelete,
                upsert: bocInvoiceItemsToUpsert.map(
                  ({ id, packingListItem, ...bocInvoiceItem }) => {
                    return {
                      where: {
                        id,
                      },
                      update: {
                        ...prismaUpdate(bocInvoiceItem),
                        packingListItem: {
                          connect: { id: packingListItem.id },
                        },
                      },
                      create: {
                        ...prismaCreate(bocInvoiceItem),
                        packingListItem: {
                          connect: { id: packingListItem.id },
                        },
                      },
                    };
                  }
                ),
              },
            },
          },
        });
      },
      (error) =>
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        })
    )(e);
  };

  const {
    data: { bureauOfCustomsRequirement } = {},
    loading,
    error,
  } = useBureauOfCustomsRequirementQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: { id },
    },
  });

  const [updateBureauOfCustomsRequirement, { loading: mutationLoading }] =
    useUpdateBureauOfCustomsRequirementMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(-1);
      },
    });

  useEffect(() => {
    if (bureauOfCustomsRequirement) {
      methods.reset({ ...cleanPayload(bureauOfCustomsRequirement) });
    }
  }, [bureauOfCustomsRequirement, methods]);

  if (loading) return <Loading />;

  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <BureauOfCustomsRequirementForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="update"
        />
      </FormProvider>
    </>
  );
};

export default UpdateBureauOfCustomsRequirementPage;
