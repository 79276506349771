import { Box, Button, TextField, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { gradientButton } from '../../styles/gradientButton';
import { FormProps } from '../../types/FormProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import FormContainer from '../common/FormContainer';
import { FormData } from './utils';

const ConsigneeForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
}: FormProps) => {
  const { control } = useFormContext<FormData>();
  return (
    <FormContainer maxWidth="sm">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
        gutterBottom
      >
        {title}
      </Typography>
      <Box component="form" onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 1,
          }}
        >
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Name"
                fullWidth
                sx={{ gridColumn: 'span 2' }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="code"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Code"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="tinNumber"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Tin Number"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="companyName"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Company Name"
                fullWidth
                sx={{ gridColumn: 'span 2' }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="businessAddress"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Business Address"
                fullWidth
                sx={{ gridColumn: 'span 2' }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="businessStyle"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Business Style"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="vendorCode"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Vendor Code"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="remarks"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Remarks"
                fullWidth
                sx={{ gridColumn: 'span 2' }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
        </Box>

        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ mt: 3, ...gradientButton }}
          disabled={mutationLoading}
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default ConsigneeForm;
