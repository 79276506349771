import { mixed, InferType, object, string } from 'yup';
import { ItemCategory } from '../../graphql/enums/ItemCategory';


export const schema = object({
  name: string().required(),
  itemCategory: mixed<ItemCategory>()
    .oneOf(Object.values(ItemCategory))
    .required(),
}).required();

export type FormData = InferType<typeof schema>