import { Paper } from '@mui/material';
import Fade from '@mui/material/Fade';
import MuiPopper, { PopperPlacementType } from '@mui/material/Popper';
import { ReactNode } from 'react';

interface Props {
  open: boolean;
  anchorEl: HTMLElement | null;
  placement: PopperPlacementType | undefined;
  children: ReactNode;
}
const Popper = ({ open, anchorEl, placement, children }: Props) => {
  return (
    <MuiPopper
      open={open}
      anchorEl={anchorEl}
      placement={placement}
      transition
      style={{ zIndex: 1001 }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper sx={{ p: 2 }}>{children}</Paper>
        </Fade>
      )}
    </MuiPopper>
  );
};

export default Popper;
