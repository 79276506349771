import { array, date, InferType, mixed, number, object, string } from 'yup';
import { ShipmentType } from '../../graphql/enums/ShipmentType';
import { emptyStringToNull } from '../../utils/yup';



export const schema = object({
  bureauOfCustomsRequirement: object().nullable().default(null).required(),
  proformaInvoice: object().nullable().default(null).required(),
  shipmentType: mixed<ShipmentType>().oneOf(Object.values(ShipmentType)).required(),
  invoiceDate: date().required(),
  purchaseOrders: array().of(object({
    id: number().required(),
    number: string().required()
  })).required().min(1),
  shipmentReports: array().of(object({
    id: number().required(),
    transactionNumber: string().required(),
    shipmentReportItems: array()
  })).required().min(1),
  dutiesAndTaxes: number().transform(emptyStringToNull).nullable(),
  bankFee: number().transform(emptyStringToNull).nullable(),
  brokerage: number().transform(emptyStringToNull).nullable(),
  containerDeposit: number().transform(emptyStringToNull).nullable(),
  expediting: number().transform(emptyStringToNull).nullable(),
  handling: number().transform(emptyStringToNull).nullable(),
  others: number().transform(emptyStringToNull).nullable(),
  draft: number().transform(emptyStringToNull).nullable(),
  shipmentPreCostingItems: array().of(object({
    shipmentReportItem: object().nullable().default(null).required(),
    remarks: string().nullable(),
  })),
}).required();

export type FormData = InferType<typeof schema>