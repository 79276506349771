export enum Region {
  "ARMM" = "ARMM",
  "CAR" = "CAR",
  "NCR" = "NCR",
  "Region I" = "REGION_I",
  "Region II" = "REGION_II",
  "Region III" = "REGION_III",
  "Region IVA" = "REGION_IVA",
  "Region IVB" = "REGION_IVB",
  "Region IX" = "REGION_IX",
  "Region V" = "REGION_V",
  "Region VI" = "REGION_VI",
  "Region VII" = "REGION_VII",
  "Region VIII" = "REGION_VIII",
  "Region X" = "REGION_X",
  "Region XI" = "REGION_XI",
  "Region XII" = "REGION_XII",
  "Region XIII" = "REGION_XIII",
}
