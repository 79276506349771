import { Paper } from '@mui/material';

const HomePage = () => {
  return (
    <>
      <Paper elevation={3} sx={{ m: 'auto', px: 3, py: 2 }}>
        Home Page
      </Paper>
    </>
  );
};

export default HomePage;
