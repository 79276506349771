import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import { useCreatePutToUnitMutation } from '../../graphql/generated/types';
import { prismaCreate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Meta from '../page/Meta';
import PutToUnitForm from './PutToUnitForm';

import { FormData, schema } from './utils';

const CreatePutToUnitPage = () => {
  const title = `Create Put to Unit`;
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({
        part,
        motherUnit,
        shipmentReport,
        missingItemShipmentReport,
        ...data
      }) =>
        await createPutToUnit({
          variables: {
            data: {
              ...prismaCreate(data),
              missingItem: {
                connect: {
                  partId_motherUnitId_shipmentReportId: {
                    motherUnitId: motherUnit.id,
                    partId: part.id,
                    shipmentReportId: missingItemShipmentReport.id,
                  },
                },
              },
              shipmentReport: {
                connect: {
                  id: shipmentReport.id,
                },
              },
            },
          },
        }),
      (error) =>
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        })
    )(e);
  };

  const [createPutToUnit, { loading: mutationLoading }] =
    useCreatePutToUnitMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(location.pathname.replace('/create', ''));
      },
    });

  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <PutToUnitForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
        />
      </FormProvider>
    </>
  );
};

export default CreatePutToUnitPage;
