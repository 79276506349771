import { Typography } from '@mui/material';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { RequireKeys } from '../../../types/UtilityTypes';
import Dropzone from './Dropzone';

interface Props {
  title?: string;
  readOnly?: boolean;
}

const Upload = <T extends FieldValues>({
  title,
  name,
  control,
  defaultValue,
  readOnly,
}: Props & RequireKeys<UseControllerProps<T>, 'defaultValue' | 'control'>) => {
  if (!readOnly)
    return (
      <>
        {title && (
          <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
            {title}
          </Typography>
        )}
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field: { onChange, value } }) => (
            <Dropzone files={value} setFiles={onChange} />
          )}
        />
      </>
    );
  return <></>;
};

export default Upload;
