import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import queryString from 'query-string';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { getEnumKeyFromValue } from '../../utils/stringFunctions';

interface Props {
  field: string;
  label: string;
  enumObj: Record<string, string>;
}

const EnumSearchFilter = ({ field, enumObj, label }: Props) => {
  let navigate = useNavigate();
  let location = useLocation();
  const parsed = queryString.parse(location.search);
  const initialQueryStringValue = parsed[field] || undefined;

  const [selectValue, setSelectValue] = useState(initialQueryStringValue);

  const handleChange = (e: SelectChangeEvent<any>) => {
    let value = e.target.value;
    setSelectValue(value);
    if (!value) {
      delete parsed[field];
      navigate({
        pathname: location.pathname,
        search: queryString.stringify({ ...parsed }),
      });
    } else {
      navigate({
        pathname: location.pathname,
        search: queryString.stringify({ ...parsed, [field]: value }),
      });
    }
  };

  return (
    <>
      <FormControl size="small">
        <InputLabel id={`${field}-label`}>{label}</InputLabel>
        <Select
          labelId={`${field}-label`}
          label={label}
          value={selectValue ?? ''}
          onChange={handleChange}
          sx={{
            backgroundColor: (theme) => theme.palette.common.white,
            minWidth: '220px',
          }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {Object.values(enumObj).map((value, idx) => {
            return (
              <MenuItem key={idx} value={value}>
                {getEnumKeyFromValue(enumObj, value)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};

export default EnumSearchFilter;
