import KeyIcon from '@mui/icons-material/Key';
import { Checkbox, MenuItem } from '@mui/material';
import { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  AggregateUserDocument,
  Employee,
  QueryMode,
  SortOrder,
  useDeleteManyUserMutation,
  UserGroup,
  UserRole,
  UsersDocument,
  UserWhereInput,
  useUsersQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { HandleDelete } from '../../types/HandleDelete';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { fullName, getEnumKeyFromValue } from '../../utils/stringFunctions';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import EnumSearchFilter from '../common/EnumSearchFilter';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';

const UsersPage = ({ title = 'Users' }) => {
  const { handleSnackbarOpen } = useSnackbarContext();
  const location = useLocation();
  const navigate = useNavigate();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();

  const [searchQueryString, userGroupQueryString] = useGetQueryString([
    'search',
    'userGroup',
  ]);

  const where: UserWhereInput = {
    ...(userGroupQueryString && {
      userGroups: {
        has: userGroupQueryString as UserGroup,
      },
    }),
    ...(typeof searchQueryString === 'string' && {
      OR: [
        {
          username: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
        {
          employee: {
            is: {
              firstName: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },
        {
          employee: {
            is: {
              middleName: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },
        {
          employee: {
            is: {
              lastName: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },
      ],
    }),
  };
  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        username: SortOrder.Asc,
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { users } = {},
    loading,
    error,
  } = useUsersQuery({
    fetchPolicy: 'network-only',
    variables,
  });

  // const [deleteUser] = useDeleteUserMutation({
  //   update(cache, { data }) {
  //     cache.modify({
  //       fields: {
  //         users(existingUsers, { readField }) {
  //           return existingUsers.filter(
  //             (user: User) => data?.deleteUser.id !== readField('id', user)
  //           );
  //         },
  //       },
  //     });
  //   },
  // });

  const [deleteManyUser] = useDeleteManyUserMutation({
    refetchQueries: [
      { query: AggregateUserDocument, variables: countVariables },
      {
        query: UsersDocument,
        variables,
      },
    ],
    onError: (error) =>
      handleSnackbarOpen({
        message: 'Delete' + title.toLowerCase() + ' error! ' + error,
        severity: 'error',
      }),
    // update(cache, { data }) {
    //   cache.modify({
    //     fields: {
    //       users(existingUsers, { readField }) {
    //         return existingUsers.filter(
    //           (user: User) =>
    //             !data?.deleteManyUser.some(
    //               ({ id }) => id === readField('id', user)
    //             )
    //         );
    //       },
    //     },
    //   });
    // },
  });

  const columns: Columns<Paths<ObjectFromArray<NonNullable<typeof users>>>> = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },
    {
      header: 'Username',
      accessor: 'username',
    },
    {
      header: 'User Groups',
      accessor: 'userGroups',
      Cell: (children?: string[]) =>
        children
          ?.map((child) => getEnumKeyFromValue(UserGroup, child))
          .join(', '),
    },
    {
      header: 'User Role',
      accessor: 'userRole',
      Cell: (children: string) => getEnumKeyFromValue(UserRole, children),
    },
    {
      header: 'Name',
      accessor: 'employee',
      Cell: (children: Employee) => fullName(children),
    },
  ];

  const handleDelete: HandleDelete = (ids) => {
    deleteManyUser({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <>
      <ListView
        loading={loading}
        error={error}
        title={title}
        actionsMenu={
          <ActionsMenu
            handleDelete={handleDelete}
            selected={selected}
            setSelected={setSelected}
          >
            {selected.length === 1 && (
              <MenuItem
                onClick={() =>
                  navigate(
                    `${location.pathname}/${selected[0]}/change-password`
                  )
                }
              >
                <KeyIcon />
                Change Password
              </MenuItem>
            )}
          </ActionsMenu>
        }
        filters={
          <>
            <EnumSearchFilter
              label="User Group"
              field="userGroup"
              enumObj={UserGroup}
            />
            <Search />
          </>
        }
        dataTable={<DataTable data={users} columns={columns} />}
        pagination={
          <Pagination
            query={AggregateUserDocument}
            perPage={perPage}
            page={Number(page)}
            variables={countVariables}
          />
        }
      />
    </>
  );
};

export default UsersPage;
