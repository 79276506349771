import { endOfDay, startOfDay, subDays } from 'date-fns';
import {
  AggregatePayableProformaInvoiceDocument,
  Currency,
  ProformaInvoiceWhereInput,
  QueryMode,
  SortOrder,
  usePayableProformaInvoicesQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import DataTable from '../common/DataTable';
import DateRangeFilter from '../common/DateRangeFilter';
import EnumSearchFilter from '../common/EnumSearchFilter';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import { proformaInvoiceColumns } from '../proformaInvoices/ProformaInvoicesPage';
import ListView from '../templates/ListView';

const PayableProformaInvoicesPage = ({ title = 'PI Payable' }) => {
  const dateFilter = subDays(new Date(), 14);
  const { page, perPage } = usePagination();
  const [
    searchQueryString,
    startDateQueryString,
    endDateQueryString,
    currencyQueryString,
  ] = useGetQueryString(['search', 'startDate', 'endDate', 'currency']);

  const where: ProformaInvoiceWhereInput = {
    invoicePaymentItems: {
      none: {},
    },
    invoiceDate: {
      lt: startOfDay(dateFilter),
    },
    ...(currencyQueryString && {
      currency: {
        equals: currencyQueryString as Currency,
      },
    }),
    ...(typeof startDateQueryString === 'string' &&
      typeof endDateQueryString === 'string' && {
        invoiceDate: {
          gte: startOfDay(new Date(startDateQueryString)),
          lte: endOfDay(new Date(endDateQueryString)),
          lt: startOfDay(dateFilter),
        },
      }),
    ...(typeof searchQueryString === 'string' && {
      OR: [
        {
          number: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },

        {
          supplier: {
            is: {
              name: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },
        {
          purchaseOrders: {
            some: {
              number: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },
        {
          proformaInvoiceItems: {
            some: {
              item: {
                is: {
                  name: {
                    contains: searchQueryString,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
            },
          },
        },
        {
          proformaInvoiceItems: {
            some: {
              item: {
                is: {
                  internalCode: {
                    contains: searchQueryString,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
            },
          },
        },
      ],
    }),
  };
  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [{ invoiceDate: SortOrder.Desc }],
  };
  const countVariables = {
    where,
  };
  const {
    data: { payableProformaInvoices } = {},
    loading,
    error,
  } = usePayableProformaInvoicesQuery({
    fetchPolicy: 'network-only',
    variables,
  });

  return (
    <>
      <ListView
        loading={loading}
        error={error}
        title={title}
        filters={
          <>
            <DateRangeFilter />
            <EnumSearchFilter
              label="Currency"
              field="currency"
              enumObj={Currency}
            />
            <Search />
          </>
        }
        dataTable={
          <DataTable
            data={payableProformaInvoices}
            columns={proformaInvoiceColumns}
          />
        }
        pagination={
          <Pagination
            query={AggregatePayableProformaInvoiceDocument}
            perPage={perPage}
            page={Number(page)}
            variables={countVariables}
          />
        }
      />
    </>
  );
};

export default PayableProformaInvoicesPage;
