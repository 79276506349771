import { Box, Link } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  margin: '20px 0',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
};

const thumb = {
  margin: '0 10px',
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
  position: 'relative',
};

const closeButton = {
  cursor: 'pointer',
  zIndex: '2',
  position: 'absolute',
  right: '-10%',
  top: '-10%',
  border: '0',
  borderRadius: '50%',
  fontWeight: '700',
  backgroundColor: '#ff1744',
  color: '#ffffff',
  padding: '2px 5px',
  outline: '0',
};

const thumbInner = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

const links = {
  marginBottom: '5px',
  marginRight: '5px',
};

interface FileWithPreview extends File {
  preview: string;
}
interface Props {
  files: FileWithPreview[];
  setFiles: (files: FileWithPreview[]) => void;
  disabled?: boolean;
}
const Dropzone = ({ files, setFiles, disabled = false }: Props) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    disabled: disabled,
    maxFiles: 25,
    maxSize: 5242880,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const thumbs = files.map((file, idx) => {
    if (typeof file === 'string') {
      return (
        <div key={idx} style={links}>
          <Link target="_blank" rel="noopener noreferrer" href={file}>{`Photo ${
            idx + 1
          }`}</Link>
        </div>
      );
    } else {
      return (
        <Box component="div" sx={thumb} key={idx}>
          <Box
            component="button"
            sx={closeButton}
            onClick={() => setFiles(files.filter((file, nidx) => idx !== nidx))}
          >
            x
          </Box>
          <Box component="div" sx={thumbInner}>
            <img src={file.preview} style={img} alt={`${file.name} preview`} />
          </Box>
        </Box>
      );
    }
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <section className="container">
      <Box component="div" {...getRootProps({ sx: style })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </Box>
      <Box component="aside" sx={thumbsContainer}>
        {thumbs}
      </Box>
    </section>
  );
};

export default Dropzone;
