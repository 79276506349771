import RemoveIcon from '@mui/icons-material/Remove';
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { nestedGrid } from '../../styles/nestedGrid';
import { InputGroupProps } from '../../types/InputGroupProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import { FormData } from './utils';

const defaultValue = {
  date: format(new Date(), 'yyyy-MM-dd') as unknown as Date,
  particular: '',
};

const ItemHistory = ({ schema }: InputGroupProps) => {
  const { control } = useFormContext<FormData>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'itemHistories',
  });

  const handleAddItemHistory = () => {
    append(defaultValue);
  };

  const handleRemoveItemHistory = (index: number) => {
    remove(index);
  };

  return (
    <>
      <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
        Item History
      </Typography>
      <Stack direction="column" spacing={1}>
        {fields.map((field, index) => {
          return (
            <Box
              key={field.id}
              sx={{
                ...nestedGrid,
              }}
            >
              <Box>
                <Controller
                  name={`itemHistories.${index}.date`}
                  control={control}
                  defaultValue={'' as unknown as Date}
                  render={({ field, fieldState }) => (
                    <TextField
                      label={`Date ${index + 1}`}
                      fullWidth
                      {...textFieldHelperProps(field, fieldState, schema)}
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name={`itemHistories.${index}.particular`}
                  control={control}
                  defaultValue={''}
                  render={({ field, fieldState }) => (
                    <TextField
                      label={`Particular ${index + 1}`}
                      fullWidth
                      sx={{ gridColumn: 'span 2' }}
                      {...textFieldHelperProps(field, fieldState, schema)}
                      {...field}
                    />
                  )}
                />
              </Box>

              <IconButton
                aria-label="delete"
                color="primary"
                size="small"
                onClick={() => handleRemoveItemHistory(index)}
              >
                <RemoveIcon />
              </IconButton>
            </Box>
          );
        })}
      </Stack>
      <Stack direction="row" justifyContent="flex-end">
        <Button
          sx={{ my: 1 }}
          type="button"
          variant="contained"
          color="primary"
          onClick={handleAddItemHistory}
          size="small"
        >
          Add
        </Button>
      </Stack>
    </>
  );
};

export default ItemHistory;
