import {
  Autocomplete,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import {
  GetInFormPurchaseOrdersDocument,
  ShipmentReportDestination,
  ShipmentReportsDocument,
  ShipmentType,
  useBureauOfCustomsRequirementsQuery,
  useProformaInvoicesQuery,
} from '../../graphql/generated/types';
import { gradientButton } from '../../styles/gradientButton';
import { nestedGrid } from '../../styles/nestedGrid';
import { FormProps } from '../../types/FormProps';
import { roundFloat } from '../../utils/numberFunctions';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import { getEnumKeyFromValue } from '../../utils/stringFunctions';
import EnumSelect from '../common/EnumSelect';
import FormContainer from '../common/FormContainer';
import SearchAsYouTypeAutocomplete from '../common/SearchAsYouTypeAutocomplete';
import { FormData } from './utils';

const ShipmentPreCostingForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
  type,
}: FormProps) => {
  const { control, setValue } = useFormContext<FormData>();
  const { fields } = useFieldArray({
    control,
    name: 'shipmentPreCostingItems',
  });

  const [
    dutiesAndTaxes,
    bankFee,
    brokerage,
    containerDeposit,
    expediting,
    handling,
    others,
    draft,
    shipmentPreCostingItems,
    shipmentReports,
    proformaInvoice,
  ] = useWatch({
    name: [
      'dutiesAndTaxes',
      'bankFee',
      'brokerage',
      'containerDeposit',
      'expediting',
      'handling',
      'others',
      'draft',
      'shipmentPreCostingItems',
      'shipmentReports',
      'proformaInvoice',
    ],
    control,
  });

  const totalExpenses =
    +(dutiesAndTaxes || 0) +
    +(bankFee || 0) +
    +(brokerage || 0) +
    +(containerDeposit || 0) +
    +(expediting || 0) +
    +(handling || 0) +
    +(others || 0) +
    +(draft || 0);

  const {
    data: { bureauOfCustomsRequirements } = {},
    loading: bureauOfCustomsRequirementsLoading,
  } = useBureauOfCustomsRequirementsQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: {
        shipmentPreCosting: null,
      },
    },
  });

  const { data: { proformaInvoices } = {}, loading: proformaInvoicesLoading } =
    useProformaInvoicesQuery({
      fetchPolicy: 'network-only',
      variables: {
        where: {
          shipmentPreCosting: null,
        },
      },
    });

  return (
    <FormContainer maxWidth="lg">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
        gutterBottom
      >
        {title}
      </Typography>
      <Box component="form" onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gap: 1,
          }}
        >
          {/* <Controller
            name="bureauOfCustomsRequirement"
            control={control}
            defaultValue={null}
            render={({ field, fieldState }) => (
              <SearchAsYouTypeAutocomplete
                textFieldProps={{
                  ...textFieldHelperProps(field, fieldState, schema),
                  label: 'BOC Invoice No.',
                }}
                queryOptions={(value) => {
                  return {
                    query: BureauOfCustomsRequirementsDocument,
                    variables: {
                      where: {
                        OR: [
                          {
                            invoiceNumber: {
                              contains: value,
                              mode: 'insensitive',
                            },
                          },
                        ],
                      },
                    },
                  };
                }}
                getOptionLabel={(option) => option?.invoiceNumber}
                value={field.value}
                onChange={(e, value) => field.onChange(value)}
                disabled={type === 'update'}
              />
            )}
          /> */}
          <Controller
            name="bureauOfCustomsRequirement"
            control={control}
            defaultValue={null}
            render={({ field, fieldState }) => (
              <Autocomplete
                disabled={
                  type === 'update' || bureauOfCustomsRequirementsLoading
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...textFieldHelperProps(field, fieldState, schema)}
                    label="BOC Invoice No."
                  />
                )}
                options={bureauOfCustomsRequirements || []}
                getOptionLabel={(option: any) => option?.invoiceNumber}
                value={field.value}
                onChange={(e, value) => field.onChange(value)}
              />
            )}
          />
          {/* <Controller
            name="proformaInvoice"
            control={control}
            defaultValue={null}
            render={({ field, fieldState }) => (
              <SearchAsYouTypeAutocomplete
                textFieldProps={{
                  ...textFieldHelperProps(field, fieldState, schema),
                  label: 'Proforma Invoice No.',
                }}
                queryOptions={(value) => {
                  return {
                    query: ProformaInvoicesDocument,
                    variables: {
                      where: {
                        OR: [
                          {
                            number: {
                              contains: value,
                              mode: 'insensitive',
                            },
                          },
                        ],
                      },
                    },
                  };
                }}
                getOptionLabel={(option) => option?.number}
                value={field.value}
                onChange={(e, value) => field.onChange(value)}
              />
            )}
          /> */}
          <Controller
            name="proformaInvoice"
            control={control}
            defaultValue={null}
            render={({ field, fieldState }) => (
              <Autocomplete
                disabled={proformaInvoicesLoading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...textFieldHelperProps(field, fieldState, schema)}
                    label="Proforma Invoice No."
                  />
                )}
                options={proformaInvoices || []}
                getOptionLabel={(option: any) => option?.number}
                value={field.value}
                onChange={(e, value) => field.onChange(value)}
              />
            )}
          />
          <EnumSelect
            name="shipmentType"
            label="Shipment Type"
            schema={schema}
            enumObj={ShipmentType}
            control={control}
            defaultValue={'' as unknown as undefined}
          />
          <Controller
            name="invoiceDate"
            control={control}
            defaultValue={'' as unknown as Date}
            render={({ field, fieldState }) => (
              <TextField
                label="Invoice Date"
                fullWidth
                type="date"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="purchaseOrders"
            control={control}
            defaultValue={[]}
            render={({ field, fieldState }) => (
              <SearchAsYouTypeAutocomplete
                sx={{ gridColumn: 'span 2' }}
                multiple
                textFieldProps={{
                  ...textFieldHelperProps(field, fieldState, schema),
                  label: 'Purchase Orders',
                }}
                queryOptions={(value) => {
                  return {
                    query: GetInFormPurchaseOrdersDocument,
                    variables: {
                      where: {
                        approvedBy: {
                          not: {
                            equals: null,
                          },
                        },
                        proformaInvoices: {
                          some: {
                            id: {
                              equals: proformaInvoice?.id,
                            },
                          },
                        },
                        OR: [
                          {
                            number: {
                              contains: value,
                              mode: 'insensitive',
                            },
                          },
                        ],
                      },
                    },
                  };
                }}
                getOptionLabel={(option: any) => option?.number}
                value={field.value as any}
                onChange={(e, value) => field.onChange(value)}
                disabled={!proformaInvoice}
              />
            )}
          />
          <Controller
            name="shipmentReports"
            control={control}
            defaultValue={[]}
            render={({ field, fieldState }) => (
              <SearchAsYouTypeAutocomplete
                sx={{ gridColumn: 'span 2' }}
                multiple
                textFieldProps={{
                  ...textFieldHelperProps(field, fieldState, schema),
                  label: 'Shipment Reports',
                }}
                queryOptions={(value) => {
                  return {
                    query: ShipmentReportsDocument,
                    variables: {
                      where: {
                        OR: [
                          {
                            transactionNumber: {
                              contains: value,
                              mode: 'insensitive',
                            },
                          },
                        ],
                      },
                    },
                  };
                }}
                getOptionLabel={(option: any) => option?.transactionNumber}
                value={field.value as any}
                onChange={(e, value) => {
                  if (value.length > (shipmentReports || []).length) {
                    setValue('shipmentPreCostingItems', [
                      ...(shipmentPreCostingItems || []),
                      ...value
                        .at(-1)
                        .shipmentReportItems.map((shipmentReportItem: any) => {
                          return {
                            remarks: '',
                            shipmentReportItem: {
                              ...shipmentReportItem,
                            },
                          };
                        }),
                    ]);
                  } else {
                    const shipmentReportItemIdsToRemove = (
                      shipmentReports || []
                    )
                      .at(-1)
                      ?.shipmentReportItems?.map(
                        (shipmentReportItem: any) => shipmentReportItem.id
                      );

                    setValue(
                      'shipmentPreCostingItems',
                      shipmentPreCostingItems?.filter(
                        ({ shipmentReportItem }) =>
                          !shipmentReportItemIdsToRemove?.includes(
                            shipmentReportItem?.id
                          )
                      )
                    );
                  }
                  field.onChange(value);
                }}
              />
            )}
          />
          <TextField
            label={`Packing Lists`}
            fullWidth
            value={
              (proformaInvoice &&
                proformaInvoice.packingLists
                  ?.map(({ number }: { number: string }) => number)
                  .join(', ')) ??
              ''
            }
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              readOnly: true,
            }}
            sx={{ gridColumn: 'span 4' }}
          />
        </Box>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Expenses
        </Typography>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gap: 1,
          }}
        >
          <Controller
            name="dutiesAndTaxes"
            control={control}
            defaultValue={'' as unknown as number}
            render={({ field, fieldState }) => (
              <TextField
                label="Duties and Taxes"
                fullWidth
                type="number"
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="bankFee"
            control={control}
            defaultValue={'' as unknown as number}
            render={({ field, fieldState }) => (
              <TextField
                label="Bank Fee"
                fullWidth
                type="number"
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="brokerage"
            control={control}
            defaultValue={'' as unknown as number}
            render={({ field, fieldState }) => (
              <TextField
                label="Brokerage"
                fullWidth
                type="number"
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="containerDeposit"
            control={control}
            defaultValue={'' as unknown as number}
            render={({ field, fieldState }) => (
              <TextField
                label="Container Deposit"
                fullWidth
                type="number"
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="expediting"
            control={control}
            defaultValue={'' as unknown as number}
            render={({ field, fieldState }) => (
              <TextField
                label="Expediting"
                fullWidth
                type="number"
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="handling"
            control={control}
            defaultValue={'' as unknown as number}
            render={({ field, fieldState }) => (
              <TextField
                label="Handling"
                fullWidth
                type="number"
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="others"
            control={control}
            defaultValue={'' as unknown as number}
            render={({ field, fieldState }) => (
              <TextField
                label="Others"
                fullWidth
                type="number"
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="draft"
            control={control}
            defaultValue={'' as unknown as number}
            render={({ field, fieldState }) => (
              <TextField
                label="Draft"
                fullWidth
                type="number"
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
        </Box>
        <Typography variant="h6" align="right" sx={{ mt: 1 }}>
          Total Expenses: {roundFloat(totalExpenses)}
        </Typography>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Shipment Pre Costing Items
        </Typography>
        <Stack direction="column" spacing={1}>
          {fields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <TextField
                    label={`Date Received ${index + 1}`}
                    fullWidth
                    value={
                      shipmentPreCostingItems &&
                      shipmentPreCostingItems[index]?.shipmentReportItem
                        ?.shipmentReport?.dateReceived
                        ? format(
                            new Date(
                              shipmentPreCostingItems[
                                index
                              ]?.shipmentReportItem?.shipmentReport?.dateReceived
                            ),
                            'MM/dd/yy'
                          )
                        : ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    label={`Location ${index + 1}`}
                    fullWidth
                    value={
                      (shipmentPreCostingItems &&
                        getEnumKeyFromValue(
                          ShipmentReportDestination,
                          shipmentPreCostingItems[index]?.shipmentReportItem
                            ?.shipmentReport?.shipmentReportDestination
                        )) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    label={`Item Name ${index + 1}`}
                    fullWidth
                    value={
                      (shipmentPreCostingItems &&
                        shipmentPreCostingItems[index]?.shipmentReportItem?.item
                          ?.name) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    label={`Internal Code ${index + 1}`}
                    fullWidth
                    value={
                      (shipmentPreCostingItems &&
                        shipmentPreCostingItems[index]?.shipmentReportItem?.item
                          ?.internalCode) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    label={`Boxes Received ${index + 1}`}
                    fullWidth
                    value={
                      (shipmentPreCostingItems &&
                        shipmentPreCostingItems[index]?.shipmentReportItem
                          ?.numberOfBoxesReceived) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    label={`Quantity / Box ${index + 1}`}
                    fullWidth
                    value={
                      (shipmentPreCostingItems &&
                        shipmentPreCostingItems[index]?.shipmentReportItem
                          ?.quantityPerBox) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    label={`Subtotal ${index + 1}`}
                    fullWidth
                    value={
                      (shipmentPreCostingItems &&
                        shipmentPreCostingItems[index]?.shipmentReportItem
                          ?.subtotal) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    label={`Incomplete ${index + 1}`}
                    fullWidth
                    value={
                      (shipmentPreCostingItems &&
                        shipmentPreCostingItems[index]?.shipmentReportItem
                          ?.incompleteQuantity) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    label={`Damaged ${index + 1}`}
                    fullWidth
                    value={
                      (shipmentPreCostingItems &&
                        shipmentPreCostingItems[index]?.shipmentReportItem
                          ?.damagedQuantity) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    label={`Good ${index + 1}`}
                    fullWidth
                    value={
                      (shipmentPreCostingItems &&
                        shipmentPreCostingItems[index]?.shipmentReportItem
                          ?.goodQuantity) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    label={`Put to Unit ${index + 1}`}
                    fullWidth
                    value={
                      (shipmentPreCostingItems &&
                        shipmentPreCostingItems[index]?.shipmentReportItem
                          ?.putToUnitsTotalQuantity) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    label={`Final Quantity ${index + 1}`}
                    fullWidth
                    value={
                      (shipmentPreCostingItems &&
                        shipmentPreCostingItems[index]?.shipmentReportItem
                          ?.finalQuantity) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <Controller
                    name={`shipmentPreCostingItems.${index}.remarks`}
                    control={control}
                    defaultValue=""
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`Remarks ${index + 1}`}
                        fullWidth
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                      />
                    )}
                  />
                </Box>
              </Box>
            );
          })}
        </Stack>

        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ mt: 3, ...gradientButton }}
          disabled={mutationLoading}
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default ShipmentPreCostingForm;
