import { Box, Button, TextField, Typography } from '@mui/material';
import logoGreen from '../../images/logos/logo-green.png';
import { useEffect, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import {
  BocClassification,
  BrandsDocument,
  ItemCommissionType,
  ItemSubcategoriesDocument,
  ItemType,
  NamingGroupsDocument,
  SortOrder,
  Status,
  useFindFirstItemQuery,
} from '../../graphql/generated/types';
import { gradientButton } from '../../styles/gradientButton';
import { FormProps } from '../../types/FormProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import EnumSelect from '../common/EnumSelect';
import FormContainer from '../common/FormContainer';
import SearchAsYouTypeAutocomplete from '../common/SearchAsYouTypeAutocomplete';
import Upload from '../common/upload/Upload';
import SupplierAutocomplete from '../shared/SupplierAutocomplete';
import ItemHistory from './ItemHistory';
import Parts from './Parts';
import Specifications from './Specifications';
import { FormData } from './utils';

const ItemForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
  type,
  readOnly,
}: FormProps) => {
  const { control, setValue } = useFormContext<FormData>();
  const [supplier, namingGroup, itemType, photoUrls] = useWatch({
    control,
    name: ['supplier', 'namingGroup', 'itemType', 'photoUrls'],
  });

  const { data: { findFirstItem } = {} } = useFindFirstItemQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: {
        namingGroup: {
          is: {
            name: {
              equals: namingGroup?.name,
            },
          },
        },
        itemType: {
          equals: itemType,
        },
      },
      orderBy: {
        internalCode: SortOrder.Desc,
      },
    },
    skip: !namingGroup || !itemType || type !== 'create',
  });
  const internalCodeDefault = useMemo(() => {
    if (namingGroup && itemType) {
      const number =
        (findFirstItem ? +findFirstItem.internalCode.slice(-3) : 0) + 1;
      const paddedNumber = number.toString().padStart(3, '0');
      return `${namingGroup.code}${itemType.slice(
        -1
      )}${paddedNumber}`.toUpperCase();
    } else {
      return '';
    }
  }, [namingGroup, itemType, findFirstItem]);

  useEffect(() => {
    if (type === 'create' && internalCodeDefault) {
      setValue('internalCode', internalCodeDefault);
    }
  }, [internalCodeDefault, setValue, type]);

  return (
    <FormContainer maxWidth="xl" readOnly={readOnly}>
      {readOnly && photoUrls?.length > 0 && (
        <Box
          component="img"
          src={photoUrls[0]}
          alt={photoUrls[0]}
          sx={{
            height: '200px',
            width: '200px',
            objectFit: 'contain',
            border: '1px solid black',
          }}
        />
      )}
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
        gutterBottom
      >
        {title}
      </Typography>
      <Box component="form" onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gap: 1,
          }}
        >
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Name"
                fullWidth
                sx={{ gridColumn: 'span 2' }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="brand"
            control={control}
            defaultValue={null}
            render={({ field, fieldState }) => (
              <SearchAsYouTypeAutocomplete
                textFieldProps={{
                  ...textFieldHelperProps(field, fieldState, schema),
                  label: 'Brand',
                }}
                queryOptions={(value) => {
                  return {
                    query: BrandsDocument,
                    variables: {
                      where: {
                        OR: [
                          {
                            name: {
                              contains: value,
                              mode: 'insensitive',
                            },
                          },
                        ],
                      },
                    },
                  };
                }}
                getOptionLabel={(option) => option?.name}
                value={field.value}
                onChange={(e, value) => field.onChange(value)}
              />
            )}
          />
          <EnumSelect
            name="status"
            label="Status"
            schema={schema}
            enumObj={Status}
            control={control}
            defaultValue={'' as unknown as undefined}
          />
          <SupplierAutocomplete
            name="supplier"
            schema={schema}
            control={control}
            label="Supplier"
            defaultValue={null}
          />
          <TextField
            label="Origin"
            value={supplier?.origin ?? ''}
            fullWidth
            InputProps={{ readOnly: true }}
            InputLabelProps={{
              shrink: true,
            }}
            // sx={{
            //   '.MuiInputLabel-root::after': {
            //     content: '"\u2666"',
            //     color: 'red',
            //   },
            // }}
          />
          <Controller
            name="supplierItemCode"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Supplier's Item Code"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="supplierItemName"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Supplier's Item Name"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="namingGroup"
            control={control}
            defaultValue={null}
            render={({ field, fieldState }) => (
              <SearchAsYouTypeAutocomplete
                textFieldProps={{
                  ...textFieldHelperProps(field, fieldState, schema),
                  label: 'Naming Group',
                }}
                queryOptions={(value) => {
                  return {
                    query: NamingGroupsDocument,
                    variables: {
                      where: {
                        OR: [
                          {
                            name: {
                              contains: value,
                              mode: 'insensitive',
                            },
                          },
                        ],
                      },
                    },
                  };
                }}
                getOptionLabel={(option) => option?.name}
                value={field.value}
                onChange={(e, value) => field.onChange(value)}
              />
            )}
          />
          <EnumSelect
            name="itemType"
            label="Item Type"
            schema={schema}
            enumObj={ItemType}
            control={control}
            defaultValue={'' as unknown as undefined}
          />
          <Controller
            name="internalCode"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Internal Code"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="birthday"
            control={control}
            defaultValue={'' as unknown as Date}
            render={({ field, fieldState }) => (
              <TextField
                label="Birthday"
                fullWidth
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="itemSubcategory"
            control={control}
            defaultValue={null}
            render={({ field, fieldState }) => (
              <SearchAsYouTypeAutocomplete
                textFieldProps={{
                  ...textFieldHelperProps(field, fieldState, schema),
                  label: 'Item Subcategory',
                }}
                queryOptions={(value) => {
                  return {
                    query: ItemSubcategoriesDocument,
                    variables: {
                      where: {
                        OR: [
                          {
                            name: {
                              contains: value,
                              mode: 'insensitive',
                            },
                          },
                        ],
                      },
                    },
                  };
                }}
                getOptionLabel={(option) => option?.name}
                value={field.value}
                onChange={(e, value) => field.onChange(value)}
              />
            )}
          />
          <EnumSelect
            name="itemCommissionType"
            label="Item Commission Type"
            schema={schema}
            enumObj={ItemCommissionType}
            control={control}
            defaultValue={'' as unknown as undefined}
          />
          <Controller
            name="marketingName"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Marketing Name"
                fullWidth
                sx={{ gridColumn: 'span 2' }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="quantityPerBox"
            control={control}
            defaultValue={0}
            render={({ field, fieldState }) => (
              <TextField
                label="Quantity Per Box"
                fullWidth
                type="number"
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="netWeight"
            control={control}
            defaultValue={0}
            render={({ field, fieldState }) => (
              <TextField
                label="Net Weight"
                fullWidth
                type="number"
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="grossWeight"
            control={control}
            defaultValue={0}
            render={({ field, fieldState }) => (
              <TextField
                label="Gross Weight"
                fullWidth
                type="number"
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="cubicMeterPerBox"
            control={control}
            defaultValue={0}
            render={({ field, fieldState }) => (
              <TextField
                label="Cubic Meter Per Box"
                fullWidth
                type="number"
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="features"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Features"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="stockLevel"
            control={control}
            defaultValue={0}
            render={({ field, fieldState }) => (
              <TextField
                label="Stock Level"
                fullWidth
                type="number"
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <EnumSelect
            name="bocClassification"
            label="BOC Classification"
            schema={schema}
            enumObj={BocClassification}
            control={control}
            defaultValue={'' as unknown as undefined}
          />
          <Controller
            name="remarks"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Remarks"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
        </Box>
        <Upload
          title="Upload Photos"
          name="photoUrls"
          control={control}
          defaultValue={[]}
          readOnly={readOnly}
        />
        <Specifications schema={schema} />
        <Parts schema={schema} />
        <ItemHistory schema={schema} />

        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ mt: 3, ...gradientButton }}
          disabled={mutationLoading}
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default ItemForm;
