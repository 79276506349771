import { isValid, parseISO } from "date-fns";
import { dateToString } from "./stringFunctions";

export const cleanPayload = (payload: {}, dateToStringFormat: string ="yyyy-MM-dd") => JSON.parse(JSON.stringify(payload, (key, value) => {
  if (key === '__typename') {
      delete value[key];
  } else if (isNaN(+value) && isValid(parseISO(value))) {
    return dateToString(parseISO(value), dateToStringFormat)
  } else if (value == null) {
    return
  } else {
    return value;
  }
}));