import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import { useCreateItemMutation } from '../../graphql/generated/types';
import { getUploads, prismaCreate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Meta from '../page/Meta';
import ItemForm from './ItemForm';
import { schema, FormData } from './utils';

const CreateItemPage = () => {
  const title = `Create Item`;
  const location = useLocation();
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({ photoUrls, parts, itemHistories, ...data }) =>
        await createItem({
          variables: {
            data: {
              ...prismaCreate(data),
              brand: {
                connect: {
                  id: data.brand.id,
                },
              },
              itemSubcategory: {
                connect: {
                  id: data.itemSubcategory.id,
                },
              },
              namingGroup: {
                connect: {
                  id: data.namingGroup.id,
                },
              },
              supplier: {
                connect: {
                  id: data.supplier.id,
                },
              },
              photoUrlsUploads: getUploads(photoUrls),
              parts: {
                connect: parts?.map(({ internalCode }) => {
                  return { internalCode };
                }),
              },
              itemHistories: {
                create: itemHistories?.map((itemHistory) => {
                  return {
                    ...prismaCreate(itemHistory),
                  };
                }),
              },
            },
          },
        }),
      (error) =>
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        })
    )(e);
  };

  const [createItem, { loading: mutationLoading }] = useCreateItemMutation({
    onError: (error) =>
      handleSnackbarOpen({
        message: capitalizeFirstLetter(title) + ' error! ' + error,
        severity: 'error',
      }),
    onCompleted: () => {
      handleSnackbarOpen({
        message: capitalizeFirstLetter(title) + ' success!',
        severity: 'success',
      });
      navigate(location.pathname.replace('/create', ''));
    },
  });

  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <ItemForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="create"
        />
      </FormProvider>
    </>
  );
};

export default CreateItemPage;
