import PageviewIcon from '@mui/icons-material/Pageview';
import { Checkbox, MenuItem } from '@mui/material';
import { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  AggregateConsigneeBranchDocument,
  ConsigneeBranchesDocument,
  ConsigneeBranchWhereInput,
  QueryMode,
  SortOrder,
  useConsigneeBranchesLazyQuery,
  useConsigneeBranchesQuery,
  useDeleteManyConsigneeBranchMutation,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { FullName } from '../../types/FullName';
import { HandleDelete } from '../../types/HandleDelete';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { dateToString, fullName } from '../../utils/stringFunctions';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import DownloadCsvButton from '../common/DownloadCsvButton';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';

const ConsigneeBranchesPage = ({ title = 'Consignee Branches' }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();
  const [searchQueryString] = useGetQueryString(['search']);

  const where: ConsigneeBranchWhereInput = {
    ...(typeof searchQueryString === 'string' && {
      OR: [
        {
          name: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
        {
          code: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
        {
          storeCode: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
        {
          storeName: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
        {
          employee: {
            is: {
              lastName: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },
        {
          employee: {
            is: {
              firstName: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },
      ],
    }),
  };
  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        consignee: {
          name: SortOrder.Asc,
        },
      },
      {
        name: SortOrder.Asc,
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { consigneeBranches } = {},
    loading,
    error,
  } = useConsigneeBranchesQuery({
    fetchPolicy: 'network-only',
    variables,
  });
  const [
    getDownloadData,
    { data: downloadData, loading: downloadDataLoading },
  ] = useConsigneeBranchesLazyQuery({
    variables: {
      where,
    },
  });
  const [deleteManyConsigneeBranch] = useDeleteManyConsigneeBranchMutation({
    refetchQueries: [
      { query: AggregateConsigneeBranchDocument, variables: countVariables },
      {
        query: ConsigneeBranchesDocument,
        variables,
      },
    ],
    onError: (error) =>
      handleSnackbarOpen({
        message: 'Delete' + title.toLowerCase() + ' error! ' + error,
        severity: 'error',
      }),
  });

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof consigneeBranches>>>
  > = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },
    {
      header: 'Consignee',
      accessor: 'consignee.name',
    },
    {
      header: 'Name',
      accessor: 'name',
    },
    {
      header: 'Code',
      accessor: 'code',
    },
    {
      header: 'Address',
      accessor: 'address',
    },
    {
      header: 'Region',
      accessor: 'region',
    },
    {
      header: 'Store Code',
      accessor: 'storeCode',
    },
    {
      header: 'Store Name',
      accessor: 'storeName',
    },
    {
      header: 'Date Opened',
      accessor: 'dateOpened',
      Cell: (children?: Date) => dateToString(children),
    },
    {
      header: 'Contact No.',
      accessor: 'contactNumber',
    },
    {
      header: 'Email',
      accessor: 'email',
    },
    {
      header: 'Coordinator',
      accessor: 'employee',
      Cell: (children: FullName) => fullName(children),
    },
  ];

  const handleDelete: HandleDelete = (ids) => {
    deleteManyConsigneeBranch({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <>
      <ListView
        loading={loading}
        error={error}
        title={title}
        miscButtons={
          <DownloadCsvButton
            downloadData={downloadData?.consigneeBranches.map(
              (consigneeBranch) => {
                return {
                  ...consigneeBranch,
                  employee: fullName(consigneeBranch?.employee),
                };
              }
            )}
            getDownloadData={getDownloadData}
            downloadDataLoading={downloadDataLoading}
            columns={columns}
          />
        }
        actionsMenu={
          <ActionsMenu
            handleDelete={handleDelete}
            selected={selected}
            setSelected={setSelected}
          >
            {selected.length === 1 && (
              <MenuItem
                onClick={() => navigate(`${location.pathname}/${selected[0]}`)}
              >
                <PageviewIcon />
                View
              </MenuItem>
            )}
          </ActionsMenu>
        }
        filters={<Search />}
        dataTable={<DataTable data={consigneeBranches} columns={columns} />}
        pagination={
          <Pagination
            query={AggregateConsigneeBranchDocument}
            perPage={perPage}
            page={Number(page)}
            variables={countVariables}
          />
        }
      />
    </>
  );
};

export default ConsigneeBranchesPage;
