import { useApolloClient } from '@apollo/client';
import {
  Autocomplete,
  AutocompleteProps,
  CircularProgress,
  debounce,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { useEffect, useState } from 'react';

const isObject = (value: any) => {
  if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
    return true;
  } else {
    return false;
  }
};
//https://github.com/mui/material-ui/blob/1fd983abd9c26d3d751f99d2efbfa2e5ab063786/packages/material-ui-lab/src/Autocomplete/Autocomplete.spec.tsx

interface SearchAsYouTypeAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  queryOptions: (value: string) => object;
  textFieldProps: TextFieldProps;
}

function SearchAsYouTypeAutocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  queryOptions,
  textFieldProps,
  sx,
  ...props
}: Omit<
  SearchAsYouTypeAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  'options' | 'renderInput'
>) {
  const client = useApolloClient();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly any[]>([]);
  const [loading, setLoading] = useState(false);

  const getOptions = debounce(async ({ query, variables }) => {
    setLoading(true);
    const { data = {} } = await client.query({
      query,
      variables,
      fetchPolicy: 'network-only',
    });
    const options = data[Object.keys(data)[0]];
    setOptions(options);
    setLoading(false);
  }, 350);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      sx={{ ...sx }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      loading={loading}
      onInputChange={(event, value) => {
        if (event && value) {
          getOptions({ ...queryOptions(value) });
        }
      }}
      {...props}
      options={options}
      isOptionEqualToValue={(option: any, value: any) => {
        if (isObject(value)) {
          return option?.id === value?.id;
        } else {
          return option === value;
        }
      }}
      renderInput={(params) => (
        <TextField
          placeholder="Type to search..."
          {...params}
          {...textFieldProps}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default SearchAsYouTypeAutocomplete;
