import { array, date, InferType, mixed, number, object, string } from 'yup';

import { ContainerCapacity } from '../../graphql/enums/ContainerCapacity';


export const schema = object({
  supplier: object().nullable().default(null).required(),
  containerCapacity: mixed<ContainerCapacity>()
  .oneOf(Object.values(ContainerCapacity))
  .required(),
  date: date().required(),
  notes: string().nullable(),
  purchaseOrderItems: array().of(object({
    item: object().nullable().default(null).required(),
    quantity: number().required(),

    netWeight: number().required(),
    grossWeight: number().required(),
    cubicMeterPerBox: number().required(),
    remarks: string()
  }))
}).required();

export type FormData = InferType<typeof schema>

