import RemoveIcon from '@mui/icons-material/Remove';
import { Box, IconButton, TextField } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { BaseSchema } from 'yup';
import { GetInFormItemsDocument } from '../../graphql/generated/types';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import SearchAsYouTypeAutocomplete from '../common/SearchAsYouTypeAutocomplete';
import { FormData } from './utils';

interface Props {
  index: number;
  type?: string;
  schema: BaseSchema;
  handleRemoveShipmentReportItem: (index: number) => void;
}
const ShipmentReportItem = ({
  index,
  type,
  schema,
  handleRemoveShipmentReportItem,
}: Props) => {
  const { control, setValue } = useFormContext<FormData>();
  const [shipmentReportItems, putToUnits] = useWatch({
    control,
    name: ['shipmentReportItems', 'putToUnits'],
  });
  const subtotal =
    (shipmentReportItems?.[index]?.numberOfBoxesReceived || 0) *
    (shipmentReportItems?.[index]?.quantityPerBox || 0);
  const putToUnitQuantity =
    putToUnits?.find(
      ({ part }) => part?.id === shipmentReportItems?.[index].item?.id
    )?.quantity || 0;
  const finalQuantity =
    (shipmentReportItems?.[index]?.goodQuantity || 0) - putToUnitQuantity;

  const incompleteQuantity =
    shipmentReportItems?.[index]?.incompleteQuantity || 0;
  const damagedQuantity = shipmentReportItems?.[index]?.damagedQuantity || 0;
  useEffect(() => {
    const goodQuantity = subtotal - incompleteQuantity - damagedQuantity;

    setValue(`shipmentReportItems.${index}.goodQuantity`, goodQuantity);
  }, [index, setValue, incompleteQuantity, damagedQuantity, subtotal]);
  return (
    <>
      <Box>
        <Controller
          name={`shipmentReportItems.${index}.item`}
          control={control}
          defaultValue={null}
          render={({ field, fieldState }) => (
            <SearchAsYouTypeAutocomplete
              disabled={type === 'update'}
              textFieldProps={{
                ...textFieldHelperProps(field, fieldState, schema),
                label: `Item Name ${index + 1}`,
                // ,
              }}
              queryOptions={(value) => {
                return {
                  query: GetInFormItemsDocument,
                  variables: {
                    where: {
                      OR: [
                        {
                          name: {
                            contains: value,
                            mode: 'insensitive',
                          },
                        },
                      ],
                    },
                  },
                };
              }}
              getOptionLabel={(option: any) => option?.name}
              value={field.value}
              onChange={(e, value) => field.onChange(value)}
            />
          )}
        />
        <TextField
          label={`Internal Code ${index + 1}`}
          fullWidth
          value={
            (shipmentReportItems &&
              shipmentReportItems[index]?.item?.internalCode) ??
            ''
          }
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            readOnly: true,
          }}
        />
        <Controller
          name={`shipmentReportItems.${index}.numberOfBoxesReceived`}
          control={control}
          defaultValue={0}
          render={({ field, fieldState }) => (
            <TextField
              label={`Boxes Received ${index + 1}`}
              fullWidth
              type="number"
              {...textFieldHelperProps(field, fieldState, schema)}
              {...field}
            />
          )}
        />
        <Controller
          name={`shipmentReportItems.${index}.quantityPerBox`}
          control={control}
          defaultValue={0}
          render={({ field, fieldState }) => (
            <TextField
              label={`Quantity Per Box ${index + 1}`}
              fullWidth
              type="number"
              {...textFieldHelperProps(field, fieldState, schema)}
              {...field}
            />
          )}
        />
        <TextField
          label={`Subtotal ${index + 1}`}
          fullWidth
          value={subtotal ?? ''}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            readOnly: true,
          }}
        />
        <Controller
          name={`shipmentReportItems.${index}.incompleteQuantity`}
          control={control}
          defaultValue={0}
          render={({ field, fieldState }) => (
            <TextField
              label={`Incomplete ${index + 1}`}
              fullWidth
              type="number"
              {...textFieldHelperProps(field, fieldState, schema)}
              {...field}
            />
          )}
        />
        <Controller
          name={`shipmentReportItems.${index}.damagedQuantity`}
          control={control}
          defaultValue={0}
          render={({ field, fieldState }) => (
            <TextField
              label={`Damaged ${index + 1}`}
              fullWidth
              type="number"
              {...textFieldHelperProps(field, fieldState, schema)}
              {...field}
            />
          )}
        />
        <Controller
          name={`shipmentReportItems.${index}.goodQuantity`}
          control={control}
          defaultValue={0}
          render={({ field, fieldState }) => (
            <TextField
              label={`Good ${index + 1}`}
              fullWidth
              type="number"
              {...textFieldHelperProps(field, fieldState, schema)}
              {...field}
            />
          )}
        />
        <TextField
          label={`Put to Unit ${index + 1}`}
          fullWidth
          value={putToUnitQuantity ?? ''}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          label={`Final Quantity ${index + 1}`}
          fullWidth
          value={finalQuantity ?? ''}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            readOnly: true,
          }}
        />
        <Controller
          name={`shipmentReportItems.${index}.remarks`}
          control={control}
          defaultValue=""
          render={({ field, fieldState }) => (
            <TextField
              label={`Remarks ${index + 1}`}
              fullWidth
              {...textFieldHelperProps(field, fieldState, schema)}
              {...field}
            />
          )}
        />
        <Box
          component="img"
          src={
            (shipmentReportItems &&
              shipmentReportItems[index]?.item?.photoUrls?.[0]) ||
            undefined
          }
          sx={{
            width: '100%',
            height: '100%',
            display: shipmentReportItems?.[index]?.item?.photoUrls?.[0]
              ? 'block'
              : 'none',
          }}
          alt={shipmentReportItems && shipmentReportItems[index]?.item?.name}
        />
      </Box>
      <IconButton
        aria-label="delete"
        color="primary"
        size="small"
        onClick={() => handleRemoveShipmentReportItem(index)}
      >
        <RemoveIcon />
      </IconButton>
    </>
  );
};

export default ShipmentReportItem;
