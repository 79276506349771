import { Checkbox } from '@mui/material';
import { ReactNode } from 'react';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  AggregateConsigneeDocument,
  ConsigneesDocument,
  ConsigneeWhereInput,
  QueryMode,
  SortOrder,
  useConsigneesQuery,
  useDeleteManyConsigneeMutation,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { FullName } from '../../types/FullName';
import { HandleDelete } from '../../types/HandleDelete';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { dateToString, fullName } from '../../utils/stringFunctions';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import DataTableAccordion from '../common/DataTableAccordion';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';

const ConsigneesPage = ({ title = 'Consignees' }) => {
  const { handleSnackbarOpen } = useSnackbarContext();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();
  const [searchQueryString] = useGetQueryString(['search']);

  const where: ConsigneeWhereInput = {
    ...(typeof searchQueryString === 'string' && {
      OR: [
        {
          name: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
        {
          code: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
        {
          companyName: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
      ],
    }),
  };
  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        name: SortOrder.Asc,
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { consignees } = {},
    loading,
    error,
  } = useConsigneesQuery({
    fetchPolicy: 'network-only',
    variables,
  });

  const [deleteManyConsignee] = useDeleteManyConsigneeMutation({
    refetchQueries: [
      { query: AggregateConsigneeDocument, variables: countVariables },
      {
        query: ConsigneesDocument,
        variables,
      },
    ],
    onError: (error) =>
      handleSnackbarOpen({
        message: 'Delete' + title.toLowerCase() + ' error! ' + error,
        severity: 'error',
      }),
  });

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof consignees>>>
  > = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },

    {
      header: 'Name',
      accessor: 'name',
    },
    {
      header: 'Code',
      accessor: 'code',
    },
    {
      header: 'Tin No.',
      accessor: 'tinNumber',
    },
    {
      header: 'Company Name',
      accessor: 'companyName',
    },
    {
      header: 'Business Address',
      accessor: 'businessAddress',
    },
    {
      header: 'Business Style',
      accessor: 'businessStyle',
    },
    {
      header: 'Vendor Code',
      accessor: 'vendorCode',
    },
    {
      header: 'Remarks',
      accessor: 'remarks',
    },
    {
      header: 'Branches',
      accessor: 'consigneeBranches',
      Cell: (children: {}[], index: number) => {
        const consigneeBranchesColumns = [
          {
            header: 'Name',
            accessor: 'name',
          },
          {
            header: 'Code',
            accessor: 'code',
          },

          {
            header: 'Region',
            accessor: 'region',
          },
          {
            header: 'Store Code',
            accessor: 'storeCode',
          },
          {
            header: 'Store Name',
            accessor: 'storeName',
          },
          {
            header: 'Date Opened',
            accessor: 'dateOpened',
            Cell: (children?: Date) => dateToString(children),
          },
          {
            header: 'Contact No.',
            accessor: 'contactNumber',
          },
          {
            header: 'Email',
            accessor: 'email',
          },
          {
            header: 'Coordinator',
            accessor: 'employee',
            Cell: (children: FullName) => fullName(children),
          },
        ];
        return (
          <DataTableAccordion
            title="Names"
            data={children}
            index={index}
            columns={consigneeBranchesColumns}
          />
        );
      },
    },
  ];

  const handleDelete: HandleDelete = (ids) => {
    deleteManyConsignee({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <>
      <ListView
        loading={loading}
        error={error}
        title={title}
        actionsMenu={
          <ActionsMenu
            handleDelete={handleDelete}
            selected={selected}
            setSelected={setSelected}
          />
        }
        filters={<Search />}
        dataTable={<DataTable data={consignees} columns={columns} />}
        pagination={
          <Pagination
            query={AggregateConsigneeDocument}
            perPage={perPage}
            page={Number(page)}
            variables={countVariables}
          />
        }
      />
    </>
  );
};

export default ConsigneesPage;
