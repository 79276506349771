import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { Currency, GetInFormPackingListsDocument } from '../../graphql/generated/types';
import { gradientButton } from '../../styles/gradientButton';
import { nestedGrid } from '../../styles/nestedGrid';
import { FormProps } from '../../types/FormProps';
import { roundFloat } from '../../utils/numberFunctions';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import EnumSelect from '../common/EnumSelect';
import FormContainer from '../common/FormContainer';
import SearchAsYouTypeAutocomplete from '../common/SearchAsYouTypeAutocomplete';
import Upload from '../common/upload/Upload';
import SupplierAutocomplete from '../shared/SupplierAutocomplete';
import { FormData } from './utils';

const BureauOfCustomsRequirementForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
  type,
}: FormProps) => {
  const { control, setValue } = useFormContext<FormData>();
  const { fields } = useFieldArray({
    control,
    name: 'bocInvoiceItems',
  });

  const [bocInvoiceItems, packingLists, currency, supplier] = useWatch({
    name: ['bocInvoiceItems', 'packingLists', 'currency', 'supplier'],
    control,
  });

  const computeSubtotal = (bocInvoiceItem: any) => {
    return (
      (bocInvoiceItems &&
        bocInvoiceItem.unitPrice *
          bocInvoiceItem.packingListItem?.actualQuantity) ||
      0
    );
  };
  const total = bocInvoiceItems?.reduce((acc, cur) => {
    return acc + computeSubtotal(cur);
  }, 0);

  return (
    <FormContainer maxWidth="lg">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
        gutterBottom
      >
        {title}
      </Typography>
      <Box component="form" onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(6, 1fr)',
            gap: 1,
          }}
        >
          <Controller
            name="date"
            control={control}
            defaultValue={'' as unknown as Date}
            render={({ field, fieldState }) => (
              <TextField
                label="Date"
                fullWidth
                type="date"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="invoiceNumber"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Invoice Number"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <SupplierAutocomplete
            name="supplier"
            schema={schema}
            control={control}
            label="Supplier"
            defaultValue={null}
          />

          <Controller
            name="packingLists"
            control={control}
            defaultValue={[]}
            render={({ field, fieldState }) => (
              <SearchAsYouTypeAutocomplete
                sx={{ gridColumn: 'span 3' }}
                multiple
                textFieldProps={{
                  ...textFieldHelperProps(field, fieldState, schema),
                  label: 'Packing Lists',
                }}
                queryOptions={(value) => {
                  return {
                    query: GetInFormPackingListsDocument,
                    variables: {
                      where: {
                        bureauOfCustomsRequirementId: {
                          equals: null,
                        },
                        purchaseOrders: {
                          some: {
                            supplier: {
                              is: {
                                id: {
                                  equals: supplier?.id,
                                },
                              },
                            },
                          },
                        },
                        OR: [
                          {
                            number: {
                              contains: value,
                              mode: 'insensitive',
                            },
                          },
                        ],
                      },
                    },
                  };
                }}
                getOptionLabel={(option: any) => option?.number}
                value={field.value as any}
                onChange={(e, value) => {
                  if (value && value.length > 0) {
                    setValue('origin', value[0].origin);
                    setValue('destination', value[0].destination);
                  } else {
                    setValue('origin', '');
                    setValue('destination', '');
                  }
                  if (value.length > (packingLists || []).length) {
                    setValue('bocInvoiceItems', [
                      ...(bocInvoiceItems || []),
                      ...value
                        .at(-1)
                        .packingListItems.map((packingListItem: any) => {
                          return {
                            unitPrice: 0,
                            packingListItem: {
                              ...packingListItem,
                            },
                          };
                        }),
                    ]);
                  } else {
                    const packingListItemIdsToRemove = (packingLists || [])
                      .at(-1)
                      ?.packingListItems?.map(
                        (packingListItem: any) => packingListItem.id
                      );

                    setValue(
                      'bocInvoiceItems',
                      bocInvoiceItems?.filter(
                        ({ packingListItem }) =>
                          !packingListItemIdsToRemove?.includes(
                            packingListItem?.id
                          )
                      )
                    );
                  }
                  field.onChange(value);
                }}
                disabled={type === 'update' || !supplier}
              />
            )}
          />
          <Controller
            name="vessel"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Vessel"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="origin"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Origin"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="destination"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Destination"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="madeIn"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Made In"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="remarks"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Remarks"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <EnumSelect
            name="currency"
            label="Currency"
            schema={schema}
            enumObj={Currency}
            control={control}
            defaultValue={'' as unknown as undefined}
          />
          {/* <Controller
            name="currency"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Currency"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          /> */}
        </Box>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          BOC Invoice Items
        </Typography>
        <Stack direction="column" spacing={1}>
          {fields.map((field, index) => {
            const subtotal = computeSubtotal(bocInvoiceItems?.[index]);

            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <TextField
                    label={`Actual Quantity ${index + 1}`}
                    fullWidth
                    value={
                      (bocInvoiceItems &&
                        bocInvoiceItems[index]?.packingListItem
                          ?.actualQuantity) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    label={`Actual No. of Boxes ${index + 1}`}
                    fullWidth
                    value={
                      (bocInvoiceItems &&
                        bocInvoiceItems[index]?.packingListItem
                          ?.actualNumberOfBoxes) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />

                  <TextField
                    label={`Internal Code ${index + 1}`}
                    fullWidth
                    value={
                      (bocInvoiceItems &&
                        bocInvoiceItems[index]?.packingListItem?.item
                          ?.internalCode) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    label={`Item Name ${index + 1}`}
                    fullWidth
                    value={
                      (bocInvoiceItems &&
                        bocInvoiceItems[index]?.packingListItem?.item?.name) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    label={`Supplier's Item Code ${index + 1}`}
                    fullWidth
                    value={
                      (bocInvoiceItems &&
                        bocInvoiceItems[index]?.packingListItem?.item
                          ?.supplierItemCode) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    label={`Quantity / Box ${index + 1}`}
                    fullWidth
                    value={
                      (bocInvoiceItems &&
                        bocInvoiceItems[index]?.packingListItem?.item
                          ?.quantityPerBox) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    label={`CBM / Box ${index + 1}`}
                    fullWidth
                    value={
                      (bocInvoiceItems &&
                        bocInvoiceItems[index]?.packingListItem
                          ?.cubicMeterPerBox) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    label={`Total CBM ${index + 1}`}
                    fullWidth
                    value={
                      (bocInvoiceItems &&
                        bocInvoiceItems[index]?.packingListItem?.totalCbm) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    label={`Remarks ${index + 1}`}
                    fullWidth
                    value={
                      (bocInvoiceItems &&
                        bocInvoiceItems[index]?.packingListItem?.remarks) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <Controller
                    name={`bocInvoiceItems.${index}.unitPrice`}
                    control={control}
                    defaultValue={0}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`Unit Price ${index + 1}`}
                        fullWidth
                        type="number"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                      />
                    )}
                  />
                  <TextField
                    label={`Subtotal ${index + 1}`}
                    fullWidth
                    value={`${currency || ''} ${roundFloat(subtotal)}` ?? ''}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Box>
              </Box>
            );
          })}
        </Stack>
        <Typography variant="h6" align="right">
          Total: {currency || ''} {roundFloat(total || 0)}
        </Typography>

        <Upload
          title="Upload Documents"
          name="documentUrls"
          control={control}
          defaultValue={[]}
        />
        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ mt: 3, ...gradientButton }}
          disabled={mutationLoading}
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default BureauOfCustomsRequirementForm;
