import queryString from 'query-string';
import { useLocation } from 'react-router';

const useGetQueryString = (keys: string[]) => {
  let location = useLocation();
  const paramsObj = queryString.parse(location.search);
  const queryStringValues = keys.map((key) => paramsObj[key]);
  return queryStringValues;
};

export default useGetQueryString;
