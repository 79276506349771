import { Checkbox } from '@mui/material';
import { endOfDay, startOfDay } from 'date-fns';
import { ReactNode } from 'react';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  AggregatePutToUnitDocument,
  PutToUnitsDocument,
  PutToUnitWhereInput,
  QueryMode,
  SortOrder,
  useDeleteManyPutToUnitMutation,
  usePutToUnitsLazyQuery,
  usePutToUnitsQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { HandleDelete } from '../../types/HandleDelete';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { dateToString } from '../../utils/stringFunctions';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import DateRangeFilter from '../common/DateRangeFilter';
import DownloadCsvButton from '../common/DownloadCsvButton';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';

const PutToUnitsPage = ({ title = 'Put to Units' }) => {
  const { handleSnackbarOpen } = useSnackbarContext();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();
  const [searchQueryString, startDateQueryString, endDateQueryString] =
    useGetQueryString(['search', 'startDate', 'endDate']);

  const where: PutToUnitWhereInput = {
    ...(typeof startDateQueryString === 'string' &&
      typeof endDateQueryString === 'string' && {
        shipmentReport: {
          is: {
            dateReceived: {
              gte: startOfDay(new Date(startDateQueryString)),
              lte: endOfDay(new Date(endDateQueryString)),
            },
          },
        },
      }),
    ...(typeof searchQueryString === 'string' && {
      OR: [
        {
          missingItem: {
            is: {
              shipmentReport: {
                is: {
                  transactionNumber: {
                    contains: searchQueryString,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
            },
          },
        },
        {
          remarks: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
        {
          shipmentReport: {
            is: {
              supplier: {
                is: {
                  name: {
                    contains: searchQueryString,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
            },
          },
        },
        {
          missingItem: {
            is: {
              part: {
                is: {
                  name: {
                    contains: searchQueryString,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
            },
          },
        },
        {
          missingItem: {
            is: {
              part: {
                is: {
                  internalCode: {
                    contains: searchQueryString,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
            },
          },
        },
        {
          missingItem: {
            is: {
              motherUnit: {
                is: {
                  name: {
                    contains: searchQueryString,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
            },
          },
        },
      ],
    }),
  };
  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        shipmentReport: {
          dateReceived: SortOrder.Desc,
        },
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { putToUnits } = {},
    loading,
    error,
  } = usePutToUnitsQuery({
    fetchPolicy: 'network-only',
    variables,
  });
  const [
    getDownloadData,
    { data: downloadData, loading: downloadDataLoading },
  ] = usePutToUnitsLazyQuery({
    variables: {
      where,
    },
  });
  const [deleteManyPutToUnit] = useDeleteManyPutToUnitMutation({
    refetchQueries: [
      { query: AggregatePutToUnitDocument, variables: countVariables },
      {
        query: PutToUnitsDocument,
        variables,
      },
    ],
    onError: (error) =>
      handleSnackbarOpen({
        message: 'Delete' + title.toLowerCase() + ' error! ' + error,
        severity: 'error',
      }),
  });

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof putToUnits>>>
  > = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },
    {
      header: 'TR No.',
      accessor: 'missingItem.shipmentReport.transactionNumber',
    },

    {
      header: 'Date Received',
      accessor: 'shipmentReport.dateReceived',
      Cell: (children?: Date) => dateToString(children),
    },
    {
      header: 'Supplier',
      accessor: 'shipmentReport.supplier.name',
    },
    {
      header: 'Missing Item TR No.',
      accessor: 'missingItem.shipmentReport.transactionNumber',
    },
    {
      header: 'Part Internal Code',
      accessor: 'missingItem.part.internalCode',
    },
    {
      header: 'Part Item Name',
      accessor: 'missingItem.part.name',
    },
    {
      header: 'Mother Unit Item Name',
      accessor: 'missingItem.motherUnit.name',
    },
    {
      header: 'Quantity',
      accessor: 'quantity',
      Cell: (children: number) => children.toLocaleString(),
    },

    {
      header: 'Remarks',
      accessor: 'remarks',
    },
  ];

  const handleDelete: HandleDelete = (ids) => {
    deleteManyPutToUnit({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <>
      <ListView
        loading={loading}
        error={error}
        title={title}
        miscButtons={
          <DownloadCsvButton
            downloadData={downloadData?.putToUnits}
            getDownloadData={getDownloadData}
            downloadDataLoading={downloadDataLoading}
            columns={columns}
          />
        }
        actionsMenu={
          <ActionsMenu
            handleDelete={handleDelete}
            selected={selected}
            setSelected={setSelected}
          />
        }
        filters={
          <>
            <DateRangeFilter />
            <Search />
          </>
        }
        dataTable={<DataTable data={putToUnits} columns={columns} />}
        pagination={
          <Pagination
            query={AggregatePutToUnitDocument}
            perPage={perPage}
            page={Number(page)}
            variables={countVariables}
          />
        }
      />
    </>
  );
};

export default PutToUnitsPage;
