import PageviewIcon from '@mui/icons-material/Pageview';
import { Checkbox, MenuItem } from '@mui/material';
import { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  AggregateEmployeeDocument,
  Department,
  EmployeesDocument,
  EmployeeWhereInput,
  QueryMode,
  Rank,
  SortOrder,
  useDeleteManyEmployeeMutation,
  useEmployeesQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { FullName } from '../../types/FullName';
import { HandleDelete } from '../../types/HandleDelete';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import {
  dateToString,
  fullName,
  getEnumKeyFromValue,
} from '../../utils/stringFunctions';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import EnumSearchFilter from '../common/EnumSearchFilter';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';

const EmployeesPage = ({ title = 'Employees' }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();

  const [searchQueryString, rankQueryString, departmentQueryString] =
    useGetQueryString(['search', 'rank', 'department']);
  const where: EmployeeWhereInput = {
    ...(rankQueryString && {
      rank: {
        equals: rankQueryString as Rank,
      },
    }),
    ...(departmentQueryString && {
      departments: {
        has: departmentQueryString as Department,
      },
    }),
    ...(typeof searchQueryString === 'string' && {
      OR: [
        {
          group: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
        {
          address: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
        {
          firstName: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
        {
          middleName: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
        {
          lastName: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
      ],
    }),
  };
  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        lastName: SortOrder.Asc,
      },
      { firstName: SortOrder.Asc },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { employees } = {},
    loading,
    error,
  } = useEmployeesQuery({
    fetchPolicy: 'network-only',
    variables,
  });

  const [deleteManyEmployee] = useDeleteManyEmployeeMutation({
    refetchQueries: [
      { query: AggregateEmployeeDocument, variables: countVariables },
      {
        query: EmployeesDocument,
        variables,
      },
    ],
    onError: (error) =>
      handleSnackbarOpen({
        message: 'Delete' + title.toLowerCase() + ' error! ' + error,
        severity: 'error',
      }),
  });

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof employees>>>
  > = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },
    {
      header: 'Last Name',
      accessor: 'lastName',
    },
    {
      header: 'First Name',
      accessor: 'firstName',
    },
    {
      header: 'Middle Name',
      accessor: 'middleName',
    },
    {
      header: 'Suffix',
      accessor: 'suffix',
    },
    {
      header: 'Address',
      accessor: 'address',
    },
    {
      header: 'Birthday',
      accessor: 'birthday',
      Cell: (children?: Date) => dateToString(children),
    },
    {
      header: 'Date Hired',
      accessor: 'dateHired',
      Cell: (children?: Date) => dateToString(children),
    },
    {
      header: 'Coordinator',
      accessor: 'coordinator',
      Cell: (children?: FullName) => fullName(children),
    },
    {
      header: 'Departments',
      accessor: 'departments',
      Cell: (children?: string[]) =>
        children
          ?.map((child) => getEnumKeyFromValue(Department, child))
          .join(', '),
    },
    {
      header: 'Group',
      accessor: 'group',
    },
    {
      header: 'Rank',
      accessor: 'rank',
      Cell: (children: string) => getEnumKeyFromValue(Rank, children),
    },
  ];

  const handleDelete: HandleDelete = (ids) => {
    deleteManyEmployee({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <>
      <ListView
        loading={loading}
        error={error}
        title={title}
        actionsMenu={
          <ActionsMenu
            handleDelete={handleDelete}
            selected={selected}
            setSelected={setSelected}
          >
            {selected.length === 1 && (
              <MenuItem
                onClick={() => navigate(`${location.pathname}/${selected[0]}`)}
              >
                <PageviewIcon />
                View
              </MenuItem>
            )}
          </ActionsMenu>
        }
        filters={
          <>
            <EnumSearchFilter
              label="Department"
              field="department"
              enumObj={Department}
            />
            <EnumSearchFilter label="Rank" field="rank" enumObj={Rank} />
            <Search />
          </>
        }
        dataTable={<DataTable data={employees} columns={columns} />}
        pagination={
          <Pagination
            query={AggregateEmployeeDocument}
            perPage={perPage}
            page={Number(page)}
            variables={countVariables}
          />
        }
      />
    </>
  );
};

export default EmployeesPage;
