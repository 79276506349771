import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import SaveIcon from '@mui/icons-material/Save';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import iterateObjectValuesNullToBlank from '../../utils/objectFunctions';

const DownloadCsvButton = ({
  downloadData,
  getDownloadData,
  downloadDataLoading,
  columns,
}: any) => {
  const [clicked, setClicked] = useState(false);
  const csvLink = useRef<any>(null);
  const location = useLocation();

  useEffect(() => {
    if (
      clicked &&
      downloadData &&
      csvLink.current.link &&
      !downloadDataLoading
    ) {
      csvLink.current.link.click();
      setClicked(false);
    }
  }, [downloadData, downloadDataLoading, clicked]);

  return (
    <>
      <CSVLink
        headers={columns
          .filter(({ accessor }: any) => accessor !== 'id')
          .map((column: any) => {
            return { label: column.header, key: column.accessor };
          })}
        filename={`${location.pathname}-${format(
          Date.now(),
          'yyyy-MM-dd'
        )}.csv`}
        data={(downloadData || []).map((item: Record<string, any>) =>
          iterateObjectValuesNullToBlank(item)
        )}
        ref={csvLink}
      />
      {downloadDataLoading ? (
        <LoadingButton
          loading
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="contained"
          sx={{ marginRight: 'auto' }}
        >
          Loading...
        </LoadingButton>
      ) : (
        <Button
          onClick={() => {
            setClicked(true);
            getDownloadData();
          }}
          variant="contained"
          startIcon={<DownloadIcon />}
          sx={{ marginRight: 'auto' }}
        >
          Download
        </Button>
      )}
    </>
  );
};

export default DownloadCsvButton;
