import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  useConsigneeBranchQuery,
  useUpdateConsigneeBranchMutation,
} from '../../graphql/generated/types';
import { cleanPayload } from '../../utils/apolloClient';
import { prismaUpdate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Loading from '../common/Loading';
import Meta from '../page/Meta';
import ConsigneeBranchForm from './ConsigneeBranchForm';
import { schema, FormData } from './utils';

const UpdateConsigneeBranchPage = ({
  title = `Update Consignee Branch`,
  readOnly = false,
}) => {
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async (data) => {
        await updateConsigneeBranch({
          variables: {
            where: {
              id,
            },
            data: {
              ...prismaUpdate(data),
              consignee: {
                connect: {
                  id: data?.consignee.id,
                },
              },
              employee: data?.employee?.id
                ? {
                    connect: {
                      id: data?.employee?.id,
                    },
                  }
                : {
                    disconnect: true,
                  },
            },
          },
        });
      },
      (error) =>
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        })
    )(e);
  };

  const {
    data: { consigneeBranch } = {},
    loading,
    error,
  } = useConsigneeBranchQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: { id },
    },
  });

  const [updateConsigneeBranch, { loading: mutationLoading }] =
    useUpdateConsigneeBranchMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(-1);
      },
    });

  useEffect(() => {
    if (consigneeBranch) {
      methods.reset({ ...cleanPayload(consigneeBranch) });
    }
  }, [consigneeBranch, methods]);

  if (loading) return <Loading />;

  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <ConsigneeBranchForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          readOnly={readOnly}
        />
      </FormProvider>
    </>
  );
};

export default UpdateConsigneeBranchPage;
