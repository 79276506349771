import { array, date, InferType, mixed, number, object, string } from 'yup';
import { Currency } from '../../graphql/enums/Currency';



export const schema = object({
  number: string().required(),
  supplier: object().nullable().default(null).required(),
  invoiceDate: date().required(),
  paymentDueDate: date().required(),
  currency: mixed<Currency>().oneOf([null, "", ...Object.values(Currency)] as Currency[]),
  purchaseOrders: array().of(object({
    id: number().required(),
    number: string().required()
  })).required().min(1),
  proformaInvoiceItems: array().of(object({
    subtotal: number(),
    internalCode: string(),
    itemName: string(),
    quantity: number().required(),
    unitGrossCost: number().required(),
    unitNetCost: number().required(),
  })),
  documentUrls: array(mixed()).defined()
}).required();

export type FormData = InferType<typeof schema>