import { Stack, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format, parse } from 'date-fns';
import queryString from 'query-string';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const DateRangeFilter = ({
  startField = 'startDate',
  endField = 'endDate',
  formatString = 'yyyy-MM-dd',
}) => {
  let navigate = useNavigate();
  let location = useLocation();
  const parsed = queryString.parse(location.search);
  const startQueryStringValue = parsed[startField];
  const endQueryStringValue = parsed[endField];

  const [startValue, setStartValue] = useState(
    typeof startQueryStringValue === 'string'
      ? parse(startQueryStringValue, formatString, new Date())
      : null
  );
  const [endValue, setEndValue] = useState(
    typeof endQueryStringValue === 'string'
      ? parse(endQueryStringValue, formatString, new Date())
      : null
  );

  const handleChangeStartValue = (value: Date | null) => {
    setStartValue(value);
    if (endValue && value) {
      delete parsed['page'];
      navigate({
        pathname: location.pathname,
        search: queryString.stringify({
          ...parsed,
          [startField]: format(value, 'yyyy-MM-dd'),
          [endField]: format(endValue, 'yyyy-MM-dd'),
        }),
      });
    }
  };

  const handleChangeEndValue = (value: Date | null) => {
    setEndValue(value);

    if (startValue && value) {
      delete parsed['page'];
      navigate({
        pathname: location.pathname,
        search: queryString.stringify({
          ...parsed,
          [startField]: format(startValue, 'yyyy-MM-dd'),
          [endField]: format(value, 'yyyy-MM-dd'),
        }),
      });
    }
  };

  const handleClearDateFilters = () => {
    delete parsed[startField];
    delete parsed[endField];

    navigate({
      pathname: location.pathname,
      search: queryString.stringify({ ...parsed }),
    });
    setStartValue(null);
    setEndValue(null);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={1}>
        <DatePicker
          label="Start Date"
          value={startValue ?? null}
          onChange={handleChangeStartValue}
          renderInput={(params: any) => (
            <TextField
              {...params}
              onKeyDown={(e) => e.preventDefault()}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              sx={{
                backgroundColor: (theme) => theme.palette.common.white,
              }}
            />
          )}
        />
        <DatePicker
          label="End Date"
          value={endValue || null}
          onChange={handleChangeEndValue}
          renderInput={(params: any) => (
            <TextField
              {...params}
              onKeyDown={(e) => e.preventDefault()}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              sx={{
                backgroundColor: (theme) => theme.palette.common.white,
              }}
            />
          )}
        />
        <Button onClick={handleClearDateFilters}>Clear</Button>
      </Stack>
    </LocalizationProvider>
  );
};

export default DateRangeFilter;
