import RemoveIcon from '@mui/icons-material/Remove';
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import {
  Department,
  GetInFormEmployeesDocument,
  GetInFormItemsDocument,
  GetInFormPackingListsDocument,
  GetInFormPurchaseOrdersDocument,
  ShipmentReportContainerType,
  ShipmentReportDestination,
  ShipmentReportOrigin,
  ShipmentReportsDocument,
} from '../../graphql/generated/types';
import { disabledSx } from '../../styles/disabledSx';
import { gradientButton } from '../../styles/gradientButton';
import { nestedGrid } from '../../styles/nestedGrid';
import { FormProps } from '../../types/FormProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import { fullName } from '../../utils/stringFunctions';
import EnumSelect from '../common/EnumSelect';
import FormContainer from '../common/FormContainer';
import SearchAsYouTypeAutocomplete from '../common/SearchAsYouTypeAutocomplete';
import Upload from '../common/upload/Upload';
import SupplierAutocomplete from '../shared/SupplierAutocomplete';
import ShipmentReportItem from './ShipmentReportItem';
import { FormData } from './utils';

const ShipmentReportForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
  type,
}: FormProps) => {
  const { control, setValue } = useFormContext<FormData>();
  const {
    fields: shipmentReportItemFields,
    append: appendShipmentReportItem,
    remove: removeShipmentReportItem,
  } = useFieldArray({
    control,
    name: 'shipmentReportItems',
  });

  const {
    fields: missingItemFields,
    append: appendMissingItem,
    remove: removeMissingItem,
  } = useFieldArray({
    control,
    name: 'missingItems',
  });

  const {
    fields: putToUnitFields,
    append: appendPutToUnit,
    remove: removePutToUnit,
  } = useFieldArray({
    control,
    name: 'putToUnits',
  });

  const [
    supplier,
    createdBy,
    checkedBy,
    lastUpdatedBy,
    missingItems,
    putToUnits,
    packingList,
  ] = useWatch({
    control,
    name: [
      'supplier',
      'createdBy',
      'checkedBy',
      'lastUpdatedBy',
      'missingItems',
      'putToUnits',
      'packingList',
    ],
  });

  const handleAddShipmentReportItem = () => {
    appendShipmentReportItem({
      item: null,
      numberOfBoxesReceived: 0,
      quantityPerBox: 0,
      goodQuantity: 0,
      incompleteQuantity: 0,
      damagedQuantity: 0,
      remarks: '',
    });
  };

  const handleRemoveShipmentReportItem = (index: number) => {
    removeShipmentReportItem(index);
  };

  const handleAddMissingItem = () => {
    appendMissingItem({
      part: null,
      motherUnit: null,
      quantity: 0,
      remarks: '',
    });
  };

  const handleRemoveMissingItem = (index: number) => {
    removeMissingItem(index);
  };

  const handleAddPutToUnit = () => {
    appendPutToUnit({
      part: null,
      motherUnit: null,
      shipmentReport: null,
      quantity: 0,
      remarks: '',
    });
  };

  const handleRemovePutToUnit = (index: number) => {
    removePutToUnit(index);
  };

  // const total = shipmentReportItems?.reduce((acc, cur) => {
  //   if (!cur.amount) return acc;
  //   return acc + cur.amount;
  // }, 0);

  // useEffect(() => {
  //   setValue('purchaseOrders', []);
  // }, [supplier, setValue]);

  return (
    <FormContainer maxWidth="lg">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
        gutterBottom
      >
        {title}
      </Typography>
      <Box component="form" onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(6, 1fr)',
            gap: 1,
          }}
        >
          <Controller
            name="transactionNumber"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Transaction Number"
                fullWidth
                disabled={type === 'update'}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <SupplierAutocomplete
            name="supplier"
            schema={schema}
            control={control}
            label="Supplier"
            defaultValue={null}
            onChange={() => {
              setValue('purchaseOrders', []);
            }}
          />
          <Controller
            name="checkIn"
            control={control}
            defaultValue={''}
            render={({ field, fieldState }) => (
              <TextField
                label="Check In"
                fullWidth
                type="time"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="checkOut"
            control={control}
            defaultValue={''}
            render={({ field, fieldState }) => (
              <TextField
                label="Check Out"
                fullWidth
                type="time"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <TextField
            label={`Created By`}
            fullWidth
            value={createdBy ?? ''}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            label={`Last Updated By`}
            fullWidth
            value={lastUpdatedBy ?? ''}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              readOnly: true,
            }}
          />
          <EnumSelect
            name="shipmentReportContainerType"
            label="Container Type"
            schema={schema}
            enumObj={ShipmentReportContainerType}
            control={control}
            defaultValue={'' as unknown as undefined}
          />
          <Controller
            name="numberOfBoxesReceived"
            control={control}
            defaultValue={0}
            render={({ field, fieldState }) => (
              <TextField
                label="No. of Boxes Received"
                fullWidth
                type="number"
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <EnumSelect
            name="shipmentReportOrigin"
            label="Origin"
            schema={schema}
            enumObj={ShipmentReportOrigin}
            control={control}
            defaultValue={'' as unknown as undefined}
          />
          <EnumSelect
            name="shipmentReportDestination"
            label="Destination"
            schema={schema}
            enumObj={ShipmentReportDestination}
            control={control}
            defaultValue={'' as unknown as undefined}
          />
          <Controller
            name="dateReceived"
            control={control}
            defaultValue={'' as unknown as Date}
            render={({ field, fieldState }) => (
              <TextField
                label="Date Received"
                fullWidth
                type="date"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="packingList"
            control={control}
            defaultValue={null}
            render={({ field, fieldState }) => (
              <SearchAsYouTypeAutocomplete
                textFieldProps={{
                  ...textFieldHelperProps(field, fieldState, schema),
                  label: 'Packing List',
                }}
                queryOptions={(value) => {
                  return {
                    query: GetInFormPackingListsDocument,
                    variables: {
                      where: {
                        shipmentReport: null,
                        purchaseOrders: {
                          some: {
                            supplierId: {
                              equals: supplier?.id,
                            },
                          },
                        },
                        OR: [
                          {
                            number: {
                              contains: value,
                              mode: 'insensitive',
                            },
                          },
                        ],
                      },
                    },
                  };
                }}
                getOptionLabel={(option: any) => option?.number}
                value={field.value}
                onChange={(e, value) => field.onChange(value)}
                disabled={!supplier}
              />
            )}
          />
          <Controller
            name="purchaseOrders"
            control={control}
            defaultValue={[]}
            render={({ field, fieldState }) => (
              <SearchAsYouTypeAutocomplete
                sx={{ gridColumn: 'span 3' }}
                multiple
                textFieldProps={{
                  ...textFieldHelperProps(field, fieldState, schema),
                  label: 'Purchase Orders',
                }}
                queryOptions={(value) => {
                  return {
                    query: GetInFormPurchaseOrdersDocument,
                    variables: {
                      where: {
                        packingLists: {
                          some: {
                            id: {
                              equals: packingList?.id,
                            },
                          },
                        },
                        approvedBy: {
                          not: null,
                        },
                        OR: [
                          {
                            number: {
                              contains: value,
                              mode: 'insensitive',
                            },
                          },
                        ],
                      },
                    },
                  };
                }}
                getOptionLabel={(option: any) => option?.number}
                value={field.value as any}
                onChange={(e, value) => field.onChange(value)}
                disabled={!packingList}
              />
            )}
          />
          <Controller
            name="employees"
            control={control}
            defaultValue={[]}
            render={({ field, fieldState }) => (
              <SearchAsYouTypeAutocomplete
                sx={{ gridColumn: 'span 3' }}
                multiple
                textFieldProps={{
                  ...textFieldHelperProps(field, fieldState, schema),
                  label: 'Employees',
                }}
                queryOptions={(value) => {
                  return {
                    query: GetInFormEmployeesDocument,
                    variables: {
                      where: {
                        departments: {
                          has: Department.Warehouse,
                        },
                        OR: [
                          {
                            firstName: {
                              contains: value,
                              mode: 'insensitive',
                            },
                          },
                          {
                            middleName: {
                              contains: value,
                              mode: 'insensitive',
                            },
                          },
                          {
                            lastName: {
                              contains: value,
                              mode: 'insensitive',
                            },
                          },
                        ],
                      },
                    },
                  };
                }}
                getOptionLabel={(option: any) => fullName(option)}
                value={field.value as any}
                onChange={(e, value) => field.onChange(value)}
              />
            )}
          />
          <TextField
            label={`Checked By`}
            fullWidth
            value={checkedBy ?? ''}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              readOnly: true,
            }}
          />
          {/* <Controller
            name="checkedBy"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Checked By"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          /> */}
        </Box>

        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Shipment Report Items
        </Typography>
        <Stack direction="column" spacing={1}>
          {shipmentReportItemFields.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <ShipmentReportItem
                  index={index}
                  handleRemoveShipmentReportItem={
                    handleRemoveShipmentReportItem
                  }
                  schema={schema}
                  type={type}
                />
              </Box>
            );
          })}
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            sx={{ my: 1 }}
            type="button"
            variant="contained"
            color="primary"
            onClick={handleAddShipmentReportItem}
            size="small"
            disabled={type === 'update'}
          >
            Add
          </Button>
        </Stack>
        <Box sx={type === 'update' ? { ...disabledSx } : {}}>
          <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
            Missing Items
          </Typography>
          <Stack direction="column" spacing={1}>
            {missingItemFields.map((field, index) => {
              return (
                <Box
                  key={field.id}
                  sx={{
                    ...nestedGrid,
                  }}
                >
                  <Box>
                    <Controller
                      name={`missingItems.${index}.part`}
                      control={control}
                      defaultValue={null}
                      render={({ field, fieldState }) => (
                        <SearchAsYouTypeAutocomplete
                          textFieldProps={{
                            ...textFieldHelperProps(field, fieldState, schema),
                            label: `Part Item Name ${index + 1}`,
                            // ,
                          }}
                          queryOptions={(value) => {
                            return {
                              query: GetInFormItemsDocument,
                              variables: {
                                where: {
                                  OR: [
                                    {
                                      name: {
                                        contains: value,
                                        mode: 'insensitive',
                                      },
                                    },
                                  ],
                                },
                              },
                            };
                          }}
                          getOptionLabel={(option: any) => option?.name}
                          value={field.value}
                          onChange={(e, value) => field.onChange(value)}
                        />
                      )}
                    />
                    <TextField
                      label={`Internal Code ${index + 1}`}
                      fullWidth
                      value={
                        (missingItems &&
                          missingItems[index]?.part?.internalCode) ??
                        ''
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <Controller
                      name={`missingItems.${index}.motherUnit`}
                      control={control}
                      defaultValue={null}
                      render={({ field, fieldState }) => (
                        <SearchAsYouTypeAutocomplete
                          textFieldProps={{
                            ...textFieldHelperProps(field, fieldState, schema),
                            label: `Mother Unit Item Name ${index + 1}`,
                            // ,
                          }}
                          queryOptions={(value) => {
                            return {
                              query: GetInFormItemsDocument,
                              variables: {
                                where: {
                                  OR: [
                                    {
                                      name: {
                                        contains: value,
                                        mode: 'insensitive',
                                      },
                                    },
                                  ],
                                },
                              },
                            };
                          }}
                          getOptionLabel={(option: any) => option?.name}
                          value={field.value}
                          onChange={(e, value) => field.onChange(value)}
                        />
                      )}
                    />
                    <Controller
                      name={`missingItems.${index}.quantity`}
                      control={control}
                      defaultValue={0}
                      render={({ field, fieldState }) => (
                        <TextField
                          label={`Quantity ${index + 1}`}
                          fullWidth
                          type="number"
                          {...textFieldHelperProps(field, fieldState, schema)}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name={`missingItems.${index}.remarks`}
                      control={control}
                      defaultValue=""
                      render={({ field, fieldState }) => (
                        <TextField
                          label={`Remarks ${index + 1}`}
                          fullWidth
                          {...textFieldHelperProps(field, fieldState, schema)}
                          {...field}
                        />
                      )}
                    />
                    <Box
                      component="img"
                      src={
                        (missingItems &&
                          missingItems[index]?.part?.photoUrls?.[0]) ||
                        undefined
                      }
                      sx={{
                        width: '100%',
                        height: '100%',
                        display: missingItems?.[index]?.part?.photoUrls?.[0]
                          ? 'block'
                          : 'none',
                      }}
                      alt={missingItems && missingItems[index]?.part?.name}
                    />
                  </Box>
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    size="small"
                    onClick={() => handleRemoveMissingItem(index)}
                  >
                    <RemoveIcon />
                  </IconButton>
                </Box>
              );
            })}
          </Stack>
          <Stack direction="row" justifyContent="flex-end">
            <Button
              sx={{ my: 1 }}
              type="button"
              variant="contained"
              color="primary"
              onClick={handleAddMissingItem}
              size="small"
            >
              Add
            </Button>
          </Stack>
        </Box>
        <Box sx={type === 'update' ? { ...disabledSx } : {}}>
          <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
            Put To Unit Items
          </Typography>
          <Stack direction="column" spacing={1}>
            {putToUnitFields.map((field, index) => {
              return (
                <Box
                  key={field.id}
                  sx={{
                    ...nestedGrid,
                  }}
                >
                  <Box>
                    <Controller
                      name={`putToUnits.${index}.part`}
                      control={control}
                      defaultValue={null}
                      render={({ field, fieldState }) => (
                        <SearchAsYouTypeAutocomplete
                          textFieldProps={{
                            ...textFieldHelperProps(field, fieldState, schema),
                            label: `Part Item Name ${index + 1}`,
                            // ,
                          }}
                          queryOptions={(value) => {
                            return {
                              query: GetInFormItemsDocument,
                              variables: {
                                where: {
                                  OR: [
                                    {
                                      name: {
                                        contains: value,
                                        mode: 'insensitive',
                                      },
                                    },
                                  ],
                                },
                              },
                            };
                          }}
                          getOptionLabel={(option: any) => option?.name}
                          value={field.value}
                          onChange={(e, value) => field.onChange(value)}
                        />
                      )}
                    />
                    <TextField
                      label={`Internal Code ${index + 1}`}
                      fullWidth
                      value={
                        (putToUnits && putToUnits[index]?.part?.internalCode) ??
                        ''
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <Controller
                      name={`putToUnits.${index}.motherUnit`}
                      control={control}
                      defaultValue={null}
                      render={({ field, fieldState }) => (
                        <SearchAsYouTypeAutocomplete
                          textFieldProps={{
                            ...textFieldHelperProps(field, fieldState, schema),
                            label: `Mother Unit Item Name ${index + 1}`,
                            // ,
                          }}
                          queryOptions={(value) => {
                            return {
                              query: GetInFormItemsDocument,
                              variables: {
                                where: {
                                  OR: [
                                    {
                                      name: {
                                        contains: value,
                                        mode: 'insensitive',
                                      },
                                    },
                                  ],
                                },
                              },
                            };
                          }}
                          getOptionLabel={(option: any) => option?.name}
                          value={field.value}
                          onChange={(e, value) => field.onChange(value)}
                        />
                      )}
                    />
                    <Controller
                      name={`putToUnits.${index}.shipmentReport`}
                      control={control}
                      defaultValue={null}
                      render={({ field, fieldState }) => (
                        <SearchAsYouTypeAutocomplete
                          textFieldProps={{
                            ...textFieldHelperProps(field, fieldState, schema),
                            label: `TR No. ${index + 1}`,
                            // ,
                          }}
                          queryOptions={(value) => {
                            return {
                              query: ShipmentReportsDocument,
                              variables: {
                                where: {
                                  missingItems: {
                                    some: {
                                      AND: [
                                        {
                                          motherUnitId: {
                                            equals:
                                              putToUnits?.[index]?.motherUnit
                                                ?.id,
                                          },
                                        },
                                        {
                                          partId: {
                                            equals:
                                              putToUnits?.[index]?.part?.id,
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  OR: [
                                    {
                                      transactionNumber: {
                                        contains: value,
                                        mode: 'insensitive',
                                      },
                                    },
                                  ],
                                },
                              },
                            };
                          }}
                          getOptionLabel={(option: any) =>
                            option?.transactionNumber
                          }
                          value={field.value}
                          onChange={(e, value) => field.onChange(value)}
                          disabled={
                            !putToUnits?.[index]?.motherUnit?.id ||
                            !putToUnits?.[index]?.part?.id
                          }
                        />
                      )}
                    />
                    <Controller
                      name={`putToUnits.${index}.quantity`}
                      control={control}
                      defaultValue={0}
                      render={({ field, fieldState }) => (
                        <TextField
                          label={`Quantity Per Box ${index + 1}`}
                          fullWidth
                          type="number"
                          {...textFieldHelperProps(field, fieldState, schema)}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name={`putToUnits.${index}.remarks`}
                      control={control}
                      defaultValue=""
                      render={({ field, fieldState }) => (
                        <TextField
                          label={`Remarks ${index + 1}`}
                          fullWidth
                          {...textFieldHelperProps(field, fieldState, schema)}
                          {...field}
                        />
                      )}
                    />
                    <Box
                      component="img"
                      src={
                        (putToUnits &&
                          putToUnits[index]?.part?.photoUrls?.[0]) ||
                        undefined
                      }
                      sx={{
                        width: '100%',
                        height: '100%',
                        display: putToUnits?.[index]?.part?.photoUrls?.[0]
                          ? 'block'
                          : 'none',
                      }}
                      alt={putToUnits && putToUnits[index]?.part?.name}
                    />
                  </Box>
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    size="small"
                    onClick={() => handleRemovePutToUnit(index)}
                  >
                    <RemoveIcon />
                  </IconButton>
                </Box>
              );
            })}
          </Stack>
          <Stack direction="row" justifyContent="flex-end">
            <Button
              sx={{ my: 1 }}
              type="button"
              variant="contained"
              color="primary"
              onClick={handleAddPutToUnit}
              size="small"
            >
              Add
            </Button>
          </Stack>
        </Box>
        {/* <Typography variant="h6" align="right">
          Total Payment: {shipmentReportItems?.[0]?.currency || ''}{' '}
          {roundFloat(total || 0)}
        </Typography> */}

        <Upload
          title="Upload Documents"
          name="documentUrls"
          control={control}
          defaultValue={[]}
        />
        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ mt: 3, ...gradientButton }}
          disabled={mutationLoading}
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default ShipmentReportForm;
