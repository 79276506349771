import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { array, date, InferType, number, object, string } from 'yup';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import { useCreateManyItemPricingMutation } from '../../graphql/generated/types';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Meta from '../page/Meta';
import CreateManyItemPricingForm from './CreateManyItemPricingForm';
export const schema = object({
  consignee: object().nullable().default(null).required(),
  itemPricingItems: array().of(
    object({
      item: object().nullable().default(null).required(),
      consigneeItemName: string().required(),
      effectivityDate: date().required(),
      price: number().required(),
      remarks: string().nullable(),
      sku: string().nullable(),
      smItemCategory: object({ id: number(), itemCategoryName: string() })
        .nullable()
        .default(null),
    })
  ),
}).required();

export type FormData = InferType<typeof schema>;

const CreateManyItemPricingPage = () => {
  const title = `Create Item Pricing`;
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async (data) =>
        await createManyItemPricing({
          variables: {
            data:
              data?.itemPricingItems?.map((itemPricingItem) => {
                return {
                  ...itemPricingItem,
                  consignee: {
                    connect: {
                      id: data?.consignee.id,
                    },
                  },
                  item: {
                    connect: {
                      id: itemPricingItem?.item.id,
                    },
                  },
                  smItemCategory: itemPricingItem?.smItemCategory?.id
                    ? {
                        connect: {
                          id: itemPricingItem?.smItemCategory?.id,
                        },
                      }
                    : {},
                };
              }) || [],
          },
        }),
      (error) =>
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        })
    )(e);
  };

  const [createManyItemPricing, { loading: mutationLoading }] =
    useCreateManyItemPricingMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(location.pathname.replace('/create', ''));
      },
    });

  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <CreateManyItemPricingForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
        />
      </FormProvider>
    </>
  );
};

export default CreateManyItemPricingPage;
