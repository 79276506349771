// iterate through object and change null values to empty object

import { isValid, parseISO } from "date-fns";
import { dateToString } from "./stringFunctions";

function isValidDate(dateString: string) {
  var date = new Date(dateString);
  return !isNaN(date.getTime());
}

function isValidISOString(dateString: string) {
  var date = new Date(dateString);
  return date.toISOString() === dateString;
}

const iterateObjectValuesNullToBlank = (obj: Record<string, any>) => {

  return Object.keys(obj).reduce((acc: Record<string, any>, cur: string): Record<string, any> => {

    if (Array.isArray(obj[cur])) {
      return {
        ...acc,
        [cur]: obj[cur].map((item: any) => iterateObjectValuesNullToBlank(item)),
      };
    }
    if (typeof obj[cur] === "object") {
      // double check bec typeof null === obj (weird thing about javascript)
      if (obj[cur] === null) {
        return { ...acc, [cur]: "" };
      } else {
        return { ...acc, [cur]: iterateObjectValuesNullToBlank(obj[cur]) };
      }
    }
    if (isValid(parseISO(obj[cur])) && isValidDate(obj[cur]) && isValidISOString(obj[cur])) {

      return { ...acc, [cur]: dateToString(obj[cur]) };
    }
    return { ...acc, [cur]: obj[cur] };
  }, {});
};

export default iterateObjectValuesNullToBlank;
