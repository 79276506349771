import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";

const link = createUploadLink({
  uri: `${process.env.REACT_APP_BACKEND_URI}`,
  credentials: "include",
  headers: {
    "Apollo-Require-Preflight": "true",
  },
});

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});

export default apolloClient;
