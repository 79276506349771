import {
  AggregatePendingOrderDocument,
  usePendingOrdersQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import DataTable from '../common/DataTable';
import DateRangeFilter from '../common/DateRangeFilter';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import { purchaseOrderColumns } from '../purchaseOrders/PurchaseOrdersPage';
import ListView from '../templates/ListView';

const PendingOrdersPage = ({ title = 'Pending Orders' }) => {
  const { page, perPage } = usePagination();
  const [searchQueryString, startDateQueryString, endDateQueryString] =
    useGetQueryString(['search', 'startDate', 'endDate']);

  const variables = {
    ...(typeof searchQueryString === 'string' && {
      searchTerm: searchQueryString,
    }),
    ...(typeof startDateQueryString === 'string' && {
      startDate: startDateQueryString,
    }),
    ...(typeof endDateQueryString === 'string' && {
      endDate: endDateQueryString,
    }),
    skip: page * perPage - perPage,
    take: perPage,
  };
  const countVariables = {
    searchTerm: searchQueryString,
    startDate: startDateQueryString,
    endDate: endDateQueryString,
  };
  const {
    data: { pendingOrders } = {},
    loading,
    error,
  } = usePendingOrdersQuery({
    fetchPolicy: 'network-only',
    variables,
  });

  return (
    <>
      <ListView
        loading={loading}
        error={error}
        title={title}
        filters={
          <>
            <DateRangeFilter />
            <Search />
          </>
        }
        dataTable={
          <DataTable data={pendingOrders} columns={purchaseOrderColumns} />
        }
        pagination={
          <Pagination
            query={AggregatePendingOrderDocument}
            perPage={perPage}
            page={Number(page)}
            variables={countVariables}
          />
        }
      />
    </>
  );
};

export default PendingOrdersPage;
