import RemoveIcon from '@mui/icons-material/Remove';
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import {
  GetInFormItemsDocument,
  GetInFormPurchaseOrdersDocument,
  ProformaInvoicesDocument,
  PurchaseOrderItem
} from '../../graphql/generated/types';
import { gradientButton } from '../../styles/gradientButton';
import { nestedGrid } from '../../styles/nestedGrid';
import { FormProps } from '../../types/FormProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import FormContainer from '../common/FormContainer';
import SearchAsYouTypeAutocomplete from '../common/SearchAsYouTypeAutocomplete';
import SupplierAutocomplete from '../shared/SupplierAutocomplete';
import { FormData } from './utils';

const packingListItemDefaultValue = {
  item: null,
  purchaseOrderItem: null,
  actualQuantity: 0,
  actualNumberOfBoxes: 0,
  netWeight: 0,
  grossWeight: 0,
  cubicMeterPerBox: 0,
  remarks: '',
};
const PackingListForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
  readOnly,
  type,
}: FormProps) => {
  const { control, setValue } = useFormContext<FormData>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'packingListItems',
  });

  const [supplier, packingListItems, number, purchaseOrders, proformaInvoices] =
    useWatch({
      control,
      name: [
        'supplier',
        'packingListItems',
        'number',
        'purchaseOrders',
        'proformaInvoices',
      ],
    });

  const handleAddPackingListItem = () => {
    append({
      ...packingListItemDefaultValue,
    });
  };

  // useEffect(() => {
  //   setValue('purchaseOrders', []);
  //   setValue('proformaInvoices', []);
  //   setValue('packingListItems', []);
  // }, [supplier, setValue]);

  const handleRemovePackingListItem = (index: number) => {
    remove(index);
  };

  return (
    <FormContainer maxWidth="lg" readOnly={readOnly}>
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
        gutterBottom
      >
        {title}
        {type === 'update' && `: ${number}`}
      </Typography>
      <Box component="form" onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(5, 1fr)',
            gap: 1,
          }}
        >
          <Controller
            name="date"
            control={control}
            defaultValue={'' as unknown as Date}
            render={({ field, fieldState }) => (
              <TextField
                label="Date"
                fullWidth
                type="date"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <SupplierAutocomplete
            name="supplier"
            schema={schema}
            control={control}
            label="Supplier"
            defaultValue={null}
            onChange={(value) => {
              if (!value) {
                setValue('purchaseOrders', []);
                setValue('proformaInvoices', []);
                setValue('packingListItems', []);
              }
            }}
          />
          <Controller
            name="estimatedTimeOfDeparture"
            control={control}
            defaultValue={'' as unknown as Date}
            render={({ field, fieldState }) => (
              <TextField
                label="Est Time of Departure"
                fullWidth
                type="date"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />

          <Controller
            name="origin"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Origin"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="destination"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Destination"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="proformaInvoices"
            control={control}
            defaultValue={[]}
            render={({ field, fieldState }) => (
              <SearchAsYouTypeAutocomplete
                sx={{ gridColumn: 'span 2' }}
                multiple
                textFieldProps={{
                  ...textFieldHelperProps(field, fieldState, schema),
                  label: 'Proforma Invoices',
                }}
                queryOptions={(value) => {
                  return {
                    query: ProformaInvoicesDocument,
                    variables: {
                      where: {
                        ...(supplier && {
                          supplier: {
                            is: {
                              name: {
                                equals: supplier.name,
                              },
                            },
                          },
                        }),
                        OR: [
                          {
                            number: {
                              contains: value,
                              mode: 'insensitive',
                            },
                          },
                        ],
                      },
                    },
                  };
                }}
                getOptionLabel={(option: any) => option?.number}
                value={field.value as any}
                onChange={(e, value) => field.onChange(value)}
                disabled={!supplier}
              />
            )}
          />
          <Controller
            name="purchaseOrders"
            control={control}
            defaultValue={[]}
            render={({ field, fieldState }) => (
              <SearchAsYouTypeAutocomplete
                sx={{ gridColumn: 'span 2' }}
                multiple
                textFieldProps={{
                  ...textFieldHelperProps(field, fieldState, schema),
                  label: 'Purchase Orders',
                }}
                queryOptions={(value) => {
                  return {
                    query: GetInFormPurchaseOrdersDocument,
                    variables: {
                      where: {
                        ...(proformaInvoices && {
                          proformaInvoices: {
                            some: {
                              id: {
                                in: proformaInvoices.map(({ id }) => id),
                              },
                            },
                          },
                        }),
                        approvedBy: {
                          not: null,
                        },
                        OR: [
                          {
                            number: {
                              contains: value,
                              mode: 'insensitive',
                            },
                          },
                        ],
                      },
                    },
                  };
                }}
                getOptionLabel={(option: any) => option?.number}
                value={field.value as any}
                onChange={(e, value) => {
                  if (value.length > (purchaseOrders || []).length) {
                    setValue('packingListItems', [
                      ...(packingListItems || []),
                      ...value
                        .at(-1)
                        .purchaseOrderItems.map(
                          ({
                            item,
                            netWeight,
                            grossWeight,
                            cubicMeterPerBox,
                            ...purchaseOrderItem
                          }: PurchaseOrderItem) => {
                            return {
                              ...packingListItemDefaultValue,
                              item: item,
                              purchaseOrderItem: {
                                ...purchaseOrderItem,
                              },
                              netWeight,
                              grossWeight,
                              cubicMeterPerBox,
                            };
                          }
                        ),
                    ]);
                  } else {
                    const purchaseOrderItemIdsToRemove = (purchaseOrders || [])
                      .at(-1)
                      ?.purchaseOrderItems?.map(
                        (purchaseOrderItem: any) => purchaseOrderItem.id
                      );
                    setValue(
                      'packingListItems',
                      packingListItems?.filter(
                        ({ purchaseOrderItem }) =>
                          !purchaseOrderItemIdsToRemove?.includes(
                            purchaseOrderItem?.id
                          )
                      )
                    );
                  }
                  field.onChange(value);
                }}
                // disabled={!supplier || type === 'update'}
              />
            )}
          />
        </Box>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Items
        </Typography>
        <Stack direction="column" spacing={1}>
          {fields.map((field, index) => {
            const hasPurchaseOrderItem =
              packingListItems?.[index]?.purchaseOrderItem != null;

            const totalCbm =
              (packingListItems &&
                packingListItems[index]?.cubicMeterPerBox *
                  packingListItems[index]?.actualNumberOfBoxes) ||
              0;
            const totalQuantity =
              (packingListItems &&
                packingListItems[index]?.purchaseOrderItem?.quantity) ||
              0;

            const totalBoxes =
              packingListItems &&
              totalQuantity /
                (packingListItems[index]?.item?.quantityPerBox || 1);
            const totalBalance =
              packingListItems &&
              (packingListItems[index]?.purchaseOrderItem?.poBalance || 0) -
                (packingListItems[index]?.actualQuantity || 0);
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  <Controller
                    name={`packingListItems.${index}.item`}
                    control={control}
                    defaultValue={null}
                    render={({ field, fieldState }) => (
                      <SearchAsYouTypeAutocomplete
                        textFieldProps={{
                          ...textFieldHelperProps(field, fieldState, schema),
                          label: `Internal Code ${index + 1}`,
                          // ,
                        }}
                        disabled={hasPurchaseOrderItem}
                        queryOptions={(value) => {
                          return {
                            query: GetInFormItemsDocument,
                            variables: {
                              where: {
                                OR: [
                                  {
                                    internalCode: {
                                      contains: value,
                                      mode: 'insensitive',
                                    },
                                  },
                                ],
                              },
                            },
                          };
                        }}
                        getOptionLabel={(option: any) => option?.internalCode}
                        value={field.value}
                        onChange={(e, value) => {
                          field.onChange(value);
                          setValue(
                            `packingListItems.${index}.netWeight`,
                            value?.netWeight || 0
                          );
                          setValue(
                            `packingListItems.${index}.grossWeight`,
                            value?.grossWeight || 0
                          );
                          setValue(
                            `packingListItems.${index}.cubicMeterPerBox`,
                            value?.cubicMeterPerBox || 0
                          );
                        }}
                      />
                    )}
                  />
                  <TextField
                    label={`Supplier's Item Code ${index + 1}`}
                    fullWidth
                    value={
                      (packingListItems &&
                        packingListItems[index]?.item?.supplierItemCode) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    label={`Item Name ${index + 1}`}
                    fullWidth
                    value={
                      (packingListItems &&
                        packingListItems[index]?.item?.name) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />

                  <TextField
                    label={`Qty / Box ${index + 1}`}
                    fullWidth
                    value={
                      (packingListItems &&
                        packingListItems[index]?.item?.quantityPerBox) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    label={`Total Boxes ${index + 1}`}
                    fullWidth
                    value={totalBoxes ?? ''}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    label={`Total Qty ${index + 1}`}
                    fullWidth
                    value={totalQuantity ?? ''}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <Controller
                    name={`packingListItems.${index}.actualNumberOfBoxes`}
                    control={control}
                    defaultValue={0}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`Actual No. of Boxes ${index + 1}`}
                        fullWidth
                        type="number"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name={`packingListItems.${index}.actualQuantity`}
                    control={control}
                    defaultValue={0}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`Actual Qty ${index + 1}`}
                        fullWidth
                        type="number"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                      />
                    )}
                  />
                  <TextField
                    label={`Total Balance ${index + 1}`}
                    fullWidth
                    value={totalBalance ?? ''}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />

                  <Controller
                    name={`packingListItems.${index}.netWeight`}
                    control={control}
                    defaultValue={0}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`Net Weight ${index + 1}`}
                        fullWidth
                        type="number"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name={`packingListItems.${index}.grossWeight`}
                    control={control}
                    defaultValue={0}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`Gross Weight ${index + 1}`}
                        fullWidth
                        type="number"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name={`packingListItems.${index}.cubicMeterPerBox`}
                    control={control}
                    defaultValue={0}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`CBM / Box ${index + 1}`}
                        fullWidth
                        type="number"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                      />
                    )}
                  />
                  <TextField
                    label={`Total CBM ${index + 1}`}
                    fullWidth
                    value={totalCbm ?? ''}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <Controller
                    name={`packingListItems.${index}.remarks`}
                    control={control}
                    defaultValue=""
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`Remarks ${index + 1}`}
                        fullWidth
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                      />
                    )}
                  />
                </Box>
                <IconButton
                  aria-label="delete"
                  color="primary"
                  size="small"
                  onClick={() => handleRemovePackingListItem(index)}
                  // disabled={hasPurchaseOrderItem}
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            );
          })}
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            sx={{ my: 1 }}
            type="button"
            variant="contained"
            color="primary"
            onClick={handleAddPackingListItem}
            size="small"
          >
            Add
          </Button>
        </Stack>

        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ mt: 3, ...gradientButton }}
          disabled={mutationLoading}
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default PackingListForm;
