import { Box, Button, TextField, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { BranchLocationCode, Region } from '../../graphql/generated/types';
import { gradientButton } from '../../styles/gradientButton';
import { FormProps } from '../../types/FormProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import EnumSelect from '../common/EnumSelect';
import FormContainer from '../common/FormContainer';
import ConsigneeAutocomplete from '../shared/ConsigneeAutocomplete';
import EmployeeAutocomplete from '../shared/EmployeeAutocomplete';
import { FormData } from './utils';

const ConsigneeBranchForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
  readOnly,
}: FormProps) => {
  const { control } = useFormContext<FormData>();
  return (
    <FormContainer maxWidth="sm" readOnly={readOnly}>
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
        gutterBottom
      >
        {title}
      </Typography>
      <Box component="form" onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: 1,
          }}
        >
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Name"
                fullWidth
                sx={{ gridColumn: 'span 2' }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <ConsigneeAutocomplete
            schema={schema}
            name="consignee"
            control={control}
            label="Consignee"
            defaultValue={null}
          />
          {/* <Controller
            name="consignee"
            control={control}
            defaultValue={null}
            render={({ field, fieldState }) => (
              <SearchAsYouTypeAutocomplete
                textFieldProps={{
                  ...textFieldHelperProps(field, fieldState, schema),
                  label: 'Consignee',
                }}
                queryOptions={(value) => {
                  return {
                    query: ConsigneesDocument,
                    variables: {
                      where: {
                        OR: [
                          {
                            name: {
                              contains: value,
                              mode: 'insensitive',
                            },
                          },
                        ],
                      },
                    },
                  };
                }}
                getOptionLabel={(option) => option?.name}
                value={field.value}
                onChange={(e, value) => field.onChange(value)}
              />
            )}
          /> */}
          <Controller
            name="code"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Code"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <EnumSelect
            name="locationCode"
            label="Location Code"
            schema={schema}
            enumObj={BranchLocationCode}
            control={control}
            defaultValue={'' as unknown as undefined}
          />
          <EnumSelect
            name="region"
            label="Region"
            schema={schema}
            enumObj={Region}
            control={control}
            defaultValue={'' as unknown as undefined}
          />
          <Controller
            name="address"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Address"
                fullWidth
                sx={{ gridColumn: 'span 3' }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="province"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Province"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="city"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="City"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="storeCode"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Store Code"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="storeName"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Store Name"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="dateOpened"
            control={control}
            defaultValue={'' as unknown as Date}
            render={({ field, fieldState }) => (
              <TextField
                label="Date Opened"
                fullWidth
                type="date"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="dateClosed"
            control={control}
            defaultValue={'' as unknown as Date}
            render={({ field, fieldState }) => (
              <TextField
                label="Date Closed"
                fullWidth
                type="date"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="contactNumber"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Contact Number"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Email"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <EmployeeAutocomplete
            schema={schema}
            name="employee"
            control={control}
            label="Coordinator"
            defaultValue={null}
          />
          <Controller
            name="remarks"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Remarks"
                fullWidth
                sx={{ gridColumn: 'span 3' }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
        </Box>

        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ mt: 3, ...gradientButton }}
          disabled={mutationLoading}
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default ConsigneeBranchForm;
