import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  useItemQuery,
  useUpdateItemMutation,
} from '../../graphql/generated/types';
import { cleanPayload } from '../../utils/apolloClient';
import {
  getItemsForDelete,
  getItemsForUpsert,
  getUploads,
  prismaCreate,
  prismaUpdate,
} from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Loading from '../common/Loading';
import Meta from '../page/Meta';
import ItemForm from './ItemForm';
import { FormData, schema } from './utils';

const UpdateItemPage = ({ title = `Update Item`, readOnly = false }) => {
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({ photoUrls, parts, itemHistories, ...data }) => {
        const childrenToDelete = getItemsForDelete(
          item?.itemHistories,
          itemHistories
        );
        const childrenToUpsert = getItemsForUpsert(itemHistories);
        await updateItem({
          variables: {
            where: {
              id,
            },
            data: {
              ...prismaUpdate(data),
              brand: {
                connect: {
                  id: data.brand.id,
                },
              },
              itemSubcategory: {
                connect: {
                  id: data.itemSubcategory.id,
                },
              },
              namingGroup: {
                connect: {
                  id: data.namingGroup.id,
                },
              },
              supplier: {
                connect: {
                  id: data.supplier.id,
                },
              },
              parts: {
                set: parts?.map(({ internalCode }) => {
                  return { internalCode };
                }),
              },
              photoUrlsUploads: getUploads(photoUrls),
              itemHistories: {
                delete: childrenToDelete,
                upsert: childrenToUpsert.map(({ id, ...child }) => {
                  return {
                    where: {
                      id,
                    },
                    update: {
                      ...prismaUpdate(child),
                    },
                    create: {
                      ...prismaCreate(child),
                    },
                  };
                }),
              },
            },
          },
        });
      },
      (error) =>
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        })
    )(e);
  };

  const {
    data: { item } = {},
    loading,
    error,
  } = useItemQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: { id },
    },
  });

  const [updateItem, { loading: mutationLoading }] = useUpdateItemMutation({
    onError: (error) =>
      handleSnackbarOpen({
        message: capitalizeFirstLetter(title) + ' error! ' + error,
        severity: 'error',
      }),
    onCompleted: () => {
      handleSnackbarOpen({
        message: capitalizeFirstLetter(title) + ' success!',
        severity: 'success',
      });
      navigate(-1);
    },
  });

  useEffect(() => {
    if (item) {
      methods.reset({ ...cleanPayload(item) });
    }
  }, [item, methods]);

  if (loading) return <Loading />;

  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <ItemForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          readOnly={readOnly}
        />
      </FormProvider>
    </>
  );
};

export default UpdateItemPage;
