import { Checkbox } from '@mui/material';
import { ReactNode } from 'react';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  AggregateItemSubcategoryDocument,
  ItemCategory,
  ItemSubcategoriesDocument,
  ItemSubcategoryWhereInput,
  QueryMode,
  SortOrder,
  useDeleteManyItemSubcategoryMutation,
  useItemSubcategoriesQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { HandleDelete } from '../../types/HandleDelete';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { getEnumKeyFromValue } from '../../utils/stringFunctions';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import EnumSearchFilter from '../common/EnumSearchFilter';
import Pagination from '../common/Pagination';
import Search from '../common/Search';
import ListView from '../templates/ListView';

const ItemSubcategoriesPage = ({ title = 'Item Subcategories' }) => {
  const { handleSnackbarOpen } = useSnackbarContext();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();

  const [searchQueryString, itemCategoryQueryString] = useGetQueryString([
    'search',
    'itemCategory',
  ]);

  const where: ItemSubcategoryWhereInput = {
    ...(itemCategoryQueryString && {
      itemCategory: {
        equals: itemCategoryQueryString as ItemCategory,
      },
    }),
    ...(typeof searchQueryString === 'string' && {
      OR: [
        {
          name: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
      ],
    }),
  };
  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        name: SortOrder.Asc,
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { itemSubcategories } = {},
    loading,
    error,
  } = useItemSubcategoriesQuery({
    fetchPolicy: 'network-only',
    variables,
  });

  const [deleteManyItemSubcategory] = useDeleteManyItemSubcategoryMutation({
    refetchQueries: [
      { query: AggregateItemSubcategoryDocument, variables: countVariables },
      {
        query: ItemSubcategoriesDocument,
        variables,
      },
    ],
    onError: (error) =>
      handleSnackbarOpen({
        message: 'Delete' + title.toLowerCase() + ' error! ' + error,
        severity: 'error',
      }),
  });

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof itemSubcategories>>>
  > = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },
    {
      header: 'Item Category',
      accessor: 'itemCategory',
      Cell: (children: string) => getEnumKeyFromValue(ItemCategory, children),
    },
    {
      header: 'Name',
      accessor: 'name',
    },

    // {
    //   header: 'Items',
    //   accessor: 'items',
    //   Cell: (children?: Date) => dateToString(children),
    // },
  ];

  const handleDelete: HandleDelete = (ids) => {
    deleteManyItemSubcategory({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <>
      <ListView
        loading={loading}
        error={error}
        title={title}
        actionsMenu={
          <ActionsMenu
            handleDelete={handleDelete}
            selected={selected}
            setSelected={setSelected}
          />
        }
        filters={
          <>
            <EnumSearchFilter
              label="Item Cateogry"
              field="itemCategory"
              enumObj={ItemCategory}
            />
            <Search />
          </>
        }
        dataTable={<DataTable data={itemSubcategories} columns={columns} />}
        pagination={
          <Pagination
            query={AggregateItemSubcategoryDocument}
            perPage={perPage}
            page={Number(page)}
            variables={countVariables}
          />
        }
      />
    </>
  );
};

export default ItemSubcategoriesPage;
