import { CloudUpload } from '@mui/icons-material';

import { Button, Checkbox } from '@mui/material';
import { endOfDay, startOfDay } from 'date-fns';
import { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  AggregateItemPricingDocument,
  ItemPricingsDocument,
  ItemPricingWhereInput,
  QueryMode,
  SortOrder,
  useDeleteManyItemPricingMutation,
  useItemPricingsLazyQuery,
  useItemPricingsQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { HandleDelete } from '../../types/HandleDelete';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { formatMoney } from '../../utils/numberFunctions';
import { dateToString } from '../../utils/stringFunctions';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import DateRangeFilter from '../common/DateRangeFilter';
import DownloadCsvButton from '../common/DownloadCsvButton';
import Pagination from '../common/Pagination';
import RangeFilter from '../common/RangeFilter';
import Search from '../common/Search';
import ListView from '../templates/ListView';

const ItemPricingsPage = ({ title = 'Item Pricings' }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();

  const [
    startQueryString,
    endQueryString,
    searchQueryString,
    startDateQueryString,
    endDateQueryString,
  ] = useGetQueryString([
    'minPrice',
    'maxPrice',
    'search',
    'startDate',
    'endDate',
  ]);

  const where: ItemPricingWhereInput = {
    ...(typeof startDateQueryString === 'string' &&
      typeof endDateQueryString === 'string' && {
        effectivityDate: {
          gte: startOfDay(new Date(startDateQueryString)),
          lte: endOfDay(new Date(endDateQueryString)),
        },
      }),
    ...(typeof startQueryString === 'string' && {
      price: {
        gte: +startQueryString,
      },
    }),
    ...(typeof endQueryString === 'string' && {
      price: {
        lte: +endQueryString,
      },
    }),
    ...(typeof startQueryString === 'string' &&
      typeof endQueryString === 'string' && {
        price: {
          gte: +startQueryString,
          lte: +endQueryString,
        },
      }),
    ...(typeof searchQueryString === 'string' && {
      OR: [
        {
          consignee: {
            is: {
              name: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },
        {
          item: {
            is: {
              internalCode: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },
        {
          item: {
            is: {
              name: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },
        {
          smItemCategory: {
            is: {
              itemCategoryName: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },
        {
          sku: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
        {
          remarks: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
      ],
    }),
  };
  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        effectivityDate: SortOrder.Desc,
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { itemPricings } = {},
    loading,
    error,
  } = useItemPricingsQuery({
    fetchPolicy: 'network-only',
    variables,
  });

  const [
    getDownloadData,
    { data: downloadData, loading: downloadDataLoading },
  ] = useItemPricingsLazyQuery({
    variables: {
      where,
    },
  });

  const [deleteManyItemPricing] = useDeleteManyItemPricingMutation({
    refetchQueries: [
      { query: AggregateItemPricingDocument, variables: countVariables },
      {
        query: ItemPricingsDocument,
        variables,
      },
    ],
    onError: (error) =>
      handleSnackbarOpen({
        message: 'Delete' + title.toLowerCase() + ' error! ' + error,
        severity: 'error',
      }),
  });

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof itemPricings>>>
  > = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },
    {
      header: 'Consignee',
      accessor: 'consignee.name',
    },
    {
      header: 'Internal Code',
      accessor: 'item.internalCode',
    },
    {
      header: 'Item Name',
      accessor: 'item.name',
    },
    {
      header: 'Consignee Item Name',
      accessor: 'consigneeItemName',
    },
    {
      header: 'Price',
      accessor: 'price',
      Cell: (children) => formatMoney(children),
    },
    {
      header: 'SKU',
      accessor: 'sku',
    },
    {
      header: 'Effectivity Date',
      accessor: 'effectivityDate',
      Cell: (children?: Date) => dateToString(children),
    },
    {
      header: 'SM Item Category',
      accessor: 'smItemCategory.itemCategoryName',
    },
    {
      header: 'Remarks',
      accessor: 'remarks',
    },
  ];

  const handleDelete: HandleDelete = (ids) => {
    deleteManyItemPricing({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <>
      <ListView
        loading={loading}
        error={error}
        title={title}
        miscButtons={
          <>
            <DownloadCsvButton
              downloadData={downloadData?.itemPricings}
              getDownloadData={getDownloadData}
              downloadDataLoading={downloadDataLoading}
              columns={columns}
            />
            <Button
              onClick={() => {
                navigate(`${location.pathname}/upload`);
              }}
              variant="contained"
              startIcon={<CloudUpload />}
              sx={{ marginRight: 'auto' }}
            >
              Upload
            </Button>
          </>
        }
        actionsMenu={
          <ActionsMenu
            handleDelete={handleDelete}
            selected={selected}
            setSelected={setSelected}
          />
        }
        filters={
          <>
            <RangeFilter
              startParameter="minPrice"
              startLabel="Price Minimum"
              endParameter="maxPrice"
              endLabel="Price Maximum"
            />
            <DateRangeFilter />
            <Search />
          </>
        }
        dataTable={<DataTable data={itemPricings} columns={columns} />}
        pagination={
          <Pagination
            query={AggregateItemPricingDocument}
            perPage={perPage}
            page={Number(page)}
            variables={countVariables}
          />
        }
      />
    </>
  );
};

export default ItemPricingsPage;
