import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  useUpdateUserMutation,
  useUserQuery,
} from '../../graphql/generated/types';
import { cleanPayload } from '../../utils/apolloClient';
import { prismaUpdate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Loading from '../common/Loading';
import Meta from '../page/Meta';
import UserForm from './UserForm';
import { FormData, schema } from './utils';

const UpdateUserPage = () => {
  const title = `Update User`;
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: yupResolver(schema.omit(['username', 'password'])),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async ({ employee, userGroups, ...data }) =>
        await updateUser({
          variables: {
            where: {
              id,
            },
            data: {
              ...prismaUpdate(data),
              employee: {
                connect: {
                  id: employee?.id,
                },
              },
              userGroups: {
                set: userGroups || [],
              },
            },
          },
        }),
      (error) =>
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        })
    )(e);
  };

  const {
    data: { user } = {},
    loading,
    error,
  } = useUserQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: { id },
    },
  });

  const [updateUser, { loading: mutationLoading }] = useUpdateUserMutation({
    onError: (error) =>
      handleSnackbarOpen({
        message: capitalizeFirstLetter(title) + ' error! ' + error,
        severity: 'error',
      }),
    onCompleted: () => {
      handleSnackbarOpen({
        message: capitalizeFirstLetter(title) + ' success!',
        severity: 'success',
      });
      navigate(-1);
    },
  });

  useEffect(() => {
    if (user) {
      methods.reset({ ...cleanPayload(user) });
    }
  }, [user, methods]);

  if (loading) return <Loading />;

  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <UserForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
          type="update"
        />
      </FormProvider>
    </>
  );
};

export default UpdateUserPage;
