import RemoveIcon from '@mui/icons-material/Remove';
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { ContainerCapacity } from '../../graphql/enums/ContainerCapacity';
import { useItemsLazyQuery } from '../../graphql/generated/types';
import { gradientButton } from '../../styles/gradientButton';
import { nestedGrid } from '../../styles/nestedGrid';
import { FormProps } from '../../types/FormProps';
import { textFieldHelperProps } from '../../utils/reactHookForm';
import EnumSelect from '../common/EnumSelect';
import FormContainer from '../common/FormContainer';
import SupplierAutocomplete from '../shared/SupplierAutocomplete';
import { FormData } from './utils';

const PurchaseOrderForm = ({
  title = '',
  onSubmit,
  schema,
  mutationLoading,
  readOnly,
  type,
}: FormProps) => {
  const { control, setValue } = useFormContext<FormData>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'purchaseOrderItems',
  });

  const [purchaseOrderItems, containerCapacity, supplier] = useWatch({
    control,
    name: ['purchaseOrderItems', 'containerCapacity', 'supplier'],
  });

  // const containerCapacity = useWatch({
  //   control,
  //   name: 'containerCapacity',
  // });

  const grandTotalCbm =
    purchaseOrderItems?.reduce((acc, cur) => {
      if (!cur.cubicMeterPerBox || !cur.item || !cur.quantity) return acc;
      return (
        acc + cur.cubicMeterPerBox * (cur.quantity / cur.item.quantityPerBox)
      );
    }, 0) || 0;

  const remainingCbmCapacity =
    +(containerCapacity?.match(/\d+/) || 0) - grandTotalCbm;

  const handleAddPurchaseOrderItem = () => {
    append({
      item: null,
      quantity: 0,
      netWeight: 0,
      grossWeight: 0,
      cubicMeterPerBox: 0,
      remarks: '',
    });
  };

  const handleRemovePurchaseOrderItem = (index: number) => {
    remove(index);
  };

  const [getItems, { data, loading }] = useItemsLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: {
        supplier: {
          is: {
            id: {
              equals: supplier?.id,
            },
          },
        },
      },
    },
  });

  return (
    <FormContainer maxWidth="lg" readOnly={readOnly}>
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
        gutterBottom
      >
        {title}
      </Typography>
      <Box component="form" onSubmit={onSubmit} noValidate>
        <Box
          component="div"
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gap: 1,
          }}
        >
          <Controller
            name="date"
            control={control}
            defaultValue={'' as unknown as Date}
            render={({ field, fieldState }) => (
              <TextField
                label="Date"
                fullWidth
                type="date"
                InputLabelProps={{ shrink: true }}
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
          <EnumSelect
            name="containerCapacity"
            label="Container Capacity"
            schema={schema}
            enumObj={ContainerCapacity}
            control={control}
            defaultValue={'' as unknown as undefined}
          />
          <SupplierAutocomplete
            name="supplier"
            schema={schema}
            control={control}
            label="Supplier"
            defaultValue={null}
            onChange={() => {
              getItems();
            }}
          />
          <Controller
            name="notes"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                label="Notes"
                fullWidth
                {...textFieldHelperProps(field, fieldState, schema)}
                {...field}
              />
            )}
          />
        </Box>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }} gutterBottom>
          Items
        </Typography>
        <Stack direction="column" spacing={1}>
          {fields.map((field, index) => {
            const totalBoxes =
              (purchaseOrderItems &&
                purchaseOrderItems[index]?.quantity /
                  purchaseOrderItems[index]?.item?.quantityPerBox) ||
              0;
            const totalCbm =
              (purchaseOrderItems &&
                purchaseOrderItems[index]?.cubicMeterPerBox * totalBoxes) ||
              0;
            return (
              <Box
                key={field.id}
                sx={{
                  ...nestedGrid,
                }}
              >
                <Box>
                  {/* <Controller
                    name={`purchaseOrderItems.${index}.item`}
                    control={control}
                    defaultValue={null}
                    render={({ field, fieldState }) => (
                      <SearchAsYouTypeAutocomplete
                        disabled={!supplier}
                        textFieldProps={{
                          ...textFieldHelperProps(field, fieldState, schema),
                          label: `Internal Code ${index + 1}`,
                        }}
                        queryOptions={(value) => {
                          return {
                            query: ItemsDocument,
                            variables: {
                              where: {
                                supplier: {
                                  is: {
                                    id: {
                                      equals: supplier.id,
                                    },
                                  },
                                },
                                OR: [
                                  {
                                    internalCode: {
                                      contains: value,
                                      mode: 'insensitive',
                                    },
                                  },
                                ],
                              },
                            },
                          };
                        }}
                        getOptionLabel={(option: any) => option?.internalCode}
                        value={field.value}
                        onChange={(e, value) => {
                          setValue(
                            `purchaseOrderItems.${index}.netWeight`,
                            value.netWeight
                          );
                          setValue(
                            `purchaseOrderItems.${index}.grossWeight`,
                            value.grossWeight
                          );
                          setValue(
                            `purchaseOrderItems.${index}.cubicMeterPerBox`,
                            value.cubicMeterPerBox || 0
                          );
                          field.onChange(value);
                        }}
                      />
                    )}
                  /> */}
                  <Controller
                    name={`purchaseOrderItems.${index}.item`}
                    control={control}
                    defaultValue={null}
                    render={({ field, fieldState }) => (
                      <Autocomplete
                        disabled={loading || !supplier}
                        readOnly={readOnly}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            {...textFieldHelperProps(field, fieldState, schema)}
                            label={`Internal Code ${index + 1}`}
                          />
                        )}
                        options={data?.items || []}
                        getOptionLabel={(option: any) => option?.internalCode}
                        value={field.value}
                        onChange={(e, value) => {
                          setValue(
                            `purchaseOrderItems.${index}.netWeight`,
                            value.netWeight
                          );
                          setValue(
                            `purchaseOrderItems.${index}.grossWeight`,
                            value.grossWeight
                          );
                          setValue(
                            `purchaseOrderItems.${index}.cubicMeterPerBox`,
                            value.cubicMeterPerBox || 0
                          );
                          field.onChange(value);
                        }}
                      />
                    )}
                  />
                  <TextField
                    label={`Item Name ${index + 1}`}
                    fullWidth
                    value={
                      (purchaseOrderItems &&
                        purchaseOrderItems[index]?.item?.name) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    label={`Supplier's Item Code ${index + 1}`}
                    fullWidth
                    value={
                      (purchaseOrderItems &&
                        purchaseOrderItems[index]?.item?.supplierItemCode) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    label={`Supplier's Item Name ${index + 1}`}
                    fullWidth
                    value={
                      (purchaseOrderItems &&
                        purchaseOrderItems[index]?.item?.supplierItemName) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <Controller
                    name={`purchaseOrderItems.${index}.quantity`}
                    control={control}
                    defaultValue={0}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`Quantity ${index + 1}`}
                        fullWidth
                        type="number"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                      />
                    )}
                  />
                  <TextField
                    label={`Quantity / Box ${index + 1}`}
                    fullWidth
                    value={
                      (purchaseOrderItems &&
                        purchaseOrderItems[index]?.item?.quantityPerBox) ??
                      ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    label={`Total Boxes ${index + 1}`}
                    fullWidth
                    value={totalBoxes ?? ''}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <Controller
                    name={`purchaseOrderItems.${index}.netWeight`}
                    control={control}
                    defaultValue={0}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`Net Weight ${index + 1}`}
                        fullWidth
                        type="number"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name={`purchaseOrderItems.${index}.grossWeight`}
                    control={control}
                    defaultValue={0}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`Gross Weight ${index + 1}`}
                        fullWidth
                        type="number"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name={`purchaseOrderItems.${index}.cubicMeterPerBox`}
                    control={control}
                    defaultValue={0}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`CBM / Box ${index + 1}`}
                        fullWidth
                        type="number"
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                      />
                    )}
                  />
                  <TextField
                    label={`Total CBM ${index + 1}`}
                    fullWidth
                    value={totalCbm ?? ''}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <Controller
                    name={`purchaseOrderItems.${index}.remarks`}
                    control={control}
                    defaultValue=""
                    render={({ field, fieldState }) => (
                      <TextField
                        label={`Remarks ${index + 1}`}
                        fullWidth
                        {...textFieldHelperProps(field, fieldState, schema)}
                        {...field}
                      />
                    )}
                  />
                  <Box
                    component="img"
                    src={
                      (purchaseOrderItems &&
                        purchaseOrderItems[index]?.item?.photoUrls?.[0]) ||
                      undefined
                    }
                    sx={{
                      width: '100%',
                      height: '100%',
                      display: purchaseOrderItems?.[index]?.item?.photoUrls?.[0]
                        ? 'block'
                        : 'none',
                    }}
                    alt={
                      purchaseOrderItems &&
                      purchaseOrderItems[index]?.item?.name
                    }
                  />
                </Box>
                <IconButton
                  aria-label="delete"
                  color="primary"
                  size="small"
                  onClick={() => handleRemovePurchaseOrderItem(index)}
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            );
          })}
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            sx={{ my: 1 }}
            type="button"
            variant="contained"
            color="primary"
            onClick={handleAddPurchaseOrderItem}
            size="small"
          >
            Add
          </Button>
        </Stack>
        {type !== 'print' && (
          <>
            <Typography variant="h6" align="right">
              Grand Total CBM: {grandTotalCbm}
            </Typography>
            <Typography variant="h6" align="right">
              Remaining CBM Capacity: {remainingCbmCapacity}
            </Typography>
          </>
        )}
        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ mt: 3, ...gradientButton }}
          disabled={mutationLoading}
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default PurchaseOrderForm;
