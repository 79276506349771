import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';
interface Props {
  children?: ReactNode;
}

const StyledDrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const DrawerHeader = ({ children }: Props) => {
  return <StyledDrawerHeader>{children}</StyledDrawerHeader>;
};

export default DrawerHeader;
