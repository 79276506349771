import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { date, InferType, number, object, string } from 'yup';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  useItemPricingQuery,
  useUpdateItemPricingMutation,
} from '../../graphql/generated/types';
import { cleanPayload } from '../../utils/apolloClient';
import { prismaUpdate } from '../../utils/prisma';
import { capitalizeFirstLetter } from '../../utils/stringFunctions';
import Loading from '../common/Loading';
import Meta from '../page/Meta';
import UpdateItemPricingForm from './UpdateItemPricingForm';

export const schema = object({
  consignee: object().nullable().default(null).required(),
  item: object().nullable().default(null).required(),
  consigneeItemName: string().required(),
  effectivityDate: date().required(),
  price: number().required(),
  remarks: string().nullable(),
  sku: string().nullable(),
  smItemCategory: object().nullable().default(null),
}).required();

export type FormData = InferType<typeof schema>;

const UpdateItemPricingPage = () => {
  const title = `Update Item Pricing`;
  const { id: idParam } = useParams();
  const id = parseInt(idParam || '');
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbarContext();
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    methods.handleSubmit(
      async (data) => {
        await updateItemPricing({
          variables: {
            where: {
              id,
            },
            data: {
              ...prismaUpdate(data),
              consignee: {
                connect: {
                  id: data?.consignee.id,
                },
              },
              item: {
                connect: {
                  id: data?.item.id,
                },
              },
              smItemCategory: data?.smItemCategory?.id
                ? {
                    connect: {
                      id: data?.smItemCategory?.id,
                    },
                  }
                : {
                    disconnect: true,
                  },
            },
          },
        });
      },
      (error) =>
        handleSnackbarOpen({
          message: 'Form validation error!',
          severity: 'error',
        })
    )(e);
  };

  const {
    data: { itemPricing } = {},
    loading,
    error,
  } = useItemPricingQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: { id },
    },
  });

  const [updateItemPricing, { loading: mutationLoading }] =
    useUpdateItemPricingMutation({
      onError: (error) =>
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' error! ' + error,
          severity: 'error',
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: capitalizeFirstLetter(title) + ' success!',
          severity: 'success',
        });
        navigate(-1);
      },
    });

  useEffect(() => {
    if (itemPricing) {
      methods.reset({ ...cleanPayload(itemPricing) });
    }
  }, [itemPricing, methods]);

  if (loading) return <Loading />;

  return (
    <>
      <Meta pageTitle={title} />
      <FormProvider {...methods}>
        <UpdateItemPricingForm
          title={title}
          onSubmit={onSubmit}
          schema={schema}
          mutationLoading={mutationLoading}
        />
      </FormProvider>
    </>
  );
};

export default UpdateItemPricingPage;
