import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { stringToUrl } from '../../utils/stringFunctions';

interface Props {
  handleDrawerClose: (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const navLinks = [
  {
    groupName: 'General',
    routes: [
      {
        name: 'Users',
      },
      {
        name: 'Audit Trails',
      },
    ],
  },
  {
    groupName: 'Masterlist Information',
    routes: [
      {
        name: 'Suppliers',
      },
      {
        name: 'Item Subcategories',
      },
      {
        name: 'Naming Groups',
      },
      {
        name: 'Brands',
      },
      {
        name: 'SM Item Categories',
      },
      {
        name: 'Items',
      },
      {
        name: 'Item Pricings',
      },
    ],
  },
  {
    groupName: 'Purchasing',
    routes: [
      {
        name: 'Purchase Orders',
      },
      {
        name: 'Proforma Invoices',
      },
      {
        name: 'Invoice Payments',
      },
      {
        name: 'Packing Lists',
      },
      {
        name: 'BOC Requirements',
      },
      {
        name: 'Shipment Reports',
      },
      {
        name: 'Missing Items',
      },
      {
        name: 'Put to Units',
      },
      {
        name: 'Shipment Pre-costings',
      },
      {
        name: 'Shipment Costs',
      },
      {
        name: 'PI Pending',
        path: 'pending-proforma-invoices',
      },
      {
        name: 'PI Payable',
        path: 'payable-proforma-invoices',
      },
      {
        name: 'PL Pending',
        path: 'pending-packing-lists',
      },
      {
        name: 'Pending Orders',
      },
      {
        name: 'Shipment Summary',
      },
      {
        name: 'Shipment Issues Tracker',
      },
      {
        name: 'Cost Monitoring',
      },
      {
        name: 'SKU Price List',
      },
    ],
  },
  {
    groupName: 'Sales',
    routes: [
      {
        name: 'Consignees',
      },
      {
        name: 'Consignee Branches',
      },
    ],
  },
  {
    groupName: 'Human Resources',
    routes: [
      {
        name: 'Employees',
      },
    ],
  },
];

const Nav = ({ handleDrawerClose }: Props) => {
  const navigate = useNavigate();
  return (
    <>
      <Box onClick={handleDrawerClose} onKeyDown={handleDrawerClose}>
        <List component="nav">
          {navLinks.map((item, idx) => (
            <div key={idx}>
              <ListSubheader
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.main,
                  color: (theme) => theme.palette.common.white,
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                }}
              >
                {item.groupName}
              </ListSubheader>
              {item.routes.map((route, idx) => (
                <ListItemButton
                  key={idx}
                  onClick={() =>
                    route?.path
                      ? navigate(route.path)
                      : navigate(stringToUrl(route.name))
                  }
                >
                  <ListItemText disableTypography inset={false}>
                    {route.name}
                  </ListItemText>
                </ListItemButton>
              ))}
            </div>
          ))}
        </List>
      </Box>
    </>
  );
};

export default Nav;
