import React from 'react';
import { NumericFormat } from 'react-number-format';

interface NumberFormatProps {
  inputRef: React.RefObject<HTMLInputElement>;
  onChange: (event: { target: { value: string } }) => void;
  name: string;
  decimalScale?: number;
}

export const NumberFormat = React.forwardRef<
  HTMLInputElement,
  NumberFormatProps
>((props, ref) => {
  const { inputRef, onChange, decimalScale = 2, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      decimalScale={decimalScale}
      fixedDecimalScale
    />
  );
});
