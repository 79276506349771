import { Checkbox, Link } from '@mui/material';
import { endOfDay, startOfDay } from 'date-fns';
import { ReactNode } from 'react';
import { useSnackbarContext } from '../../contexts/snackbarContext';
import {
  AggregateShipmentReportDocument,
  QueryMode,
  ShipmentReportContainerType,
  ShipmentReportDestination,
  ShipmentReportsDocument,
  ShipmentReportsQuery,
  ShipmentReportWhereInput,
  SortOrder,
  useDeleteManyShipmentReportMutation,
  useShipmentReportsLazyQuery,
  useShipmentReportsQuery,
} from '../../graphql/generated/types';
import useGetQueryString from '../../hooks/useGetQueryString';
import usePagination from '../../hooks/usePagination';
import { useSelectCheckbox } from '../../hooks/useSelectCheckbox';
import { Columns } from '../../types/Columns';
import { HandleDelete } from '../../types/HandleDelete';
import { ObjectFromArray, Paths } from '../../types/UtilityTypes';
import { roundFloat } from '../../utils/numberFunctions';
import { dateToString, getEnumKeyFromValue } from '../../utils/stringFunctions';
import ActionsMenu from '../common/ActionsMenu';
import DataTable from '../common/DataTable';
import DataTableAccordion from '../common/DataTableAccordion';
import DateRangeFilter from '../common/DateRangeFilter';
import DownloadCsvButton from '../common/DownloadCsvButton';
import Pagination from '../common/Pagination';
import PrintMenuItem from '../common/PrintMenuItem';
import Search from '../common/Search';
import ListView from '../templates/ListView';

export const shipmentReportItemsColumns: Columns<
  Paths<
    ObjectFromArray<
      ObjectFromArray<
        NonNullable<ShipmentReportsQuery['shipmentReports']>
      >['shipmentReportItems']
    >
  >
> = [
  {
    header: 'Internal Code',
    accessor: 'item.internalCode',
  },
  {
    header: 'Item Name',
    accessor: 'item.name',
  },
  {
    header: 'Boxes Received',
    accessor: 'numberOfBoxesReceived',
    Cell: (children: number) => roundFloat(children),
  },
  {
    header: 'Quantity Per Box',
    accessor: 'quantityPerBox',
    Cell: (children: number) => roundFloat(children),
  },
  {
    header: 'Subtotal',
    accessor: 'subtotal',
    Cell: (children: number) => roundFloat(children),
  },
  {
    header: 'Incomplete',
    accessor: 'incompleteQuantity',
    Cell: (children: number) => roundFloat(children),
  },
  {
    header: 'Damaged',
    accessor: 'damagedQuantity',
    Cell: (children: number) => roundFloat(children),
  },
  {
    header: 'Good',
    accessor: 'goodQuantity',
    Cell: (children: number) => roundFloat(children),
  },
  {
    header: 'Put to Unit',
    accessor: 'putToUnitsTotalQuantity',
    Cell: (children: number) => roundFloat(children),
  },
  {
    header: 'Final Quantity',
    accessor: 'finalQuantity',
    Cell: (children: number) => roundFloat(children),
  },
  {
    header: 'Remarks',
    accessor: 'remarks',
  },
];

export const missingItemsColumns: Columns<
  Paths<
    ObjectFromArray<
      ObjectFromArray<
        NonNullable<ShipmentReportsQuery['shipmentReports']>
      >['missingItems']
    >
  >
> = [
  {
    header: 'Part Internal Code',
    accessor: 'part.internalCode',
  },
  {
    header: 'Part Item Name',
    accessor: 'part.name',
  },
  {
    header: 'Mother Unit Item Name',
    accessor: 'motherUnit.name',
  },

  {
    header: `Quantity`,
    accessor: 'quantity',
    Cell: (children: number) => roundFloat(children),
  },

  {
    header: `Remarks`,
    accessor: 'remarks',
  },
];

export const putToUnitItemsColumns: Columns<
  Paths<
    ObjectFromArray<
      ObjectFromArray<
        NonNullable<ShipmentReportsQuery['shipmentReports']>
      >['putToUnits']
    >
  >
> = [
  {
    header: 'Transaction No.',
    accessor: 'missingItem.shipmentReport.transactionNumber',
  },
  {
    header: 'Part Internal Code',
    accessor: 'missingItem.part.internalCode',
  },
  {
    header: 'Part Item Name',
    accessor: 'missingItem.part.name',
  },
  {
    header: 'Mother Unit Item Name',
    accessor: 'missingItem.motherUnit.name',
  },
  {
    header: `Lacking Quantity`,
    accessor: 'lackingQuantity',
    Cell: (children: number) => roundFloat(children),
  },
  {
    header: `Quantity`,
    accessor: 'quantity',
    Cell: (children: number) => roundFloat(children),
  },
  {
    header: `Remarks`,
    accessor: 'remarks',
  },
];
const ShipmentReportsPage = ({ title = 'Shipment Reports' }) => {
  const { handleSnackbarOpen } = useSnackbarContext();
  const { selected, setSelected, isSelected, handleSelect } =
    useSelectCheckbox();
  const { page, perPage } = usePagination();
  const [searchQueryString, startDateQueryString, endDateQueryString] =
    useGetQueryString(['search', 'startDate', 'endDate']);

  const where: ShipmentReportWhereInput = {
    ...(typeof startDateQueryString === 'string' &&
      typeof endDateQueryString === 'string' && {
        dateReceived: {
          gte: startOfDay(new Date(startDateQueryString)),
          lte: endOfDay(new Date(endDateQueryString)),
        },
      }),
    ...(typeof searchQueryString === 'string' && {
      OR: [
        {
          transactionNumber: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },

        {
          createdBy: {
            contains: searchQueryString,
            mode: QueryMode.Insensitive,
          },
        },
        {
          supplier: {
            is: {
              name: {
                contains: searchQueryString,
                mode: QueryMode.Insensitive,
              },
            },
          },
        },

        {
          shipmentReportItems: {
            some: {
              item: {
                is: {
                  name: {
                    contains: searchQueryString,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
            },
          },
        },
      ],
    }),
  };
  const variables = {
    where,
    skip: page * perPage - perPage,
    take: perPage,
    orderBy: [
      {
        dateReceived: SortOrder.Desc,
      },
    ],
  };
  const countVariables = {
    where,
  };
  const {
    data: { shipmentReports } = {},
    loading,
    error,
  } = useShipmentReportsQuery({
    fetchPolicy: 'network-only',
    variables,
  });
  const [
    getDownloadData,
    { data: downloadData, loading: downloadDataLoading },
  ] = useShipmentReportsLazyQuery({
    variables: {
      where,
    },
  });
  const [deleteManyShipmentReport] = useDeleteManyShipmentReportMutation({
    refetchQueries: [
      {
        query: AggregateShipmentReportDocument,
        variables: countVariables,
      },
      {
        query: ShipmentReportsDocument,
        variables,
      },
    ],
    onError: (error) =>
      handleSnackbarOpen({
        message: 'Delete' + title.toLowerCase() + ' error! ' + error,
        severity: 'error',
      }),
  });

  const columns: Columns<
    Paths<ObjectFromArray<NonNullable<typeof shipmentReports>>>
  > = [
    {
      header: selected.length > 0 ? `${selected.length} selected` : 'Select',
      accessor: 'id',
      Cell: (children: ReactNode) =>
        children && (
          <Checkbox
            color="primary"
            checked={isSelected(+children)}
            onClick={() => handleSelect(+children)}
          />
        ),
    },
    {
      header: 'Transaction Number',
      accessor: 'transactionNumber',
    },
    {
      header: 'Date Received',
      accessor: 'dateReceived',
      Cell: (children?: Date) => dateToString(children),
    },

    {
      header: 'Supplier',
      accessor: 'supplier.name',
    },
    {
      header: 'Container Type',
      accessor: 'shipmentReportContainerType',
      Cell: (children: string) =>
        getEnumKeyFromValue(ShipmentReportContainerType, children),
    },
    {
      header: 'No. of Boxes',
      accessor: 'numberOfBoxesReceived',
    },
    {
      header: 'Destination',
      accessor: 'shipmentReportDestination',
      Cell: (children: string) =>
        getEnumKeyFromValue(ShipmentReportDestination, children),
    },
    {
      header: 'Created By',
      accessor: 'createdBy',
    },
    {
      header: 'Checked By',
      accessor: 'checkedBy',
    },
    {
      header: 'Documents',
      accessor: 'documentUrls',
      Cell: (children: string[]) =>
        children.map((url, idx) => (
          <Link
            key={idx}
            target="_blank"
            rel="noopener noreferrer"
            href={url}
            sx={{ ':not(:last-child)::after': { content: `", "` } }}
          >{`Document ${idx + 1}`}</Link>
        )),
    },

    {
      header: 'Items',
      accessor: 'shipmentReportItems',
      Cell: (children: {}[], index: number) => {
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={shipmentReportItemsColumns}
          />
        );
      },
    },
    {
      header: 'Incomplete',
      accessor: 'missingItems',
      Cell: (children: {}[], index: number) => {
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={missingItemsColumns}
          />
        );
      },
    },
    {
      header: 'Put to Unit',
      accessor: 'putToUnits',
      Cell: (children: {}[], index: number) => {
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={putToUnitItemsColumns}
          />
        );
      },
    },
  ];

  const handleDelete: HandleDelete = (ids) => {
    deleteManyShipmentReport({
      variables: {
        where: ids.map((id) => {
          return { id };
        }),
      },
    });
  };

  return (
    <>
      <ListView
        loading={loading}
        error={error}
        title={title}
        miscButtons={
          <DownloadCsvButton
            downloadData={downloadData?.shipmentReports.reduce(
              (acc: any, { shipmentReportItems, ...cur }: any) => {
                if (shipmentReportItems.length > 0) {
                  return [
                    ...acc,
                    ...(shipmentReportItems || [])?.map(
                      (shipmentReportItem: any) => {
                        return { ...shipmentReportItem, ...cur };
                      }
                    ),
                  ];
                } else {
                  return [...acc, { ...cur }];
                }
              },
              []
            )}
            getDownloadData={getDownloadData}
            downloadDataLoading={downloadDataLoading}
            columns={[...columns, ...shipmentReportItemsColumns].filter(
              ({ accessor }) => accessor !== 'shipmentReportItems'
            )}
          />
        }
        actionsMenu={
          <ActionsMenu
            handleDelete={handleDelete}
            selected={selected}
            setSelected={setSelected}
          >
            <PrintMenuItem selected={selected} />
          </ActionsMenu>
        }
        filters={
          <>
            <DateRangeFilter />
            <Search />
          </>
        }
        dataTable={<DataTable data={shipmentReports} columns={columns} />}
        pagination={
          <Pagination
            query={AggregateShipmentReportDocument}
            perPage={perPage}
            page={Number(page)}
            variables={countVariables}
          />
        }
      />
    </>
  );
};

export default ShipmentReportsPage;
