import { PopperPlacementType } from '@mui/material';
import { useState } from 'react';

const usePopper = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState<PopperPlacementType>();

  const handleClick =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen((prevState) => !prevState);
      setPlacement(newPlacement);
    };

  return { open, setOpen, anchorEl, placement, handleClick };
};

export default usePopper;
