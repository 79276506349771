import { InferType, object, string } from 'yup';


export const schema = object({
  departmentCode: string().required(),
  itemCategoryCode: string().required(),
  itemCategoryName: string().required(),
  remarks: string().nullable(),
}).required();

export type FormData = InferType<typeof schema>